import React from "react";
import apiContext from "../../api";
import { Component } from "react";

import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../state/ducks/loading";
import Card from "../../components/Card/Card";
import Modal from "../../components/Modal/Modal";
import CondominioService from "../../services/condominio";
import ClienteService from "../../services/cliente";
import Search from "../../components/Search/Search";
import { IconHome } from "../../components/Icons/Icons";
import TicketService from "../../services/ticket";
import moment from "moment";

class selectAddressList extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      addressList: [],
      selectedAddress: "",
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
      isDenied: false,
      outdatedTickets: [],
      verifyStatus: [],
      complementaryData: [],
    };
  }

  componentDidMount() {
    const { CPFCNPJ } = ClienteService.localEntrega || {};
    const { endereco } = CondominioService.selected || {};
    const dataFim = moment().startOf("month").format("YYYY/MM/DD");
    const dataIni = moment()
      .subtract(1, "year")
      .endOf("month")
      .format("YYYY/MM/DD");

    this.props.showLoading();
    this.context.ConsigazApi.consultaLocalEntrega(CPFCNPJ)
      .then(ClienteService.consultaLocalEntregaApiParser)
      .then(({ Cliente }) => {
        if (Cliente) {
          this.props.dismissLoading();
          this.setState({
            addressList: Cliente.ClienteRetorno[0].ClienteEntrRetorno,
          });
        }
      })
      .catch((errorDescription) => {
        this.props.dismissLoading();
        this.setState({}, () => this.props.showToast("Ops!", errorDescription));
      });

    this.context.ConsigazApi.memConsultaNotaFiscal(
      CPFCNPJ,
      dataIni,
      dataFim
    ).then(({ Notas }) => {
      if (Notas && Notas.NotasFiscais && Notas.NotasFiscais.length) {
        const tickets = Notas.NotasFiscais.filter((ticket) => {
          if (endereco) return ticket["endereco"] === endereco;
          return true;
        });
        if (tickets && tickets.length) {
          this.setState({
            outdatedTickets: tickets.filter(
              (i) => TicketService.getTicketStatus(i) === "outdated"
            ),
          });
        }
      }
    });
  }

  isSelected = (address) =>
    address["cod-condominio"] ===
      this.state.selectedCondominio["cod-condominio"] &&
    address["local-entrega"] === this.state.selectedCondominio["local-entrega"];

  iscomplementaryData = (address) => {
    const { complementaryData } = this.state;
    const { addressList } = this.state;
    if (
      (complementaryData.indexOf(address) > -1 &&
        complementaryData.length !== addressList.length) ||
      (address === "all" && complementaryData.length === addressList.length)
    )
      return true;
  };

  getAddressStyleName = (address) => {
    const { cardClass } = this.props;

    if (
      (cardClass === "service" && this.iscomplementaryData(address)) ||
      (cardClass !== "service" && this.isSelected(address))
    )
      return "primary";
    else return "ontlineSecondary";
  };

  selectCondominio = (address, qntd) => {
    const { complementaryData, outdatedTickets } = this.state;
    const { cardClass, service } = this.props;
    const verifyStatus =
      cardClass !== "service"
        ? outdatedTickets &&
          outdatedTickets.filter(
            (i) => address["endereco"].indexOf(i["endereco"]) > -1
          )
        : {};

    if (cardClass !== "service") {
      CondominioService.selected = address;
      this.setState({
        complementaryData: {
          ...complementaryData,
          address: address,
        },
        selectedCondominio: address,
      });
    } else if (qntd !== "all") {
      address["idservice"] = service.service;
      address["price"] = service.price;
      address["codMotivo"] = service.codMotivo;
      address["nrAcordoComercial"] = service.nrAcordoComercial;
      if (complementaryData.indexOf(address) === -1)
        this.setState({
          complementaryData: [...complementaryData, address],
        });
      else
        this.setState({
          complementaryData: complementaryData.filter((un) => un !== address),
        });
    } else {
      address &&
        address
          .filter((addr) => addr.situacao !== 2)
          .map((address) => {
            address["idservice"] = service.service;
            address["price"] = service.price;
            address["codMotivo"] = service.codMotivo;
            address["nrAcordoComercial"] = service.nrAcordoComercial;
            return address;
          });
      if (complementaryData.length === 0)
        this.setState({
          complementaryData: address,
        });
      if (complementaryData.indexOf(address) > -1)
        this.setState({
          complementaryData: [],
        });
      else complementaryData.push(address);
    }
    if (
      cardClass !== "service" &&
      (address.situacao === 2 || (address.credito || '').toLowerCase() === 'suspenso')
    )
      this.modalDenied && this.modalDenied.open();

    return verifyStatus;
  };

  onSubmit = () => {
    const { onSubmit, cardClass } = this.props;
    const {
      complementaryData: { address },
      complementaryData,
    } = this.state;
    if (
      cardClass !== "service" &&
      (address.situacao === 2 || (address.credito || '').toLowerCase() === 'suspenso')
    ) {
      this.modalDenied && this.modalDenied.close();
    } else if (cardClass !== "service") {
      onSubmit && onSubmit(address);
    } else {
      onSubmit && onSubmit(complementaryData);
    }
  };

  render() {
    const {
      addressList,
      selectedAddress,
      selectedCondominio,
    } = this.state;
    const { cardClass } = this.props;
    const { segmento } = ClienteService.localEntrega;

    const addressFilter = addressList.filter((address) => {
      if (selectedCondominio === "all") return address["situacao"] !== 2;
      if (selectedAddress !== "")
        return address["endereco"].indexOf(selectedAddress.toUpperCase()) > -1;
      else return address;
    });

    return (
      <>
        <br />
        <h2 className="service-detail-title">Meus locais de entrega</h2>
        <p style={{ textAlign: "left" }}>
          Selecione um endereço para efetuar a contratação do serviço.
        </p>
        <Search
          placeholder="Buscar endereço"
          className="block no-margin"
          value={selectedAddress}
          onChange={(e) => this.setState({ selectedAddress: e.target.value })}
        ></Search>
        <div
          className="services-container"
          style={{ display: "block", maxHeight: "calc(50vh - 220px)" }}
        >
          {segmento === "INDUSTRIAL" && cardClass === "service" && (
            <Card
              className={this.isSelected("all") ? "" : "no-shadow"}
              styleName={this.getAddressStyleName("all")}
              onClick={() => {
                this.selectCondominio(addressList, "all");
              }}
            >
              <div style={{ display: "flex" }}>
                <IconHome size={40} />
                <div>
                  <p>Selecionar TODOS os meus endereços</p>
                </div>
              </div>
            </Card>
          )}
          {addressFilter &&
            addressFilter.map((address) => (
              <Card
                key={`address-${address["cod-condominio"]}-${address["local-entrega"]}`}
                className={this.isSelected(address) ? "" : "no-shadow"}
                styleName={this.getAddressStyleName(address)}
                onClick={() => {
                  this.selectCondominio(address);
                }}
                style={{ height: 100 }}
              >
                <div style={{ display: "flex" }}>
                  <IconHome size={30} style={{ marginRight: 20 }} />
                  <div>
                    <p className="title">{address["nome-condominio"]}</p>
                    <p>{address.endereco}</p>
                    <p>{`${address.cidade} / ${address.estado}`}</p>
                  </div>
                </div>
              </Card>
            ))}
        </div>
        <footer className="service-detail-footer">
          <button
            className="block"
            onClick={this.onSubmit}
            disabled={selectedCondominio === "all" && cardClass !== "service"}
          >
            Avançar
          </button>
        </footer>

        <Modal
          ref={(ref) => (this.modalDenied = ref)}
          shown={false}
          title="Fique Atento!"
          confirmButton={true}
          confirmButtonText="OK"
          confirmButtonAction={() => {
            this.onSubmit();
          }}
        >
          <br />
          {selectedCondominio["situacao"] !== 2
            ? "Cadastro bloqueado para abastecimento. Para restabelecer o fornecimento, efetue o pagamento das faturas atrasadas."
            : "Este local de entrega está bloqueado para abastecimento. Para restabelecer o fornecimento, clique aqui e solicite a reativação do seu cadastro."}
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(selectAddressList);
