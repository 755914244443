import React, { Component } from 'react'

import ClienteService from '../../services/cliente'

import Page, { redirect } from '../../components/Page/Page'
import PageCenterContent from '../../components/PageCenterContent/PageCenterContent'

import apiContext from '../../api'

import BackgroundImage from '../../assets/background.jpg'
import { validateCpfCnpj, maskCpfCnpj } from '../../components/Masks/Masks'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toastOperations } from '../../state/ducks/toast'
import { loadingOperations } from '../../state/ducks/loading'

class FakeLogin extends Component {

  static contextType = apiContext

  constructor(props) {
    super(props)

    this.state = {
      haveSubmited: false,
      cpfcnpj: '',
      isCpfcnpjValid: false,
      codCliente: '',
      isCodClienteValid: false,
    }

    if (ClienteService.localEntrega !== undefined)
      redirect(props, '/home')
  }

  handleCpfcnpjChange = e => {
    const maskedCpfcnpj = maskCpfCnpj(e.target.value)

    this.setState({
      cpfcnpj: maskedCpfcnpj,
      isCpfcnpjValid: validateCpfCnpj(maskedCpfcnpj)
    })
  }

  onSubmit = () => {
    if (this.state.isCpfcnpjValid) {
      const cleanCpfcnpj = this.state.cpfcnpj.replace(/[^\d]+/g,'')
      this.props.showLoading()
      this.context.ConsigazApi
        .consultaLocalEntrega(cleanCpfcnpj)
        .then(
          ({ Cliente }) => {
            this.props.dismissLoading()
            if (Cliente && Cliente.ClienteRetorno && Cliente.ClienteRetorno.length)
                if (this.state.codCliente) {
                    if (parseInt(this.state.codCliente) === Cliente.ClienteRetorno[0]['cod-emitente']) {
                        ClienteService.localEntrega = {
                            ...Cliente.ClienteRetorno[0],
                            isAdmLogin: true,
                        }
                        redirect(this.props, '/home')
                    }
                    else {
                        // The code provided is incorrect
                        this.setState({
                            haveSubmited: true,
                            isCodClienteValid: false
                        }, () => this.props.showToast('Ops', 'Código cliente inválido'))
                    }
                } else {
                    // No code provided
                    this.setState({
                        haveSubmited: true,
                        isCodClienteValid: false
                    }, () => this.props.showToast('Ops', 'Você precisa informar um código de cliente'))
                }

            //   if (Cliente.ClienteRetorno[0].segmento === "CONTASIM")
            //     redirect(this.props, '/', { cliente: Cliente.ClienteRetorno[0] })
            //   else
            //     this.props.showToast("Em breve!", "Estamos preparando uma novidade pra você e em breve ela estará disponível aqui! Você poderá consultar suas faturas e entregas, contratar serviços e muito mais!", 10000)
          },
          () => {
             this.props.showToast('Ops', `Esse cpf/cnpj não pertence a nenhum cliente Consigaz`)
           }
        )

    }
    this.setState({
      haveSubmited: true,
      isCodClienteValid: true
    })
  }

  render() {
    return (
      <Page
        history={this.props.history}
        style={{
          background: `var(--dark-blue) url(${BackgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundBlendMode: 'multiply'
        }}>
        <PageCenterContent>
          <h1>Simule</h1>
          <p>Digite abaixo o CPF e código de cliente que deseja fazer a simulação.</p>

          <br />
          <br />

          <input
            type="text"
            maxLength='18'
            className={`block ${!this.state.isCpfcnpjValid && this.state.haveSubmited ? 'error' : ''}`}
            placeholder="Digite o CPF do usuário"
            value={this.state.cpfcnpj}
            onChange={this.handleCpfcnpjChange}
            onKeyPress={e => e.charCode === 13 && this.onSubmit()}/>

          <br />

          <input
            type="text"
            className={`block ${!this.state.isCodClienteValid && this.state.haveSubmited ? 'error' : ''}`}
            placeholder="Digite o código do cliente"
            value={this.state.codCliente}
            onChange={e => this.setState({ codCliente: (e.target.value.match(/\d+/g) || []).join('') })}
            onKeyPress={e => e.charCode === 13 && this.onSubmit()}/>

          <br />

          <button
            type="submit"
            className="block"
            onClick={this.onSubmit}>
            Avançar
          </button>
        </PageCenterContent>
      </Page>
    )
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    showToast: toastOperations.show,
    showLoading: loadingOperations.show,
    dismissLoading: loadingOperations.dismiss
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(FakeLogin)