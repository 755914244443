import React, { Component } from 'react'
import FileSaver from 'file-saver'

import './PdfPage.scss'

import Page from '../Page/Page'
import { LoadingToast } from '../Toast/Toast'

export default class PdfPage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      params: {}
    }

    const querystring = this.props.location && this.props.location.search

    if (querystring)
      Object.assign(this.state, {
        params: decodeURI(querystring).replace("?", "").split("&").reduce((obj, param) => {
          const [ key, value ] = param.split('=')
          obj[key] = value
          return obj
        }, {})
      })
  }

  get fileName() {
    return 'report'
  }

  donwloadPdf() {
    const { file } = this.state

    const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    }

    var data = new Blob([b64toBlob(file, 'application/pdf')], { type: 'application/pdf' })
    FileSaver.saveAs(data, this.fileName)

    return null
  }

  render() {
    const { file, params } = this.state
    if (file && params.download)
      return this.donwloadPdf()
    else if (file)
      return (
        <iframe
          title='pdf'
          src={`data:application/pdf;base64,${file}`}
          frameborder="0"
          style={{
            border: '0',
            top: '0px',
            left: '0px',
            bottom: '0px',
            right: '0px',
            width: '100vw',
            height: '100vh'
          }}
          allowfullscreen />
      )
    else
      return (
        <Page>
          <LoadingToast />
        </Page>
      )
  }

}