import { redirect } from "../components/Page/Page";

export default [
  {
    title: "Serviços",
    subtitle: "Serviços",
    related: ["serviço", "solicitação", "solicitar", "contratar"],
    action: (props) => redirect(props, "/home-services"),
  },
  {
    title: "Vazamento",
    subtitle: "Serviços",
    related: ["vazamento"],
    action: (props) => redirect(props, "/home-services"),
  },
  {
    title: "Regulagem de pressão",
    subtitle: "Serviços",
    related: ["regulagem", "pressao"],
    action: (props) => redirect(props, "/home-services"),
  },
  {
    title: "Teste de estanqueidade",
    subtitle: "Serviços",
    related: ["teste", "estanqueidade"],
    action: (props) => redirect(props, "/home-services"),
  },
  {
    title: "Troca de medidor",
    subtitle: "Serviços",
    related: ["trocar", "medidores"],
    segmento: "CONTASIM",
    action: (props) => redirect(props, "/home-services"),
  },
  {
    title: "Religue",
    subtitle: "Serviços",
    related: ["religar"],
    segmento: "CONTASIM",
    action: (props) => redirect(props, "/home-services"),
  },
  {
    title: "Laudo Técnico + ART",
    subtitle: "Serviços",
    related: ["laudo", "tecnico", "art"],
    segmento: "INDUSTRIAL",
    action: (props) => redirect(props, "/home-services"),
  },
  {
    title: "Alteração no prazo de pagamento",
    subtitle: "Serviços",
    related: ["alteraçao", "prazo de pagamento"],
    segmento: "INDUSTRIAL",
    action: (props) => redirect(props, "/home-services"),
  },
  {
    title: "Fale com seu representante",
    subtitle: "Serviços",
    related: ["representante"],
    segmento: "INDUSTRIAL",
    action: (props) => redirect(props, "/home-services"),
  },
  {
    title: "Alterações cadastrais",
    subtitle: "Serviços",
    related: ["alterações", "alterar", "cadastrais", "cadastro"],
    segmento: "INDUSTRIAL",
    action: (props) => redirect(props, "/home-services"),
  },
  {
    title: "Segunda via de contrato",
    subtitle: "Serviços",
    related: ["segunda via", "contrato"],
    segmento: "INDUSTRIAL",
    action: (props) => redirect(props, "/home-services"),
  },
  {
    title: "Aviso de Férias Coletivas",
    subtitle: "Entregas",
    related: ["ferias coletivas", "aviso"],
    segmento: "INDUSTRIAL",
    action: (props) => redirect(props, "/home-delivery"),
  },
  {
    title: "Solicitar Abastecimento",
    subtitle: "Entregas",
    related: ["solicitar", "solicitação", "abastecimento", "abastecer"],
    segmento: "INDUSTRIAL",
    action: (props) => redirect(props, "/home-delivery"),
  },
  {
    title: "Solicitação e Alteração de programação",
    subtitle: "Entregas",
    related: ["solicitar", "programacao", "alterar"],
    segmento: "INDUSTRIAL",
    action: (props) => redirect(props, "/home-delivery"),
  },
  {
    title: "Protocolos",
    subtitle: "Serviços",
    related: ["serviços", "protocolos"],
    action: (props) => redirect(props, "/home-services", { tab: "protocol" }),
  },
  {
    title: "Consumo",
    subtitle: "Consumo",
    related: ["consumo", "gráfico"],
    action: (props) => redirect(props, "/consumption"),
  },
  {
    title: "Financeiro",
    subtitle: "Financeiro",
    related: ["financeiro", "fatura", "nota fiscal", "boleto"],
    action: (props) => redirect(props, "/finances"),
  },
  {
    title: "Carta de quitação anual",
    subtitle: "Financeiro",
    related: ["carta", "quitação", "carta de quitação"],
    action: (props) =>
      redirect(props, "/finances", { tab: "acquittance-letter" }),
  },
  {
    title: "Carta de quitação total",
    subtitle: "Financeiro",
    related: ["carta", "quitação", "carta de quitação"],
    action: (props) =>
      redirect(props, "/finances", { tab: "acquittance-letter" }),
  },
  {
    title: "Meus Imóveis",
    subtitle: "Imóveis",
    related: ["imóvel", "imóveis", "endereço"],
    action: (props) => redirect(props, "/address-list"),
  },
  {
    title: "Cancelamento de cadastro",
    subtitle: "Imóveis",
    related: ["imóvel", "imóveis", "endereço", "cancelar", "cancelamento"],
    action: (props) => redirect(props, "/address-list"),
  },
  {
    title: "Reativação de cadastro",
    subtitle: "Imóveis",
    related: ["imóvel", "imóveis", "endereço", "reativar", "reativação"],
    action: (props) => redirect(props, "/address-list"),
  },
  {
    title: "Dados pessoais",
    subtitle: "Meus dados",
    related: ["nome", "email", "e-mail", "dados", "telefone"],
    action: (props) => redirect(props, "/personal-data"),
  },
  {
    title: "Ajuda",
    subtitle: "Ajuda",
    related: ["ajuda", "whatsapp", "fale conosco", "contato", "telefone"],
    action: (props) => redirect(props, "/help"),
  },
  {
    title: "Contatos da empresa",
    subtitle: "Contatos",
    related: ["contatos", "empresa", "telefone", "email"],
    segmento: "INDUSTRIAL",
    action: (props) => redirect(props, "/industrial-contact"),
  },
  {
    title: "Indicação de clientes",
    subtitle: "Indicação",
    related: ["clientes", "indicação", "contatos", "empresa"],
    segmento: "INDUSTRIAL",
    action: (props) => redirect(props, "/recommendation"),
  },
  {
    title: "Entregas",
    subtitle: "Entregas",
    related: [
      "entregas",
      "serviços",
      "programação",
      "férias",
      "solicitação",
      "solicitar",
    ],
    segmento: "INDUSTRIAL",
    action: (props) => redirect(props, "/home-delivery"),
  },
];
