import React, { Component } from "react";

import ClienteService from "../../services/cliente";
import apiContext from "../../api";
import Modal from "../Modal/Modal";
import InvoiceImage from "../../assets/illustrations/invoice.png";

import "./InvoiceModal.scss";
import CondominioService from "../../services/condominio";
import InvoiceService from "../../services/invoice";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { connect } from "react-redux";

class InvoiceModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceData: props.invoiceData,
    };

  }

  static contextType = apiContext;

  static getDerivedStateFromProps(props, state) {
    return { invoiceData: props.invoiceData };
  }

  copyToClipboard = () => {
    this.textArea && this.textArea.select();
    document.execCommand("copy");
    this.props.showToast(
      "Copiado",
      "Seu código foi copiado para sua área de transferência."
    );
    this.props.onCopy && this.props.onCopy();
  };

  sendByEmail = () => {
    const {email, segmento } = ClienteService?.localEntrega;
    const emailContato = ClienteService?.industrialContato?.email

    if (
      (segmento !== "INDUSTRIAL" && !email) ||
      (segmento === "INDUSTRIAL" && !emailContato)
    ) {
      this.props.showToast(
        "Ops!",
        "Parece que você não tem e-mail cadastrado. Entre no perfil e cadastre agora mesmo para poder receber suas contas!"
      );
      return null;
    }

    const { invoiceData,  } = this.state;
    if (!invoiceData) return null;

    const { cod_estab, cod_ser_docto, cod_tit_acr } = invoiceData;

    this.context.ConsigazApi.shareTicket(cod_estab, cod_ser_docto,  cod_tit_acr).then(
      () =>
        this.props.showToast(
          "Enviado!",
          `O boleto foi enviado para ${emailContato || email}.`
        ),
      () => this.props.showToast("Ops!", "Erro ao enviar o boleto.")
    );
  };

  getDate = () => {
    const { invoiceData } = this.state;

    if (!invoiceData) return null;

    const date = new Date(`${invoiceData["dat_transacao"]} 00:00:00`);

    return `${String(date.getMonth() + 1).padStart(
      2,
      0
    )}/${date.getFullYear()}`;
  };

  getInvoiceBadge(invoiceData) {
    const status = InvoiceService.getInvoiceStatus(invoiceData);

    return {
      paid: <span className="badge success">Pago</span>,
      outdated: <span className="badge danger">Vencido</span>,
      open: <span className="badge warning">Aguardando Pagamento</span>,
    }[status];
  }

  open = () => this.modalRef && this.modalRef.open();

  close = () => this.modalRef && this.modalRef.close();

  openDocument = (invoiceData) => {
    InvoiceService.getInvoiceStatus(invoiceData) !== "paid" ? exportInvoice(this.props.showToast, invoiceData) : exportDanf(this.props.showToast, invoiceData) 
  }

  render() {
    const { invoiceData } = this.state;

    if (!invoiceData) return null;

    return (
      <Modal
        shown={false}
        ref={(ref) => (this.modalRef = ref)}
        className="invoice-modal"
        title={InvoiceService.getInvoiceStatus(invoiceData) !== "paid" ? `Boleto do mês ${this.getDate()}` : `NF do mês ${this.getDate()}`}
        closeButton={true}
      >
        {this.getInvoiceBadge(invoiceData)}
        <br />

        <img
          src={InvoiceImage}
          alt="Invoice Illustration"
          height={150}
          onClick={()=> this.openDocument(invoiceData)}
        />

        <div className="text-center">
          <button onClick={() => this.openDocument(invoiceData)} className="link secondary">
            Clique na imagem para visualizar
          </button>
        </div>

        <textarea
          ref={(ref) => (this.textArea = ref)}
          style={{ opacity: 0, height: 0 }}
          value={invoiceData["linha-digitavel"] || ""}
          onChange={() => null}
        />

        <footer className="text-center">
          {InvoiceService.getInvoiceStatus(invoiceData) !== "paid" && (
            <button onClick={this.copyToClipboard} className="link">
              Copiar código de barras
            </button>
          )}
          <br />
          <button onClick={() => this.sendByEmail()} className="link secondary">
            Compartilhar por email
          </button>
        </footer>
      </Modal>
    );
  }
}

// ##################################################################################
// ###################### DOWNLOAD DO BOLETO ########################################
// ##################################################################################
function exportInvoice(showToast, invoiceData) {
  const { cod_estab } = invoiceData;

  if(!!!cod_estab) {
    showToast("Ops!", 'Esse arquivo não pode ser encontrado.');
    return;
  }
 
  window.open(`/invoice-pdf?acao=ArquivoBoleto&titulo=${titleInvoice(invoiceData)}`);
}

// ##################################################################################
// ###################### DOWNLOAD DA NOTA FISCAL ###################################
// ##################################################################################
function exportDanf(showToast, invoiceData) {
  const { cod_estab } = invoiceData;
  
  if(!!!cod_estab) {
    showToast("Ops!", 'Esse arquivo não pode ser encontrado.');
    return;
  }

  window.open(`/danfe-pdf?acao=ArquivoNF&titulo=${titleNF(invoiceData)}`);
}

// cod_estab == cod_estabel | cod_espec_docto | cod_ser_docto == serie | cod_tit_acr = nr_nota_fis | cod_parcela
function titleInvoice(invoiceData) {
  const { cod_estab, cod_espec_docto, cod_ser_docto, cod_tit_acr, cod_parcela } = invoiceData;
  return `${cod_estab}|${cod_espec_docto}|${cod_ser_docto}|${cod_tit_acr}|${cod_parcela}`;
}

// cod_estab == cod_estabel |  cod_tit_acr == nr-nota-fis | cod_ser_docto == serie 
function titleNF(invoiceData) {
  const { cod_estab: cod_estabel, cod_ser_docto: serie, cod_tit_acr: nr_nota_fis } = invoiceData;
  return `${cod_estabel}|${serie}|${nr_nota_fis}`;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(
  InvoiceModal
);
