import React, { Component } from 'react'

import Page, { redirect } from '../../components/Page/Page'
import PageCenterContent from '../../components/PageCenterContent/PageCenterContent'
import Modal from '../../components/Modal/Modal'
import PageHeaderImage from '../../components/PageHeaderImage/PageHeaderImage'

import BuildingWarningImage from '../../assets/illustrations/building-warning.png'
import WarningImage from '../../assets/illustrations/warning.png'

export default class AddressActive extends Component {

  constructor(props) {
    super(props)

    this.state = {
      redirectState: {},
      haveSubmited: false,
      contentVariationKey: 'default'
    }
  }

  getContentVariation = key => ({
    default: {
      title: 'Cadastro ativo no imóvel',
      description: 'Notamos que já tem um cadastro vinculado ao imóvel, deseja fazer a substituição da titularidade?',
      confirmButtonText: 'Quero substituir',
      confirmButtonAction: () => this.confirmModal && this.confirmModal.setState({ shown: true }),
      declineButtonText: 'Não quero substituir',
      declineButtonAction: () => this.setState({ contentVariationKey: 'advice' }),
    },
    advice: {
      title: 'Tem certeza?',
      description: 'Para vincular este imóvel à sua conta, será necessário substituir o cadastro atualmente ativo.',
      confirmButtonText: 'Ok, realizar substituição',
      confirmButtonAction: () => this.confirmModal && this.confirmModal.open(),
      declineButtonText: 'Desistir do cadastramento',
      declineButtonAction: () => redirect(this.props, '/'),
    }
  })[key || 'default']

  render() {
    const content = this.getContentVariation(this.state.contentVariationKey)

    return (
      <Page
        history={this.props.history}>
        <PageCenterContent>
          <PageHeaderImage src={BuildingWarningImage} />

          <h1>{content.title}</h1>

          <p style={{ marginBottom: '5rem' }}>{content.description}</p>

          <button className="block" onClick={content.confirmButtonAction}>
            {content.confirmButtonText}
          </button><br />
          <button className="block outline" onClick={content.declineButtonAction}>
            {content.declineButtonText}
          </button>
        </PageCenterContent>
        <Modal
          ref={ref => this.confirmModal = ref}
          title="Atenção"
          shown={false}
          imgSrc={WarningImage}
          declineButton={true}
          declineButtonText="Desistir"
          declineButtonAction={() => {
            this.setState({ contentVariationKey: 'advice' })
            this.confirmModal.close()
          }}
          confirmButton={true}
          confirmButtonText="Confirmar"
          confirmButtonAction={() => redirect(this.props, '/address-done', { shouldOverwrite: true })}
          >
          <p>O cadastro atualmente ativo deste imóvel será substituido pelo seu novo cadastro.</p>
        </Modal>
      </Page>
    )
  }
}