import React, { Component } from "react";
import PropTypes from "prop-types";

import LineChart from "../LineChart/LineChart";
import colors from "../../assets/colors";
import InvoiceService from "../../services/invoice";
import { moneyFormat, weightFormat } from "../Masks/Masks";

export const getMonthLabel = (month) => {
  return [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ][month.monthNumber - 1];
};

export const getChartData = (
  invoices,
  invoiceChartCenterIdx,
  chartReference
) => {
  const validInvoices = InvoiceService.getValidInvoices(invoices);

  if (!validInvoices) return null;

  const mapedData = validInvoices.map((invoice, itemIdx) => {
    const dadosGerais = invoice["tt-dados-gerais"][0];
    const invoiceDate = new Date(`${dadosGerais["dt-leitura"]} 00:00:00`);

    return {
      itemIdx,
      monthNumber: invoiceDate.getMonth() + 1,
      consumption: 0,
      value:
        chartReference === "quilos"
          ? dadosGerais["kg"]
          : invoice["val_origin_tit_acr"],
      paid: invoice["val_sdo_tit_acr"] === 0,
      open: invoice["val_sdo_tit_acr"] > 0,
      status: InvoiceService.getInvoiceStatus(invoice),
      selected: itemIdx === invoiceChartCenterIdx,
    };
  });

  return sliceData(mapedData, invoiceChartCenterIdx);
};

export const adjustCenterIdx = (data, centerIdx) => {
  if (centerIdx > data.length - 1) return parseInt(data.length / 2);
  return centerIdx;
};

export const sliceData = (data, centerIdx) => {
  centerIdx = adjustCenterIdx(data, centerIdx);

  let sliceStart = centerIdx - 2;
  let sliceEnd = centerIdx + 3;

  if (data.length <= 5) {
    sliceStart = 0;
    sliceEnd = 5;
  } else if (sliceStart < 0) {
    sliceStart = 0;
    sliceEnd = data.length < 5 ? data.length : 5;
  } else if (sliceEnd >= data.length) {
    sliceStart = data.length - 5;
    sliceEnd = data.length;
  } else if (sliceStart < 0) {
    sliceEnd += sliceStart * -1;
  }

  return data
    .filter((e) => e)
    .map((month) => ({
      ...month,
      selected: month.itemIdx === centerIdx,
    }))
    .slice(sliceStart, sliceEnd);
};

export default class MonthlyConsumptionLineChart extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        monthNumber: PropTypes.number,
        consumption: PropTypes.number,
        value: PropTypes.number,
        period: PropTypes.shape({
          start: PropTypes.string,
          end: PropTypes.string,
        }),
        paid: PropTypes.bool,
        open: PropTypes.bool,
      })
    ),
  };

  getMonthColor = (monthData) =>
    ({
      paid: colors.green,
      outdated: colors.red,
      open: colors.yellow,
    }[monthData.status]);

  getPointColor = (points) => {
    const idx = points.itterIdx || 0;
    points.itterIdx = idx + 1;
    const monthData = this.props.data[idx];
    return this.getMonthColor(monthData);
  };

  getPointBorderColor = (point) => "white";

  getPointLabel = (point) => {
    if (this.props.chartReference === "quilos") return weightFormat(point.y);
    else return moneyFormat(point.y);
  };

  render() {
    const { data } = this.props;

    const chartData = [
      {
        id: "Valor",
        data: data.map((month, idx) => ({
          x: `${idx}`,
          y: `${month.value || 0}`,
        })),
      },
    ];

    return (
      <LineChart
        data={chartData}
        pointColor={this.getPointColor}
        pointBorderColor={this.getPointBorderColor}
        enablePointLabel={true}
        pointLabel={this.getPointLabel}
        onClick={(node) => {
          if (this.props.onClick) {
            // const monthData = this.props.monthsData[node.index]
            // this.props.onClick(monthData)
          }
        }}
      />
    );
  }
}
