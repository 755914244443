import React from "react";
import { maskCpfCnpj } from "../../components/Masks/Masks";
import Select from "../../components/Select/Select";

const FormIndustrial = (props) => {
  const {
    department,
    departmentOptions,
    onDepartmentChange,
    onPhoneNumberChange,
    isPhoneNumberValid,
    haveSubmited,
    phoneNumber,
    disabledInput,
    name,
    isNameValid,
    onNameChange,
    cpf,
    isCpfValid,
    onCpfChange,
    isEmailValid,
    email,
    onEmailChange,
  } = props;

  return (
    <>
      <input
        type="text"
        value={name}
        onChange={onNameChange}
        disabled={disabledInput}
        className={`block ${!isNameValid && haveSubmited ? "error" : ""}`}
      />
      <input
        type="text"
        value={maskCpfCnpj(cpf || "")}
        onChange={onCpfChange}
        disabled={disabledInput}
        className={`block ${!isCpfValid && haveSubmited ? "error" : ""}`}
      />
      <Select
        type="text"
        className="block"
        value={department}
        disabled={disabledInput}
        onChange={onDepartmentChange}
        options={departmentOptions || []}
        style={{ color: "var(--light-blue)" }}
      />
      <input
        type="text"
        className={`block ${
          !isPhoneNumberValid && haveSubmited ? "error" : ""
        }`}
        placeholder="Telefone"
        value={phoneNumber}
        onChange={onPhoneNumberChange}
        disabled={disabledInput}
      />
      <input
        type="text"
        className={`block ${!isEmailValid && haveSubmited ? "error" : ""}`}
        placeholder="Telefone"
        value={email}
        onChange={onEmailChange}
        disabled={disabledInput}
      />
    </>
  );
};

export default FormIndustrial;
