import React, { Component } from 'react'

import './Help.scss'

import HomeTemplate from '../HomeTemplate/HomeTemplate'

export default class Help extends Component {

  render() {
    return (
      <HomeTemplate
        history={this.props.history}
        className="help-page">
        <h1>Precisando de ajuda?</h1>

        <p>Entre em contato conosco pelo Whatsapp. Caso não tenha instalado em seu smartphone, envie um e-mail que em breve entraremos em contato com você!</p>

        <a
          href={`https://api.whatsapp.com/send?phone=5511997770000`}
          target="blank"
          className="block"
          style={{ background: '#00bd4c', borderColor: '#00bd4c' }}>
            Whatsapp
        </a><br />
        <a
          href="mailto:cac@consigaz.com.br"
          target="blank"
          className="block outline"
          style={{ textTransform: 'none' }}>
            cac@consigaz.com.br
        </a>

        <h1>Ou pode nos ligar também</h1>

        <p>Telefones da CAC (Central de atendimento ao cliente)</p>

        <button disabled={true} className="block outline">0800 770 4922</button><br />
        <button disabled={true} className="block outline">(11) 4197 9300</button>
      </HomeTemplate>
    )
  }
}