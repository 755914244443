import apiContext from '../../api'

import PdfPage from '../../components/PdfPage/PdfPage'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toastOperations } from '../../state/ducks/toast'

class DanfePdfPage extends PdfPage {

  static contextType = apiContext

  get fileName() {
    return 'Danfe'
  }

  componentDidMount() {

    // captura os parammetros passados na URL
    const { acao, titulo, 'data-fim': endDate, nota } = this.state.params;
    const { cpfcnpj, startDate, finalDate, deliveryLocation, email, codEstabel, codSerDocto, codTitAcr } = this.state.params;

    // faz download com o novo boleto
    if(!!acao && !!titulo) {

      const useDescricao2 = true;

      this.context.ConsigazApi.download(acao, titulo, useDescricao2).then(([err, file]) => {
        if(err) { 
          this.props.showToast("Ops!", err, 20000); 
          return;
        };

        this.setState({ file })
      });
      return;
    }

    // faz download com o novo boleto
    if(!!cpfcnpj && !!endDate && !!nota) {
      this.context.ConsigazApi.externaldanfe(cpfcnpj, endDate, nota).then(([err, file]) => {
        if(err) { 
          this.props.showToast("Ops!", err, 10000); 
          return;
        };

        this.setState({ file })
      });
      return;
    }

    // faz download na maneira antiga
    this.context.ConsigazReportApi.exportDanfe(cpfcnpj, startDate, finalDate, deliveryLocation, email, codEstabel, codSerDocto, codTitAcr).then(
      ({ data: file }) => this.setState({ file }),
      errorDescription => this.props.showToast('Ops!', errorDescription)
    )
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    showToast: toastOperations.show
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(DanfePdfPage)