import React from "react";
import { IconSearch } from "../Icons/Icons";

export default ({ className, onChange, disabled, style, placeholder }) => (
  <>
    <div className="search-page">
      <div style={{ alignContent: "center" }}>
        <input className={className} onChange={onChange} placeholder={placeholder} />
        <IconSearch
          color="#333"
          style={{ width: "16px", marginLeft: "-5px", marginRight: "6px" }}
        />
      </div>
    </div>
    <br />
  </>
);
