import React from "react"

import TextField from '@material-ui/core/TextField';
import { Select, MenuItem } from '@material-ui/core';

const Select2 = ({ options, label, onSelect }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <span>{label}</span>
      <Select
        className="material-ui-select"
        options={options}
        getOptionLabel={(option) => option.title}
        style={{ width: 200 }}
        renderInput={(params) => 
          <TextField className="blue-text" {...params} />
        }
        onChange={(ev) => onSelect(ev.target.value)}
        noOptionsText="Opção não encontrada"
      >
      {options.map((option, idx) => (
          <MenuItem className="blue-text" key={idx} value={option.value}>{option.title}</MenuItem>
      ))}
      </Select>
    </div>
  )  
}

export default Select2;