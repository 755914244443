import React, { Component } from "react";

import "./AddressSelect.scss";
import CondominioService from "../../services/condominio";
import Page, { redirect } from "../../components/Page/Page";
// import PageCenterContent from '../../components/PageCenterContent/PageCenterContent'
import BackgroundImage from "../../assets/background.jpg";
import apiContext from "../../api";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { loadingOperations } from "../../state/ducks/loading";
import { IconHome } from "../../components/Icons/Icons";
import Card from "../../components/Card/Card";
import ClienteService from "../../services/cliente";
import PageCenterContent from "../../components/PageCenterContent/PageCenterContent";

class AddressSelect extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      addressList: [],
      selectedCondominio: CondominioService.selected || {},
      errorMessage: null,
    };
  }

  componentDidMount() {
    const { ClienteEntrRetorno } = ClienteService.localEntrega || {};

    if (ClienteEntrRetorno) {
      this.setState({
        addressList: ClienteEntrRetorno,
      });
    }

    // this.props.showLoading();
    // this.context.ConsigazApi.consultaLocalEntrega(CPFCNPJ)
    //   .then(ClienteService.consultaLocalEntregaApiParser)
    //   .then(({ Cliente }) => {
    //     if (Cliente) {
    //       this.props.dismissLoading();
    //       this.setState({
    //         addressList: Cliente.ClienteRetorno[0].ClienteEntrRetorno,
    //       });
    //     }
    //   })
    //   .catch((errorDescription) => {
    //     this.props.dismissLoading();
    //     this.setState({}, () => this.props.showToast("Ops!", errorDescription));
    //   });
  }

  isSelected = (address) =>
    address["cod-condominio"] ===
      this.state.selectedCondominio["cod-condominio"] &&
    address["local-entrega"] === this.state.selectedCondominio["local-entrega"];

  getAddressStyleName = (address) => {
    if (this.isSelected(address)) return "outlinePrimaryClear";
    else return "outlineWhite";
  };

  selectCondominio = (address) => {
    if (address) {
      CondominioService.selected = address;
      this.setState({ selectedCondominio: address }, () => {
        redirect(this.props, "/home");
      });
    }
  };

  handleSelectAll = () => {
    CondominioService.selectAll = true;
    redirect(this.props, "/home");
  };

  render() {
    const { addressList } = this.state;

    const { segmento } = ClienteService.localEntrega;

    return (
      <Page
        history={this.props.history}
        className="address-select-page"
        style={{
          background: `var(--dark-blue) url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundBlendMode: "multiply",
        }}
      >
        <span className="header-fade">
          <h1>Selecione um endereço</h1>
          <p>Você poderá alterar o endereço a qualquer momento.</p>
        </span>
        <br />
        <PageCenterContent
          style={{
            zIndex: 0,
            marginTop: 100,
            height: 650,
          }}
        >
          {segmento === "INDUSTRIAL" && (
            <Card styleName="outlineWhite" onClick={this.handleSelectAll}>
              <div>
                <IconHome size={40} />
              </div>
              <div>
                <p>Quero ver informações de TODOS os meus locais de entrega</p>
              </div>
            </Card>
          )}

          {addressList &&
            addressList.map((address) => (
              <Card
                key={`address-${address["cod-condominio"]}-${address["local-entrega"]}`}
                className={this.isSelected(address) ? "" : "no-shadow"}
                styleName={this.getAddressStyleName(address)}
                onClick={() => this.selectCondominio(address)}
              >
                <div>
                  <IconHome size={40} />
                </div>
                <div>
                  <p className="title">{address["nome-condominio"]}</p>
                  <p>{address.endereco}</p>
                  <p>{`${address.cidade} / ${address.estado}`}</p>
                  {address.situacao === 2 && segmento === "INDUSTRIAL" && (
                    <button
                      style={{
                        backgroundColor: "#ea454b",
                        borderColor: "#ea454b",
                      }}
                      disabled
                    >
                      Local inativo
                    </button>
                  )}
                </div>
                <div></div>
              </Card>
            ))}
        </PageCenterContent>
      </Page>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(AddressSelect);
