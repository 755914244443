import React, { Component } from "react";

import "./IndustrialCard.scss";
import CondominioService from "../../services/condominio";

import IconCollectiveVacation from "../../assets/illustrations/ic_collectivevacation_service.png";
import IconChangeProgram from "../../assets/illustrations/ic_changeprogram_service.png";
import IconNextDelivery from "../../assets/illustrations/ic_nextdelivery_service.png";
import IconRequestSupply from "../../assets/illustrations/ic_requestsupply_service.png";
import { IconCheck } from "../Icons/Icons";
import IconChangePayment from "../../assets/illustrations/ic_changepayment_service.png";
import IconDuplicateContract from "../../assets/illustrations/ic_duplicatecontract_service.png";
import IconLeakage from "../../assets/illustrations/ic_leakage_service.png";
import IconRegulation from "../../assets/illustrations/ic_regulation_service.png";
import IconRepresentativeContact from "../../assets/illustrations/ic_representativecontact_service.png";
import IconTechnicalReport from "../../assets/illustrations/ic_technicalreport_service.png";
import IconTightnessTest from "../../assets/illustrations/ic_tightnesstest_service.png";
import IconChangeData from "../../assets/illustrations/ic_changedata_service.png";

export default class IndustrialCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked || false,
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
    };
  }

  filterImage() {
    if (this.props.image === "12;;") return IconLeakage;
    if (this.props.image === "14;;") return IconRegulation;
    if (this.props.image === "15;;") return IconTightnessTest;
    if (this.props.image === "17;;") return IconTechnicalReport;
    if (this.props.image === "18;;") return IconChangePayment;
    if (this.props.image === "19;;") return IconCollectiveVacation;
    if (this.props.image === "20;;") return IconRepresentativeContact;
    if (this.props.image === "21;;") return IconChangeData;
    if (this.props.image === "22;;") return IconRequestSupply;
    if (this.props.image === "23;;") return IconChangeProgram;
    if (this.props.image === "24;;") return IconDuplicateContract;
    else return IconNextDelivery;
  }

  componentDidMount() {
    if (this.props.cardClass !== "service" && this.props.selectedService)
      this.props.onDetail(9);
  }

  render() {
    const { selectedCondominio } = this.state;

    return (
      <div
        className={`delivery-card ${(this.state.checked && "checked") || ""}`}
        onClick={() => this.props.onDetail(this.props.serviceId)}
        style={{ cursor: "pointer" }}
      >
        {this.state.checked &&
          (selectedCondominio === "all" ? (
            <div className="icon-check" style={{ display: "flex" }}>
              <p style={{ margin: 0 }}>
                {CondominioService.selectMany &&
                  CondominioService.selectMany.length > 0 &&
                  CondominioService.selectMany.filter(
                    (select) => select.idservice === this.props.image
                  ).length}
              </p>
              <IconCheck color="#00a326" size={24} />
            </div>
          ) : (
            <IconCheck className="icon-check" color="#00a326" size={28} />
          ))}
        {this.props.title && <p className="title">{this.props.title}</p>}
        {
          <img
            src={this.filterImage()}
            alt="Logo"
            className={
              this.props.cardClass !== "service" ? "image" : "imgservice"
            }
          />
        }
      </div>
    );
  }
}
