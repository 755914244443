import React, { Component } from 'react'
// import { ResponsiveLine } from 'nivo'
import { ResponsiveLine } from '@nivo/line'

import './LineChart.scss'
// import colors from '../../assets/colors'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export default class LineChart extends Component {

  get margin() {
    return { top: 80, right: 40, bottom: 10, left: 40 }
  }

  render() {
    const {
      data,
      pointColor,
      pointBorderColor,
      pointLabel,
      pointLabelFormat,
      onClick
    } = this.props

    return (
      <ResponsiveLine
          data={data}
          margin={this.margin}
          xScale={{ type: 'point' }}
          yScale={{ type: 'linear', reverse: false }}
          curve='cardinal'
          minY={'auto'}
          axisTop={null}
          axisBottom={null}
          axisRight={null}
          axisLeft={null}
          pointSize={15}
          pointColor={pointColor}
          pointBorderWidth={5}
          pointBorderColor={pointBorderColor}
          enablePointLabel={true}
          pointLabel={pointLabel}
          pointLabelFormat={pointLabelFormat}
          pointLabelYOffset={-20}
          colors={["#cefec8"]}
          useMesh={true}
          onClick={onClick}
          lines={[{color: '#2065a8'}]}
          enableGridX={false}
          enableGridY={false}
          enableArea={true}
          areaBaselineValue={0}
          areaOpacity={1}
          isInteractive={false}
      />
    )
  }
}