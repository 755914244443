import React, { Component } from "react";

import "./IndustrialContact.scss";

import apiContext from "../../api";
import ClienteService from "../../services/cliente";
import BuildingImg from "../../assets/illustrations/building.png";
import HomeTemplate from "../HomeTemplate/HomeTemplate";
import Card from "../../components/Card/Card";
import {
  IconEmail,
  IconPlus,
  IconTelephone,
} from "../../components/Icons/Icons";
import CondominioService from "../../services/condominio";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { loadingOperations } from "../../state/ducks/loading";
import {
  toTitleCase,
  maskPhoneNumber,
  validatePhoneNumber,
  validateEmailAddress,
} from "../../components/Masks/Masks";
import Modal from "../../components/Modal/Modal";
import Select from "../../components/Select/Select";

class IndustrialContact extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      redirectState: {},
      selectedCondominio: CondominioService.selected || {},
      clientContactData: [],
      contactData: this.initialContactData,
      contactTypes: [],
      phoneNumber: "",
      isPhoneNumberValid: false,
      emailAddress: "",
      isEmailAddressValid: false,
    };
  }

  get initialContactData() {
    return {
      type: "",
      name: "",
      department: "Nfe",
      telefone: null,
      email: null,
    };
  }

  get renderListTelephone() {
    const { clientContactData } = this.state;
    return (clientContactData || []).filter((c) => c.type === "telefone");
  }

  get renderListEmail() {
    const { clientContactData } = this.state;
    return (clientContactData || []).filter((c) => c.type === "email");
  }

  componentDidMount() {
    this.props.showLoading();

    this.loadContactData();

    this.context.ConsigazApi.tiposContato().then(
      ({ Retorno }) => {
        if (Retorno && Retorno.length) {
          const contactTypes = Retorno[0].descricao.split(",");
          this.setState({
            contactTypes,
            department:
              contactTypes && contactTypes.length > 0 && contactTypes[0],
            isDepartmentValid: contactTypes && contactTypes.length > 0,
          });
        } else
          this.props.showToast("Ops", "Não foram encontrados tipos de contato");
      },
      (error) => {
        console.error(error);
        this.props.showToast("Ops", "Erro ao buscar os tipos de contato");
      }
    );
  }

  loadContactData() {
    const { CPFCNPJ } = ClienteService.localEntrega || {};
    const { cpf: cpfContato } = ClienteService.industrialContato || {};

    this.setState({ clientContactData: null });
    this.context.ConsigazApi.consultaContatos(CPFCNPJ).then(({ Cliente }) => {
      if (Cliente && Cliente.ClienteRetorno && Cliente.ClienteRetorno.length)
        if (Cliente.ClienteRetorno[0].ClienteContatoRetorno)
          this.props.dismissLoading();
      this.setState({
        clientContactData: (
          Cliente.ClienteRetorno[0].ClienteContatoRetorno || []
        )
          .filter((dados) => dados["cpf-contato"] === cpfContato)
          .reduce(
            (reduced, contact) => {
              if (contact["e-mail"]) {
                reduced.values.push({
                  index: reduced.emails,
                  sequencia: contact["sequencia"],
                  name: contact.nome,
                  title: contact.tipo,
                  value: contact["e-mail"],
                  type: "email",
                });
                reduced.emails++;
              }
              if (contact.telefone) {
                reduced.values.push({
                  index: reduced.phones,
                  sequencia: contact["sequencia"],
                  name: contact.nome,
                  title: contact.tipo,
                  value: maskPhoneNumber(contact.telefone),
                  type: "telefone",
                });
                reduced.phones++;
              }
              return reduced;
            },
            { values: [], phones: 0, emails: 0 }
          ).values,
      });
    });
  }

  submitNewContact = () => {
    const { CPFCNPJ } = ClienteService.localEntrega;
    const {
      clientContactData,
      contactData,
      isPhoneNumberValid,
      isEmailAddressValid,
      contactTypes,
    } = this.state;
    const { cpf: cpfContato } = ClienteService.industrialContato || {};

    const verifyExistentTelephone = (clientContactData || []).filter(
      (contact) => {
        return contact.value === contactData["telefone"];
      }
    );
    const verifyExistentEmail = (clientContactData || []).filter((contact) => {
      return contact.value === contactData["email"];
    });

    if (contactData["type"] === "telefone" && this.renderListTelephone.length >= 3) {
      this.props.showToast(
        "Ops!",
        "Você atingiu o número maximo de telefones cadastrados. (3)"
      );
    } else if (contactData["type"] === "email" && this.renderListEmail.length >= 5) {
      this.props.showToast(
        "Ops!",
        "Você atingiu o número maximo de telefones cadastrados. (5)"
      );
    } else if (contactData["type"] === "telefone" && !isPhoneNumberValid) {
      this.props.showToast(
        "Ops!",
        "É necessário inserir um número de telefone válido."
      );
    } else if (contactData["type"] === "email" && !isEmailAddressValid) {
      this.props.showToast("Ops!", "É necessário inserir um e-mail válido.");
    } else if (
      contactData["type"] === "telefone" &&
      verifyExistentTelephone.length > 0
    ) {
      this.props.showToast("Ops", "Esse telefone já está cadastrado.");
    } else if (
      contactData["type"] === "email" &&
      verifyExistentEmail.length > 0
    ) {
      this.props.showToast("Ops", "Esse e-mail já está cadastrado.");
    } else
      this.context.ConsigazApi.manutencaoContatos(
        CPFCNPJ,
        cpfContato,
        contactData["name"] || "",
        contactData["telefone"] || "",
        contactData["email"] || "",
        undefined,
        contactData["department"] || (contactTypes || [""])[0]
      ).then(
        () => {
          this.props.showToast("Sucesso!", "Contato inserido com sucesso.");
          this.loadContactData();
        },
        (errorDescription) => {
          this.props.dismissLoading();
          this.props.showToast("Ops!", String(errorDescription));
        }
      );
    this.ModalEdit && this.ModalEdit.close();
    this.ModalNewContact && this.ModalNewContact.close();
  };

  submitChangeData = () => {
    const {
      contactData,
      isPhoneNumberValid,
      isEmailAddressValid,
      contactTypes,
    } = this.state;
    const { CPFCNPJ } = ClienteService.localEntrega || {};
    const { cpf: cpfContato } = ClienteService.industrialContato || {};

    if (isEmailAddressValid === false || isPhoneNumberValid === false)
      return this.props.showToast("OPS!", "Você inseriu um dado inválido.");

    (isEmailAddressValid || isPhoneNumberValid) &&
      this.context.ConsigazApi.manutencaoContatos(
        CPFCNPJ,
        cpfContato,
        contactData["name"] || "",
        contactData["telefone"] || "",
        contactData["email"] || "",
        contactData["sequencia"],
        contactData["department"] || (contactTypes || [""])[0]
      ).then(
        () => {
          this.props.showToast("Sucesso", "Dados alterados com sucesso!");
          this.loadContactData();
        },
        (errorDescription) => {
          this.props.dismissLoading();
          this.props.showToast("Ops!", String(errorDescription));
        }
      );

    this.ModalEdit && this.ModalEdit.close();
    this.ModalNewContact && this.ModalNewContact.close();
  };

  deleteContact = () => {
    const { contactData } = this.state;
    const { CPFCNPJ } = ClienteService.localEntrega || {};
    const { cpf: cpfContato } = ClienteService.industrialContato || {};

    this.context.ConsigazApi.manutencaoContatos(
      CPFCNPJ,
      cpfContato,
      contactData["name"] || "",
      contactData["telefone"] || "",
      contactData["email"] || "",
      contactData["sequencia"],
      "",
      true
    ).then(
      () => {
        this.props.showToast("Sucesso", "Dado excluido com sucesso!");
        this.loadContactData();
      },
      (errorDescription) => {
        this.props.dismissLoading();
        this.props.showToast("Ops!", String(errorDescription));
      }
    );

    (this.ModalEdit && this.ModalEdit.close()) ||
      (this.ModalEdit && this.ModalEdit.close());
  };

  handlePhoneNumberChange = (e) => {
    const phoneNumberMasked = maskPhoneNumber(e.target.value);
    const { contactData } = this.state;

    this.setState({
      phoneNumber: phoneNumberMasked,
      isPhoneNumberValid: validatePhoneNumber(phoneNumberMasked),
      contactData: {
        ...contactData,
        telefone: phoneNumberMasked,
      },
    });
  };

  handleEmailAddressChange = (e) => {
    const emailAddress = e.target.value;
    const { contactData } = this.state;

    this.setState({
      emailAddress: emailAddress,
      isEmailAddressValid: validateEmailAddress(emailAddress),
      contactData: {
        ...contactData,
        email: emailAddress,
      },
    });
  };

  render() {
    const {
      redirectState,
      clientContactData,
      contactData,
      contactTypes,
    } = this.state;

    return (
      <HomeTemplate
        ref={(ref) => (this.template = ref)}
        history={this.props.history}
        redirectFrom="IndustrialContact"
        redirectState={redirectState}
        className="industrial-contact-page"
      >
        <img src={BuildingImg} alt="Prédio" />
        <h1>Contatos da Empresa</h1>

        <p>
          Você poderá cadastrar até 3 telefones (celulares ou fixos) e 5 emails
        </p>

        <div className="button-box">
          <button
            className="rectangle"
            style={{ marginRight: "20px" }}
            onClick={() => {
              // if (filterList.length >= 3)
              //   this.props.showToast(
              //       "Ops!",
              //       "Você já inseriu o número máximo de telefones.",
              //       5000
              //     )
              // else
              this.setState(
                {
                  contactData: { type: "telefone" },
                },
                () => this.ModalNewContact && this.ModalNewContact.open()
              );
            }}
          >
            <IconPlus /> Novo telefone
          </button>
          <button
            className="rectangle"
            onClick={() => {
              // if (filterEmail.length >= 5)
              //   this.props.showToast(
              //       "Ops!",
              //       "Você já inseriu o número máximo de e-mails."
              //     )

              // else
              this.setState(
                {
                  contactData: { type: "email" },
                },
                () => this.ModalNewContact && this.ModalNewContact.open()
              );
            }}
          >
            <IconPlus /> Novo Email
          </button>
          <br />
        </div>

        {this.renderListTelephone &&
          (this.renderListTelephone || []).map((contact) => (
            <Card key={`contact-${contact.type}-${contact.index + 1}`}>
              <div>
                <IconTelephone size={40} />
              </div>
              <div>
                <p className="field-title">{`${toTitleCase(contact.type)} ${
                  contact.index + 1
                }`}</p>
                <p>
                  <p>Departamento: {toTitleCase(contact.title)}</p>
                </p>
                {contact.value}

                <br />
                <br />
                <button
                  className="rectangle"
                  style={{ marginRight: "20px", borderRadius: "5px" }}
                  onClick={() => {
                    const newState = {
                      contactData: {
                        name: contact.name,
                        type: contact.type,
                        sequencia: contact.sequencia,
                      },
                    };

                    newState["phoneNumber"] = contact.value;
                    newState["isPhoneNumberValid"] = validatePhoneNumber(
                      contact.value
                    );
                    newState.contactData = {
                      ...newState.contactData,
                      telefone: contact.value,
                    };

                    this.setState(newState, () => {
                      this.ModalEdit && this.ModalEdit.open();
                    });
                  }}
                >
                  Editar
                </button>
                <button
                  className="rectangle"
                  style={{ borderRadius: "5px" }}
                  onClick={() => {
                    this.setState(
                      {
                        contactData: {
                          name: contact.name,
                          type: contact.type,
                          sequencia: contact.sequencia,
                        },
                      },
                      () => {
                        this.ModalDelete && this.ModalDelete.open();
                      }
                    );
                  }}
                >
                  Excluir
                </button>
              </div>
              <div></div>
            </Card>
          ))}

        {this.renderListEmail &&
          (this.renderListEmail || []).map((contact) => (
            <Card key={`contact-${contact.type}-${contact.index + 1}`}>
              <div>
                <IconEmail size={40} />
              </div>
              <div>
                <p className="field-title">{`${toTitleCase(contact.type)} ${
                  contact.index + 1
                }`}</p>
                <p>
                  <p>Departamento: {toTitleCase(contact.title)}</p>
                </p>
                {contact.value}

                <br />
                <br />
                <button
                  className="rectangle"
                  style={{ marginRight: "20px", borderRadius: "5px" }}
                  onClick={() => {
                    const newState = {
                      contactData: {
                        name: contact.name,
                        type: contact.type,
                        sequencia: contact.sequencia,
                      },
                    };

                    newState["emailAddress"] = contact.value;
                    newState["isEmailAddressValid"] = validateEmailAddress(
                      contact.value
                    );
                    newState.contactData = {
                      ...newState.contactData,
                      email: contact.value,
                    };

                    this.setState(newState, () => {
                      this.ModalEdit && this.ModalEdit.open();
                    });
                  }}
                >
                  Editar
                </button>
                <button
                  className="rectangle"
                  style={{ borderRadius: "5px" }}
                  onClick={() => {
                    this.setState(
                      {
                        contactData: {
                          name: contact.name,
                          type: contact.type,
                          sequencia: contact.sequencia,
                        },
                      },
                      () => {
                        this.ModalDelete && this.ModalDelete.open();
                      }
                    );
                  }}
                >
                  Excluir
                </button>
              </div>
              <div></div>
            </Card>
          ))}

        <Modal
          ref={(ref) => (this.ModalDelete = ref)}
          shown={false}
          title={`Excluir ${
            contactData["type"] === "telefone" ? "telefone" : "e-mail"
          }`}
          confirmButton={true}
          confirmButtonText="Sim, quero excluir"
          confirmButtonAction={(modal) => {
            const theType = contactData.type;

            modal.close();

            if (
              (clientContactData || []).filter(
                (cData) => cData.type === theType
              ).length === 1
            )
              this.props.showToast(
                "Ops!",
                `É necessário apresentar pelo menos um ${
                  contactData["type"] === "telefone" ? "telefone" : "e-mail"
                } cadastrado. Você poderá editar o ${
                  contactData["type"] === "telefone" ? "número" : "e-mail"
                } inserido.`
              );
            else this.deleteContact();
          }}
          declineButton={true}
          declineButtonText="Cancelar"
        >
          <p>
            Tem certeza que deseja excluir este{" "}
            {contactData["type"] === "telefone"
              ? "número de telefone"
              : "e-mail"}
          </p>
        </Modal>

        <Modal
          ref={(ref) => (this.ModalNewContact = ref)}
          shown={false}
          closeButton={true}
          confirmButton={true}
          confirmButtonText="Confirmar"
          confirmButtonAction={() => this.submitNewContact()}
        >
          <h3 style={{ color: "var(--light-blue)" }}>
            Insira o novo{" "}
            {contactData["type"] === "telefone" ? "telefone" : "e-mail"} e o
            departamento ao qual ele pertence
          </h3>
          {contactData["type"] === "telefone" ? (
            <label>
              <input
                type="text"
                className={`block ${
                  !this.state.isPhoneNumberValid && this.state.haveSubmited
                    ? "error"
                    : ""
                }`}
                placeholder="Digite seu telefone"
                value={this.state.phoneNumber}
                onChange={this.handlePhoneNumberChange}
              />
            </label>
          ) : (
            <label>
              <input
                type="text"
                className={`block ${
                  !this.state.isEmailAddressValid && this.state.haveSubmited
                    ? "error"
                    : ""
                }`}
                placeholder="Digite seu e-mail"
                value={this.state.emailAddress}
                onChange={this.handleEmailAddressChange}
              />
            </label>
          )}
          <Select
            className="block"
            style={{ width: "50%" }}
            options={(contactTypes || []).map((contactType) => ({
              title: toTitleCase(contactType),
              value: contactType,
            }))}
            value={contactData["department"]}
            onChange={(e) =>
              this.setState({
                contactData: {
                  ...contactData,
                  department: e.target.value,
                },
              })
            }
          />
        </Modal>

        <Modal
          ref={(ref) => (this.ModalEdit = ref)}
          shown={false}
          closeButton={true}
          confirmButton={true}
          confirmButtonText="Confirmar"
          confirmButtonAction={() => this.submitChangeData()}
        >
          <h3 style={{ color: "var(--light-blue)" }}>
            Altere o {contactData.type === "email" ? "email" : "telefone"} e o
            departamento ao qual ele pertence
          </h3>
          {contactData.type === "email" ? (
            <label>
              <input
                type="text"
                className={`block ${
                  !this.state.isEmailAddressValid && this.state.haveSubmited
                    ? "error"
                    : ""
                }`}
                placeholder="Digite seu e-mail"
                value={this.state.emailAddress}
                onChange={this.handleEmailAddressChange}
              />
            </label>
          ) : (
            <label>
              <input
                type="text"
                className={`block ${
                  !this.state.isPhoneNumberValid && this.state.haveSubmited
                    ? "error"
                    : ""
                }`}
                placeholder="Digite seu telefone"
                value={this.state.phoneNumber}
                onChange={this.handlePhoneNumberChange}
              />
            </label>
          )}
          <Select
            className="block"
            style={{ width: "50%" }}
            options={(contactTypes || []).map((contactType) => ({
              title: toTitleCase(contactType),
              value: contactType,
            }))}
            onChange={(e) =>
              this.setState({
                contactData: {
                  ...contactData,
                  department: e.target.value,
                },
              })
            }
            value={contactData.department}
          />
        </Modal>
      </HomeTemplate>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(IndustrialContact);
