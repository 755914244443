import memoize from "memoizee";
import HttpHandler from "./HttpHandler";

// MOCK
// export const API_URL = "http://lsoranzo.dev.br:3004";
// const BASIC_AUTH = "";

// DEV
// export const API_URL = "https://homologsbitdna.grupomir.com.br/consigaz";
// const BASIC_AUTH = "Y29uc2lnYXotaG9tb2xvZzoxMjM==";

// PRD
export const API_URL = "https://itdna.grupomir.com.br/consigaz";
const BASIC_AUTH = "QXRlbmRpbWVudG9DbGllbnRlOmYyY3VDaFBsZw==";

class ConsigazAPI {
  constructor(dependencies = { HttpHandler }) {
    const baseUrl = API_URL;
    this.api = new dependencies.HttpHandler({ baseUrl });
  }
  //
  get token() {
    const endpoint = () => `/GeraToken`;
    return this.api
      .get({
        url: endpoint(),
        headers: {
          Authorization: `Basic ${BASIC_AUTH}`,
        },
      })
      .then(({ data: { Token } }) => Token);
  }

  getHeaders = (token) => ({
    "Content-Type": "application/json",
    Authorization: (!token && `Basic ${BASIC_AUTH}`) || undefined,
    token,
  });

  getClientIp = async () => {
    try {
      return await this.api
        .get({
          url: "https://geolocation-db.com/json/",
        })
        .then(({ data }) => data.IPv4);
    } catch (error) {
      return undefined;
    }
  };

  formatDate = (date) =>
    `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}/${String(date.getDate()).padStart(2, "0")}`;

  clearMemoize = () => {
    function getAllMethods(toCheck) {
      var props = [];
      var obj = toCheck;
      do {
        props = props.concat(Object.getOwnPropertyNames(obj));
        obj = Object.getPrototypeOf(obj);
      } while (obj);

      return props
        .sort()
        .filter(
          (e, i, arr) => e !== arr[i + 1] && typeof toCheck[e] == "function"
        );
    }
    getAllMethods(this).forEach((method) => {
      if (method.substr(0, 3) === "mem") this[method].clear();
    });
  };

  memConsultaLocalEntrega = memoize(this.consultaLocalEntrega, {
    promise: true,
  });
  memConsultaSegundaViaBoleto = memoize(this.consultaSegundaViaBoleto, {
    promise: true,
  });
  memConsultaProtocolos = memoize(this.consultaProtocolos, { promise: true });
  memConsultaServicos = memoize(this.consultaServicos, { promise: true });
  memConsultaNotaFiscal = memoize(this.consultaNotaFiscal, { promise: true });
  memConsultaPrevEntrega = memoize(this.consultaPrevEntrega, { promise: true });

  async consultaLocalEntrega(cpfcnpj) {
    const url = `/ConsultaLocalEntrega?cpfcnpj=${cpfcnpj}&versao=1`;
    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async consultaCondominios(cep) {
    const url = `/ConsultaCondominios?cep=${cep}&versao=1`;
    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async consultaOcupacao(codigo, localEntrega, localizacao) {
    const url = `/ConsultaOcupacao?codigo=${codigo}&local-entrega=${localEntrega}&localizacao=${localizacao}`
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async consultaServicos(cpfCnpjOrCode, localEntrega = "PADRAO", segmento) {
    const url = `/ConsultaServicos?cpfcnpj=${cpfCnpjOrCode}&local-entrega=${localEntrega}&segmento=${segmento}`
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async cadastraCondominio(
    CPFCNPJ,
    nome,
    telefone,
    email,
    localizacao,
    codigo,
    codEntrega,
    codEntregaCond,
    nrSequencia,
    conteudo
  ) {
    const url = `/CadastraCondomino`;
    const token = await this.token;

    if (token) {
      return this.api
        .post({
          url,
          headers: this.getHeaders(token),
          body: {
            Condomino: [
              {
                versao: "1",
                usuario: "app-site",
                CPFCNPJ, // Dados do Condômino
                nome, // Dados do Condômino
                telefone: telefone.replace(/[^\d]/g, ""), // Dados do Condômino
                email, // Dados do Condômino
                localizacao, // Composição da informações enviadas pelo Cliente
                codigo, // Código do Condomínio, retornado no método ConsultaCondominios
                "cod-entrega": (codEntrega || "")
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, ""), // Código do Local de Entrega do Condominio, retornado no método ConsultaCondominios
                "cod-entrega-cond": (codEntregaCond || "")
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, ""),
                "nr-sequencia": nrSequencia, // Retornado no método ConsultaCondominios, na lista “tt-tipo-inst”
                conteudo, // Aqui devem ser informados os valores utilizados para compor campo Localização, só que gravados de forma individual
                religue: false,
                instalacao: false,
              },
            ],
          },
        })
        .then(({ data }) => data);
    }
  }

  async geraOcorrencia(
    sessionId,
    servico,
    vlServico,
    codMotivo,
    nrAcordoComercial,
    cpfcnpj,
    localEntrega = "PADRAO",
    complementaryData
  ) {
    const url = `/GeraOcorrencia`;
    const token = await this.token;

    this.memConsultaProtocolos.clear();

    if (token) {
      return this.api
        .post({
          url,
          headers: this.getHeaders(token),
          body: {
            sessionId,
            servico: (complementaryData || {}).servico || servico,
            cpfcnpj,
            "local-entrega": (localEntrega || "")
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, ""),
            "cod-motivo": codMotivo,
            "nr-acordo-comercial": nrAcordoComercial,
            "vl-servico": (complementaryData || {}).value || vlServico,
            "tipo-vazamento": (complementaryData || {}).tipoVazamento,
            "local-vazamento": (complementaryData || {}).localVazamento,
            "gas-fechado": (complementaryData || {}).gasFechado,
            informacoes: (complementaryData || {}).informacoes,

            // Cancelamento
            contato: (complementaryData || {}).contact,
            "sobrenome-depto": (complementaryData || {}).lastNameDept,
            telefone: (complementaryData || {}).phone,
            "e-mail": (complementaryData || {}).email,
            "dt-saida-apto": (complementaryData || {}).leavingDate,
            "portaria-eletronica": (complementaryData || {}).portariaEletronica,
            "morador-no-local": (complementaryData || {}).moradorNoLocal,
            "inform-acesso": (complementaryData || {}).howWillAccess,

            // Contestação
            "leitura-dia": (complementaryData || {}).reading,

            // Ferias coletivas
            "it-codigo": (complementaryData || {}).itCodigo,
            "dt-inicio": (complementaryData || {}).dtInicio,
            "dt-fim": (complementaryData || {}).dtFim,

            usuario: "app-site",
          },
        })
        .then(({ data }) => data);
    }
  }

  async geraAbastecimento(codEmitente, codEntrega, complementaryData) {
    const url = `/GeraAbastecimento`;
    const token = await this.token;

    if (token) {
      return this.api
        .post({
          url,
          headers: this.getHeaders(token),
          body: {
            dsPedido: {
              Itens: [
                {
                  "it-codigo": (complementaryData || {}).selectedModel,
                  qtde: (complementaryData || {}).qtde,
                },
              ],
              Abastecimento: [
                {
                  "cod-emitente": codEmitente || "",
                  "cod-entrega": codEntrega || "",
                  "dt-entrega": (complementaryData || {}).data,
                  usuario: "app-site",
                },
              ],
            },
          },
        })
        .then(({ data }) => data);
    }
  }

  async manutencaoContatos(
    cpfcnpj,
    cpfContato,
    nome,
    telefone,
    email,
    sequencia = undefined,
    tipo = undefined,
    elimina = false
  ) {
    const url = `/ManutencaoContatos`;
    const token = await this.token;

    if (token) {
      return this.api
        .post({
          url,
          headers: this.getHeaders(token),
          body: {
            sequencia,
            cpfcnpj,
            nome,
            telefone: telefone.replace(/[^\d]/g, ""),
            email,
            [`cpf-contato`]: cpfContato,
            tipo,
            elimina,
          },
        })
        .then(({ data }) => data);
    }
  }

  async consultaHistoricoConsumo(
    cpfcnpj,
    dataIni,
    dataFim,
    localEntrega = "PADRAO"
  ) {
    const url = `/ConsultaHistoricoConsumo?cpfcnpj=${cpfcnpj}&data-ini=${dataIni}&data-fim=${dataFim}&local-entrega=${localEntrega}`
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async consultaSegundaViaBoleto(
    cpfcnpj,
    dataIni,
    dataFim,
    localEntrega = "PADRAO",
    quitadas = "no"
  ) {
    const url = `/Consulta2ViaBoleto?cpfcnpj=${cpfcnpj}&data-fim=${dataFim}&data-ini=${dataIni}&quitadas=${quitadas}&origem=app-site&local-entrega=${localEntrega
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")}`;
    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async consultaProtocolos(cpfcnpj, dataFim, dataIni, empresa, localEntrega) {
    const url = `/ConsultaProtocolos?cpfcnpj=${cpfcnpj}&data-fim=${dataFim}&data-ini=${dataIni}&empresa=${empresa}&local-entrega=${(
      localEntrega || ""
    )
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")}&usuario=app-site`;
    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async cancelaProtocolo(cpfcnpj, localEntrega, tipo, nrOrdemServico, motivo) {
    const url = `/CancelaProtocolo?cpfcnpj=${cpfcnpj}&motivo=${encodeURIComponent(
      motivo
    )}&local-entrega=${(localEntrega || "")
      .normalize("NFD")
      .replace(
        /[\u0300-\u036f]/g,
        ""
      )}&tipo=${tipo}&nr-ordem-servico=${nrOrdemServico}&usuario=app-site`;
    const token = await this.token;

    this.memConsultaProtocolos.clear();

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async consultaCartaQuitacao(cpfcnpj, empresa, ano = 9999) {
    const url = `/ConsultaCartaQuitacao?ano=${ano}&cpfcnpj=${cpfcnpj}&empresa=${empresa}`;
    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async verificaProtocolos(codCliente, localEntrega, codMotivo, servico) {
    let url = `/VerificaProtocolo?codigo=${codCliente}&usuario=app-site&local-entrega=${(
      localEntrega || ""
    )
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")}&servico=${servico}`;

    if (codMotivo) url += `&cod-motivo=${codMotivo}`;

    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async consultaNotaFiscal(cpfcnpj, dataIni, dataFim) {
    let url = `/ConsultaNotaFiscal?cpfcnpj=${cpfcnpj}&data-fim=${dataFim}&data-ini=${dataIni}`;
    // if (localEntrega)
    //   url += `&local-entrega=${(localEntrega || "").normalize('NFD').replace(/[\u0300-\u036f]/g, "")}`

    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async consultaPrevEntrega(cpfcnpj, localEntrega, itCodigo) {
    let url = `/ConsultaPrevEntrega?cpfcnpj=${cpfcnpj}&local-entrega=${localEntrega
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")}&it-codigo=${itCodigo}`;
    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async consultaContatos(cpfcnpj) {
    let url = `/ConsultaContatos?cpfcnpj=${cpfcnpj}`;

    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async utilidades(acao, params = undefined) {
    let url = `/Utilidades?acao=${acao}`;

    url += Object.entries(params || {})
      .map(([key, value]) => `&${key}=${value}`)
      .join("");
    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async geraBoleto(titulo) {
    let url = `/GeraBoleto?titulo=${titulo}&origem=site`;

    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async admnistrativeLogin(login, senha) {
    return this.utilidades("login", { login, senha });
  }

  async tiposContato() {
    return this.utilidades("TiposContato");
  }

  async motivosAltProgcao() {
    return this.utilidades("MotivosAltProgcao");
  }

  async verificaAceiteTermo(codEmitente, cpf) {
    return this.utilidades("VerificaAceite", {
      [`cod-emitente`]: codEmitente,
      cpf,
    });
  }

  async aceiteTermo(codEmitente, cpf) {
    const idDispositivo = await this.getClientIp();
    return this.utilidades("AceiteTermo", {
      [`cod-emitente`]: codEmitente,
      cpf,
      [`id-dispositivo`]: idDispositivo,
      origem: "Site",
    });
  }

  async download(acao, titulo, useDescricao2 = false) {
    await this.token;

    return this.utilidades(acao, { [`titulo`]: titulo }).then((response) => {
      try {
        // Valida se a resposta é um array com valor
        const { Retorno } = response;
        if (
          !Array.isArray(Retorno) ||
          (Array.isArray(Retorno) && Retorno.length === 0)
        ) {
          throw new Error("Nenhum resultado foi encontrado.");
        }
        
        if(useDescricao2) {
          const { descricao2 } = Retorno[0];

          if(!!!descricao2) throw new Error("Nenhum conteúdo foi encontrado para essa ação.");

          return [null, processFileDownlod(descricao2)];
        } else {
          const { Descricao, descricao } = Retorno[0];

          if (!!descricao) throw new Error(descricao);
          if (!!!Descricao) throw new Error("Nenhum conteúdo foi encontrado para essa ação.");

          return [null, processFileDownlod(Descricao)];
        }
      } catch (err) {
        return [err.message, null];
      }
    });
  }

  async downloadBoleto(titulo) {
    await this.token;

    return this.geraBoleto(titulo).then((response) => {
      try {
        // Valida se a resposta é um array com valor
        const { Retorno } = response;
        if (
          !Array.isArray(Retorno) ||
          (Array.isArray(Retorno) && Retorno.length === 0)
        ) {
          throw new Error("Nenhum resultado foi encontrado.");
        }
        // // valida se a respota tem algum conteúdo
        const { Descricao, descricao } = Retorno[0];

        // a a API retorna mensaem de erro
        if (!!descricao) {
          throw new Error(descricao);
        }

        // se não vier nenhum conteúdo
        if (!!!Descricao) {
          throw new Error("Nenhum conteúdo foi encontrado para essa ação.");
        }

        // processa o arquivo e faz o download
        return [null, processFileDownlod(Descricao)];
      } catch (err) {
        return [err.message, null];
      }
    });
  }

  async externalInvoice(cpfcnpj, endDate, nota) {
    const dataIni = formatDate(subtractDays(365, new Date(endDate)));
    const { dsRetorno } = await this.consultaSegundaViaBoleto(
      cpfcnpj,
      dataIni,
      endDate,
      "ENTREGA"
    );

    if (
      !dsRetorno.hasOwnProperty("tt-cliente-retorno") ||
      !Array.isArray(dsRetorno["tt-cliente-retorno"])
    ) {
      return ["nenhum arquivo encontrado", null];
    }

    endDate = endDate.replaceAll("/", "-");

    const { "tt-cliente-retorno": invoices } = dsRetorno;
    const invoicesFiltred = invoices.filter(
      (invoice) =>
        invoice["cod_tit_acr"] === nota && invoice["dat_transacao"] === endDate
    );

    if (invoicesFiltred.length === 0) {
      return ["nenhum arquivo encontrado", null];
    }

    return this.download("ArquivoBoleto", tituloBoleto(invoicesFiltred[0]));
  }

  async externaldanfe(cpfcnpj, endDate, nota) {
    const dataIni = formatDate(subtractDays(365, new Date(endDate)));
    const { Notas } = await this.consultaNotaFiscal(cpfcnpj, dataIni, endDate);

    if (
      !Notas.hasOwnProperty("NotasFiscais") ||
      !Array.isArray(Notas["NotasFiscais"])
    ) {
      return ["nenhum arquivo encontrado", null];
    }

    const { NotasFiscais: nfs } = Notas;

    const nfsFiltred = nfs.filter(
      (nfs) => nfs["nr-nota-fis"] === nota && nfs["dt-emis-nota"] === endDate
    );

    if (nfsFiltred.length === 0) {
      return ["nenhum arquivo encontrado", null];
    }

    // const nfs = Notas['NotasFiscais'] || '';
    return this.download("ArquivoNF", tituloNF(nfsFiltred[0]));
  }

  async dinamicBanners() {
    let url = '/RetornaBanner';

    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async generateOwnershipExchangeCode(clientCode) {
    const url = `/VerificaCodigo`;
    const token = await this.token;

    if (token) {
      return this.api
        .post({
          url,
          headers: this.getHeaders(token),
          body: {
            CodCliente: clientCode,
          }
        })
        .then(({ data }) => data);
    }
  }

  async checkOwnershipExchangeCode(exchangeCode) {
    const url = `/ValidaCodigo`;
    const token = await this.token;

    if (token) {
      return this.api
        .post({
          url,
          headers: this.getHeaders(token),
          body: {
            CodigoVerificacao: exchangeCode,
          }
        })
        .then(({ data }) => data);
    }
  }

  async sendEmail(cpfcnpj, empresa, ano = 9999) {
    const url = `/EnviaEmail?EMPRESA=${empresa}&CPFCNPJ=${cpfcnpj}&ANO=${ano}&ACAO=CartaQuitacao&ENVIAEMAIL=Yes`
    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }

  async shareTicket(cod_estab, cod_ser_docto,  cod_tit_acr) {
    let codEstab = cod_estab.toString();
    
    if (codEstab.startsWith('0')) {
      codEstab = codEstab.replace(/^0+/, '');
    }
    
    const url = `/CompartilhamentoBoleto?estabelec=${codEstab}&serie=${cod_ser_docto}&notafiscal=${cod_tit_acr}`
    const token = await this.token;

    if (token) {
      return this.api
        .get({
          url,
          headers: this.getHeaders(token),
        })
        .then(({ data }) => data);
    }
  }
}

const consigazAPI = new ConsigazAPI();
export default Object.freeze(consigazAPI);

function processFileDownlod(base64) {
  // Remapeia o base64 para binário
  var binary = atob(base64.replace(/\s/g, ""));
  var len = binary.length;
  var buffer = new ArrayBuffer(len);

  // converte o binario em bytes para matrix de 24bits
  var view = new Uint8Array(buffer);
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }

  const dataURL = window.URL.createObjectURL(
    new Blob([view], { type: "application/pdf" })
  );
  const a = window.document.createElement(`a`);
  a.href = dataURL;
  a.download = `${Date.now()}_consigaz.pdf`;
  window.document.body.appendChild(a);
  a.click();
  window.open(dataURL);

  return base64;
}

function subtractDays(numOfDays, date = new Date()) {
  date.setDate(date.getDate() - numOfDays);

  return date;
}

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function formatDate(date) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
}

function tituloBoleto(invoiceData) {
  const {
    cod_estab,
    cod_espec_docto,
    cod_ser_docto,
    cod_tit_acr,
    cod_parcela,
  } = invoiceData;
  return `${cod_estab}|${cod_espec_docto}|${cod_ser_docto}|${cod_tit_acr}|${cod_parcela}`;
}

function tituloNF(ticketData) {
  const serie = ticketData["serie"];
  const cod_estab = ticketData["cod-estabel"];
  const nr_nota_fis = ticketData["nr-nota-fis"];
  return `${cod_estab}|${serie}|${nr_nota_fis}`;
}

