import * as types from './types'
import { createReducer } from '../../utils'

/* State shape
  {
    title,
    description
  }
*/

const initialState = null

const reducer = createReducer(initialState)({
  [types.SHOW]: (state, action) => {
    return action.payload
  },
  [types.DISMISS]: () => ({}),
})

export default reducer