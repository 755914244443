import React from "react";
import { getMonthTicketValue } from "../../components/MonthlyTicketsLineChart/MonthlyTicketsLineChart";
import { weightFormat } from "../../components/Masks/Masks";
import Card from "../../components/Card/Card";
import moment from "moment";

export const addressReduceTickets = (data) =>
  (data || []).reduce((result, item) => {
    const key = item["endereco"];
    if (Object.keys(result).indexOf(key) === -1) result[key] = [];
    result[key].push(item);
    return result;
  }, {});

export const getTicketValue = (ticket, filters) => {
  const value = getMonthTicketValue(ticket, filters);

  if (!filters || filters.selectedProduct === "granel")
    return weightFormat(value);
  else return `${(value || 0).toFixed(0)} un.`;
};
const MonthTickets = (props) => {
  const { data, filters } = props;

  if (!data.length) return null;

  const reducedData = addressReduceTickets(data);

  return (
    <Card className="ticket-in-focus" styleName="toplinePrimary">
      {Object.entries(reducedData || {}).map(([key, tickets], idx) => (
        <div key={`month-item-${idx}-${key}`} className="address-item">
          <p className="title">{key}</p>

          {(tickets || []).reverse().map((ticket, subIdx) => (
            <p
              key={`month-item-${idx}-${subIdx}-${key}-${ticket["dt-emis-nota"]}`}
              className="item"
            >
              <span>
                <small>Data de fornecimento</small>
                {moment(ticket["dt-emis-nota"], "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )}
              </span>
              <span style={{ textAlign: "right" }}>
                <small>Volume de gás</small>
                {getTicketValue(ticket, filters)}
              </span>
            </p>
          ))}
        </div>
      ))}
      <p className="text-center no-margin">
        <button className="block link" onClick={() => props.onSendMail(data)}>
          Compartilhar por email
        </button>
      </p>
    </Card>
  );
};

export default MonthTickets;
