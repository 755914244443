import React, { Component } from "react";

import ClienteService from "../../services/cliente";

import apiContext from "../../api";

import Page, { redirect } from "../../components/Page/Page";
import PageCenterContent from "../../components/PageCenterContent/PageCenterContent";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { loadingOperations } from "../../state/ducks/loading";
import CondominioService from "../../services/condominio";

class DeliveryConfirmContentComponent extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    let services = [];
    if (props.location && props.location.state && props.location.state.services)
      services = props.location.state.services;

    this.state = {
      serviceRemoveId: null,
      submited: false,
      services,
    };
  }

  componentDidMount() {
    if (!this.state.submited) this.handleSubmit();
  }

  handleSubmit = () => {
    const {
      CPFCNPJ,
      email,
      [`nome-emit`]: nome, // nome emitente - change
      telefone,
    } = ClienteService.localEntrega;

    const { name } = ClienteService.industrialContato;

    const { [`local-entrega`]: localEntrega } = CondominioService.selected;
    const splitedName = name.split(" ");
    const firstName = splitedName.shift();
    const lastName = splitedName.join(" ");

    const { services } = this.state;
    const updatedServices = [];

    this.context.ConsigazApi.geraOcorrencia(
      "",
      services[0].service,
      services[0].price,
      services[0].codMotivo,
      services[0].nrAcordoComercial,
      CPFCNPJ,
      localEntrega,
      {
        ...services[0].complementaryData,
        contact: firstName,
        lastNameDept: lastName,
        phone: telefone,
        email: email,
      }
    ).then(
      ({ Ocorrencia }) => {
        this.props.dismissLoading();
        if (Ocorrencia) {
          services[0].protocol = Ocorrencia[0]["v-nr-ocorrencia"] || "";
          services[0].dtprev = Ocorrencia[0]["v-dt-prev-atendimento"] || "";
          if (services[0].dtprev.length === 10) {
            const [year, month, day] = services[0].dtprev.split("-");
            services[0].dtprev = new Date(
              parseInt(year),
              parseInt(month) - 1,
              parseInt(day)
            ).toLocaleDateString();
          }
          updatedServices.push(services[0]);

          this.setState(
            {
              submited: true,
              services: updatedServices,
            },
            () => this.props.onFinish && this.props.onFinish(updatedServices)
          );
        } else
          this.props.showToast("Ops!", "Não foi possível gerar a ocorrencia.");
      },
      (errorDescription) => {
        this.props.dismissLoading();
        this.props.showToast("Ops!", String(errorDescription));
      }
    );

    this.setState({
      submited: true,
    });
  };

  render() {
    return null;
  }
}

function mapDispatchToDeliveryConfirmContentProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export const DeliveryConfirmContent = connect(
  null,
  mapDispatchToDeliveryConfirmContentProps
)(DeliveryConfirmContentComponent);

const DeliveryConfirmPage = (props) => (
  <Page history={props.history}>
    <PageCenterContent style={{ width: "900px" }}>
      <DeliveryConfirmContent
        history={props.history}
        location={props.location}
        onFinish={() => redirect(props, "/home")}
      />
    </PageCenterContent>
  </Page>
);

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(DeliveryConfirmPage);
