import React, { Component } from "react";
import PropTypes from "prop-types";

import "./NavigationBar.scss";

export default class NavigationBar extends Component {
  static propTypes = {
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequred,
        onClick: PropTypes.func,
        value: PropTypes.any,
      })
    ).isRequired,
    activeTab: PropTypes.number,
    activeStyle: PropTypes.oneOf(["undermark", "fill"]),
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: props.activeTab,
    };
  }

  getTabClassName(idx) {
    const { activeTab } = this.state;
    const { tabs, activeStyle, defineActive } = this.props;

    if (defineActive)
      return `${
        tabs[idx].active ? `active-${activeStyle || "undermark"}` : ""
      }`;
    else
      return `${
        idx === activeTab ? `active-${activeStyle || "undermark"}` : ""
      }`;
  }

  handleTabClick(e, tab, idx) {
    this.setState(
      { activeTab: idx },
      () => tab.onClick && tab.onClick(tab.value)
    );
  }

  renderTabs() {
    const { tabs } = this.props;

    if (tabs)
      return tabs.map((tab, idx) => !!tab.label && (
        <button
          key={`navbar-button-${idx}`}
          className={`${this.getTabClassName(idx)} ${
            tab.disabled ? "disabled" : ""
          }`}
          onClick={!tab.disabled && ((e) => this.handleTabClick(e, tab, idx))}
        >
          <a
            href={tab.href || "#"}
            target={tab.target || ""}
            onClick={!tab.disabled && tab.onClick}
            className={`clear`}
          >
            {tab.icon && <tab.icon size={20} />}
            {tab.label && <span className="label">{tab.label}</span>}
          </a>
        </button>
      ));
  }

  render() {
    const { style } = this.props;
    return (
      <div className={`navigation-bar ${this.props.className}`} style={style}>
        {this.renderTabs()}
      </div>
    );
  }
}
