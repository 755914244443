import React, { Component } from "react";
import Modal from "../../components/Modal/Modal";

class EmissionBlockedModal extends Component {
  constructor(props) {
    super(props);
  }
 
  render() {
    const { shown } = this.props;

    if (shown === false) return null;

    let filteredProps = {
      ...this.props,
      onSubmit: null,
    };

    return (
      <Modal className="filter-modal" {...filteredProps}>
        <h3>Visualização indisponível. Clique abaixo para falar com a nossa Central de Atendimento ao Cliente.</h3>
        <button className="block" onClick={() => this.props.goToHelp()}>
          Falar com a Central de Atendimento ao Cliente
        </button>
      </Modal>
    );
  }
}
export default EmissionBlockedModal;
