import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Page from '../../components/Page/Page'
import PageCenterContent from '../../components/PageCenterContent/PageCenterContent'
import PageHeaderImage from '../../components/PageHeaderImage/PageHeaderImage'

import CheckIcon from '../../assets/illustrations/checkIcon.png'

export default class ReligueProtocol extends Component {
  render() {
    return (
      <Page
        history={this.props.history}
        className="existent-client-page">
        <PageCenterContent>
          <PageHeaderImage src={CheckIcon} />
          <br />
          <br />
          <br />

          <div>
            <h1>Número do protocolo:</h1>

            <p
              style={{
                color: "var(--light-blue)",
                fontSize: "26px",
                fontWeight: 600,
                fontStyle: "oblique",
              }}
            >
              {this.props?.location.state?.protocolNumber}
            </p>

            <br/>
            <h2>O religue será realizado em até 2 dias úteis.</h2>
          </div>

          <br />
          <br />

          <Link
            to="/save-time"
            className="block"
            onClick={this.setCurrentClient}
          >Avançar</Link><br />
        </PageCenterContent>
      </Page>
    )
  }
}