import React, { Component } from "react";

import "./Recommendation.scss";

import apiContext from "../../api";
import ClienteService from "../../services/cliente";
import BuildingImg from "../../assets/illustrations/buildings.svg";
import HomeTemplate, { redirect } from "../HomeTemplate/HomeTemplate";
import Card from "../../components/Card/Card";
import { IconPlus, IconTelephone } from "../../components/Icons/Icons";
import CondominioService from "../../services/condominio";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { loadingOperations } from "../../state/ducks/loading";
import {
  toTitleCase,
  maskPhoneNumber,
  validatePhoneNumber,
} from "../../components/Masks/Masks";
import Modal from "../../components/Modal/Modal";
import moment from "moment";

class IndustrialContact extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      redirectState: {},
      listContact: this.initialListContact || [],
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
      contactData: this.initialContactData,
      phoneNumber: "",
      isPhoneNumberValid: false,
      deleteData: null,
      address: {},
    };
  }

  get initialContactData() {
    return {
      name: "",
      telefone: null,
    };
  }

  get initialListContact() {
    return {
      name: "",
      telefone: [],
    };
  }

  componentDidMount() {
    const { CPFCNPJ } = ClienteService.localEntrega || {};
    this.props.showLoading();
    this.context.ConsigazApi.consultaLocalEntrega(CPFCNPJ)
      .then(ClienteService.consultaLocalEntregaApiParser)
      .then(({ Cliente }) => {
        if (Cliente) {
          this.props.dismissLoading();
          this.setState({
            address: Cliente.ClienteRetorno[0].ClienteEntrRetorno[0],
          });
        }
      })
      .catch((errorDescription) => {
        this.props.dismissLoading();
        this.setState({}, () => this.props.showToast("Ops!", errorDescription));
      });
  }

  submitRecommendation = () => {
    const {
      contactData,
      listContact,
      deleteData,
      isPhoneNumberValid,
    } = this.state;

    if (contactData["name"] === "" || contactData["telefone"] === null)
      this.props.showToast("OPS", "Insira todos os dados.");
    else if (!isPhoneNumberValid)
      this.props.showToast("OPS", "Insira um telefone válido.");
    else if (deleteData === null) {
      if (listContact["telefone"].indexOf(contactData["telefone"]) === -1) {
        this.setState({
          listContact: { ...listContact, name: contactData["name"] },
        });
        listContact["telefone"].push(contactData["telefone"]);
      } else this.props.showToast("OPS", "Esse número já foi inserido.");
    } else {
      listContact["telefone"].splice(
        listContact["telefone"].indexOf(deleteData),
        1
      );
      this.setState({ deleteData: null });
    }
  };

  generateOrder = () => {
    const { listContact, address } = this.state;
    const { [`local-entrega`]: localEntrega } = CondominioService.selectAll
      ? address
      : CondominioService.selected;
    const {
      CPFCNPJ,
      email,
      [`nome-emit`]: nome,
      telefone,
    } = ClienteService.localEntrega;
    const splitedName = nome.split(" ");
    const firstName = splitedName.shift();
    const lastName = splitedName.join(" ");

    this.context.ConsigazApi.geraOcorrencia(
      null,
      "indicacao",
      null,
      null,
      null,
      CPFCNPJ,
      localEntrega,
      {
        informacoes: `${listContact}`,
        contact: firstName,
        lastNameDept: lastName,
        phone: telefone,
        email: email,
      }
    ).then(
      ({ Ocorrencia }) => {
        this.props.dismissLoading();
        if (Ocorrencia) {
          this.setState({
            protocol: {
              ...Ocorrencia[0],
              title: "Indicação de Clientes",
            },
            content: "protocol",
          });
          this.setState({
            nrpedido: Ocorrencia[0]["v-nr-ocorrencia"],
            prazo: moment(Ocorrencia[0]["v-dt-prev-atendimento"]),
          });

          this.thanksModal && this.thanksModal.open();
        } else
          this.props.showToast("Ops!", "Não foi possível gerar a ocorrencia.");
      },
      (errorDescription) => {
        this.props.dismissLoading();
        this.props.showToast("Ops!", String(errorDescription));
      }
    );
  };

  deleteContact = () => {
    const { contactData } = this.state;
    const { CPFCNPJ } = ClienteService.localEntrega || {};
    const { cpf: cpfContato } = ClienteService.industrialContato || {};

    this.context.ConsigazApi.manutencaoContatos(
      CPFCNPJ,
      cpfContato,
      contactData["name"] || "",
      contactData["telefone"] || "",
      contactData["email"] || "",
      contactData["sequencia"],
      "",
      true
    ).then(
      () => {
        this.props.showToast("Sucesso", "Dado excluido com sucesso!");
        this.loadContactData();
      },
      (errorDescription) => {
        this.props.dismissLoading();
        this.props.showToast("Ops!", String(errorDescription));
      }
    );

    (this.ModalEdit && this.ModalEdit.close()) ||
      (this.ModalEdit && this.ModalEdit.close());
  };

  handlePhoneNumberChange = (e) => {
    const phoneNumberMasked = maskPhoneNumber(e.target.value);
    const { contactData } = this.state;

    this.setState({
      phoneNumber: phoneNumberMasked,
      isPhoneNumberValid: validatePhoneNumber(phoneNumberMasked),
      contactData: {
        ...contactData,
        telefone: phoneNumberMasked,
      },
    });
  };

  render() {
    const {
      redirectState,
      contactData,
      listContact,
      selectedCondominio,
      address,
      nrpedido,
      prazo,
    } = this.state;
    var today = moment(new Date().toISOString().split("T")[0]);
    if (selectedCondominio === "all") CondominioService.selected = address;
    return (
      <HomeTemplate
        ref={(ref) => (this.template = ref)}
        history={this.props.history}
        redirectFrom="ClientRecommendation"
        redirectState={redirectState}
        className="client-recommendation-page"
      >
        <img src={BuildingImg} alt="Prédio" />
        <h1>Indicação de Clientes</h1>
        <p>Indique um novo cliente para a Consigaz.</p>
        <label>
          <input
            className="business-name block"
            placeholder="Nome do contato comercial da empresa"
            type="text"
            maxLength={150}
            onChange={(e) =>
              this.setState({
                contactData: {
                  ...contactData,
                  name: e.target.value,
                },
              })
            }
          ></input>
        </label>
        <label>
          <input
            className="business-tel block"
            placeholder="Telefone da empresa"
            type="text"
            value={this.state.phoneNumber}
            onChange={this.handlePhoneNumberChange}
          ></input>
        </label>
        {listContact && listContact["telefone"].length ? (
          <div className="button-box">
            <button
              className="rectangle"
              onClick={() => {
                listContact && listContact["telefone"].length > 2
                  ? this.props.showToast(
                      "OPS",
                      "Você inseriu o número máximo de telefones (3)."
                    )
                  : this.submitRecommendation();
              }}
            >
              <IconPlus /> Novo telefone
            </button>
          </div>
        ) : (
          ""
        )}
        {listContact &&
          (listContact["telefone"] || []).map((contact) => (
            <Card key={`contact-${contact}`}>
              <div>
                <IconTelephone size={40} />
              </div>
              <div>
                <p className="field-title">{`${toTitleCase("Telefone")} `}</p>
                {contact}
                <br />
                <br />
                <button
                  className="rectangle"
                  style={{ borderRadius: "5px" }}
                  onClick={() => {
                    this.setState(
                      {
                        deleteData: contact,
                      },
                      () => {
                        this.submitRecommendation();
                      }
                    );
                  }}
                >
                  Excluir
                </button>
              </div>
              <div></div>
            </Card>
          ))}
        <div>
          <button
            className="button"
            style={{
              backgroundColor: "#FFF",
              color: "#444",
              borderColor: "#444",
            }}
            onClick={
              listContact && listContact["telefone"].length
                ? this.generateOrder
                : this.submitRecommendation
            }
          >
            Salvar
          </button>
        </div>

        <Modal
          shown={false}
          ref={(ref) => (this.thanksModal = ref)}
          title="Obrigado!"
          closeButton={true}
          confirmButton={true}
          confirmButtonText="Ok"
          confirmButtonAction={() => {
            redirect(this.props, "/home-services", { tab: "protocol" });
          }}
        >
          <br />
          Entraremos em contato com sua indicação no prazo de até{" "}
          {prazo && prazo.diff(today, "days")} dias úteis!
          <br />
          <br />
          <b>Nº do protocolo:</b> {nrpedido}
        </Modal>
      </HomeTemplate>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(IndustrialContact);
