import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Page from '../../components/Page/Page'
import PageCenterContent from '../../components/PageCenterContent/PageCenterContent'
import PageHeaderImage from '../../components/PageHeaderImage/PageHeaderImage'

import BuildingImage from '../../assets/illustrations/building.png'

import './ExistentClient.scss'
import ClienteService from '../../services/cliente'
export default class ExistentClient extends Component {

  getRedirectState() {
    if (this.props.location && this.props.location.state) {
      const locationState = this.props.location.state

      locationState.from = undefined

      return locationState
    }
  }

  setCurrentClient = () => {
    if (this.props.location && this.props.location.state) {
      const { cliente } = this.props.location.state || {}

      if (cliente) {
        ClienteService.localEntrega = cliente
      }
    }
  }

  render () {
    return (
      <Page
        history={this.props.history}
        className="existent-client-page">
        <PageCenterContent>
          <PageHeaderImage src={BuildingImage} />

          <h1>Você já é cadastrado!</h1>
          <p>Encontramos pelo menos um imóvel vinculado ao seu CPF/CNPJ. Se quiser, você pode carregar os registros dos seus imóveis no aplicativo e já começar a usar.</p>

          <Link
            className="block"
            to={{
              pathname: '/wellcome-again',
              state: {
                ...this.getRedirectState(),
                from: '/existent-client'
              }
            }}>
            Carregar registros dos meus endereços
          </Link>

          <br />

          <p>Ou, se preferir, você pode cadastrar um novo imóvel no seu CPF/CNPJ.</p>

          <Link
            to="/address-search"
            className="block"
            onClick={this.setCurrentClient}
            >Cadastrar um novo imóvel</Link><br />
        </PageCenterContent>
      </Page>
    )
  }
}