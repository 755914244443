import React from 'react';
import Modal from "../../components/Modal/Modal";
import WarningImage from "../../assets/illustrations/warning.png";
import { redirect } from '../../components/Page/Page';

const ReactivationModal = (props) => (
  <Modal
    ref={props.modalRef}
    shown={props.shown}
    title="Reativar imóvel?"
    imgSrc={WarningImage}
    closeButton={false}
    confirmButton={true}
    confirmButtonText="Confirmar"
    confirmButtonAction={() =>
      redirect(props, "/address-complement", {
        addressOnReactivation: props.addressOnReactivation,
      })
    }
    declineButton={true}
    declineButtonText="Cancelar"
    declineButtonAction={(modal) => {
      modal.close();
      props.declineButtonAction && props.declineButtonAction(modal)
    }}
  >
    Reative seu cadastro para ter acesso aos nossos serviços
  </Modal>
)

export default ReactivationModal