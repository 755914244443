import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './LabelSwitch.scss'

export default class LabelSwitch extends Component {

  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequred,
      onClick: PropTypes.func,
      value: PropTypes.any
    })).isRequired,
    className: PropTypes.string
  }

  constructor(props) {
    super(props)

    let activeOption = 0
    if (props.selected)
      activeOption = this.getSelectedIdxByValue(props.selected)

    this.state = {
      activeOption
    }
  }

  get className() {
    return [
      'label-switch',
      `active-${this.state.activeOption}`,
      `steps-${this.props.options.length}`,
      this.props.className
    ].filter(c => c).join(' ')
  }

  getSelectedIdxByValue(value) {
    return this.props.options.reduce((reduced, opt, idx) => {
      if (opt.value === value)
        return idx

      return reduced
    }, 0)
  }

  componentDidUpdate() {
    if (this.props.selected) {
      const { activeOption } = this.state
      const selectedIdx = this.getSelectedIdxByValue(this.props.selected)

      if (selectedIdx !== activeOption)
        this.setState({ activeOption: selectedIdx })
    }
  }

  handleOptionClick = (e, opt, activeOption) => {
    this.setState({ activeOption },
      () => opt.onClick && opt.onClick(e, opt.value)
    )
  }

  renderOptions() {
    const { options } = this.props

    if (options && options.length > 0)
      return options.map(
        (opt, activeOption) => (
          <button
            key={`label-switch-option-${activeOption}`}
            onClick={e => this.handleOptionClick(e, opt, activeOption)}>
            {opt.label}
          </button>
        ))
  }

  render() {
    return (
      <div
        className={this.className}>
        {this.renderOptions()}
      </div>
    )
  }

}