import React, { Component } from "react";
import Helmet from "react-helmet"

import "./Home.scss";

import ClienteService from "../../services/cliente";
import CondominioService from "../../services/condominio";

import apiContext from "../../api";

import HomeTemplate, { redirect } from "../HomeTemplate/HomeTemplate";

import Card from "../../components/Card/Card";
import Modal from "../../components/Modal/Modal";
import MonthlyConsumptionLineChart, {
  getMonthLabel,
  getChartData,
} from "../../components/MonthlyConsumptionLineChart/MonthlyConsumptionLineChart";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import Notifications from "../../components/Notifications/Notifications";
import InvoiceModal from "../../components/InvoiceModal/InvoiceModal";
import LabelSwitch from "../../components/LabelSwitch/LabelSwitch";
import {
  maskCpfCnpj,
  maskPhoneNumber,
  moneyFormat,
  toTitleCase,
} from "../../components/Masks/Masks";
import EmptyDataInvoiceImage from "../../assets/illustrations/empty-data-invoice.png";
import CalendarImage from "../../assets/illustrations/calendar.png";

import { IconSpinner, IconSearch } from "../../components/Icons/Icons";
import ConsumptionModal from "../../components/ConsumptionModal/ConsumptionModal";
import InvoiceService from "../../services/invoice";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toastOperations } from "../../state/ducks/toast";
import { SearchOptions } from "../../components/SearchOptions/SearchOptions";
import fastSearchOptions from "../../assets/fast-search";
import MonthlyTicketsLineChart, {
  getTicketsChartData,
} from "../../components/MonthlyTicketsLineChart/MonthlyTicketsLineChart";
import moment from "moment";
import TicketService from "../../services/ticket";
import CardCarrossel from "./CardCarrossel";

const LoadingLine = (props) => (
  <p className="text-center">
    <IconSpinner color="#000" style={{ animation: "1s infinite spin" }} />
  </p>
);

class Home extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    const locaisEntrega = ClienteService.localEntrega;
    const notifications = [];
    const chat = [];
    const { segmento } = ClienteService.localEntrega;

    const { "cod-motivo": codMotivo } = CondominioService.selected || {};

    const { credito } = ClienteService.localEntrega || {};

    if (credito === "Suspenso") {
      if (codMotivo === 41 && segmento === "INDUSTRIAL")
        notifications.push({
          title: "Inadimplência",
          styleName: "danger",
          children: (
            <>
              Cadastro bloqueado para abastecimento. Para restabelecer o
              fornecimento, efetue o pagamento das faturas atrasadas.
            </>
          ),
          closeButton: true,
          onClick: () => redirect(this.props, "/finances", { tab: "invoices" }),
        });
      if (codMotivo === 41 && segmento !== "INDUSTRIAL")
        notifications.push({
          title: "Inadimplência",
          styleName: "danger",
          children: <>O gás deste imóvel está cortado.</>,
          closeButton: true,
          onClick: () =>
            redirect(this.props, "/finances", { tab: "open-invoices" }),
        });
      else if (codMotivo === 53)
        notifications.push({
          title: "Problemas no medidor",
          styleName: "danger",
          children: <>{segmento === 'INDUSTRIAL' ? 'O gás deste endereço está cortado.' : 'O gás deste imóvel está cortado.'}</>,
          closeButton: true,
          onClick: () => redirect(this.props, "/home-services"),
        });
      else if (codMotivo !== 0)
        notifications.push({
          title:
            {
              "15": "Corte no Gás",
              "41": "Inadimplência",
              "51": "Imóvel Cancelado",
              "52": "Cadastro Incompleto",
              "53": "Problemas no Medidor",
            }[String(codMotivo)] || "",
          styleName: "danger",
          children: <>{segmento === 'INDUSTRIAL' ? 'O gás deste endereço está cortado.' : 'O gás deste imóvel está cortado.'}</>,
          closeButton: true,
          onClick: () => redirect(this.props, "/home-services"),
        });
    }

    this.state = {
      addresses: (locaisEntrega && locaisEntrega.ClienteEntrRetorno) || [],
      invoices: null,
      chartCenterIdx: null,
      chartReference: "reais",
      notifications,
      chat,
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
      renderConfirmDataModal: false,
      clientContactData: null,
      addressOnReactivation: null,
    };
  }

  get shouldShowInactive() {
    return this.state.selectedCondominio !== "all" &&
      CondominioService.selected &&
      (CondominioService.selected || {}).situacao === 2
  }

  componentDidMount() {
    const { CPFCNPJ, segmento } = ClienteService.localEntrega || {};
    const { cpf: cpfContato } = ClienteService.industrialContato || {};

    if (segmento === "INDUSTRIAL") this.getIndustrialChartData();
          else this.getContaSimChartData();

    // this.context.ConsigazApi.memConsultaLocalEntrega(CPFCNPJ)
    //   .then(ClienteService.consultaLocalEntregaApiParser)
    //   .then(
    //     () => {
    //       const { segmento } = ClienteService.localEntrega || {};

    //       if (segmento === "INDUSTRIAL") this.getIndustrialChartData();
    //       else this.getContaSimChartData();
    //     },
    //     () =>
    //       this.props.showToast(
    //         "Ops",
    //         "Erro ao buscar as informações de local de entrega."
    //       )
    //   );

    if (segmento === "CONTASIM")
      this.setState({
        renderConfirmDataModal: !ClienteService.haveClosedPersonalDataModal(
          CPFCNPJ
        ),
      });
    else if (segmento === "INDUSTRIAL") {
      this.context.ConsigazApi.consultaContatos(CPFCNPJ).then(({ Cliente }) => {
        if (Cliente && Cliente.ClienteRetorno && Cliente.ClienteRetorno.length)
          if (Cliente.ClienteRetorno[0].ClienteContatoRetorno)
            this.setState({
              renderConfirmDataModal: true,
              clientContactData: (
                Cliente.ClienteRetorno[0].ClienteContatoRetorno || []
              )
                .filter((dados) => dados["cpf-contato"] === cpfContato)
                .reduce(
                  (reduced, contact) => {
                    if (contact["e-mail"]) {
                      reduced.values.push({
                        index: reduced.emails,
                        title: contact.tipo,
                        value: contact["e-mail"],
                        type: "email",
                      });
                      reduced.emails++;
                    }
                    if (contact.telefone) {
                      reduced.values.push({
                        index: reduced.phones,
                        title: contact.tipo,
                        value: maskPhoneNumber(contact.telefone),
                        type: "telefone",
                      });
                      reduced.phones++;
                    }
                    return reduced;
                  },
                  { values: [], phones: 0, emails: 0 }
                ).values,
            });
      });
    }
  }

  getIndustrialChartData() {
    const { CPFCNPJ } = ClienteService.localEntrega;
    
    const dataFim = moment().startOf("month").format("YYYY/MM/DD");
    const dataIni = moment()
      .subtract(1, "year")
      .endOf("month")
      .format("YYYY/MM/DD");
    const { endereco } = CondominioService.selected || {};
    this.context.ConsigazApi.memConsultaNotaFiscal(CPFCNPJ, dataIni, dataFim)
      .then(({ Notas }) => {
        if (Notas && Notas.NotasFiscais && Notas.NotasFiscais.length) {
          const tickets = Notas.NotasFiscais.filter((ticket) => {
            if (endereco) return endereco.indexOf(ticket["endereco"]) > -1;
            return true;
          });

          const notifications = this.state.notifications || [];

          if (tickets && tickets.length) {
            const outdatedTickets = tickets.filter(
              (i) => TicketService.getTicketStatus(i) === "outdated"
            );
            const avencerTickets = tickets.filter(
              (i) => TicketService.getTicketStatus(i) === "avencer"
            );

            if (outdatedTickets.length > 0)
              notifications.push({
                styleName: "toplineDanger",
                children: (
                  <>
                    Faturas em atraso{" "}
                    <span
                      style={{
                        float: "right",
                        color: "var(--red)",
                        fontWeight: "bold",
                      }}
                    >
                      {outdatedTickets.length}
                    </span>
                    <br />
                    Efetue o pagamento e evite a suspensão do abastecimento.
                  </>
                ),
                closeButton: true,
                onClick: () =>
                  redirect(this.props, "/finances", {
                    tab: "invoices",
                    selectedStatus: ["outdated"],
                  }),
              });

            if (avencerTickets.length > 0)
              notifications.push({
                styleName: "toplinePrimary",
                children: (
                  <>
                    Faturas a vencer{" "}
                    <span
                      style={{
                        float: "right",
                        color: "var(--blue)",
                        fontWeight: "bold",
                      }}
                    >
                      {avencerTickets.length}
                    </span>
                    <br />
                    Você tem {avencerTickets.length} faturas que vencerão nos
                    próximos 3 dias. Efetue o pagamento e evite a suspensão do
                    abastecimento.
                  </>
                ),
                closeButton: true,
                onClick: () =>
                  redirect(this.props, "/finances", {
                    tab: "invoices",
                    selectedStatus: ["outdated"],
                  }),
              });
          }
          this.setState({
            tickets,
            chartReference:
              tickets &&
                tickets.some((ticket) =>
                  (ticket.Itens || []).some((i) => i.un.toLowerCase() === "kg")
                ) === true
                ? "granel"
                : "cilindros",
            chartCenterIdx: Notas.NotasFiscais.length - 1,
            invoiceInFocus: Notas.NotasFiscais[Notas.NotasFiscais.length - 1],
          });
        } else {
          this.setState({ tickets: [] });
        }
      })
      .catch((errorDescription) =>
        this.props.showToast("Ops!", errorDescription)
      );
  }

  generateOrder = () => {
    const { complementaryData } = this.state;
    const {
      [`local-entrega`]: localEntrega,
      email,
      [`nome-emit`]: nome,
      telefone,
    } = CondominioService.selected;
    
    const splitedName = nome.split(" ");
    const firstName = splitedName.shift();
    const lastName = splitedName.join(" ");

    const { CPFCNPJ } = ClienteService.localEntrega;

    this.context.ConsigazApi.geraOcorrencia(
      null,
      "reativacao",
      null,
      null,
      null,
      CPFCNPJ,
      localEntrega,
      {
        ...complementaryData,
        contact: firstName,
        lastNameDept: lastName,
        phone: telefone,
        email: email,
      }
    ).then(
      ({ Ocorrencia }) => {
        if (Ocorrencia) {
          this.setState({
            protocol: {
              ...Ocorrencia[0],
              title: "Reativação",
            },
            content: "protocol",
          });
          redirect(this.props, "/home-services", { tab: "protocol" });
        } else
          this.props.showToast("Ops!", "Não foi possível gerar a ocorrencia.");
      },
      (errorDescription) => {
        this.props.showToast("Ops!", String(errorDescription));
      }
    );
  };

  getContaSimChartData() {
    const { CPFCNPJ } = ClienteService.localEntrega;
    const { [`local-entrega`]: localEntrega } =
      CondominioService.selected || {};

    const dataFim = new Date();
    const dataIni = new Date();
    dataIni.setFullYear(dataFim.getFullYear() - 1);

    this.context.ConsigazApi.memConsultaSegundaViaBoleto(
      CPFCNPJ,
      this.context.ConsigazApi.formatDate(dataIni),
      this.context.ConsigazApi.formatDate(dataFim),
      localEntrega
    )
      .then(({ dsRetorno }) => {
        if (dsRetorno && dsRetorno["tt-cliente-retorno"]) {
          const invoices = dsRetorno["tt-cliente-retorno"];

          const notifications = this.state.notifications || [];
          if (invoices && invoices.length) {
            const openInvoices = invoices.filter(
              (i) => InvoiceService.getInvoiceStatus(i) === "outdated"
            );

            if (openInvoices.length > 0)
              notifications.push({
                styleName: "toplineDanger",
                children: (
                  <>
                    Você possui faturas pendentes{" "}
                    <span
                      style={{
                        float: "right",
                        color: "var(--red)",
                        fontWeight: "bold",
                      }}
                    >
                      {openInvoices.length}
                    </span>
                  </>
                ),
                closeButton: true,
                onClick: () =>
                  redirect(this.props, "/finances", { tab: "open-invoices" }),
              });
          }

          const validInvoices = InvoiceService.getValidInvoices(invoices);

          this.setState({
            invoices,
            notifications,
            chartCenterIdx: validInvoices.length - 1,
            invoiceInFocus: validInvoices[validInvoices.length - 1],
          });
        } else {
          this.setState({ invoices: [] });
        }
      })
      .catch((errorDescription) =>
        this.props.showToast("Ops!", errorDescription)
      );
    this.context.ConsigazApi.memConsultaServicos(CPFCNPJ, localEntrega)
      .then(({ ServicoRetorno }) => {
        this.setState({ services: ServicoRetorno || [] });
      })
      .catch(() => console.warn("Erro ao buscar os serviços"));
  }

  getChartData() {
    const { tickets, invoices, chartCenterIdx, chartReference } = this.state;
    const { segmento } = ClienteService.localEntrega || {};

    if (segmento === "INDUSTRIAL")
      return getTicketsChartData(tickets, chartCenterIdx, {
        selectedProduct: chartReference,
      });
    else return getChartData(invoices, chartCenterIdx, chartReference);
  }

  getProximaLeitura() {
    const { invoices } = this.state;

    let lastInvoice =
      invoices && invoices.length && invoices[invoices.length - 1];

    if (!lastInvoice) return null;

    try {
      return new Date(
        `${lastInvoice["tt-dados-gerais"][0]["prox-leitura"]} 00:00:00`
      ).toLocaleDateString("pt-br");
    } catch (error) { }

    return null;
  }

  renderPersonalDataModal() {
    const {
      renderConfirmDataModal: shouldRenderModal,
      clientContactData,
    } = this.state;
    const { CPFCNPJ, [`nome-emit`]: nome, telefone, email, segmento } =
      ClienteService.localEntrega || {};

    if (!CPFCNPJ || ClienteService.haveClosedPersonalDataModal(CPFCNPJ))
      return null;

    if (shouldRenderModal && segmento === "CONTASIM") {
      return (
        <Modal
          ref={(ref) => (this.personalDataModal = ref)}
          title="Confirme seus dados"
          closeButton={true}
          className="personal-data-home-modal"
        >
          <div className="content">
            <p className="field-title">Cpf</p>
            {maskCpfCnpj(CPFCNPJ)}

            <p className="field-title">Nome completo</p>
            {nome}

            <p className="field-title">Telefone</p>
            {maskPhoneNumber(telefone)}

            <p className="field-title">Email</p>
            {email}
          </div>

          <footer>
            <button
              onClick={() => {
                ClienteService.setHaveClosedPersonalDataModal(CPFCNPJ);
                redirect(this.props, "/personal-data");
              }}
              className="block link"
            >
              Completar meus dados
            </button>

            <button
              onClick={() => {
                ClienteService.setHaveClosedPersonalDataModal(CPFCNPJ);
                this.personalDataModal && this.personalDataModal.close();
              }}
              className="block link secondary"
            >
              Meus dados estão corretos
            </button>
          </footer>
        </Modal>
      );
    } else if (shouldRenderModal && segmento === "INDUSTRIAL") {
      return (
        <Modal
          ref={(ref) => (this.personalDataModal = ref)}
          title="Confirme os dados de contato da empresa"
          closeButton={true}
          className="personal-data-home-modal"
        >
          <div className="content">
            {clientContactData.map((contact) => (
              <>
                <p className="field-title">{`${toTitleCase(contact.type)} ${contact.index + 1
                  }`}</p>
                <p>
                  <small>{toTitleCase(contact.title)}</small>
                </p>
                {contact.value}
              </>
            ))}
          </div>

          <footer>
            <button
              onClick={() => {
                ClienteService.setHaveClosedPersonalDataModal(CPFCNPJ);
                redirect(this.props, "/personal-data");
              }}
              className="block link"
            >
              Atualizar os dados
            </button>

            <button
              onClick={() => {
                ClienteService.setHaveClosedPersonalDataModal(CPFCNPJ);
                this.personalDataModal && this.personalDataModal.close();
              }}
              className="block link secondary"
            >
              Os dados estão corretos
            </button>
          </footer>
        </Modal>
      );
    }
  }

  renderInvoiceCards() {
    const { invoices } = this.state;
    const proximaLeitura = this.getProximaLeitura();

    let lastInvoice =
      invoices && invoices.length && invoices[invoices.length - 1];

    return (
      (
        <>
          <Card
            title="Última fatura"
            styleName="primary"
            className="last-invoice"
            footerButtons={[
              {
                label: "Ver fatura",
                onClick: () => {
                  const validInvoices = InvoiceService.getValidInvoices(
                    invoices
                  );
                  this.setState(
                    {
                      chartCenterIdx: validInvoices.length - 1,
                      invoiceInFocus: validInvoices[validInvoices.length - 1],
                    },
                    () => this.invoiceModal && this.invoiceModal.open()
                  );
                },
                disabled: !lastInvoice,
              },
            ]}
          >
            {(lastInvoice && (
              <>
                {InvoiceService.getInvoiceBadge(lastInvoice)}
                {(() => {
                  const date = new Date(
                    `${lastInvoice["dat_transacao"]} 00:00:00`
                  );
                  return `${String(date.getMonth() + 1).padStart(
                    2,
                    0
                  )}/${date.getFullYear()}`;
                })()}
                <span className="price">
                  {moneyFormat(lastInvoice["val_origin_tit_acr"])}
                </span>
              </>
            )) ||
              "Não existem faturas abertas"}
          </Card>
          {proximaLeitura && (
            <Card title="Data prevista da próxima leitura" styleName="clear">
              {proximaLeitura}
            </Card>
          )}
          <CardCarrossel />
        </>
      ) || <LoadingLine />
    );
  }

  renderContaSimContent(fastSearchText, filteredSearch) {
    const { invoices, chartCenterIdx } = this.state;

    const chartData = this.getChartData();

    return (
      <>
        {<Notifications items={this.state.notifications} />}
        <div className="home-page-grid">
          <div className="invoices">
            <p
              style={{
                paddingTop: "2rem",
                textAlign: "left",
                position: "relative",
              }}
            >
              <input
                type="text"
                className="block"
                onChange={(e) =>
                  this.setState({ fastSearchText: e.target.value })
                }
                value={fastSearchText}
                style={{
                  color: "grey",
                  borderColor: "darkgrey",
                  width: "calc(100% - 30px)",
                }}
                placeholder="O que você está procurando ?"
              />
              <IconSearch
                color="#333"
                style={{
                  width: "16px",
                  marginLeft: "-22px",
                  marginRight: "6px",
                }}
              />
              {fastSearchText && (
                <span style={{ margin: "0 auto", maxHeight: "0px" }}>
                  <SearchOptions
                    itemLeftIcon={IconSearch}
                    showEmptyMessage={
                      filteredSearch && filteredSearch.length === 0
                    }
                    options={filteredSearch}
                    style={{
                      position: "absolute",
                      transform: "translateX(-50%)",
                      left: "50%",
                      top: "62px",
                      zIndex: 999,
                      "-webkit-box-shadow": "4px 6px 21px -4px rgba(0,0,0,0.5)",
                      "-moz-box-shadow": "4px 6px 21px -4px rgba(0,0,0,0.5)",
                      "box-shadow": "4px 6px 21px -4px rgba(0,0,0,0.5)",
                      marginTop: ".3rem",
                    }}
                  />
                </span>
              )}
              <br />
            </p>
            {(invoices !== null && this.renderInvoiceCards()) || (
              <LoadingLine />
            )}
          </div>
          {(invoices && (
            <>
              <div className="chart">
                <p className="title">Histórico de consumo</p>
                {chartData.length ? (
                  <>
                    <div className="chart-container">
                      <MonthlyConsumptionLineChart data={chartData} />
                    </div>
                    <NavigationBar
                      tabs={chartData.map((month) => ({
                        value: month,
                        label: getMonthLabel(month),
                        active: month.selected,
                        onClick: () =>
                          this.setState({
                            chartCenterIdx: month.itemIdx,
                            invoiceInFocus: month,
                          }),
                      }))}
                      defineActive={true}
                    />
                  </>
                ) : (
                  <p className="text-center">
                    <img
                      src={EmptyDataInvoiceImage}
                      alt="Empty chart placeholder"
                      height={100}
                    />
                  </p>
                )}
                <p className="text-center">
                  <button
                    className="block link"
                    onClick={() => {
                      this.setState(
                        {
                          invoiceInFocus: InvoiceService.getValidInvoices(
                            invoices
                          )[chartCenterIdx],
                        },
                        () =>
                          this.consumptionModal && this.consumptionModal.open()
                      );
                    }}
                    disabled={chartData.length === 0}
                  >
                    Detalhamento do consumo
                  </button>
                </p>
              </div>
            </>
          )) || <LoadingLine />}
        </div>
      </>
    );
  }

  renderNextDelivery() {
    const nextDelivery = TicketService.getNextDelivery();

    const { ClienteEntrRetorno: addressList } = ClienteService.localEntrega || {};
    const selectAll = CondominioService.selectAll;

    if(selectAll) {
      let deliveryList = [];
      addressList.forEach(address => {
        const nextDelivery = TicketService.getNextDeliveryFromAddress(address);
        if(nextDelivery) deliveryList.push({ ...nextDelivery, endereco: address.endereco });
      })
      
      return (
        <>
          <div
            className="services-container"
            style={{ display: "block", maxHeight: "calc(90vh - 220px)" }}
          >
            <div style={{ marginBottom: "1rem" }}>
              <CardCarrossel />
            </div>
            {
              deliveryList.map((nextDelivery) => (  
                <Card className="text-left">
                  <p className="title"
                    style={{
                      color: "var(--yellow)",
                      fontSize: 16,
                    }}
                  >
                    {nextDelivery.endereco}
                  </p>

                  <p className="no-margin"
                    style={{
                      fontSize: 16,
                    }}
                  >Previsão da próxima entrega</p>
                  <b
                    style={{
                      display: "block",
                      margin: ".4rem auto",
                      fontSize: 20,
                    }}
                  >
                    {nextDelivery["dt-prox-entrega"] &&
                      moment(nextDelivery["dt-prox-entrega"], "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )}
                  </b>

                  <p className="no-margin">
                    Produto: <b>{nextDelivery["desc-comercial"]}</b>
                  </p>
                  <p className="no-margin">
                    Programação: <b>{nextDelivery["programacao"]}</b>
                  </p>
                </Card>
            ))}
          </div>
        </>
      )
    } else if(nextDelivery) {
      return (
        <div
          className="services-container"
          style={{ display: "block", maxHeight: "calc(80vh - 220px)" }}
        >
          {!selectAll ?<Card className="text-center">
            <p className="title no-margin">Previsão da próxima entrega</p>
            <b
              style={{
                display: "block",
                margin: ".7rem auto",
                fontSize: 20,
              }}
            >
              {nextDelivery["dt-prox-entrega"] &&
                moment(nextDelivery["dt-prox-entrega"], "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )}
            </b>

            <p className="no-margin">
              Produto: <b>{nextDelivery["desc-comercial"]}</b>
            </p>
            <p className="no-margin">
              Programação: <b>{nextDelivery["programacao"]}</b>
            </p>

            <br />

            {/* <button
              className="block link"
              style={{ color: "#fff" }}
              onClick={() =>
                redirect(this.props, "/home-delivery", {
                  selectedService: "23;;",
                })
              }
            >
              Ver entregas
            </button> */}
          </Card> : 
            <span>todos..</span>
          }
          <CardCarrossel />
        </div>
      );
    } else if(!this.shouldShowInactive){
      return (
        <>
          <Card
            styleName="toplinePrimary"
            className="text-center"
            style={{ paddingTop: "1rem", cursor: "pointer" }}
            onClick={() =>
              redirect(this.props, "/home-delivery", { selectedService: "23;;" })
            }
          >
            <img src={CalendarImage} height={60} alt="Calendar" />
            <p className="title no-margin">Evite ficar desabastecido!</p>
            <small
              style={{
                color: "var(--light-blue-shade)",
                display: "block",
                marginBottom: "1rem",
              }}
            >
              Cadastre uma programação de entrega automática.
            </small>
            {/* <button className="block link">Ver entregas</button> */}
          </Card>
          <CardCarrossel />
        </>
      );
    }
  }

  renderIndustrialContent(fastSearchText, filteredSearch) {
    const { tickets, chartReference } = this.state;
    const chartData = this.getChartData();

    return (
      <>
        {<Notifications items={this.state.notifications} />}
        <div className="home-page-grid" style={{ paddingTop: 0 }}>
          <div className="next-read">
            {this.shouldShowInactive ? (
              <div
                style={{
                  display: "inline-block",
                  margin: "1rem auto",
                  border: "1px dashed gray",
                  padding: ".7rem",
                }}
              >
                Este endereço está inativo&nbsp;&nbsp;
                <button
                  className="no-margin"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState(
                      {
                        addressOnReactivation: CondominioService.selected || {},
                      },
                      () => this.reactivateModal && this.reactivateModal.open()
                    );
                  }}
                >
                  Reativar cadastro
                </button>
              </div>
            ) : (
              ""
            )}
            <p style={{ paddingTop: "2rem" }}>
              <input
                type="text"
                className="block"
                onChange={(e) =>
                  this.setState({ fastSearchText: e.target.value })
                }
                value={fastSearchText}
                style={{
                  margin: "0 auto",
                  color: "grey",
                  borderColor: "darkgrey",
                }}
                placeholder="O que você está procurando ?"
              />
              <IconSearch
                color="#333"
                style={{
                  width: "16px",
                  marginLeft: "-22px",
                  marginRight: "6px",
                }}
              />
              <br />
              {fastSearchText && (
                <span style={{ margin: "0 auto", maxHeight: "0px" }}>
                  <SearchOptions
                    itemLeftIcon={IconSearch}
                    showEmptyMessage={
                      filteredSearch && filteredSearch.length === 0
                    }
                    options={filteredSearch}
                    style={{
                      position: "absolute",
                      transform: "translateX(-50%)",
                      zIndex: 999,
                      "-webkit-box-shadow": "4px 6px 21px -4px rgba(0,0,0,0.5)",
                      "-moz-box-shadow": "4px 6px 21px -4px rgba(0,0,0,0.5)",
                      "box-shadow": "4px 6px 21px -4px rgba(0,0,0,0.5)",
                      marginTop: ".3rem",
                    }}
                  />
                </span>
              )}
            </p>
            {tickets ? this.renderNextDelivery() : <LoadingLine />}
          </div>
          {(tickets && (
            <div className="chart">
              <p className="title">Histórico de consumo</p>
              <p>
                <small>
                  {this.state.selectedCondominio !== "all"
                    ? this.state.selectedCondominio.endereco
                    : "Todos os endereços"}
                </small>
              </p>
              
              {chartData.length ? (
                <>
                  <LabelSwitch
                    className="invoice-type-switch"
                    options={[
                      (tickets.some((ticket) =>
                        (ticket.Itens || []).some(
                          (i) => i.un.toLowerCase() === "kg"
                        )
                      ) && {
                        label: "Granel",
                        onClick: () =>
                          this.setState({ chartReference: "granel" }),
                      }) ||
                      null,
                      (tickets.some((ticket) =>
                        (ticket.Itens || []).some(
                          (i) => i.un.toLowerCase() !== "kg"
                        )
                      ) && {
                        label: "Cilindros",
                        onClick: () =>
                          this.setState({ chartReference: "cilindros" }),
                      }) ||
                      null,
                    ].filter((e) => e)}
                  />
                  <br />
                  <div className="chart-container industrial">
                    <MonthlyTicketsLineChart
                      data={chartData}
                      filters={{
                        selectedProduct: chartReference,
                      }}
                      chartReference={this.state.chartReference}
                    />
                  </div>
                  <NavigationBar
                    tabs={chartData.map((month) => ({
                      value: month,
                      label: getMonthLabel(month),
                      active: month.selected,
                      onClick: () =>
                        this.setState({
                          chartCenterIdx: month.itemIdx,
                          invoiceInFocus: month,
                        }),
                    }))}
                    defineActive={true}
                  />
                </>
              ) : (
                <>
                  <p
                    className="text-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => redirect(this.props, "/consumption")}
                  >
                    <img
                      src={EmptyDataInvoiceImage}
                      alt="Empty chart placeholder"
                      height={100}
                    />
                    <p>Aqui você visualizará seu consumo por mês</p>
                  </p>
                </>
              )}
            </div>
          )) || <LoadingLine />}
        </div>
      </>
    );
  }

  render() {
    const { invoiceInFocus, fastSearchText, services } = this.state;
    const { segmento } = ClienteService.localEntrega || {};

    const filteredSearch =
      (fastSearchText &&
        [
          ...(fastSearchOptions || [])
            .filter((opt) => !opt.segmento || opt.segmento === segmento)
            .map((opt) => ({
              title: toTitleCase(opt.title),
              description: opt.subtitle,
              related: [opt.title, opt.subtitle, ...opt.related],
              onClick: () => opt.action(this.props),
            })),
          ...((segmento !== "INDUSTRIAL" && services) || []).map((serv) => ({
            title: toTitleCase(serv.descricao),
            description: "Serviços",
            related: ["serviços", serv.descricao],
            onClick: () => redirect(this.props, "/home-services"),
          })),
        ].filter(
          (opt) =>
            opt.related
              .join("")
              .toLowerCase()
              .indexOf(fastSearchText.toLowerCase()) > -1
        )) ||
      null;

    return (
      <HomeTemplate
        className="home-page"
        ref={(ref) => (this.template = ref)}
        history={this.props.history}
        activeTab="Home"
      >
        {segmento === "INDUSTRIAL"
          ? this.renderIndustrialContent(fastSearchText, filteredSearch)
          : this.renderContaSimContent(fastSearchText, filteredSearch)}
        {this.renderPersonalDataModal()}
        {invoiceInFocus && (
          <>
            <InvoiceModal
              ref={(ref) => (this.invoiceModal = ref)}
              template={this.template}
              invoiceData={invoiceInFocus}
              onCopy={() => this.modalBoleto && this.modalBoleto.close()}
            />
            <ConsumptionModal
              ref={(ref) => (this.consumptionModal = ref)}
              history={this.props.history}
              invoiceData={invoiceInFocus}
              onShowInvoice={(invoiceData) => {
                this.invoiceModal && this.invoiceModal.open();
                this.consumptionModal && this.consumptionModal.close();
              }}
            />
          </>
        )}
        <Modal
          shown={false}
          ref={(ref) => (this.reactivateModal = ref)}
          title="Reative!"
          closeButton={true}
          confirmButton={true}
          confirmButtonText="Ok"
          confirmButtonAction={this.generateOrder}
        >
          Ficaremos muito felizes em tê-lo de volta! Entraremos em contato para
          apresentar os próximos passos.
        </Modal>
        {segmento === "INDUSTRIAL" && (
          <>
            <Helmet>
              <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=ba090242-af83-497a-b5d1-659fe56bd4d1"></script>
            </Helmet>
          </>
        )}
      </HomeTemplate>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Home);
