import React from 'react'

import HomeTemplate from '../../pages/HomeTemplate/HomeTemplate'
import { ServicesConfirmContent } from '../ServicesConfirm/ServicesConfirm'

export default props => (
  <HomeTemplate
    history={props.history}
    activeTab="HomeServicesConfirm"
    className="home-services-confirm-page">
    <ServicesConfirmContent
      history={props.history}
      location={props.location} />
  </HomeTemplate>
)