import React, { Component } from "react";

import "./AddressList.scss";

import apiContext from "../../api";
import ClienteService from "../../services/cliente";

import HomeTemplate, { redirect } from "../HomeTemplate/HomeTemplate";
import Card from "../../components/Card/Card";
import {
  IconHome,
  IconPlus,
  IconClose,
  IconCheck,
} from "../../components/Icons/Icons";
import CondominioService from "../../services/condominio";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { loadingOperations } from "../../state/ducks/loading";
import ReactivationModal from "./ReactivationModal";
import Search from "../../components/Search/Search";
import Modal from "../../components/Modal/Modal";

class AddressList extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      addressList: [],
      redirectState: {},
      selectedCondominio: CondominioService.selected || {},
      addressOnReactivation: null,
      selectedAddress: "",
      reactivationButton: false,
      complementaryData: [],
      service: "reativacao",
    };
  }

  componentDidMount() {
    const { CPFCNPJ } = ClienteService.localEntrega || {};

    this.props.showLoading();
    this.context.ConsigazApi.consultaLocalEntrega(CPFCNPJ)
      .then(ClienteService.consultaLocalEntregaApiParser)
      .then(({ Cliente }) => {
        if (Cliente) {
          this.props.dismissLoading();
          this.setState({
            addressList: Cliente.ClienteRetorno[0].ClienteEntrRetorno,
          });
        }
      })
      .catch((errorDescription) => {
        this.props.dismissLoading();
        this.setState({}, () => this.props.showToast("Ops!", errorDescription));
      });
  }

  isSelected = (address) =>
    address["cod-condominio"] ===
      this.state.selectedCondominio["cod-condominio"] &&
    address["local-entrega"] === this.state.selectedCondominio["local-entrega"];

  isSelectedForReactivation = (address) =>
    address["cod-condominio"] ===
      this.state.selectedCondominio["cod-condominio"] &&
    address["local-entrega"] === this.state.selectedCondominio["local-entrega"];

  iscomplementaryData = (address) => {
    const { complementaryData } = this.state;
    if (complementaryData.indexOf(address) > -1) return true;
  };

  getAddressStyleName = (address) => {
    if (this.isSelected(address)) return "primary";
    else if (address.situacao === 1) return "ontlineSecondary";
    else if (address.situacao === 2) return "outlineSecondaryDashed";
    else return "outlineSecondaryDashed";
  };

  getStyleReactivation = (address) => {
    const { reactivationButton } = this.state;
    if (reactivationButton === true && this.iscomplementaryData(address))
      return "primary";
    else return "ontlineSecondary";
  };

  selectCondominio = (address) => {
    if (address) {
      CondominioService.selected = address;
      this.setState({ selectedCondominio: address }, () =>
        setTimeout(() => redirect(this.props, "/home"), 500)
      );
    }
  };

  selectCondominioReactivation = (address) => {
    const { complementaryData } = this.state;
    if (complementaryData.indexOf(address) === -1)
      this.setState({
        complementaryData: [...complementaryData, address],
      });
    else
      this.setState({
        complementaryData: complementaryData.filter((un) => un !== address),
      });
  };

  renderButton() {
    const { CPFCNPJ, segmento } = ClienteService.localEntrega || {};
    const { reactivationButton, complementaryData } = this.state;
    if (!CPFCNPJ) return null;

    if (segmento === "CONTASIM") {
      return (
        <footer>
          <button
            onClick={() => redirect(this.props, "/address-search")}
            className="fab-add-address"
          >
            <IconPlus size={30} />
          </button>
        </footer>
      );
    }
    if (
      segmento === "INDUSTRIAL" &&
      reactivationButton === true &&
      complementaryData.length !== 0
    ) {
      return (
        <div
          style={{
            width: "100%",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            padding: "1rem 0",
          }}
        >
          <button
            className="button"
            style={{
              backgroundColor: "#FFF",
              color: "#444",
              borderColor: "#444",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.setState(
                () =>
                  this.confirmReactivationModal &&
                  this.confirmReactivationModal.open()
              );
            }}
          >
            Avançar
          </button>
        </div>
      );
    }
  }

  renderAddressList() {
    const {
      addressList,
      selectedAddress,
      reactivationButton,
    } = this.state;
    const { isAdmLogin, segmento } = ClienteService.localEntrega || {};

    const addressFilter = addressList
      .filter((address) => {
        if (reactivationButton === false)
          return (
            address["endereco"].indexOf(selectedAddress.toUpperCase()) > -1
          );
        else return address;
      })
      .filter((address) => {
        if (reactivationButton === true && !selectedAddress)
          return address["situacao"] === 2;
        else if (reactivationButton === true && selectedAddress)
          return (
            address["situacao"] === 2 &&
            address["endereco"].indexOf(selectedAddress.toUpperCase()) > -1
          );
        else return address;
      });

    const addressInactiveFilter = (addressList || []).filter(
      (address) => address["situacao"] === 2
    );

    return (
      <>
        {reactivationButton === false &&
          !selectedAddress &&
          addressList &&
          addressList.map((address) => (
            <Card
              key={`address-${address["cod-condominio"]}-${address["local-entrega"]}`}
              className={this.isSelected(address) ? "" : "no-shadow"}
              styleName={this.getAddressStyleName(address)}
              onClick={() => this.selectCondominio(address)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconHome size={40} />
              </div>
              <div>
                <p className="title">{address["nome-condominio"]}</p>
                <p>{address.endereco}</p>
                <p>{`${address.cidade} / ${address.estado}`}</p>

                {address.situacao === 2 && segmento === "INDUSTRIAL" && (
                  <button
                    style={{
                      margin: ".5rem 0",
                      backgroundColor: "#ea454b",
                      borderColor: "#ea454b",
                    }}
                    disabled
                  >
                    Local inativo
                  </button>
                )}
                {address.situacao === 2 &&
                  !isAdmLogin &&
                  segmento !== "INDUSTRIAL" && (
                    <button
                      style={{ margin: ".5rem 0" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.setState(
                          { addressOnReactivation: address },
                          () =>
                            this.reactivateModal && this.reactivateModal.open()
                        );
                      }}
                    >
                      Reativar Imóvel
                    </button>
                  )}
              </div>
              <div>
                {!isAdmLogin &&
                  segmento !== "INDUSTRIAL" &&
                  (address.situacao !== 2 ? (
                    <span
                      className="icon-button"
                      style={{
                        borderColor: this.isSelected(address)
                          ? "#fff"
                          : "#ef464e",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        redirect(this.props, "/cancellation", { address });
                      }}
                    >
                      <IconClose
                        size={30}
                        color={this.isSelected(address) ? "#fff" : "#ef464e"}
                      />
                    </span>
                  ) : (
                    <span
                      className="icon-button"
                      style={{
                        borderColor: "#999",
                      }}
                    >
                      <IconClose size={30} color="#999" />
                    </span>
                  ))}
              </div>
            </Card>
          ))}

        {selectedAddress &&
          addressFilter &&
          addressFilter.map((address) => (
            <Card
              key={`address-${address["cod-condominio"]}-${address["local-entrega"]}`}
              className={
                reactivationButton === false
                  ? this.isSelected(address)
                    ? ""
                    : "no-shadow"
                  : this.iscomplementaryData(address)
                  ? ""
                  : "no-shadow"
              }
              styleName={
                reactivationButton === false
                  ? this.getAddressStyleName(address)
                  : this.getStyleReactivation(address)
              }
              onClick={() => {
                reactivationButton === false
                  ? this.selectCondominio(address)
                  : this.selectCondominioReactivation(address);
              }}
            >
              <div>
                <IconHome size={40} />
              </div>
              <div>
                <p className="title">{address["nome-condominio"]}</p>
                <p>{address.endereco}</p>
                <p>{`${address.cidade} / ${address.estado}`}</p>

                {address.situacao === 2 && segmento === "INDUSTRIAL" && (
                  <button
                    style={{
                      margin: ".5rem 0",
                      backgroundColor: "#ea454b",
                      borderColor: "#ea454b",
                    }}
                    disabled
                  >
                    Local inativo
                  </button>
                )}
                {address.situacao === 2 &&
                  !isAdmLogin &&
                  segmento !== "INDUSTRIAL" && (
                    <button
                      style={{ margin: ".5rem 0" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.setState(
                          { addressOnReactivation: address },
                          () =>
                            this.reactivateModal && this.reactivateModal.open()
                        );
                      }}
                    >
                      Reativar Imóvel
                    </button>
                  )}
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                {reactivationButton === true &&
                  this.iscomplementaryData(address) && (
                    <div
                      style={{
                        backgroundColor: "#43be39",
                        borderRadius: "20px",
                        height: "40px",
                      }}
                    >
                      <IconCheck size={30} />
                    </div>
                  )}
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                {!isAdmLogin &&
                  segmento !== "INDUSTRIAL" &&
                  (address.situacao !== 2 ? (
                    <span
                      className="icon-button"
                      style={{
                        borderColor: this.isSelected(address)
                          ? "#fff"
                          : "#ef464e",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        redirect(this.props, "/cancellation", { address });
                      }}
                    >
                      <IconClose
                        size={30}
                        color={this.isSelected(address) ? "#fff" : "#ef464e"}
                      />
                    </span>
                  ) : (
                    <div
                      style={{
                        backgroundColor: "#43be39",
                        borderRadius: "20px",
                        height: "30px",
                        width: "30px",
                        padding: "5px",
                      }}
                    >
                      <IconCheck size={"30px"} />
                    </div>
                  ))}
              </div>
            </Card>
          ))}

        {/* BOTAO ATIVADO E ENDERECO NAO PESQUISADO */}
        {reactivationButton === true &&
          !selectedAddress &&
          addressInactiveFilter &&
          addressInactiveFilter.map((address) => (
            <Card
              key={`address-${address["cod-condominio"]}-${address["local-entrega"]}`}
              className={this.iscomplementaryData(address) ? "" : "no-shadow"}
              styleName={this.getStyleReactivation(address)}
              onClick={() => this.selectCondominioReactivation(address)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconHome size={40} />
              </div>
              <div>
                <p className="title">{address["nome-condominio"]}</p>
                <p>{address.endereco}</p>
                <p>{`${address.cidade} / ${address.estado}`}</p>

                {address.situacao === 2 && segmento === "INDUSTRIAL" && (
                  <button
                    style={{
                      margin: ".5rem 0",
                      backgroundColor: "#ea454b",
                      borderColor: "#ea454b",
                    }}
                    disabled
                  >
                    Local inativo
                  </button>
                )}
                {address.situacao === 2 &&
                  !isAdmLogin &&
                  segmento !== "INDUSTRIAL" && (
                    <button
                      style={{ margin: ".5rem 0" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.setState(
                          { addressOnReactivation: address },
                          () =>
                            this.reactivateModal && this.reactivateModal.open()
                        );
                      }}
                    >
                      Reativar Imóvel
                    </button>
                  )}
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                {this.iscomplementaryData(address) && (
                  <div
                    style={{
                      backgroundColor: "#43be39",
                      borderRadius: "20px",
                      height: "30px",
                      width: "30px",
                      padding: "5px",
                    }}
                  >
                    <IconCheck size={"30px"} />
                  </div>
                )}
              </div>

              <div>
                {!isAdmLogin &&
                  segmento !== "INDUSTRIAL" &&
                  (address.situacao !== 2 ? (
                    <span
                      className="icon-button"
                      style={{
                        borderColor: this.isSelected(address)
                          ? "#fff"
                          : "#ef464e",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        redirect(this.props, "/cancellation", { address });
                      }}
                    >
                      <IconClose
                        size={30}
                        color={this.isSelected(address) ? "#fff" : "#ef464e"}
                      />
                    </span>
                  ) : (
                    <span
                      className="icon-button"
                      style={{
                        borderColor: "#999",
                      }}
                    >
                      <IconClose size={30} color="#999" />
                    </span>
                  ))}
              </div>
            </Card>
          ))}
      </>
    );
  }

  generateOrder = () => {
    const { complementaryData } = this.state;
    const { [`local-entrega`]: localEntrega } = CondominioService.selected;

    const { CPFCNPJ } = ClienteService.localEntrega;

    this.context.ConsigazApi.geraOcorrencia(
      null,
      "reativacao",
      null,
      null,
      null,
      CPFCNPJ,
      localEntrega,
      complementaryData
    ).then(
      ({ Ocorrencia }) => {
        this.props.dismissLoading();

        if (Ocorrencia) {
          this.setState({
            protocol: {
              ...Ocorrencia[0],
              title: "Reativação",
            },
            content: "protocol",
          });
          this.happyToBeBackModal && this.happyToBeBackModal.open();
        } else
          this.props.showToast("Ops!", "Não foi possível gerar a ocorrencia.");
      },
      (errorDescription) => {
        this.props.dismissLoading();
        this.props.showToast("Ops!", String(errorDescription));
      }
    );
  };

  render() {
    const {
      redirectState,
      addressOnReactivation,
      selectedAddress,
      reactivationButton,
      complementaryData,
      addressList,
    } = this.state;
    const { segmento } = ClienteService.localEntrega;

    const addressInactiveFilter = (addressList || []).filter(
      (address) => address["situacao"] === 2
    );


    return (
      <HomeTemplate
        ref={(ref) => (this.template = ref)}
        history={this.props.history}
        redirectFrom="AddressList"
        redirectState={redirectState}
        className="address-list-page"
      >
        {segmento === "INDUSTRIAL" ? (
          <>
            <h3>Meus locais de entrega</h3>
            {addressInactiveFilter.length === 0 ? (
              ""
            ) : (
              <>
                <p>
                  Reative seu cadastro selecionando o botão abaixo e os
                  endereços.
                </p>
                <button
                  className="button"
                  style={{
                    backgroundColor: reactivationButton === false ? "#FFF" : "",
                    color: reactivationButton === false ? "#444" : "",
                  }}
                  onClick={() => {
                    if (reactivationButton === false)
                      this.setState({ reactivationButton: true });
                    else this.setState({ reactivationButton: false });
                  }}
                >
                  Reativar
                </button>
                <Search
                  placeholder="Buscar endereço"
                  className="block no-margin"
                  value={selectedAddress}
                  onChange={(e) =>
                    this.setState({ selectedAddress: e.target.value })
                  }
                ></Search>
              </>
            )}
          </>
        ) : (
          ""
        )}
        {this.renderAddressList()}
        {this.renderButton()}

        <ReactivationModal
          modalRef={(ref) => (this.reactivateModal = ref)}
          history={this.props.history}
          shown={addressOnReactivation !== null}
          addressOnReactivation={addressOnReactivation}
          declineButtonAction={() =>
            this.setState({ addressOnReactivation: null })
          }
        />

        <Modal
          ref={(ref) => (this.confirmReactivationModal = ref)}
          title="Confirmar"
          shown={false}
          closeButton={true}
          confirmButton={true}
          confirmButtonText="Confirmar"
          confirmButtonAction={this.generateOrder}
        >
          <p>Você deseja fazer a reativação dos estabelecimentos abaixo?</p>
          {complementaryData && complementaryData.length > 0 ? (
            <>
              {complementaryData &&
                complementaryData.map((address) => (
                  <Card
                    key={`address-${address["cod-condominio"]}-${address["local-entrega"]}`}
                    className={
                      this.iscomplementaryData(address) ? "" : "no-shadow"
                    }
                    style={{ cursor: "auto" }}
                  >
                    <div>
                      <IconHome size={40} />
                    </div>
                    <div>
                      <p className="title">{address["nome-condominio"]}</p>
                      <p>{address.endereco}</p>
                      <p>{`${address.cidade} / ${address.estado}`}</p>
                    </div>
                    <div>
                      <IconClose
                        style={{ cursor: "pointer" }}
                        size={30}
                        onClick={() =>
                          this.selectCondominioReactivation(address)
                        }
                      />
                    </div>
                  </Card>
                ))}
            </>
          ) : (
            this.confirmReactivationModal &&
            this.confirmReactivationModal.close()
          )}
        </Modal>
        <Modal
          shown={false}
          ref={(ref) => (this.happyToBeBackModal = ref)}
          title="Reative!"
          closeButton={true}
          confirmButton={true}
          confirmButtonText="Ok"
          confirmButtonAction={() => {
            redirect(this.props, "/home-services", { tab: "protocol" });
          }}
        >
          Ficaremos muito felizes em tê-lo de volta! Entraremos em contato para
          apresentar os próximos passos.
        </Modal>
      </HomeTemplate>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(AddressList);
