import React from "react";
import { maskCpfCnpj } from "../../components/Masks/Masks";

const FormContaSim = (props) => {
  const {
    cpfcnpj,
    handleEmailAddressChange,
    handlePhoneNumberChange,
    isPhoneNumberValid,
    haveSubmited,
    isEmailAddressValid,
    phoneNumber,
    emailAddress,
    disabledInput,
    name,
  } = props;

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
        {!isEmailAddressValid && haveSubmited && <span className="error">E-mail inválido</span>}
        {!isPhoneNumberValid && haveSubmited && <span className="error">Telefone inválido</span>}
      </div>

      <div>
        <input
          type="text"
          className="block"
          value={maskCpfCnpj(cpfcnpj)}
          disabled
        />
        <input type="text" className="block" value={name} disabled />
        <input
          type="text"
          className={`block ${!isPhoneNumberValid && haveSubmited ? "error" : ""
            }`}
          placeholder="Telefone"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          disabled={disabledInput}
        />
        <input
          type="text"
          className={`block ${!isEmailAddressValid && haveSubmited ? "error" : ""
            }`}
          placeholder="Email"
          value={emailAddress}
          onChange={handleEmailAddressChange}
          disabled={disabledInput}
        />
      </div>
    </>
  );
};

export default FormContaSim;
