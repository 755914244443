import React, { Component } from "react";

import "./PersonalData.scss";

import apiContext from "../../api";
import ClienteService from "../../services/cliente";
import FormContaSim from "./FormContaSim";
import FormIndustrial from "./FormIndustrial";

import HomeTemplate, { redirect } from "../HomeTemplate/HomeTemplate";
import Card from "../../components/Card/Card";
import {
  maskPhoneNumber,
  validatePhoneNumber,
  validateEmailAddress,
  validateCpf,
} from "../../components/Masks/Masks";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { loadingOperations } from "../../state/ducks/loading";

class PersonalData extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    let name, cpfcnpj, telefone, department, email;
    const { segmento, telefone: phoneCliente, email: emailCliente } =
      ClienteService.localEntrega || {};

    telefone = phoneCliente;
    const contato = ClienteService.industrialContato;

    if (segmento === "INDUSTRIAL") {
      cpfcnpj = contato.cpf;
      name = contato.name;
      department = contato.department;
      telefone = contato.phoneNumber;
      email = contato.email;
    } else if (segmento === 'CONTASIM') {
      name = (ClienteService.localEntrega || {})['nome-emit']
      cpfcnpj = (ClienteService.localEntrega || {})['CPFCNPJ']
    }

    const maskedPhoneNumber = maskPhoneNumber(telefone || "");
    this.state = {
      cpfcnpj,
      isCpfValid: validateCpf(cpfcnpj),
      name,
      isNameValid: name ? true : false,
      phoneNumber: maskedPhoneNumber,
      isPhoneNumberValid: validatePhoneNumber(maskedPhoneNumber || ""),
      emailAddress: email || emailCliente || "",
      isEmailAddressValid: validateEmailAddress(email || emailCliente || ""),
      haveSubmited: false,
      disabledInput: true,
      department,
    };
  }

  componentDidMount() {
    this.context.ConsigazApi.tiposContato().then(
      ({ Retorno }) => {
        if (Retorno && Retorno.length) {
          const contactTypes = Retorno[0].descricao
            .split(",")
            .map((contactType) => ({ title: contactType }));
          this.setState({
            departmentOptions: contactTypes,
            department:
              this.state.department ||
              (contactTypes &&
                contactTypes.length > 0 &&
                contactTypes[0].title),
            isDepartmentValid: contactTypes && contactTypes.length > 0,
          });
        } else
          this.props.showToast("Ops", "Não foram encontrados tipos de contato");
      },
      (error) => {
        console.error(error);
        this.props.showToast("Ops", "Erro ao buscar os tipos de contato");
      }
    );
  }

  handleNameChange = (e) => {
    const name = e.target.value;

    this.setState({
      name: name,
      isNameValid: name ? true : false,
    });
  };

  handleCpfChange = (e) => {
    const cleanCpf = e.target.value.replace(/[^\d]/g, "");

    this.setState({
      cpfcnpj: cleanCpf,
      isCpfValid: validateCpf(cleanCpf),
    });
  };

  handleDepartmentChange = (e) => {
    const department = e.target.value;

    this.setState({ department: department });
  };

  handlePhoneNumberChange = (e) => {
    const phoneNumberMasked = maskPhoneNumber(e.target.value);

    this.setState({
      phoneNumber: phoneNumberMasked,
      isPhoneNumberValid: validatePhoneNumber(phoneNumberMasked),
    });
  };

  handleEmailAddressChange = (e) => {
    const emailAddress = e.target.value;

    this.setState({
      emailAddress: emailAddress,
      isEmailAddressValid: validateEmailAddress(emailAddress),
    });
  };

  handleSaveContaSim() {
    const { CPFCNPJ, [`nome-emit`]: nome } = ClienteService.localEntrega;

    this.props.showLoading();

    this.context.ConsigazApi.consultaContatos(CPFCNPJ).then(({ Cliente }) => {
      if (Cliente.ClienteRetorno && Cliente.ClienteRetorno.length) {
        const contatoRetorno = Cliente.ClienteRetorno[0].ClienteContatoRetorno[0]
        
        const { sequencia, tipo } = contatoRetorno;
        
        this.saveContact(CPFCNPJ, nome, sequencia, tipo);
      }
    }, console.error);
  }

  saveContact(CPFCNPJ, nome, sequencia, tipo) {
    const { phoneNumber, emailAddress } = this.state;

    this.context.ConsigazApi.manutencaoContatos(
      CPFCNPJ,
      undefined,
      nome,
      phoneNumber.replace(/[^\d]/g, ""),
      emailAddress,
      sequencia,
      tipo
    )
      .then(({ ClienteRetorno }) => {
        this.props.dismissLoading();
        if (ClienteRetorno) {
          this.updateLocalEntrega();
          this.props.showToast("Sucesso", "Dados alterados com sucesso!");
          setTimeout(() => redirect(this.props, "/home"), 2000);
        }
      })
      .catch((errorDescription) => {
        this.props.dismissLoading();
        this.props.showToast("Ops!", errorDescription);
      });
  }

  handleSaveIndustrial() {
    const { sequencia } = ClienteService.industrialContato;

    if (sequencia) {
      this.handleContactSave(sequencia);
    } else {
      const { CPFCNPJ } = ClienteService.localEntrega;
      this.context.ConsigazApi.consultaContatos(CPFCNPJ).then(({ Cliente }) => {
        if (Cliente.ClienteRetorno && Cliente.ClienteRetorno.length) {
          const contatoRetorno = Cliente.ClienteRetorno[0].ClienteContatoRetorno[0]
        
          const { sequencia } = contatoRetorno;
          this.handleContactSave(sequencia);
        }
      }, console.error);
    }
  }

  handleContactSave(sequencia) {
    const { CPFCNPJ } = ClienteService.localEntrega;
    const {
      cpfcnpj: cpfContato,
      name,
      phoneNumber,
      department,
      emailAddress,
    } = this.state;

    ClienteService.industrialContato = {
      ...ClienteService.industrialContato,
      cpf: cpfContato,
      name,
      phoneNumber,
      department,
      email: emailAddress,
    };
    this.context.ConsigazApi.manutencaoContatos(
      CPFCNPJ,
      cpfContato,
      name,
      phoneNumber,
      emailAddress,
      sequencia,
      department
    ).then(
      () => {
        this.props.showToast("Sucesso", "Dados alterados com sucesso!");
        setTimeout(() => redirect(this.props, "/home"), 2000);
      },
      () => this.props.showToast("Ops", "Erro ao salvar os dados")
    );
  }

  handleSubmit = () => {
    const { isNameValid, isCpfValid, phoneNumber, emailAddress } = this.state;

    const { segmento } = ClienteService.localEntrega || {};
    const isPhoneNumberValid = validatePhoneNumber(phoneNumber);
    const isEmailAddressValid = validateEmailAddress(emailAddress);

    if (segmento === "CONTASIM") {
      if (isPhoneNumberValid && isEmailAddressValid) {
        this.handleSaveContaSim();
      }
    } else {
      if (
        isNameValid &&
        isCpfValid &&
        isPhoneNumberValid &&
        isEmailAddressValid
      ) {
        this.handleSaveIndustrial();
      }
    }

    this.setState({ haveSubmited: true });
  };

  updateLocalEntrega() {
    const { CPFCNPJ } = ClienteService.localEntrega;
    this.context.ConsigazApi.consultaLocalEntrega(CPFCNPJ).then(
      ClienteService.consultaLocalEntregaApiParser
    );
  }

  render() {
    const {
      name,
      cpfcnpj,
      isCpfValid,
      phoneNumber,
      isPhoneNumberValid,
      emailAddress,
      isEmailAddressValid,
      haveSubmited,
      disabledInput,
      department,
      departmentOptions,
    } = this.state;
    const { isAdmLogin, segmento } = ClienteService.localEntrega || {};
    return (
      <HomeTemplate
        ref={(ref) => (this.template = ref)}
        history={this.props.history}
        className="personal-data-page"
      >
        <Card title="Dados Pessoais">
          {!isAdmLogin && disabledInput === true && (
            <div className="block">
              <button
                className="alterData"
                onClick={() =>
                  this.setState({
                    disabledInput: false,
                  })
                }
              >
                Alterar Dados
              </button>
            </div>
          )}

          {segmento === "CONTASIM" && (
            <FormContaSim
              cpfcnpj={cpfcnpj}
              handleEmailAddressChange={this.handleEmailAddressChange}
              isEmailAddressValid={isEmailAddressValid}
              emailAddress={emailAddress}
              handlePhoneNumberChange={this.handlePhoneNumberChange}
              isPhoneNumberValid={isPhoneNumberValid}
              phoneNumber={phoneNumber}
              haveSubmited={haveSubmited}
              disabledInput={disabledInput}
              name={name}
            />
          )}
          {segmento === "INDUSTRIAL" && (
            <FormIndustrial
              name={name}
              isNameValid={name ? true : false}
              onNameChange={this.handleNameChange}
              phoneNumber={phoneNumber}
              isPhoneNumberValid={isPhoneNumberValid}
              onPhoneNumberChange={this.handlePhoneNumberChange}
              cpf={cpfcnpj}
              isCpfValid={isCpfValid}
              onCpfChange={this.handleCpfChange}
              department={department}
              departmentOptions={departmentOptions}
              onDepartmentChange={this.handleDepartmentChange}
              isEmailValid={isEmailAddressValid}
              email={emailAddress}
              onEmailChange={this.handleEmailAddressChange}
              haveSubmited={haveSubmited}
              disabledInput={disabledInput}
            />
          )}
          {!isAdmLogin && disabledInput === false && (
            <button className="block" onClick={this.handleSubmit}>
              Salvar
            </button>
          )}
        </Card>
      </HomeTemplate>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(PersonalData);
