import React, { Component } from "react";

import CondominioService from "../../services/condominio";

import Page, { redirect } from "../../components/Page/Page";
import PageCenterContent from "../../components/PageCenterContent/PageCenterContent";
import PageHeaderImage from "../../components/PageHeaderImage/PageHeaderImage";
import Modal from "../../components/Modal/Modal";
import { maskCep, validateCep } from "../../components/Masks/Masks";

import apiContext from "../../api";

import LocationImage from "../../assets/illustrations/location.png";
import AddressNotFoundImage from "../../assets/illustrations/address-not-found.png";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { loadingOperations } from "../../state/ducks/loading";
import { SearchOptions } from "../../components/SearchOptions/SearchOptions";

class AddressSearch extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
    };
    this.debouncedSearch = this.debounce(this.search, 600);
    const locationState = props.location && props.location.state;
    if (locationState) {
      if (locationState.errorMessage)
        Object.assign(this.state, { errorMessage: locationState.errorMessage });

      if (locationState.from)
        sessionStorage.setItem("address_flow_origin", locationState.from);
      else sessionStorage.removeItem("address_flow_origin");
    } else {
      sessionStorage.removeItem("address_flow_origin");
    }
  }

  debounce = (func, delay) => {
    let timerId;
    return function () {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, arguments);
      }, delay);
    };
  };

  handleInputChange = (event) => {
    const { value } = event.target;
    this.setState({ cep: maskCep(value) });
    this.debouncedSearch(value);
  };

  handleSelectHelp = () => {
    redirect(this.props, "/help");
  };

  handlePlaceSelect = (value) => {
    CondominioService.inRegistration = value;
    redirect(this.props, "/address-complement", { condominioData: value });
  };

  search = (cep) => {
    if(!cep) return;
    cep = cep.replace(/\D/g, "");
    if (cep.length !== 8) return;

    this.props.showLoading();
    this.context.ConsigazApi.consultaCondominios(
      cep.replace(/\.|\-/g, "")
    )
      .then(({ Retorno }) => {
        if (Retorno.Condominio) {
          this.props.dismissLoading();
          this.setState({
            searchResults: Retorno.Condominio.map((cond) => ({
              title: cond.nome,
              description: `${cond.endereco} - ${cond.cidade}`,
              value: cond,
              onClick: this.handlePlaceSelect,
            })),
          });
        } else {
          this.props.dismissLoading();
          this.addressNotFoundModal &&
          this.addressNotFoundModal.open()
        }
      })
      .catch((errorDescription) => {
        this.props.dismissLoading();
        this.setState({ cep: "false" }, () => {
          this.props.showToast("Ops!", errorDescription);
          this.addressNotFoundModal && this.addressNotFoundModal.open();
        });
      })
  };

  render() {
    return (
      <Page history={this.props.history}>
        <PageCenterContent>
          <PageHeaderImage src={LocationImage} />

          <h1>Vamos encontrar seu imóvel/estabelecimento?</h1>
          <p>Busque com o CEP.</p>

          <br />
          <br />

          <input
            type="text"
            className="block"
            onChange={this.handleInputChange}
            value={this.state.cep}
            style={{ marginBottom: 0 }}
            placeholder="Digite seu cep"
          />
          <br />
          <SearchOptions options={this.state.searchResults} />
        </PageCenterContent>
        <Modal
          ref={(ref) => (this.errorModal = ref)}
          shown={false}
          title={"Atenção"}
          confirmButton={true}
          confirmButtonText={"OK"}
        >
          {this.state.errorMessage}
        </Modal>
        <Modal
          ref={(ref) => (this.addressNotFoundModal = ref)}
          title="Ops, nada encontrado!"
          shown={false}
          imgSrc={AddressNotFoundImage}
          declineButton={true}
          declineButtonText={"Preciso de ajuda"}
          declineButtonAction={this.handleSelectHelp}
          confirmButton={true}
          confirmButtonText={"OK"}
        >
          Nenhum resultado foi encontrado com este CEP. Tente pesquisar por
          outro?
        </Modal>
      </Page>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(AddressSearch);
