import HttpHandler from './HttpHandler';

// export const API_URL = 'https://projetoportaldeservicos-dev.sa-east-1.elasticbeanstalk.com/consigaz-report';
//  export const API_URL = 'https://projetoportaldeservicos-dev-new.sa-east-1.elasticbeanstalk.com/consigaz-report';
export const API_URL = 'https://portaldocliente-back.consigaz.com.br/consigaz-report'
// export const API_URL = 'https://portaldocliente-dev-back.consigaz.com.br/consigaz-report'

class ConsigazReportApi {
	constructor(dependencies = { HttpHandler }) {
		const baseUrl = API_URL;
		this.api = new dependencies.HttpHandler({ baseUrl });
	}

  getHeaders = () => ({
    'Content-Type': 'application/json'
  })

  formatDate = date => `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`

  async exportInvoice(cpfcnpj, startDate, finalDate, deliveryLocation) {
    return this.api.post({
      url: `/boleto/export`,
      headers: this.getHeaders(),
      body: {
        cpfcnpj,
        startDate,
        finalDate,
        deliveryLocation: deliveryLocation.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      }
    }).then(({ data }) => data)
  }

  async exportIndustrialInvoice(cpfcnpj, startDate, finalDate, deliveryLocation) {
    return this.api.post({
      url: `/boleto/industrial/export`,
      headers: this.getHeaders(),
      body: {
        cpfcnpj,
        startDate,
        finalDate,
        deliveryLocation: (deliveryLocation || '').normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      }
    }).then(({ data }) => data)
  }

  async sendIndustrialConsumeReport(cpfcnpj, email, clientName, issues) {
    return this.api.post({
      url: `/consume/industrial/sendReport`,
      headers: this.getHeaders(),
      body: {
        cpfcnpj,
        email,
        clientName,
        issues,
        /* {
          Issues

          "deliveryLocation": "padrao",
          "address": "AVENIDA C 2, 275 CASA 01 LOTE 16 QUADRA 20",
          "deliveryDate": "04/08/2020",
          "gasVolume": "360,3000kg"
        } */
      }
    }).then(({ data }) => data)
  }



  async sendInvoiceByEmail(cpfcnpj, startDate, finalDate, deliveryLocation, email) {
    return this.api.post({
      url: `/boleto/send`,
      headers: this.getHeaders(),
      body: {
        cpfcnpj,
        startDate,
        finalDate,
        deliveryLocation: deliveryLocation.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
        email
      }
    }).then(({ data }) => data)
  }

  async sendInvoiceIndistrualByEmail(cpfcnpj, startDate, finalDate, deliveryLocation, email) {
    return this.api.post({
      url: `/boleto/industrial/send`,
      headers: this.getHeaders(),
      body: {
        cpfcnpj,
        startDate,
        finalDate,
        deliveryLocation: (deliveryLocation || '').normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
        email
      }
    }).then(({ data }) => data)
  }

  async exportDischargeLetter(cpfcnpj, year, type="anual", company="Consigaz") {
    return this.api.post({
      url: `/discharge-letter/export`,
      headers: this.getHeaders(),
      body: {
        type,
        clientTaxId: cpfcnpj,
        year,
        company,
      }
    }).then(({ data }) => data)
  }

  async sendDischargeLetter(cpfcnpj, email, year, type="anual", company="Consigaz") {
    return this.api.post({
      url: `/discharge-letter/send`,
      headers: this.getHeaders(),
      body: {
        type,
        clientTaxId: cpfcnpj,
        year,
        company,
        email
      }
    }).then(({ data }) => data)
  }

  async exportDanfe(cpfcnpj, startDate, finalDate, deliveryLocation) {
    return this.api.post({
      url: `/danfe/export`,
      headers: this.getHeaders(),
      body: {
        cpfcnpj,
        startDate,
        finalDate,
        deliveryLocation: deliveryLocation.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      }
    }).then(({ data }) => data)
  }

  async sendDanfeByEmail(cpfcnpj, startDate, finalDate, deliveryLocation, email) {
    return this.api.post({
      url: `/danfe/send`,
      headers: this.getHeaders(),
      body: {
        cpfcnpj,
        startDate,
        finalDate,
        deliveryLocation: deliveryLocation.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
        email
      }
    }).then(({ data }) => data)
  }

  async sendCancelationEmail(name, cpfcnpj, date, email) {
    return this.api.post({
      url: `/service/email`,
      headers: this.getHeaders(),
      body: {
        name,
        cpfcnpj,
        date,
        email
      }
    }).then(({ data }) => data)
  }

}

const consigazReportApi = new ConsigazReportApi();
export default Object.freeze(consigazReportApi);
