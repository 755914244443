import React from "react";
import apiContext from "../../../api";
import { Component } from "react";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../../state/ducks/loading";
import "./ServiceDetail.scss";
import CondominioService from "../../../services/condominio";
import ClienteService from "../../../services/cliente";
import SelectAddressList from "../SelectAddressList";
import Card from "../../../components/Card/Card";
import TicketService from "../../../services/ticket";
import Select from "../../../components/Select/Select";

class ScheduleRequest extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
      complementaryData: this.initialComplementaryData,
      frequencyinfo: this.frequencyInfo,
      nextPage: false,
    };
  }

  get initialComplementaryData() {
    const nextDelivery = TicketService.getNextDelivery() || null;

    return {
      selectedProduct: nextDelivery
        ? nextDelivery["tipo"] === "GLP Granel"
          ? "granel"
          : "cilindros"
        : "",
      selectedModel:
        nextDelivery && nextDelivery["tipo"] === "GLP Envasado"
          ? nextDelivery["it-codigo"]
          : null,
    };
  }

  get frequencyInfo() {
    return [
      "Diária",
      "Trissemanal",
      "Bissemanal",
      "Semanal",
      "Quinzenal",
      "21x21",
      "Mensal",
      "42x42",
      "45x45",
      "Bimestral",
      "Trimestral",
      "Semestral",
      "Anual",
    ];
  }

  get itensCilindro() {
    const { selectedCondominio = {} } = this.state;
    return (selectedCondominio.Itens || []).filter(
      (it) => it.tipo.toLowerCase().indexOf("glp envasado") > -1
    );
  }

  get itensGranel() {
    const { selectedCondominio = {} } = this.state;
    return (selectedCondominio.Itens || []).filter(
      (it) => it.tipo.toLowerCase().indexOf("granel") > -1
    );
  }

  get itensEspecial() {
    const { selectedCondominio = {} } = this.state;
    return (selectedCondominio.Itens || []).filter(
      (it) => it.tipo.toLowerCase().indexOf("gás especial") > -1
    );
  }

  componentDidUpdate() {
    const { complementaryData } = this.state;
    const { data } = this.props;

    if (data && !complementaryData.selectedModel) {
      this.setState({
        complementaryData: this.initialComplementaryData,
      });
    }
  }

  componentDidMount() {
    const { CPFCNPJ } = ClienteService.localEntrega || {};

    this.props.showLoading();
    this.context.ConsigazApi.consultaLocalEntrega(CPFCNPJ)
      .then(ClienteService.consultaLocalEntregaApiParser)
      .then(({ Cliente }) => {
        if (Cliente) {
          this.props.dismissLoading();
          this.setState({
            addressList: Cliente.ClienteRetorno[0].ClienteEntrRetorno,
          });
        }
      })
      .catch((errorDescription) => {
        this.props.dismissLoading();
        this.setState({}, () => this.props.showToast("Ops!", errorDescription));
      });

    this.context.ConsigazApi.motivosAltProgcao().then(
      ({ Retorno }) => {
        if (Retorno && Retorno.length) {
          this.setState({
            reasons: Retorno,
          });
        } else
          this.props.showToast("Ops", "Não foram encontrados tipos de contato");
      },
      (error) => {
        console.error(error);
        this.props.showToast("Ops", "Erro ao buscar os tipos de contato");
      }
    );
  }

  toggleFrequency(value) {
    const { complementaryData } = this.state;

    if (complementaryData["frequency"] === value)
      this.setState({
        complementaryData: {
          ...complementaryData,
          frequency: null,
        },
      });
    else
      this.setState({
        complementaryData: {
          ...complementaryData,
          frequency: value,
        },
      });
  }

  toggleModel(model) {
    const { complementaryData } = this.state;

    if (complementaryData["selectedModel"] === model)
      this.setState({
        complementaryData: {
          ...complementaryData,
          selectedModel: null,
        },
      });
    else
      this.setState({
        complementaryData: {
          ...complementaryData,
          selectedModel: model,
        },
      });
  }

  submit = () => {
    const { complementaryData, nextPage } = this.state;

    if (
      complementaryData["selectedProduct"] === "cilindros" &&
      complementaryData["selectedModel"] === null
    ) {
      this.props.showToast(
        "Ops!",
        "Você precisa selecionar no mínimo um modelo de cilindro!"
      );
    } else if (complementaryData["selectedProduct"] === "") {
      this.props.showToast("Ops!", "Você precisa selecionar um produto!");
    } else if (!complementaryData["frequency"] && nextPage === true) {
      this.props.showToast("Ops!", "Você precisa selecionar uma frequencia!");
    } else if (
      (!complementaryData["motivo"] ||
        (complementaryData["motivo"] === "Outros (Justifique)" &&
          !complementaryData["informacoes"])) &&
      nextPage === true
    ) {
      this.props.showToast(
        "Ops!",
        "Você precisa justificar o motivo da alteração!"
      );
    } else if (nextPage === false) this.setState({ nextPage: true });
    else this.generateOrder();
  };

  generateOrder = () => {
    const {
      complementaryData: {
        selectedProduct,
        informacoes,
        frequency,
        motivo,
      },
    } = this.state;
    const nextDelivery = TicketService.getNextDelivery();
    const { onToggle } = this.props;
    onToggle &&
      onToggle({
        informacoes: `${
          nextDelivery !== null ? "Alteração" : "Solicitação"
        } de programação;\n Produto: ${selectedProduct};\n Programação: ${frequency};\n Motivo: ${motivo}; 
        ${motivo === "Outros (Justifique)" ? `Justificativa: ${informacoes}` : ""}.`
      });
  };

  render() {
    const {
      complementaryData,
      selectedCondominio,
      reasons,
      nextPage,
    } = this.state;
    if (selectedCondominio !== "all") {
      var { Itens } = CondominioService.selected;
    }
    const { rawData } = this.props;
    const nextDelivery = TicketService.getNextDelivery();

    return (
      <>
        <h2 className="service-detail-title">{rawData.title}</h2>
        {nextDelivery ? (
          <p style={{ textAlign: "justify" }}>{rawData.description}</p>
        ) : (
          "Este local de entrega não possui programação. Cadastre agora mesmo!"
        )}
        {selectedCondominio === "all" && (
          <>
            <SelectAddressList
              onSubmit={(address) =>
                this.setState({
                  selectedCondominio: address,
                })
              }
            />
          </>
        )}
        {selectedCondominio !== "all" && (
          <>
            <div
              style={{
                textAlign: "left",
                margin: "0 auto",
                width: "270px",
                paddingLeft: "20%",
              }}
            >
              <>
                <h3>Produto selecionado</h3>
                {(this.itensGranel || []).length > 0 ? (
                  <label>
                    <input
                      type="radio"
                      name="is-ready"
                      value="granel"
                      className={
                        complementaryData["selectedProduct"] === "granel"
                          ? "checked"
                          : ""
                      }
                      onChange={() =>
                        this.setState(
                          {
                            complementaryData: {
                              ...complementaryData,
                              selectedProduct: "granel",
                            },
                          },
                          () =>
                            this.toggleModel(this.itensGranel[0]["it-codigo"])
                        )
                      }
                    />
                    Fornecimento à granel (kg)
                  </label>
                ) : null}
                {this.itensCilindro.length > 0 && (
                  <label>
                    <input
                      type="radio"
                      name="is-ready"
                      value="null"
                      className={
                        complementaryData["selectedProduct"] === "cilindros"
                          ? "checked"
                          : ""
                      }
                      onChange={() =>
                        this.setState({
                          complementaryData: {
                            ...complementaryData,
                            selectedProduct: "cilindros",
                            selectedModel: null,
                          },
                        })
                      }
                    />
                    Cilindros (unidades)
                  </label>
                )}
                <div style={{ paddingLeft: "2rem" }}>
                  {this.itensCilindro.map((value) => {
                    return (
                      <label>
                        <input
                          type="checkbox"
                          className={
                            complementaryData["selectedModel"] ===
                            value["it-codigo"]
                              ? "checked"
                              : ""
                          }
                          onClick={() => this.toggleModel(value["it-codigo"])}
                          disabled={
                            complementaryData["selectedProduct"] !== "cilindros"
                          }
                        />
                        {value["desc-comercial"]}
                      </label>
                    );
                  })}
                </div>
                {(this.itensEspecial || []).length > 0 ? (
                  <label>
                    <input
                      type="radio"
                      name="is-ready"
                      value="Gás especial"
                      className={
                        complementaryData["selectedProduct"] === "gás especial"
                          ? "checked"
                          : ""
                      }
                      onChange={() =>
                        this.setState({
                          complementaryData: {
                            ...complementaryData,
                            selectedProduct: "gás especial",
                          },
                        })
                      }
                    />
                    Gás especial
                  </label>
                ) : null}
              </>
            </div>
            <br />
            {Itens ? (
              <>
                <Card
                  className="text-center no-shadow"
                  styleName="outlinePrimary"
                  style={{ marginBottom: 0 }}
                >
                  <p className="title no-margin">Programação atual:</p>
                  <b
                    style={{
                      display: "block",
                      fontSize: 20,
                    }}
                  >
                    {nextDelivery !== null ? (
                      <p style={{ margin: 0, color: "var(--light-blue)" }}>
                        {nextDelivery["programacao"]}
                      </p>
                    ) : (
                      <p style={{ margin: 0, color: "var(--light-blue)" }}>
                        Sem data prevista
                      </p>
                    )}{" "}
                  </b>
                </Card>
                <br />{" "}
              </>
            ) : null}
            {nextPage === true ? (
              <>
                <div
                  style={{
                    textAlign: "left",
                    margin: "0 auto",
                    width: "270px",
                    paddingLeft: "20%",
                  }}
                >
                  <h3>Nova frequencia</h3>
                  {this.frequencyInfo.map((value) => {
                    return (
                      <label>
                        <input
                          type="checkbox"
                          className={
                            complementaryData["frequency"] === value
                              ? "checked"
                              : ""
                          }
                          onClick={() => this.toggleFrequency(value)}
                        />
                        {value}
                      </label>
                    );
                  })}
                </div>
                <br />
                <Select
                  className="block"
                  style={{ width: "50%" }}
                  titleSelect="Justifique o motivo da alteração"
                  options={(reasons || []).map((r) => ({
                    title: r["descricao"],
                    value: r["descricao"],
                  }))}
                  value={complementaryData["motivo"]}
                  onChange={(e) =>
                    this.setState({
                      complementaryData: {
                        ...complementaryData,
                        motivo: e.target.value,
                      },
                    })
                  }
                />
                {complementaryData["motivo"] === "Outros (Justifique)" && (
                  <textarea
                    style={{ width: "100%" }}
                    maxLength={200}
                    value={complementaryData.informacoes || ""}
                    onChange={(e) =>
                      this.setState({
                        complementaryData: {
                          ...complementaryData,
                          informacoes: e.target.value,
                        },
                      })
                    }
                  />
                )}
              </>
            ) : null}

            <footer className="service-detail-footer">
              <button
                className="block"
                onClick={() => {
                  this.submit();
                }}
              >
                {Itens
                  ? nextPage === false
                    ? "Alterar programação"
                    : "Confirmar"
                  : "Confirmar"}
              </button>
            </footer>
          </>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(ScheduleRequest);
