import React from "react";
import apiContext from "../../../api";
import { Component } from "react";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../../state/ducks/loading";
import Modal from "../../../components/Modal/Modal";
import WarningImage from "../../../assets/illustrations/warning.png";
import { moneyFormat } from "../../../components/Masks/Masks";
import SelectAddressList from "../SelectAddressList";
import CondominioService from "../../../services/condominio";

class IndustrialTightnessTest extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      complementaryData: this.initialComplementaryData,
      haveSubmited: false,
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
    };
  }

  get isValid() {
    const { complementaryData } = this.state;
    return (
      complementaryData["tipo-teste"] !== null &&
      complementaryData["tipo-teste"] !== "indefinido"
    );
  }

  get initialComplementaryData() {
    return {
      "tipo-teste": null,
      "local-teste": null,
      tipoSelecionado: null,
    };
  }

  get value() {
    const { complementaryData } = this.state;

    if (complementaryData["tipo-teste"] === "teste-e-laudo") {
      return 2312.96;
    } else if (complementaryData["tipo-teste"] === "teste-estanqueidade") {
      return 810.96;
    } else {
      return null;
    }
  }

  submit = () => {
    const { complementaryData } = this.state;
    const { checked } = this.props;

    if (!checked) {
      if (
        complementaryData["tipo-teste"] !== null &&
        complementaryData["tipo-teste"] !== "indefinido"
      ) {
        this.localModal && this.localModal.open();
        return;
      }
    }

    this.setState({ haveSubmited: true }, this.generateOrder);
  };

  generateOrder = () => {
    const {
      complementaryData: { "tipo-teste": tipoTeste, "local-teste": localTeste, informacoes },
      selectedCondominio,
      selectedAddress,
    } = this.state;
    const { onToggle } = this.props;

    if (selectedCondominio === "all") {
      if (
        CondominioService.selectMany &&
        CondominioService.selectMany.length > 0
      ) {
        CondominioService.selectMany &&
          CondominioService.selectMany.map((teste) =>
            selectedAddress.push(teste)
          );
        CondominioService.selectMany = selectedAddress;
      } else CondominioService.selectMany = selectedAddress;
    }
    this.setState({ haveSubmited: false });

    onToggle &&
      onToggle({
        informacoes: `Tipo: ${tipoTeste}. ${informacoes || ""};\n Local: ${localTeste}`,
        value: this.value,
      });
  };

  render() {
    const { complementaryData, haveSubmited, selectedCondominio } = this.state;
    const { checked, onWarranty, rawData } = this.props;

    return (
      <>
        {selectedCondominio === "all" && haveSubmited === true && (
          <>
            <SelectAddressList
              onSubmit={(address) =>
                this.setState(
                  {
                    selectedAddress: address,
                  },
                  () => this.generateOrder()
                )
              }
              cardClass={"service"}
              service={rawData}
            />
          </>
        )}
        {haveSubmited === false && (
          <>
            <h2
              style={{
                marginTop: 0,
                textAlign: "left",
                fontStyle: "oblique",
              }}
            >
              {rawData.title}
            </h2>
            <p style={{ textAlign: "justify" }}>{rawData.description}</p>

            <p>
              <b>Por favor, nos informe o tipo de teste que será realizado.</b>
            </p>
            <div
              style={{ textAlign: "left", margin: "0 auto", width: "270px" }}
            >
              <label>
                <input
                  type="radio"
                  name="tipo-teste"
                  className={
                    complementaryData["tipo-teste"] === "teste-estanqueidade"
                      ? "checked"
                      : ""
                  }
                  value="teste-estanqueidade"
                  onChange={(e) =>
                    this.setState({
                      complementaryData: {
                        ...complementaryData,
                        "tipo-teste": e.target.value,
                        tipoSelecionado: true,
                      },
                      haveSubmited: false,
                    })
                  }
                />
                Teste de Estanqueidade
              </label>

              <label>
                <input
                  type="radio"
                  name="tipo-teste"
                  className={
                    complementaryData["tipo-teste"] === "teste-e-laudo"
                      ? "checked"
                      : ""
                  }
                  value="teste-e-laudo"
                  onChange={(e) =>
                    this.setState({
                      complementaryData: {
                        ...complementaryData,
                        "tipo-teste": e.target.value,
                        tipoSelecionado: true,
                      },
                      haveSubmited: false,
                    })
                  }
                />
                Teste de Estanqueidade + Laudo Art
              </label>
            </div>

            <br />

            {complementaryData.tipoSelecionado && (
              <>
                <p>
                  <b>Use o campo para detalhar</b>
                </p>

                <textarea
                  maxlength="200"
                  style={{ width: "80%", height: "100px" }}
                  value={complementaryData.informacoes || ""}
                  onChange={(e) =>
                    this.setState({
                      complementaryData: {
                        ...complementaryData,
                        informacoes: e.target.value,
                      },
                    })
                  }
                />
              </>
            )}

            <br />

            <footer
              style={{
                [`--container-width`]: "100%",
                background: "var(--dark-blue)",
                textAlign: "center",
                margin: "calc(var(--padding) * -1)",
                marginTop: "var(--padding)",
                borderBottomRightRadius: ".3rem",
                borderBottomLeftRadius: ".3rem",
                padding: "var(--padding)",
                color: "white",
              }}
            >
              <div style={{ marginBottom: "1rem" }}>
                {complementaryData["tipo-teste"] !== "indefinido" && (
                  <button
                    onClick={() => onWarranty && onWarranty()}
                    className="inline-link"
                  >
                    Termos de garantia
                  </button>
                )}
              </div>
              <button
                className="block"
                style={{ margin: "0rem auto" }}
                // onClick={this.submit}
                onClick={this.submit}
                disabled={!this.isValid}
              >
                {checked
                  ? "Desselecionar"
                  : haveSubmited
                  ? "Confirmar a solicitação do serviço"
                  : "Selecionar"}
              </button>
            </footer>
          </>
        )}
        <Modal
          ref={(ref) => (this.localModal = ref)}
          shown={false}
          title="Nos informe"
          closeButton={true}
          confirmButton={true}
          confirmButtonText="Confirmar"
          confirmButtonAction={(modal) => {
            modal.close();
            this.serviceValueModal && this.serviceValueModal.open();
          }}
        >
          <p>Onde será o local que será realizado o teste?</p>
          <div style={{ textAlign: "left", margin: "0 auto", width: "120px" }}>
            <label>
              <input
                type="radio"
                name="local-teste"
                className={
                  complementaryData["local-teste"] === "central"
                    ? "checked"
                    : ""
                }
                value="central"
                onChange={(e) =>
                  this.setState({
                    complementaryData: {
                      ...complementaryData,
                      "local-teste": e.target.value,
                    },
                    haveSubmited: false,
                  })
                }
              />
              Central
            </label>
            <label>
              <input
                type="radio"
                name="local-teste"
                className={
                  complementaryData["local-teste"] === "rede" ? "checked" : ""
                }
                value="rede"
                onChange={(e) =>
                  this.setState({
                    complementaryData: {
                      ...complementaryData,
                      "local-teste": e.target.value,
                    },
                    haveSubmited: false,
                  })
                }
              />
              Rede
            </label>
            <label>
              <input
                type="radio"
                name="local-teste"
                className={
                  complementaryData["local-teste"] === "central-rede"
                    ? "checked"
                    : ""
                }
                value="central-rede"
                onChange={(e) =>
                  this.setState({
                    complementaryData: {
                      ...complementaryData,
                      "local-teste": e.target.value,
                    },
                    haveSubmited: false,
                  })
                }
              />
              Central+Rede
            </label>
          </div>
        </Modal>
        <Modal
          shown={false}
          ref={(ref) => (this.serviceValueModal = ref)}
          title="Fique atento!"
          imgSrc={WarningImage}
          confirmButton={true}
          confirmButtonText={"Confirmar"}
          confirmButtonAction={(modal) => {
            modal.close();
            if (selectedCondominio !== "all") this.generateOrder();
            else this.setState({ haveSubmited: true });
          }}
        >
          O valor dos serviços é de <strong>{moneyFormat(this.value)}</strong>{" "}
          por local de entrega.
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(IndustrialTightnessTest);
