import axios from 'axios';
import { stringify } from 'query-string';

// import { navigate } from "../components/utils/rootNavigator";

export default class HttpHandler {
  constructor({ baseUrl }, dependencies = { axios, stringify }) {
    this.baseUrl = baseUrl;
    this.axios = dependencies.axios;
    this.stringify = dependencies.stringify;

    this.axios.interceptors.response.use(
      response => {
        if (response.data && response.data.Erros)
          return Promise.reject(response.data.Erros[0].Descricao)

        return response
      },
      error => {
        try {
          try {
            const parsed = error.toJSON()
            if (parsed.code === 'ECONNABORTED')
              return Promise.reject(new Error('A requisição está demorando mais que o normal. Tente novamente mais tarde.'), error)
          } catch (error) {}

          if (error.Erros)
            return Promise.reject(error.Erros[0].Descricao, error)

          if (error.response && error.response.data)
            return Promise.reject(error.response.data, error)

          if (error.message)
            return Promise.reject(error.message, error)

        } catch (err) {
          return Promise.reject(err, error)
        }
        return Promise.reject(error, error)
      })
  }

  get timeout() {
    return 60000
  }

  get(params = {}) {
    const { url = '', headers, query } = params;
    const finalUrl = this.getUrl(url, query);
    const config = { timeout: url.includes('Consulta2ViaBoleto') ? 120000 : this.timeout, headers };

    return this.axios.get(finalUrl, config)
      .then(this.onSuccess)
  }

  post(params = {}) {
    const { url = '', headers, body } = params;
    const finalUrl = this.getUrl(url);
    const config = { timeout: this.timeout, headers };

    return this.axios.post(finalUrl, body, config)
      .then(this.onSuccess)
  }

  put(params = {}) {
    const { url = '', headers, body } = params;
    const finalUrl = this.getUrl(url);
    const config = { timeout: this.timeout, headers };

    return this.axios.put(finalUrl, body, config)
      .then(this.onSuccess)
  }

  patch(params = {}) {
    const { url = '', headers, body } = params;
    const finalUrl = this.getUrl(url);
    const config = { timeout: this.timeout, headers };

    return this.axios.patch(finalUrl, body, config)
      .then(this.onSuccess)
  }

  getUrl(url, params) {
    const { baseUrl = '' } = this;
    const parsedParams = params ? `?${stringify(params)}` : '';
    return `${url.indexOf('http') !== 0 ? baseUrl : ''}${url}${parsedParams}`;
  }

  onSuccess = (response) => {
    return Promise.resolve(response);
  }
}
