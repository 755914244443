import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'

import './Toast.scss'

import { IconSpinner } from '../../components/Icons/Icons'

class Toast extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isIn: true,
      title: null,
      description: null
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.autodismiss && props.description !== state.description)
      return {
        isIn: true,
        title: props.title,
        description: props.description
      }
    else {
      return {
        title: props.title,
        description: props.description
      }
    }
  }

  handleAutodismiss() {
    const { isIn } = this.state
    const { autodismiss, dismiss } = this.props

    if ((autodismiss || dismiss) && isIn)
      setTimeout(() => {
        this.setState({ isIn: false })
        this.props.onUnmount && this.props.onUnmount()
      }, this.props.dismissTimeout || 2000)
  }

  componentDidMount() {
    this.handleAutodismiss()
  }

  componentDidUpdate() {
    this.handleAutodismiss()
  }

  render() {
    const { isIn, title, description } = this.state

    return (
      <CSSTransition
        in={isIn || undefined}
        timeout={this.props.dismissTimeout || 2000}
        unmountOnExit={true}>
        <span>
          <div className={`toast-backdrop`}></div>
          <div className={`toast`}>
            <p className="title">{title}</p>
            {description}
          </div>
        </span>
      </CSSTransition>
    )
  }
}

export const LoadingToast = props => (
  <Toast title="Carregando" description={<IconSpinner color="#fff" style={{ animation: '1s infinite spin'}} />} autodismiss={false} />
)

export default Toast