import React, { Component } from "react";

import ClienteService from "../../services/cliente";
import apiContext from "../../api";
import Modal from "../Modal/Modal";
import InvoiceImage from "../../assets/illustrations/invoice.png";

import "./TicketModal.scss";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { connect } from "react-redux";
import TicketService from "../../services/ticket";
import moment from "moment";

class TicketModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ticketData: props.ticketData,
    };
  }

  static contextType = apiContext;

  static getDerivedStateFromProps(props, state) {
    return { ticketData: props.ticketData };
  }

  get invoiceCode() {
    const { ticketData } = this.state;
    return ticketData["linha-digitavel"] || "";
  }

  sendByEmail = () => {
    const { ticketData } = this.state;
    const { CPFCNPJ, email, segmento } = ClienteService.localEntrega;
    const { [`local-entrega`]: localEntrega } = ticketData;
    const { email: emailContato } = ClienteService.industrialContato || {};

    if (
      (segmento !== "INDUSTRIAL" && !email) ||
      (segmento === "INDUSTRIAL" && !emailContato)
    ) {
      this.props.showToast(
        "Ops!",
        "Parece que você não tem e-mail cadastrado. Entre no perfil e cadastre agora mesmo para poder receber suas contas!"
      );
      return null;
    }

    if (!ticketData) return null;

    const vencimento = moment(ticketData["dt-emis-nota"], "YYYY-MM-DD").format(
      "YYYY/MM/DD"
    );

    if (TicketService.getTicketStatus(ticketData) !== "paid")
      this.context.ConsigazReportApi.sendInvoiceIndistrualByEmail(
        CPFCNPJ,
        vencimento,
        vencimento,
        localEntrega,
        segmento === "INDUSTRIAL" ? emailContato : email
      ).then(
        () =>
          this.props.showToast(
            "Enviado!",
            `O boleto foi enviado para ${email}.`
          ),
        () => this.props.showToast("Ops!", "Erro ao enviar o boleto.")
      );
    else
      this.context.ConsigazReportApi.sendDanfeByEmail(
        CPFCNPJ,
        vencimento,
        vencimento,
        localEntrega,
        segmento === "INDUSTRIAL" ? emailContato : email
      ).then(
        () =>
          this.props.showToast(
            "Enviado!",
            `A nota fiscal foi enviada para ${emailContato || email}.`
          ),
        () => this.props.showToast("Ops!", "Erro ao enviar a nota fiscal.")
      );
  };

  copyToClipboard = () => {
    this.textArea && this.textArea.select();
    document.execCommand("copy");
    this.props.showToast(
      "Copiado",
      "Seu código foi copiado para sua área de transferência."
    );
    this.props.onCopy && this.props.onCopy();
  };

  getDate = () => {
    const { ticketData } = this.state;

    if (!ticketData) return null;

    const date = new Date(`${ticketData["dt-emis-nota"]} 00:00:00`);
    return `${String(date.getMonth() + 1).padStart(
      2,
      0
    )}/${date.getFullYear()}`;
  };

  open = () => this.modalRef && this.modalRef.open();

  close = () => this.modalRef && this.modalRef.close();

  render() {
    const { ticketData } = this.state;

    if (!ticketData) return null;

    const ticketStatus = TicketService.getTicketStatus(ticketData);
    const isInvoice = ticketStatus === "avencer" || ticketStatus === "outdated";

    if (ticketData) {
      return (
        <Modal
          shown={false}
          ref={(ref) => (this.modalRef = ref)}
          className="ticket-modal"
          title={
            isInvoice
              ? `Boleto do mês ${this.getDate()}`
              : `NF do mês ${this.getDate()}`
          }
          closeButton={true}
        >
          <br />
          {TicketService.getTicketBadge(ticketData)}
          <img
            src={InvoiceImage}
            alt="Invoice Illustration"
            height={150}
            onClick={()=> ticketStatus !== "paid" ? exportInvoice(this.props.showToast, ticketData) : exportDanf(this.props.showToast, ticketData)}
          />

          <textarea
            ref={(ref) => (this.textArea = ref)}
            style={{ opacity: 0, height: 0 }}
            value={this.invoiceCode}
            onChange={() => null}
          />

          <footer className="text-center">
            {isInvoice && (
              <button onClick={this.copyToClipboard} className="link">
                Copiar código de barras
              </button>
            )}
            <br />
            <button
              onClick={() => this.sendByEmail()}
              className="link secondary"
            >
              Compartilhar por email
            </button>
          </footer>
        </Modal>
      );
    }
  }

}

// ##################################################################################
// ###################### DOWNLOAD DO BOLETO ########################################
// ##################################################################################
function exportInvoice(showToast, ticketData) {

  const { cod_estab, 'nr-nota-fis': nota  } = ticketData;

  if(!!cod_estab) {
    window.open(`/invoice-pdf?acao=ArquivoBoleto&titulo=${titleInvoice(ticketData)}`);
    return;
  }

  if(!!nota) {
    const { CPFCNPJ, 'dt-emis-nota': endDate,  } = ticketData;
    window.open(`/invoice-pdf?cpfcnpj=${CPFCNPJ}&data-fim=${endDate}&nota=${nota}`);
    return;
  }
  
  showToast("Ops!", 'Esse arquivo não pode ser encontrado.');

}

// ##################################################################################
// ###################### DOWNLOAD DA NOTA FISCAL ###################################
// ##################################################################################
function exportDanf(showToast, ticketData) {
  const { 'cod-estabel': cod_estabelb, 'nr-nota-fis': nota  } = ticketData;
  
  if(!!cod_estabelb) {
    window.open(`/danfe-pdf?acao=ArquivoNF&titulo=${titleNF(ticketData)}`);
    return;
  };

  if(!!nota) {
    const { CPFCNPJ, 'dt-emis-nota': endDate } = ticketData;
    window.open(`/danfe-pdf?cpfcnpj=${CPFCNPJ}&data-fim=${endDate}&nota=${nota}`);
    return;
  };

  showToast("Ops!", 'Esse arquivo não pode ser encontrado.');
}

// cod_estab == cod_estabel | cod_espec_docto | cod_ser_docto == serie | cod_tit_acr = nr_nota_fis | cod_parcela
function titleInvoice(ticketData) {
  const { cod_estab, cod_espec_docto, cod_ser_docto, cod_tit_acr, cod_parcela } = ticketData;
  return `${cod_estab}|${cod_espec_docto}|${cod_ser_docto}|${cod_tit_acr}|${cod_parcela}`;
}

// cod_estab == cod_estabel |  cod_ser_docto == serie | cod_tit_acr == nr-nota-fis | 
function titleNF(ticketData) {
  const { 'cod-estabel': cod_estabel, serie, 'nr-nota-fis': nr_nota_fis } = ticketData;
  return `${cod_estabel}|${serie}|${nr_nota_fis}`;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(
  TicketModal
);
