import apiContext from "../../api";

import PdfPage from "../../components/PdfPage/PdfPage";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";

class InvoicePdfPage extends PdfPage {
  static contextType = apiContext;

  get fileName() {
    return "Boleto";
  }

  componentDidMount() {
    // captura os parammetros passados na URL
    const { acao, titulo, 'data-fim': endDate, nota } = this.state.params;
    const { cpfcnpj, startDate, finalDate, deliveryLocation } = this.state.params;


    // faz download com o novo boleto
    if(!!acao && !!titulo) {
      this.context.ConsigazApi.downloadBoleto(titulo).then(([err, file]) => {
        if(err) { 
          this.props.showToast("Ops!", err, 20000); 
          return;
        };

        this.setState({ file })
      });
      return;
    }

    // faz download com o novo boleto
    if(!!cpfcnpj && !!endDate && !!nota) {
      this.context.ConsigazApi.externalInvoice(cpfcnpj, endDate, nota).then(([err, file]) => {
        if(err) { 
          this.props.showToast("Ops!", err, 20000); 
          return;
        };

        this.setState({ file })
      });
      return;
    }

    // faz download na maneira antiga
    this.context.ConsigazReportApi.exportInvoice(
      cpfcnpj,
      startDate,
      finalDate,
      deliveryLocation
    ).then(
      ({ data: file }) => this.setState({ file }),
      (errorDescription) => this.props.showToast("Ops!", errorDescription)
    );

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(InvoicePdfPage);
