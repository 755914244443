import React from "react";

import "./ServiceDetail.scss";

export default ({
  title,
  description,
  image,
  deadline,
  checked,
  rawData,
  onToggle,
  onWarranty,
}) => (
  <>
    <h2 className="service-detail-title">{title}</h2>
    {description &&
      description.split("\n").map((row, idx) => (
        <p
          key={`service-generic-detail-row-${idx}`}
          style={{ textAlign: "justify" }}
        >
          {row}
        </p>
      ))}
    {image && (
      <img
        alt="service"
        src={image}
        style={{
          maxHeight: "300px",
          maxWidth: "100%",
        }}
      />
    )}
    <div style={{ textAlign: "left" }}>
      <hr />

      <h3 style={{ margin: 0 }}>Prazos</h3>

      {rawData && rawData.condPagamento && (
        <b>Prazo de pagamento é de {rawData.condPagamento}</b>
      )}
      <br />
      {deadline && <b>Prazo de atendimento é de {deadline}</b>}
    </div>
    <footer className="service-detail-footer">
      <button onClick={onWarranty} className="inline-link">
        Termos de garantia
      </button>
      <button className="block" onClick={() => onToggle && onToggle({})}>
        {checked ? "Desselecionar" : "Selecionar"}
      </button>
    </footer>
  </>
);
