import React from "react";
import apiContext from "../../../api";
import { Component } from "react";
import Modal from "../../../components/Modal/Modal";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../../state/ducks/loading";
import "./ServiceDetail.scss";
import SelectAddressList from "../SelectAddressList";
import CondominioService from "../../../services/condominio";
import { timeFormat } from "../../../components/Masks/Masks";
class TechnicalReport extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      complementaryData: this.initialComplementaryData,
      haveSubmited: false,
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
    };
  }

  get initialComplementaryData() {
    return {
      informacoes: null,
      horario: "",
    };
  }

  submit = () => {
    const { selectedCondominio } = this.state;
    this.confirmModal && this.confirmModal.close();
    this.setState({ haveSubmited: true });
    selectedCondominio !== "all" && this.generateOrder();
  };

  get value() {
    return null;
  }

  generateOrder = () => {
    const {
      complementaryData: { informacoes, horario },
      selectedCondominio,
    } = this.state;
    const { onToggle } = this.props;

    if (
      CondominioService.selectMany &&
      CondominioService.selectMany.length > 0
    ) {
      CondominioService.selectMany &&
        CondominioService.selectMany.map((teste) =>
          selectedCondominio.push(teste)
        );
      CondominioService.selectMany = selectedCondominio;
    } else CondominioService.selectMany = selectedCondominio;

    this.setState({ haveSubmited: false });

    onToggle &&
      onToggle({
        informacoes,
        horario,
        value: this.value,
      });
  };

  handleTimeChange = (e) => {
    const timeMask = timeFormat(e.target.value);
    const { complementaryData } = this.state;

    this.setState({
      complementaryData: {
        ...complementaryData,
        horario: timeMask,
      },
    });
  };

  render() {
    const { complementaryData, selectedCondominio, haveSubmited } = this.state;
    const { checked, onWarranty, rawData } = this.props;

    return (
      <>
        {selectedCondominio === "all" && haveSubmited === true && (
          <>
            <SelectAddressList
              onSubmit={(address) =>
                this.setState(
                  {
                    selectedCondominio: address,
                  },
                  () => this.generateOrder()
                )
              }
              cardClass={"service"}
              service={rawData}
            />
          </>
        )}
        {haveSubmited === false && (
          <>
            <h2 className="service-detail-title">{rawData.title}</h2>
            <p>{rawData.description}</p>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: ".5fr 1fr .6fr .5fr",
                gridTemplateRows: "1fr",
              }}
            >
              <div></div>
              <p style={{ marginRight: 5 }}>
                {" "}
                O valor cobrado para este serviço é de
              </p>

              <p className="border-info">R$1.502,00</p>
              <div></div>
            </div>
            <footer className="service-detail-footer">
              <button onClick={onWarranty} className="inline-link">
                Termos de garantia
              </button>
              <button
                className="block"
                onClick={() => {
                  checked
                    ? this.submit()
                    : this.confirmModal && this.confirmModal.open();
                }}
                // disabled={!this.isValid}
              >
                {checked ? "Desselecionar" : "Selecionar"}
              </button>
            </footer>
          </>
        )}

        <Modal
          shown={false}
          ref={(ref) => (this.confirmModal = ref)}
          title="Confirmar?"
          confirmButton={true}
          confirmButtonText={"Confirmar"}
          confirmButtonAction={() =>
            complementaryData.informacoes && complementaryData.horario
              ? this.submit()
              : this.props.showToast("OPS!", "Preencha todas as informações.")
          }
        >
          <p style={{ textAlign: "justify" }}>
            Para este serviço será cobrado o valor de R$1.502,00. <br />
            Confirma a solicitação?
          </p>
          <br />
          <b>Use o campo para detalhar o motivo</b>
          <br />
          <br />
          <textarea
            style={{ width: "100%" }}
            maxLength={200}
            onChange={(e) =>
              this.setState({
                complementaryData: {
                  ...complementaryData,
                  informacoes: e.target.value,
                },
              })
            }
          ></textarea>
          <br />
          <br />
          <b>
            Sugira um horário entre 08:00 e 17:00 para a execução do serviço
          </b>
          <br />
          <input
            type="text"
            maxLength={5}
            value={complementaryData["horario"]}
            onChange={this.handleTimeChange}
          ></input>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(TechnicalReport);
