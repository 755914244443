import React, { Component } from "react";
import apiContext from "../../../api";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../../state/ducks/loading";
import WarningImage from "../../../assets/illustrations/warning.png";

import "./ServiceDetail.scss";
import Modal from "../../../components/Modal/Modal";
import { redirect } from "../../../components/Page/Page";

class MeterChangeDetail extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      complementaryData: this.initialComplementaryData,
    };
  }

  get initialComplementaryData() {
    return {
      foiNotificado: null,
      informacoes: "",
    };
  }

  submit = () => {
    let {
      complementaryData: { foiNotificado, informacoes },
    } = this.state;
    const { onToggle } = this.props;

    if (foiNotificado === true)
      informacoes = `O usuário foi notificado sobre problemas no medidor. ${informacoes}`;
    if (foiNotificado === false)
      informacoes = `O usuário não foi notificado sobre problemas no medidor. ${informacoes}`;

    onToggle &&
      onToggle({
        informacoes,
      });
  };

  render() {
    const { complementaryData } = this.state;
    const { rawData, image, checked } = this.props;

    return (
      <>
        <h2 className="service-detail-title">{rawData.title}</h2>
        <p>{rawData.description}</p>

        {image && (
          <img
            alt="service"
            src={image}
            style={{
              maxHeight: "300px",
              maxWidth: "100%",
            }}
          />
        )}

        <p>
          <b>Use o campo para detalhar</b>
        </p>
        <textarea
          style={{ width: "100%" }}
          value={complementaryData.informacoes || ""}
          onChange={(e) =>
            this.setState({
              complementaryData: {
                ...complementaryData,
                informacoes: e.target.value,
              },
            })
          }
        />

        <br />
        <br />

        <p>
          <b>Você foi notificado sobre problemas no medidor?</b>
        </p>
        <label>
          <input
            type="radio"
            name="local-identificado"
            value={false}
            className={
              complementaryData.foiNotificado === false ? "checked" : ""
            }
            onChange={(e) =>
              this.setState({
                complementaryData: {
                  ...complementaryData,
                  foiNotificado: false,
                },
              })
            }
          />
          Não fui notificado
        </label>
        <label>
          <input
            type="radio"
            name="local-identificado"
            value={true}
            className={complementaryData.foiNotificado ? "checked" : ""}
            onChange={(e) =>
              this.setState({
                complementaryData: {
                  ...complementaryData,
                  foiNotificado: true,
                },
              })
            }
          />
          Sim, desejo trocar o medidor
        </label>

        <footer className="service-detail-footer">
          <button
            className="block"
            disabled={complementaryData.foiNotificado === null}
            onClick={() => {
              if (checked) {
                this.submit();
              } else {
                if (complementaryData.foiNotificado === true)
                  this.confirmationModal && this.confirmationModal.open();
                else
                  this.meterInstallationModal &&
                    this.meterInstallationModal.open();
              }
            }}
          >
            {checked ? "Desselecionar" : "Selecionar"}
          </button>
        </footer>
        <Modal
          shown={false}
          ref={(ref) => (this.meterInstallationModal = ref)}
          title="Fique atento!"
          imgSrc={WarningImage}
          closeButton={true}
          confirmButton={true}
          confirmButtonText="Fale conosco"
          confirmButtonAction={(modal) => {
            modal.close();
            redirect(this.props, "help");
          }}
        >
          Neste caso será necessário contratar o serviço de instalação de
          medidor ou entrar em contato com a nossa central de atendimento.
        </Modal>
        <Modal
          shown={false}
          ref={(ref) => (this.confirmationModal = ref)}
          title="Confirmar?"
          imgSrc={WarningImage}
          closeButton={true}
          confirmButton={true}
          confirmButtonText={"Confirmar"}
          confirmButtonAction={(modal) => {
            modal.close();
            this.submit();
          }}
          declineButton={true}
          declineButtonText="Desistir"
        >
          Sua solicitação será encaminhada ao responsável para análise.
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(MeterChangeDetail);
