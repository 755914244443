import React from "react";
import apiContext from "../../../api";
import Select from "../../../components/Select/Select";
import { IconCheck } from "../../../components/Icons/Icons";
import { Component } from "react";
import Modal from "../../../components/Modal/Modal";
import WarningImage from "../../../assets/illustrations/warning.png";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../../state/ducks/loading";
import moment from "moment";
import "./ServiceDetail.scss";
import Card from "../../../components/Card/Card";
import TicketService from "../../../services/ticket";
import { redirect } from "../../../components/Page/Page";
import SelectAddressList from "../SelectAddressList";
import CondominioService from "../../../services/condominio";

class IndustrialLeakageDetail extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      complementaryData: this.initialComplementaryData,
      informationCilindro: this.initialInformationCilindro,
      haveSubmited: false,
      flagModel: true,
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
    };
  }

  get initialComplementaryData() {
    return {
      "local-vazamento": false,
      informacoes: "",
      "vl-servico": "",
    };
  }

  get initialInformationCilindro() {
    return {
      comodatado: "",
      selectedModel: [],
      quantCilindros: "",
      acceptNextDelivery: null,
    };
  }

  get isSubmitButtonDisabled() {
    const { complementaryData, informationCilindro } = this.state;
    return (
      complementaryData["local-vazamento"] === false ||
      complementaryData["local-vazamento"] === "Não selecionado" ||
      (informationCilindro["selectedModel"].length > 0 &&
        informationCilindro["acceptNextDelivery"] === "") ||
      (complementaryData["local-vazamento"] === "Interno" &&
        complementaryData["informacoes"] === "") ||
      complementaryData["local-vazamento"] === "Prumada" ||
      (complementaryData["local-vazamento"] === "Cilindro" &&
        !informationCilindro["comodatado"])
    );
  }

  toggleModel(model) {
    const { informationCilindro } = this.state;
    const { selectedModel } = informationCilindro;

    if (informationCilindro["selectedModel"].indexOf(model) === -1)
      this.setState({
        informationCilindro: {
          ...informationCilindro,
          selectedModel: [...selectedModel, model],
        },
      });
    else
      this.setState({
        informationCilindro: {
          ...informationCilindro,
          selectedModel: selectedModel.filter((un) => un !== model),
        },
      });
  }

  submit = () => {
    const {
      complementaryData,
      informationCilindro,
      checked,
      selectedCondominio,
    } = this.state;

    if (complementaryData["local-vazamento"] === "Interno") {
      this.internModal && this.internModal.open();
      return;
    }

    complementaryData["vl-servico"] = this.value;

    if (complementaryData["local-vazamento"] === "Cilindro") {
      complementaryData["informacoes"] = `
        Possui cilindro comodatado: ${informationCilindro["comodatado"] || ""}
        A troca pode ser realizada na proxima entrega: ${
          informationCilindro["acceptNextDelivery"] || ""
        }
        Modelo do cilindro: ${(informationCilindro["selectedModel"] || []).join(
          ", "
        )}
        Quantidade de cilindros:  ${informationCilindro["quantCilindros"] || ""}
      `;
    }

    if (!checked) {
      if (!checked && complementaryData["local-vazamento"] === "Interno") {
        this.priorityModal && this.priorityModal.open();
        return;
      } else if (
        complementaryData["local-vazamento"] === "Cilindro" &&
        informationCilindro["acceptNextDelivery"] === "nao"
      ) {
        this.faleConoscoModal && this.faleConoscoModal.open();
        return;
      } else if (
        complementaryData["local-vazamento"] === "Cilindro" &&
        informationCilindro["comodatado"] === "nao" &&
        !informationCilindro["acceptNextDelivery"]
      ) {
        this.verifyOriginModal && this.verifyOriginModal.open();
        return;
      } else if (
        complementaryData["local-vazamento"] === "Cilindro" &&
        informationCilindro["comodatado"] === "sim" &&
        !informationCilindro["acceptNextDelivery"]
      ) {
        this.informModelModal && this.informModelModal.open();
        return;
      }
    }
    this.setState({ haveSubmited: true });
    selectedCondominio !== "all" && this.generateOrder();
  };

  get value() {
    const {
      complementaryData: { "local-vazamento": localVazamento },
    } = this.state;
    const { rawData } = this.props;
    if (localVazamento) return rawData.priceConditional;
    return null;
  }

  generateOrder = () => {
    const {
      complementaryData: {
        "local-vazamento": localVazamento,
        informacoes,
        "vl-servico": vlservico,
      },
      selectedCondominio,
      selectedAddress,
    } = this.state;

    if (selectedCondominio === "all") {
      if (
        CondominioService.selectMany &&
        CondominioService.selectMany.length > 0
      ) {
        CondominioService.selectMany &&
          CondominioService.selectMany.map((teste) =>
            selectedAddress.push(teste)
          );
        CondominioService.selectMany = selectedAddress;
      } else CondominioService.selectMany = selectedAddress;
    }

    const { onToggle } = this.props;
    this.setState({ haveSubmited: false });
    onToggle &&
      onToggle({
        localVazamento,
        informacoes,
        vlservico,
      });
  };

  render() {
    const {
      complementaryData,
      informationCilindro,
      haveSubmited,
      selectedCondominio,
    } = this.state;
    const { checked, onWarranty, rawData, showToast } = this.props;

    const nextDelivery = TicketService.getNextDelivery();

    return (
      <>
        {selectedCondominio === "all" && haveSubmited === true && (
          <>
            <SelectAddressList
              onSubmit={(address) =>
                this.setState(
                  {
                    selectedAddress: address,
                  },
                  () => this.generateOrder()
                )
              }
              cardClass={"service"}
              service={rawData}
            />
          </>
        )}

        {haveSubmited === false && (
          <>
            <h2 className="service-detail-title">{rawData.title}</h2>
            {[
              "Feche o registro de gás.",
              "Afaste as pessoas do local.",
              "Não acione interruptores de eletricidade.",
              "Não fume nem acenda fósforos ou isqueiros.",
              "Abra portas e janelas.",
              "Em casos mais graves também entre em contato com o Corpo de Bombeiros (193)",
            ].map((item, idx) => (
              <p
                key={`leakage-service-item-${idx}`}
                style={{ textAlign: "left" }}
              >
                <IconCheck color="green" /> {item}
              </p>
            ))}

            <Select
              className="block"
              options={[
                {
                  value: "Não selecionado",
                  title: "Tipos de Vazamento",
                },
                {
                  value: "Tanque",
                  title: "No tanque",
                },
                {
                  value: "Cilindro",
                  title: "No cilindro (botijão).",
                },
                {
                  value: "Prumada",
                  title: "Na prumada (tubulação)",
                },
                {
                  value: "Interno",
                  title: "Na unidade consumidora (apartamento/casa/loja)",
                },
                {
                  value: "Desconhecido",
                  title: "Não sei onde é o vazamento",
                },
              ]}
              value={complementaryData["local-vazamento"]}
              onChange={(e) =>
                this.setState({
                  complementaryData: {
                    ...this.initialComplementaryData,
                    "local-vazamento": e.target.value,
                  },
                  haveSubmited: false,
                })
              }
              disabled={checked}
            />
            <br />
            {(((complementaryData["local-vazamento"] === "Tanque" && haveSubmited !== true) || complementaryData["local-vazamento"] === "Desconhecido")) &&
              this.priorityModal &&
              this.priorityModal.open()}

            {complementaryData["local-vazamento"] === "Interno" && (
              <>
                <b>
                  informe qual é a unidade consumidora que precisa do
                  atendimento:
                </b>
                <br />
                <input
                  type="text"
                  placeholder="apartamento/casa/loja,bloco"
                  style={{ width: 300 }}
                  value={complementaryData["informacoes"]}
                  onChange={(e) =>
                    this.setState({
                      complementaryData: {
                        ...complementaryData,
                        informacoes: e.target.value,
                      },
                    })
                  }
                  disabled={checked}
                />
              </>
            )}

            {!checked &&
              complementaryData["local-vazamento"] === "Prumada" &&
              this.prumadaModal &&
              this.prumadaModal.open()}

            {complementaryData["local-vazamento"] === "Cilindro" && (
              <>
                <p>
                  <b>Você possui cilindro comodatado?</b>
                </p>
                <label>
                  <label>
                    <input
                      type="radio"
                      name="informacoes"
                      //   checked={informationCilindro["comodatado"] === "sim"}
                      //   className={
                      //     informationCilindro["comodatado"] === "sim" && "checked"
                      //   }
                      className={
                        informationCilindro["comodatado"] === "sim"
                          ? "checked"
                          : ""
                      }
                      onChange={(e) =>
                        this.setState({
                          informationCilindro: {
                            ...informationCilindro,
                            selectedModel: [],
                            comodatado: "sim",
                          },
                        })
                      }
                      disabled={checked}
                    />
                    Sim
                  </label>
                  <input
                    type="radio"
                    name="informacoes"
                    // checked={informationCilindro["comodatado"] === "não"}
                    // className={
                    //   informationCilindro["comodatado"] === "não" && "checked"
                    // }
                    className={
                      informationCilindro["comodatado"] === "nao"
                        ? "checked"
                        : ""
                    }
                    onChange={(e) =>
                      this.setState({
                        informationCilindro: {
                          ...informationCilindro,
                          selectedModel: [],
                          comodatado: "nao",
                        },
                      })
                    }
                    disabled={checked}
                  />
                  Não
                </label>
              </>
            )}
            <br />

            {complementaryData["informacoes"] &&
              informationCilindro["selectedModel"].length > 0 && (
                <>
                  <p>
                    <b>
                      A troca pode ser realizada junto com a sua próxima
                      entrega?
                    </b>
                  </p>
                  <Card
                    className="text-center no-shadow"
                    styleName="ontlineSecondary"
                  >
                    <p className="title no-margin">Data da entrega:</p>
                    <b
                      style={{
                        display: "block",
                        margin: ".7rem auto",
                        fontSize: 20,
                      }}
                    >
                      {nextDelivery !== null &&
                        moment(
                          nextDelivery["dt-prox-entrega"],
                          "YYYY-MM-DD"
                        ).format("DD/MM/YYYY")}
                    </b>
                  </Card>
                  <label>
                    <label>
                      <input
                        type="radio"
                        name="local-vazamento"
                        checked={
                          informationCilindro["acceptNextDelivery"] === "sim"
                        }
                        className={
                          informationCilindro["acceptNextDelivery"] === "sim" &&
                          "checked"
                        }
                        onChange={(e) =>
                          this.setState({
                            informationCilindro: {
                              ...informationCilindro,
                              acceptNextDelivery: "sim",
                            },
                          })
                        }
                        disabled={checked}
                      />
                      Sim
                    </label>
                    <input
                      type="radio"
                      name="local-vazamento"
                      checked={
                        informationCilindro["acceptNextDelivery"] === "nao"
                      }
                      className={
                        informationCilindro["acceptNextDelivery"] === "nao" &&
                        "checked"
                      }
                      onChange={(e) =>
                        this.setState({
                          informationCilindro: {
                            ...informationCilindro,
                            acceptNextDelivery: "nao",
                          },
                        })
                      }
                      disabled={checked}
                    />
                    Não
                  </label>
                </>
              )}

            <footer className="service-detail-footer">
              <button onClick={onWarranty} className="inline-link">
                Termos de garantia
              </button>
              <button
                className="block"
                onClick={() => this.submit()}
                disabled={this.isSubmitButtonDisabled}
              >
                {checked
                  ? "Desselecionar"
                  : complementaryData["local-vazamento"] === "Cilindro" &&
                    informationCilindro["selectedModel"].length === 0
                  ? "Avançar"
                  : "Selecionar"}
              </button>
            </footer>
          </>
        )}

        <Modal
          shown={false}
          ref={(ref) => (this.informModelModal = ref)}
          title="Nos informe"
          confirmButton={true}
          confirmButtonText={"ok"}
          confirmButtonAction={() =>
            informationCilindro["selectedModel"].length > 0
              ? informationCilindro["quantCilindros"] !== ""
                ? this.informModelModal.close()
                : showToast(
                    "Ops",
                    "Você precisa informar a quantidade de cilindros para continuar."
                  )
              : showToast(
                  "Ops",
                  "Você precisa informar ao menos um modelo para continuar."
                )
          }
        >
          Modelo do Cilindro
          <br />
          <label>
            <input
              type="checkbox"
              className={
                informationCilindro["selectedModel"].indexOf("p13") > -1
                  ? "checked"
                  : ""
              }
              onClick={() => this.toggleModel("p13")}
            />
            P13
          </label>
          <label>
            <input
              type="checkbox"
              className={
                informationCilindro["selectedModel"].indexOf("p20") > -1
                  ? "checked"
                  : ""
              }
              onClick={() => this.toggleModel("p20")}
            />
            P20
          </label>
          <label>
            <input
              type="checkbox"
              className={
                informationCilindro["selectedModel"].indexOf("p45") > -1
                  ? "checked"
                  : ""
              }
              onClick={() => this.toggleModel("p45")}
            />
            P45
          </label>
          <input
            type="number"
            className="changeData"
            style={{ width: "80%" }}
            placeholder="Número de cilindros com problema"
            value={this.state.informationCilindro["quantCilindros"]}
            onChange={(e) =>
              this.setState({
                informationCilindro: {
                  ...informationCilindro,
                  quantCilindros: e.target.value,
                },
              })
            }
            disabled={checked}
          />
        </Modal>

        <Modal
          shown={false}
          ref={(ref) => (this.verifyOriginModal = ref)}
          title="Fique atento!"
          confirmButton={true}
          confirmButtonText={"Sim"}
          confirmButtonAction={() => {
            this.informModelModal && this.informModelModal.open();
            this.verifyOriginModal && this.verifyOriginModal.close();
          }}
          declineButton={true}
          declineButtonAction={() => {
            this.failModal && this.failModal.open();
            this.verifyOriginModal && this.verifyOriginModal.close();
          }}
          declineButtonText={"Não"}
        >
          <p style={{ textAlign: "justify" }}>
            O cilindro (botijão) é da nossa marca?
          </p>
        </Modal>

        <Modal
          shown={false}
          ref={(ref) => (this.failModal = ref)}
          title="Fique atento!"
          confirmButton={true}
          confirmButtonText={"Ok"}
          confirmButtonAction={() => {
            this.failModal && this.failModal.close();
            this.setState({
              flagModel: false,
            });
          }}
        >
          <p style={{ textAlign: "justify" }}>
            A troca não poderá ser realizada. Por favor, entre em contato com o
            fornecedor do cilindro.
          </p>
        </Modal>

        <Modal
          shown={false}
          ref={(ref) => (this.priorityModal = ref)}
          title={
            complementaryData["local-vazamento"] === "Interno"
              ? "Prioridade!"
              : "Confirmar?"
          }
          imgSrc={WarningImage}
          confirmButton={true}
          confirmButtonText={"Confirmar"}
          confirmButtonAction={() =>
            selectedCondominio !== "all"
              ? this.submit()
              : this.setState(
                  { haveSubmited: true },
                  this.priorityModal && this.priorityModal.close()
                )
          }
        >
          {complementaryData["local-vazamento"] === "Interno" ? (
            <>
              <br />
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                Solicitação com prioridade.
              </p>
              <p style={{ textAlign: "justify" }}>
                O técnico mais próximo será direcionado ao local para prestar
                este atendimento.
              </p>
            </>
          ) : (
            <p style={{ textAlign: "justify" }}>
              Sua solicitação será atendida com prioridade. O técnico mais
              próximo será direcionado ao local e prestará o atendimento.
            </p>
          )}
        </Modal>

        <Modal
          shown={false}
          ref={(ref) => (this.internModal = ref)}
          title="Fique atento!"
          imgSrc={WarningImage}
          confirmButton={true}
          confirmButtonText={"Confirmar visita técnica"}
          confirmButtonAction={(modal) => {
            modal.close();
            this.priorityModal && this.priorityModal.open();
          }}
        >
          <p style={{ textAlign: "justify" }}>
            A Consigaz não realiza manutenção em equipamentos domésticos como
            por exemplo fogões, chuveiros e aquecedores. Caso o problema seja em
            algum desses equipamentos, procure a assistência técnica dos mesmos.
            Para realizarmos a visita técnica dentro da unidade consumidora é
            cobrado o <strong>valor de R$150,00</strong> e o prazo de pagamento
            de até 30 dias.
          </p>
        </Modal>

        <Modal
          shown={false}
          ref={(ref) => (this.prumadaModal = ref)}
          title="Fique atento!"
          confirmButton={true}
          confirmButtonText={"ok"}
        >
          <p style={{ textAlign: "justify" }}>
            Não realizamos reparos na prumada (tubulação) do condomínio e das
            unidades consumidoras. <br />
            Caso o condomínio ainda esteja dentro da garantia, entre em contato
            com a construtora. Caso o prazo de garantia tenha expirado, contate
            uma assistência técnica especializada.
            {/* Enviaremos uma ocorrência ao setor responsável para que tome conhecimento do caso. */}
          </p>
        </Modal>

        <Modal
          shown={false}
          ref={(ref) => (this.faleConoscoModal = ref)}
          title="Fique atento!"
          imgSrc={WarningImage}
          confirmButton={true}
          confirmButtonText={"Fale conosco"}
          confirmButtonAction={() => redirect(this.props, "/help")}
        >
          <p style={{ textAlign: "justify" }}>
            Por favor, entre em contato com a nossa central de atendimento ao
            cliente.
          </p>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(IndustrialLeakageDetail);
