import React, { Component } from "react";

import "./Services.scss";

import ClienteService from "../../services/cliente";
import apiContext from "../../api";

import Page, { redirect } from "../../components/Page/Page";
import PageCenterContent from "../../components/PageCenterContent/PageCenterContent";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import Modal from "../../components/Modal/Modal";

import ReligueImage from "../../assets/services/religue.png";
import EquipamentosImage from "../../assets/services/equipamentos.png";
import WarningImage from "../../assets/illustrations/warning.png";
import CondominioService from "../../services/condominio";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { loadingOperations } from "../../state/ducks/loading";
import GenericDetail from "./details/GenericDetail";
import Leakage from "./details/Leakage";
import TightnessTest from "./details/TightnessTest";
import IndustrialTightnessTest from "./details/IndustrialTightnessTest";
import Regulation from "./details/Regulation";
import MeterChange from "./details/MeterChange";
import ChangeData from "./details/ChangeData";
import IndustrialLeakage from "./details/IndustrialLeakage";
import TechnicalReport from "./details/TechnicalReport";
import IndustrialRegulation from "./details/IndustrialRegulation";
import ChangePayment from "./details/ChangePayment";
import RepresentantiveContact from "./details/RepresentantiveContact";
import CollectiveVacation from "./details/CollectiveVacation";
import RequestSupply from "./details/RequestSupply";
import IndustrialCard from "../../components/IndustrialCard/IndustrialCard";
import moment from "moment";
import TicketService from "../../services/ticket";
import ScheduleRequest from "./details/ScheduleRequest";
import DuplicateContract from "./details/DuplicateContract";
import Card from "../../components/Card/Card";
import { IconHome } from "../../components/Icons/Icons";

class ServiceCardsComponent extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    props.editService && props.editService(this.editService);
    props.scheduleService && props.scheduleService(this.scheduleService);

    this.state = {
      services: null,
      detailModalServiceId: null,
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
      outdatedTickets: [],
    };
  }

  componentDidMount() {
    const { situacao } = CondominioService.selected || {};
    const { cardClass } = this.props;
    const { CPFCNPJ } = ClienteService.localEntrega;

    const dataFim = moment().startOf("month").format("YYYY/MM/DD");
    const dataIni = moment()
      .subtract(1, "year")
      .endOf("month")
      .format("YYYY/MM/DD");

    if (!ClienteService.localEntrega) {
      this.props.showToast(
        "Opa!",
        "Dados insuficientes para continuar o cadastro"
      );
      return;
    }

    if (
      (cardClass !== "delivery" && situacao !== 2) ||
      cardClass === "delivery"
    ) {
      this.props.showLoading();
      this.getServices()
        .then(({ ServicoRetorno }) => this.setServices(ServicoRetorno || []))
        .catch((errorDescription) =>
          this.setState({ services: [] }, () => {
            this.props.dismissLoading();
            if (
              !(
                errorDescription === "Cliente não possuí serviço" ||
                errorDescription === "Não encontrado condomínio para o Cliente"
              )
            )
              this.props.showToast("Opa!", errorDescription);
          })
        );
    }

    this.context.ConsigazApi.memConsultaNotaFiscal(
      CPFCNPJ,
      dataIni,
      dataFim
    ).then(({ Notas }) => {
      if (Notas && Notas.NotasFiscais && Notas.NotasFiscais.length) {
        const { "local-entrega": localEntrega } =
          CondominioService.selected || {};
        const tickets = Notas.NotasFiscais.filter((ticket) => {
          if (localEntrega) return ticket["local-entrega"] === localEntrega;
          return true;
        });

        if (tickets && tickets.length) {
          this.setState({
            outdatedTickets: tickets.filter(
              (i) => TicketService.getTicketStatus(i) === "outdated"
            ),
          });
        }
      }
    });
    // CondominioService.selectMany = [];
  }

  getServices() {
    let { [`local-entrega`]: localEntrega } = CondominioService.selected || {};
    let { segmento } = ClienteService.localEntrega;
    if (!localEntrega)
      localEntrega =
        ClienteService.localEntrega &&
        ClienteService.localEntrega.ClienteEntrRetorno[0][`cod-entrega`];

    let cpfcnpj;
    const newClientData = ClienteService.newClienteData;
    const clienteLocalEntrega = ClienteService.localEntrega;
    if (newClientData) {
      cpfcnpj = newClientData.cpfcnpj;
    } else if (clienteLocalEntrega) {
      cpfcnpj = clienteLocalEntrega.CPFCNPJ;
    }

    return this.context.ConsigazApi.consultaServicos(
      cpfcnpj,
      localEntrega,
      segmento
    );
  }

  setServices(apiServices) {
    const services = apiServices
      .map((service, idx) => ({
        id: idx,
        codMotivo: service["cod-motivo"],
        title: service["descricao"],
        description: service["detalhes"],
        type: service["ind-menu"],
        service: service["servico"],
        deadline: service["prazo-inicio-servico"],
        condPagamento: service["cod-cond-pag"],
        image: service["arq-imagem"].toLowerCase(),
        price: service["vl-servico"],
        priceConditional: service["vl-condicional"],
        ref: null, // for component ref later on
        termsAcknowledged: true, // for terms vaidation
        nrAcordoComercial: service["nr-acordo-comercial"],
      }))
      .filter((s) => s !== undefined);
    this.setState({ services }, () => this.props.dismissLoading());
  }

  get extendedFlowServices() {
    return [
      "1;;",
      "2;;",
      "3;;",
      "4;;",
      "12;;",
      "14;;",
      "15;;",
      "17;;",
      "18;;",
      "19;;",
      "20;;",
      "21;;",
      "22;;",
      "23;;",
      "24;;",
    ];
  }

  switchChecked(service, complementaryData = null) {
    if (!complementaryData) complementaryData = service.complementaryData;
    else service.complementaryData = complementaryData;

    if (this.extendedFlowServices.indexOf(service.service) > -1) {
      if (!complementaryData) {
        this.detailService(service.id);
        return;
      }
    }
    service.ref.setState({ checked: !service.ref.state.checked }, () => {
      if (service.type === "Entregas") this.handleSubmit();
    });
  }

  removeServiceSelection(service) {
    service.ref.setState({ checked: !service.ref.state.checked })
  }

  toggleServiceCheck(service, complementaryData = {}) {
    const checked = !service.ref.state.checked;
    const selectMany = CondominioService.selectMany;
    service.complementaryData = complementaryData;

    if (
      checked &&
      service.id === 0 &&
      (this.state.services || []).filter((service) => service.id === 50)
        .length > 0
    ) {
      this.religueAdviceModal.open();
      return;
    }
    if (
      this.extendedFlowServices.indexOf(service.service) > -1 &&
      !complementaryData
    ) {
      this.detailService(service.id);
    } else if (checked) {
      const { [`cod-emitente`]: codEmitente } = ClienteService.localEntrega;
      const { [`local-entrega`]: localEntrega } =
        CondominioService.selected || {};

      this.props.showLoading();
      if (CondominioService.selectAll && selectMany && selectMany.length) {
        selectMany.map((addresses) =>
          this.context.ConsigazApi.verificaProtocolos(
            codEmitente,
            addresses["local-entrega"],
            addresses.codMotivo,
            addresses.idservice
          ).then(
            ({ Retorno }) => {
              this.props.dismissLoading();
              if (Retorno && Retorno.length > 0) {
                this.setState(
                  {
                    serviceOnToggle: service,
                  },
                  () =>
                    this.modalExistentProtocol &&
                    this.modalExistentProtocol.open()
                );
                return;
              }
            },
            () => {
              this.props.dismissLoading();
              this.switchChecked(service, complementaryData);
            }
          )
        );
      } else {
        this.context.ConsigazApi.verificaProtocolos(
          codEmitente,
          localEntrega,
          service.codMotivo,
          service.service
        ).then(
          ({ Retorno }) => {
            this.props.dismissLoading();
            if (Retorno && Retorno.length > 0) {
              this.setState(
                {
                  serviceOnToggle: service,
                },
                () =>
                  this.modalExistentProtocol &&
                  this.modalExistentProtocol.open()
              );
              return;
            }
            this.switchChecked(service, complementaryData);
          },
          () => {
            this.props.dismissLoading();
            this.switchChecked(service, complementaryData);
          }
        );
      }
    } else {
      service.ref.setState({ checked: false });
    }
  }

  getServiceImage = (key) =>
  ({
    religue: ReligueImage,
    equipamentos: EquipamentosImage,
  }[key]);

  renderSeriveContent(service) {
    const { serviceData } = this.state;

    const contentParams = {
      rawData: service,
      title: service.title,
      description: service.description,
      image: this.getServiceImage(service.image),
      deadline: service.deadline,
      checked: service.ref.state.checked,
      history: this.props.history,
      removeServiceSelection: (serviceData) => this.removeServiceSelection(serviceData),
      onToggle: (complementaryData) => {
        if(service?.service === "4;;") {
          this.setState({
            ...this.state,
            services: this.state.services.map((service) => {
              return {
                ...service,
                complementaryData,
                price: service.service === "4;;" ? complementaryData.value : service.price,
              }
            })
          })
        }
        this.detailModal && this.detailModal.close();
        !service.ref.state.checked && this.toggleServiceCheck(service, complementaryData);
      },
      onDismiss: () => this.detailModal && this.detailModal.close(),
      onWarranty: () =>
        this.modalWarrantyTerms && this.modalWarrantyTerms.open(),
    };

    if (service.service === "1;;") return <Regulation {...contentParams} />;
    if (service.service === "2;;") return <Leakage {...contentParams} />;
    if (service.service === "3;;") return <MeterChange {...contentParams} />;
    if (service.service === "4;;") return <TightnessTest {...contentParams } />;    
    if (service.service === "12;;")
      return <IndustrialLeakage {...contentParams} />;
    if (service.service === "14;;")
      return <IndustrialRegulation {...contentParams} />;
    if (service.service === "15;;")
      return <IndustrialTightnessTest {...contentParams} />;
    if (service.service === "17;;")
      return <TechnicalReport {...contentParams} />;
    if (service.service === "18;;") return <ChangePayment {...contentParams} />;
    if (service.service === "19;;")
      return <CollectiveVacation {...contentParams} data={serviceData} />;
    if (service.service === "20;;")
      return <RepresentantiveContact {...contentParams} />;
    if (service.service === "21;;") return <ChangeData {...contentParams} />;
    if (service.service === "22;;") return <RequestSupply {...contentParams} />;
    if (service.service === "23;;")
      return <ScheduleRequest {...contentParams} />;
    if (service.service === "24;;")
      return <DuplicateContract {...contentParams} />;
    else return <GenericDetail {...contentParams} />;
  }

  getServiceContent(serviceId) {
    const serviceFilter = (this.state.services || []).filter(
      (serv) => serv.id === serviceId
    );

    if (serviceFilter.length) {
      const service = serviceFilter[0];
      return (service && this.renderSeriveContent(service)) || <></>;
    }
  }

  handleSubmit = () => {
    const { showToast } = this.props;

    const acquiredServices = (this.state.services || [])
      .filter((service) => service.ref && service.ref.state.checked)
      .map(
        ({
          id,
          codMotivo,
          title,
          price,
          description,
          deadline,
          condPagamento,
          service,
          nrAcordoComercial,
          complementaryData,
          priceConditional,
          type,
        }) => ({
          id,
          codMotivo,
          title,
          price,
          description,
          deadline,
          condPagamento,
          service,
          nrAcordoComercial,
          complementaryData,
          priceConditional,
          type,
        })
      );
    
    if (acquiredServices.length > 0) {
      this.props.onSubmit && this.props.onSubmit(acquiredServices);
    } else
      showToast(
        "Ops",
        "Você precisa selecionar ao menos um serviço para continuar."
      );
  };

  handleReligueAdvice = () => {
    this.religueAdviceModal.close();
    if (
      (this.state.services || []).filter((service) => service.id === 50)
        .length > 0
    ) {
      this.setState({ detailModalServiceId: 50 }, () =>
        this.detailModal.open()
      );
    }
  };

  handleConfirmReligue = () => {
    (this.state.services || []).forEach((service) => {
      if (service.id === 0) {
        this.switchChecked(service, service.complementaryData);
      }
    });
    this.religueAdviceModal.close();
  };

  editService = (key, serviceData) => {
    const { services } = this.state;

    const service = (services || []).filter((serv) => serv.service === key)[0];

    return this.detailService(service.id, serviceData);
  };

  detailService = (key, serviceData) => {
    const { services, selectedCondominio } = this.state;
    const { situacao } = CondominioService.selected || {};
    const { credito } = ClienteService.localEntrega || {};
    const serviceFilter = (services || []).find((serv) => serv.id === key);

    if (serviceFilter) {
      const service = serviceFilter;
      const refState = (service.ref || {}).state || {};

      if (refState.checked) {
        if (selectedCondominio === "all") {
          const removeProtocols = CondominioService.selectMany.filter(
            (addr) => addr.idservice !== service.service
          );

          CondominioService.selectMany = removeProtocols;
        }
        this.toggleServiceCheck(service, {});
        return;
      }
    }
    this.setState(
      {
        detailModalServiceId: key,
        serviceData,
      },
      () => {
        if (
          selectedCondominio !== "all" &&
          ((serviceFilter &&
            (serviceFilter.service === "19;;" ||
              serviceFilter.service === "22;;" ||
              serviceFilter.service === "23;;") &&
            situacao === 2) ||
            ((credito || '').toLowerCase() === "suspenso"))
        ) {
          this.modalDenied && this.modalDenied.open();
        } else if (
          serviceFilter &&
          serviceFilter.service === "19;;" &&
          selectedCondominio !== "all" &&
          (selectedCondominio.Itens || []).filter(
            (item) => item["dt-ini-ferias"]
          ).length !== 0
        ) {
          this.alreadyExistModal && this.alreadyExistModal.open();
        } else {
          this.detailModal && this.detailModal.open();
        }
      }
    );
  };

  renderServices() {
    const { services } = this.state;
    const { cardClass, selectedService } = this.props;
    const { segmento } = ClienteService.localEntrega;

    if (services && services.length > 0)
      return (
        <div className="services-container">
          {cardClass !== "delivery"
            ? segmento !== "INDUSTRIAL"
              ? services.map((service) => (
                <ServiceCard
                  title={service.title}
                  description={service.description.split(".")[0]}
                  ref={(ref) => (service.ref = ref)}
                  key={service.id}
                  serviceId={service.id}
                  onContract={() => this.toggleServiceCheck(service)}
                  onDetail={this.detailService}
                />
              ))
              : services
                .filter((service) => service.type === "Diversos")
                .map((service) => (
                  <IndustrialCard
                    title={service.title}
                    ref={(ref) => (service.ref = ref)}
                    key={service.id}
                    serviceId={service.id}
                    image={service.service}
                    cardClass={"service"}
                    onContract={() => this.toggleServiceCheck(service)}
                    onDetail={this.detailService}
                  />
                ))
            : services
              .filter((service) => service.type === "Entregas")
              .map((service) => (
                <IndustrialCard
                  title={service.title}
                  description={service.description.split(".")[0]}
                  ref={(ref) => (service.ref = ref)}
                  image={service.service}
                  selectedService={selectedService}
                  teste={service.type}
                  serviceId={service.id}
                  onDetail={this.detailService}
                />
              ))}
        </div>
      );
    else if (services != null) return "Não foram encontrados serviços";
  }

  renderDetailModal() {
    const { detailModalServiceId } = this.state;
    const { segmento } = ClienteService.localEntrega;

    if (segmento === "INDUSTRIAL" && detailModalServiceId === 1)
      return this.getServiceContent(detailModalServiceId);
    else
      return (
        <Modal
          ref={(ref) => (this.detailModal = ref)}
          shown={false}
          className="large"
          closeButton={true}
        >
          {this.getServiceContent(detailModalServiceId)}
        </Modal>
      );
  }

  renderReligueAdviceModal() {
    return (
      <Modal
        ref={(ref) => (this.religueAdviceModal = ref)}
        title="Atenção!"
        shown={false}
        imgSrc={WarningImage}
        declineButton={true}
        declineButtonText="Não"
        declineButtonAction={() => this.handleReligueAdvice()}
        confirmButton={true}
        confirmButtonText="Sim"
        confirmButtonAction={() => this.handleConfirmReligue()}
      >
        Para este serviço é necessário que você tenha o medidor e o regulador
        instalados. Você possui estes equipamentos?
      </Modal>
    );
  }

  renderWarrantyTermsModal() {
    return (
      <Modal
        ref={(ref) => (this.modalWarrantyTerms = ref)}
        className="large warranty-terms-modal"
        shown={false}
        closeButton={true}
      >
        <iframe
          title="Warranty terms frame"
          src="/warranty-terms"
          frameBorder="0"
          style={{
            width: "100%",
            height: "70vh",
            maxHeight: "500px",
          }}
        />
      </Modal>
    );
  }

  renderExistentProtocolModal() {
    const { serviceOnToggle, selectedCondominio } = this.state;
    return (
      <Modal
        ref={(ref) => (this.modalExistentProtocol = ref)}
        title="Fique Atento!"
        shown={false}
        closeButton={true}
        declineButton={true}
        declineButtonText="Não"
        confirmButton={true}
        confirmButtonText="Sim"
        confirmButtonAction={(modal) => {
          modal.close();
          const { serviceOnToggle: onToggle } = this.state;
          this.switchChecked(onToggle);
        }}
      >
        Você já possui{" "}
        {serviceOnToggle && serviceOnToggle.service === "19;;"
          ? "essa solicitação registrada"
          : `esse serviço contratado${selectedCondominio === "all"
            ? " para os endereços a seguir:"
            : "."
          }`}
        <br />
        Deseja contratar {serviceOnToggle && serviceOnToggle.title} novamente
        {selectedCondominio === "all" ? " para esses endereços?" : "?"}
        <br />
        <br />
        {selectedCondominio !== "all" && (
          <img src={WarningImage} height={140} alt="Modal img" />
        )}
        {selectedCondominio === "all" &&
          CondominioService.selectMany &&
          CondominioService.selectMany.length > -1 &&
          CondominioService.selectMany
            .filter(
              (address) =>
                address.idservice ===
                (serviceOnToggle && serviceOnToggle.service)
            )
            .map((address) => (
              <Card
                key={`address-${address["cod-condominio"]}-${address["local-entrega"]}`}
                styleName="ontlineSecondary"
                style={{ cursor: "auto", height: "90px" }}
              >
                <div>
                  <IconHome size={40} />
                </div>
                <div>
                  <p>{address.endereco}</p>
                </div>
                <div></div>
              </Card>
            ))}
      </Modal>
    );
  }

  renderAlreadyExistModal() {
    return (
      <Modal
        ref={(ref) => (this.alreadyExistModal = ref)}
        title="Ops!"
        shown={false}
        closeButton={true}
        declineButton={true}
        declineButtonText="Cancelar"
        confirmButton={true}
        confirmButtonText="Alterar o aviso de ferias coletivas"
        confirmButtonAction={(modal) => {
          modal.close();
          this.detailModal && this.detailModal.open();
        }}
      >
        Este local já possui um Aviso de Férias Coletivas cadastrado. Alterá-lo
        irá substituir a data anterior!
      </Modal>
    );
  }

  renderDeniedModal() {
    const { "cod-motivo": codMotivo } = CondominioService.selected || {};
    const { credito } = ClienteService.localEntrega || {};
    const { outdatedTickets } = this.state;
    return (
      <Modal
        ref={(ref) => (this.modalDenied = ref)}
        shown={false}
        title="Fique Atento!"
        confirmButton={true}
        confirmButtonText={
          (credito === "Suspenso" && codMotivo === 41) ||
            outdatedTickets.length > 0
            ? "OK"
            : "Reativar"
        }
        confirmButtonAction={() => {
          (credito === "Suspenso" && codMotivo === 41) ||
            outdatedTickets.length > 0
            ? redirect(this.props, "/finances", {
              tab: "invoices",
              selectedStatus: ["outdated"],
            })
            : redirect(this.props, "/home");
        }}
      >
        <br />
        {(credito || "").toLowerCase() === "suspenso"
          ? "Este local de entrega está bloqueado para abastecimento. Para restabelecer o fornecimento, efetue o pagamento das faturas em atraso."
          : "Este local de entrega está bloqueado para abastecimento. Para restabelecer o fornecimento, clique aqui no botão abaixo e solicite a reativação do seu cadastro."}
      </Modal>
    );
  }

  renderModals() {
    return (
      <>
        {this.renderDetailModal()}
        {this.renderReligueAdviceModal()}
        {this.renderWarrantyTermsModal()}
        {this.renderExistentProtocolModal()}
        {this.renderAlreadyExistModal()}
        {this.renderDeniedModal()}
      </>
    );
  }

  renderContent() {
    const { services } = this.state;
    const { cardClass } = this.props;

    return (
      <>
        {this.renderServices()}
        <br />
        {cardClass !== "delivery" && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <button
              className="block"
              onClick={
                services && services.length
                  ? this.handleSubmit
                  : () => redirect(this.props, "home")
              }
              style={{ marginTop: ".5rem" }}
            >
              Contratar
            </button>

            <button
              className="block"
              onClick={() => redirect(this.props, "/save-time")}
              style={{ marginTop: ".5rem" }}
            >
              Voltar
            </button>
          </div>
        )}
      </>
    );
  }

  render() {
    const { container: ContainerClass } = this.props;

    return (
      <>
        {ContainerClass ? (
          <ContainerClass>{this.renderContent()}</ContainerClass>
        ) : (
          this.renderContent()
        )}
        {this.renderModals()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export const ServiceCards = connect(
  null,
  mapDispatchToProps
)(ServiceCardsComponent);

class Services extends Component {
  render() {
    return (
      <Page history={this.props.history} className="services-page">
        <ServiceCards
          history={this.props.history}
          container={PageCenterContent}
          onSubmit={(services) =>
            redirect(this.props, "/services-confirm", { services })
          }
        />
      </Page>
    );
  }
}

export default connect()(Services);
