import React, { Component } from "react";
import "./Finances.scss";
import apiContext from "../../api";
import { redirect } from "../../components/Page/Page";

import Card from "../../components/Card/Card";
import Modal from "../../components/Modal/Modal";
import LabelSwitch from "../../components/LabelSwitch/LabelSwitch";

import InvoiceModal from "../../components/InvoiceModal/InvoiceModal";
import HomeTemplate from "../HomeTemplate/HomeTemplate";
import ConsumptionModal from "../../components/ConsumptionModal/ConsumptionModal";
import ClienteService from "../../services/cliente";
import CondominioService from "../../services/condominio";
import TicketFilterModal from "./TicketFilterModal";
import EmissionBlockedModal from "./EmissionBlockedModal";
import AcquittanceTabComponent from "./AcquittanceTabComponent";
import InvoiceImage from "../../assets/illustrations/invoice.png";
import InvoiceService from "../../services/invoice";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { moneyFormat } from "../../components/Masks/Masks";
import { loadingOperations } from "../../state/ducks/loading";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import EmptyDataInvoiceImage from "../../assets/illustrations/empty-data-invoice.png";
import Search from "../../components/Search/Search";
import TicketModal from "../../components/TicketModal/TicketModal";
import { IconSpinner } from "../../components/Icons/Icons";
import TicketService from "../../services/ticket";
import moment from "moment";
import { getMonthLabel } from "../../components/MonthlyConsumptionLineChart/MonthlyConsumptionLineChart";
import MonthlyTicketsLineChart, {
  reduceMonths,
  getTicketsChartData,
} from "../../components/MonthlyTicketsLineChart/MonthlyTicketsLineChart";

const LoadingLine = (props) => (
  <p className="text-center">
    <IconSpinner color="#000" style={{ animation: "1s infinite spin" }} />
  </p>
);

function mapDispatchToPropsAcquittance(dispatch) {
  return bindActionCreators(
    {
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

const AcquittanceTab = connect(
  null,
  mapDispatchToPropsAcquittance
)(AcquittanceTabComponent);

class Finances extends Component {
  
  static contextType = apiContext;
  
  constructor(props) {
    super(props);
    const {
      location: { state: locationState },
    } = this.props;

    let tab = "invoices";
    if (props.location && props.location.state && this.props.location.state.tab)
      tab = this.props.location.state.tab;

    this.state = {
      invoiceModalData: null,
      ticketModalData: null,
      showEmissionBlockedModal: false,
      showTicketFilterModal:((locationState || {}).selectedStatus || []).length === 0,
      showSearch: false,
      tab,
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
      ticketFilters: this.initialFilters,
    };
  }

  get initialFilters() {
    const {
      location: { state: locationState },
    } = this.props;
    return {
      selectedStatus: (locationState || {}).selectedStatus || [],
      selectedProduct: "todos",
      selectedUnits: [],
      selectedAddress: "todos",
      selectedAddresses: [],
      selectedInitialDate: "",
      selectedLastDate: "",
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
    };
  }

  async componentDidMount() {
    const { segmento } = ClienteService.localEntrega;

    if (segmento === "CONTASIM") {
      this.getDataForContaSim();
    }

    if (segmento === "INDUSTRIAL") {
      await this.getDataForContaSim();
      await this.getDataForIndustrial();
    }

  }

  handleNfJoin() {
    let { tickets, invoices } = this.state;

    const { "local-entrega": localEntrega } = CondominioService.selected || {};

    if(!!!invoices || !!!tickets) {
      this.setState({ tickets: tickets || [] });
    }

    const joined = tickets
    ?.filter((ticket) => {
      const local = String(ticket["local-entrega"]).replace(`ã`, `a`).replace('Ã', 'A').toLowerCase();
      let localDelivery = String(localEntrega).replace(`ã`, `a`).replace('Ã', 'A').toLowerCase()
      if (!!localEntrega) return local === localDelivery;
      return true;
    }).map((ticket) => {

      let invoice = {} ;
      if(Array.isArray(invoices)) {
        const invoicesFiltred = invoices.filter((invoice) => ticket["nr-nota-fis"] === invoice["cod_tit_acr"] && ticket["serie"] === invoice["cod_ser_docto"]);
        invoice = invoicesFiltred[0] || {};
      }
      return {...ticket, ...invoice, invoices };
    });

    this.setState({ tickets: joined || [] });

  }

  getDataForContaSim() {

    const { CPFCNPJ } = ClienteService.localEntrega || {};
    const { [`local-entrega`]: localEntrega } = CondominioService.selected || {};
    
    const dataFim = new Date();
    const dataIni = new Date();
    dataIni.setFullYear(dataFim.getFullYear() - 1);
    const { segmento } = ClienteService.localEntrega || {}

    return this.context.ConsigazApi.memConsultaSegundaViaBoleto(
      CPFCNPJ,
      this.context.ConsigazApi.formatDate(dataIni),
      this.context.ConsigazApi.formatDate(dataFim),
      localEntrega || ""
    ).then(({ dsRetorno }) => {
        if(JSON.stringify(dsRetorno) === "{}") return;

        if(!!!dsRetorno || !dsRetorno.hasOwnProperty('tt-cliente-retorno') || !Array.isArray(dsRetorno["tt-cliente-retorno"])) {
          this.setState({ invoices: [] });
        };
        
        const invoices = [...dsRetorno["tt-cliente-retorno"]].reverse();
        this.setState({ invoices }, () => {
          if (segmento === "INDUSTRIAl") this.handleNfJoin();
        });

      })
      .catch((errorDescription) =>
        this.props.showToast("Ops!", errorDescription)
      );
  }

  getDataForIndustrial() {
    const { CPFCNPJ } = ClienteService.localEntrega;

    const { [`local-entrega`]: localEntrega } =
      this.state.selectedCondominio || {};

    const dataFim = new Date();
    const dataIni = new Date();
    dataIni.setFullYear(dataFim.getFullYear() - 1);

    return this.context.ConsigazApi.memConsultaNotaFiscal(
      CPFCNPJ,
      this.context.ConsigazApi.formatDate(dataIni),
      this.context.ConsigazApi.formatDate(dataFim),
      localEntrega
    )
      .then(({ Notas }) => {

        if(!!!Notas || !Notas.hasOwnProperty('NotasFiscais') || !Array.isArray(Notas.NotasFiscais) ) {
          this.setState({ tickets: [] });
        }

        this.setState({
            tickets: Notas?.NotasFiscais,
            chartCenterIdx: Notas?.NotasFiscais?.length - 1,
            invoiceInFocus: Notas?.NotasFiscais?.[Notas?.NotasFiscais?.length - 1],
          },
          () => this.handleNfJoin()
        );
       
      })
      .catch((errorDescription) =>
        this.props.showToast("Ops!", errorDescription)
      );
  }

  getInvoiceStyle(invoiceData) {
    const status = InvoiceService.getInvoiceStatus(invoiceData);
    return {
      paid: "toplineSuccess",
      outdated: "toplineDanger",
      open: "toplineWarning",
    }[status];
  }

  static getTicketStyle(ticketData) {
    const status = TicketService.getTicketStatus(ticketData);
    return {
      paid: "toplineSuccess",
      outdated: "toplineDanger",
      open: "toplineWarning",
      cancel: "toplineCancel",
    }[status];
  }

  handleAcquittanceLetter = ({ type, year, company, cartaBase64 }) => {
    this.setState(
      {
        cartaBase64,
        typeAcquittance: type,
        yearAcquittance: year,
        companyAcquittance: company,
      },
      () => this.modalAcquittanceLetter && this.modalAcquittanceLetter.open()
    );
  };

  sendAcquittanceLetter = () => {
    const { typeAcquittance, yearAcquittance } = this.state;
    const { CPFCNPJ, email } = ClienteService.localEntrega || {};

    this.context.ConsigazApi.sendEmail(
      CPFCNPJ,
      "Consigaz",
      typeAcquittance === 'total' ? 9999 : yearAcquittance,
    ).then(
      () =>
        this.props.showToast(
          "Enviado!",
          `Carta de quitação enviada para ${email}`
        ),
      () => this.props.showToast("Ops!", `Erro ao enviar a carta de quitação`)
    );
  };

  renderInvoiceList() {
    const { invoices, tab } = this.state;

    let invoiceListFilter = () => true;
    if (tab === "open-invoices")
      invoiceListFilter = (inv) => inv["val_sdo_tit_acr"] !== 0;

    const invoicesFiltered = (invoices || []).filter(invoiceListFilter);

    if (!!invoicesFiltered && invoicesFiltered.length)
      return invoicesFiltered.map((invoice, idx) => (
        <Card
          key={`finances-card-${idx}`}
          className="finances-card"
          styleName={this.getInvoiceStyle(invoice)}
        >
          {(() => {
            const date = new Date(`${invoice["dat_transacao"]} 00:00:00`);
            return `${String(date.getMonth() + 1).padStart(
              2,
              0
            )}/${date.getFullYear()}`;
          })()}
          <p className="price">{moneyFormat(invoice["val_origin_tit_acr"])}</p>
          {InvoiceService.getInvoiceBadge(invoice)}
          <footer>
  
            { !InvoiceService.getTicketExpiration(invoice)  ? (
            <button
              onClick={() =>
                this.setState(
                  { invoiceModalData: invoice,  },
                  () => this.checkInvoiceBlockEmission(invoice)
                )
              }
              className="block link"
            >
              {InvoiceService.getInvoiceStatus(invoice) === "paid"
                ? "Visualizar nota fiscal"
                : "Visualizar boleto"}
                
            </button>) : '' }
            <button
              onClick={() =>
                this.setState(
                  { invoiceModalData: invoice },
                  () => this.modalDetalhes && this.modalDetalhes.open()
                )
              }
              className="block link secondary"
            >
              Ver detalhes de consumo
            </button>
          </footer>
        </Card>
      ));
    else if (invoices.length === 0)
      return (
        <div>
          <img
            style={{ marginTop: "40px" }}
            src={InvoiceImage}
            alt="Invoice Illustration"
            height={150}
          />
          <p>Nenhuma fatura à exibir</p>
        </div>
      );
  }

  handleFilters() {
    const { tickets, ticketFilters, selectedCondominio } = this.state;
    let ticketListFilter = () => true;

    const ticketsFiltered = (tickets || [])
      .filter(ticketListFilter)
      .filter((ticket) => {
        if (selectedCondominio !== "all")
          return (
            selectedCondominio["endereco"].indexOf(ticket["endereco"]) > -1
          );
        else return true;
      });

    const filterInitialDate =
      ticketFilters["selectedInitialDate"] &&
      moment(ticketFilters["selectedInitialDate"], "DD/MM/YYYY");
    const filterLastDate =
      ticketFilters["selectedLastDate"] &&
      moment(ticketFilters["selectedLastDate"], "DD/MM/YYYY");

    const filterList = ticketsFiltered
      .filter((ticket) => {
        if (ticketFilters["selectedStatus"].length !== 0)
          return (
            ticketFilters["selectedStatus"].indexOf(
              TicketService.getTicketStatus(ticket)
            ) > -1
          );
        else return ticket;
      })
      .filter((ticket) => {
        return (ticket.Itens || []).some((item) => {
          if (ticketFilters["selectedProduct"] === "granel")
            return item.un.toLowerCase() === "kg";
          else if (ticketFilters["selectedProduct"] === "todos") return ticket;
          else if (
            ticketFilters["selectedProduct"] === "cilindros" &&
            ticketFilters["selectedUnits"].length === 0
          )
            return item.un.toLowerCase() === "un";
          else if (ticketFilters["selectedUnits"].length > 0)
            return (
              ticketFilters["selectedUnits"].indexOf(
                item["it-codigo"].toLowerCase()
              ) > -1
            );
          else 
            return null
        });
      })
      .filter((ticket) => {
        if (ticketFilters["selectedAddress"] !== "todos")
          return (
            ticketFilters["selectedAddresses"].indexOf(ticket["endereco"]) > -1
          );
        else return ticket;
      })
      .filter((ticket) => {
        if (filterInitialDate !== "Data inválida") {
          const ticketDate = moment(ticket["dt-emis-nota"], "YYYY-MM-DD");
          return (
            !filterInitialDate || ticketDate.isSameOrAfter(filterInitialDate)
          );
        } else return ticket;
      })
      .filter((ticket) => {
        if (filterLastDate !== "Data inválida") {
          const ticketDate = moment(ticket["dt-emis-nota"], "YYYY-MM-DD");
          return !filterLastDate || ticketDate.isSameOrBefore(filterLastDate);
        } else return ticket;
      });

    return filterList;
  }

  goToHelp = () => {
    redirect(this.props, "/help");
  };

  checkBoletoBlockEmission(ticket) {
    if(ticket?.['bloqueia-emissao']) {
      this.setState({ ...this.state, showEmissionBlockedModal: true })
    } else {
      this.modalNotaFiscal && this.modalNotaFiscal.open()
    }
  }

  checkInvoiceBlockEmission(invoice) {
    if(invoice?.['bloqueia-emissao']) {
      this.setState({ ...this.state, showEmissionBlockedModal: true })
    } else {
      this.modalBoleto && this.modalBoleto.open()
    }
  }

  renderTicketList() {
    const {
      tickets,
      showSearch,
      ticketFilters,
      selectedCondominio,
      selectedNF,
    } = this.state;
    let ticketListFilter = () => true;

    const ticketsFiltered = (tickets || [])
      .filter(ticketListFilter)
      .filter((ticket) => {
        if (selectedCondominio !== "all")
          return (
            selectedCondominio["endereco"].indexOf(ticket["endereco"]) > -1
          );
        else return tickets;
      });

    if (showSearch === true) {
      const ticketFilterSearch = (tickets || []).filter(
        (ticket) => ticket["nr-nota-fis"].indexOf(selectedNF) > -1
      );
      return (
        <>
          <h3>Busque uma NF pelo número</h3>
          <Search
            className="block no-margin"
            value={selectedNF}
            onChange={(e) => this.setState({ selectedNF: e.target.value })}
          ></Search>
          {ticketFilterSearch && ticketFilterSearch.length ? (
            (ticketFilterSearch || []).map((ticket, idx) => (
              <Card
                key={`finances-card-${idx}`}
                className="finances-card"
                styleName={Finances.getTicketStyle(ticket)}
              >
                <h3 className="address">{ticket["endereco"]}</h3>
                <p>Número da nota fiscal: {ticket["nr-nota-fis"]}</p>
                {(() => {
                  const date = new Date(`${ticket["dt-emis-nota"]} 00:00:00`);
                  return `${String(date.getMonth() + 1).padStart(
                    2,
                    0
                  )}/${date.getFullYear()}`;
                })()}
                <p className="price">{moneyFormat(ticket["vl-tot-nota"])}</p>
                {TicketService.getTicketBadge(ticket)}
                <footer>

                  { !InvoiceService.getTicketExpiration(ticket)  ? (
                  <button
                    onClick={() =>
                      this.setState(
                        { invoiceModalData: ticket },
                        () => this.checkBoletoBlockEmission(ticket)
                      )
                    }
                    className="block link"
                  >
                    {TicketService.getTicketStatus(ticket) === "paid"
                      ? "Visualizar nota fiscal"
                      : "Visualizar boleto"}
                  </button>
                  ) : ''}
                  <button
                    onClick={() =>
                      this.setState(
                        { invoiceModalData: ticket },
                        () => this.modalDetalhes && this.modalDetalhes.open()
                      )
                    }
                    className="block link secondary"
                  >
                    Ver detalhes de consumo
                  </button>
                </footer>
              </Card>
            ))
          ) : (
            <div>
              <img
                style={{ marginTop: "40px" }}
                src={InvoiceImage}
                alt="Invoice Illustration"
                height={150}
              />
              <p>Nenhuma nota fiscal encontrada</p>
            </div>
          )}{" "}
        </>
      );
    } else if (ticketFilters != null) {
      const filterNF = this.handleFilters();
      return (
        <>
          {(filterNF || []).map((ticket, idx) => {
            return (
              <Card
                key={`finances-card-${idx}`}
                className="finances-card"
                styleName={Finances.getTicketStyle(ticket)}
              >
                <h3 className="address">{ticket["endereco"]}</h3>
                <p className="value" style={{ margin: 0 }}>
                  Número da nota fiscal
                </p>
                <p className="value">{ticket["nr-nota-fis"]}</p>
                {ticket["dt-emis-nota"] &&
                  moment(ticket["dt-emis-nota"], "YYYY-MM-DD").format(
                    "MM/YYYY"
                  )}
                <p className="price">{moneyFormat(ticket["vl-tot-nota"])}</p>
                {TicketService.getTicketBadge(ticket)}
                <footer>

                { !InvoiceService.getTicketExpiration(ticket)  ? (
                  <button
                    onClick={() =>
                      this.setState(
                        { ticketModalData: ticket },
                        () => this.checkBoletoBlockEmission(ticket)
                      )
                    }
                    className="block link"
                  >
                    {TicketService.getTicketStatus(ticket) === "paid"
                      ? "Visualizar nota fiscal"
                      : TicketService.getTicketStatus(ticket) === "outdated"
                      ? "Emitir segunda via de boleto"
                      : "Visualizar boleto"}
                  </button>
                ): ''}
                  <button
                    onClick={() =>
                      this.setState(
                        { ticketModalData: ticket },
                        () => this.modalDetalhes && this.modalDetalhes.open()
                      )
                    }
                    className="block link secondary"
                  >
                    Ver detalhes de consumo
                  </button>
                </footer>
              </Card>
            );
          })}

          {filterNF && filterNF.length === 0 && (
            <div>
              <img
                style={{ marginTop: "40px" }}
                src={InvoiceImage}
                alt="Invoice Illustration"
                height={150}
              />
              <p>Nenhuma nota fiscal à exibir</p>
            </div>
          )}
        </>
      );
    } else
      return (
        <>
          {ticketsFiltered.length > 0 ? (
            ticketsFiltered.map((ticket, idx) => (
              <Card
                key={`finances-card-${idx}`}
                className="finances-card"
                styleName={Finances.getTicketStyle(ticket)}
              >
                <h3 className="address">{ticket["endereco"]}</h3>
                {(() => {
                  const date = new Date(`${ticket["dt-emis-nota"]} 00:00:00`);
                  return `${String(date.getMonth() + 1).padStart(
                    2,
                    0
                  )}/${date.getFullYear()}`;
                })()}
                <p className="price">{moneyFormat(ticket["vl-tot-nota"])}</p>
                {TicketService.getTicketBadge(ticket)}
                <footer>
                { !InvoiceService.getTicketExpiration(ticket)  ? (
                  <button
                    onClick={() =>
                      this.setState(
                        { ticketModalData: ticket },
                        () => this.checkBoletoBlockEmission(ticket)
                      )
                    }
                    className="block link"
                  >
                    {TicketService.getTicketStatus(ticket) === "paid"
                      ? "Visualizar nota fiscal"
                      : TicketService.getTicketStatus(ticket) === "outdated"
                      ? "Emitir segunda via de boleto"
                      : "Visualizar boleto"}
                  </button>
                ) : ''}
                  <button
                    onClick={() =>
                      this.setState(
                        { ticketModalData: ticket },
                        () => this.modalDetalhes && this.modalDetalhes.open()
                      )
                    }
                    className="block link secondary"
                  >
                    Ver detalhes de consumo
                  </button>
                </footer>
              </Card>
            ))
          ) : (
            <div>
              <img
                src={EmptyDataInvoiceImage}
                alt="Empty data placeholder"
                height={100}
              />
              <p>Aqui você visualizará as suas notas fiscais</p>
            </div>
          )}{" "}
        </>
      );
  }

  getChartData(shouldSlice = true) {
    const { tickets, ticketFilters } = this.state;
    const { endereco } = CondominioService.selected || {};

    return getTicketsChartData(
      endereco
        ? tickets.filter((t) => endereco.indexOf(t.endereco) > -1)
        : tickets,
      this.chartCenterIdx,
      ticketFilters,
      shouldSlice,
      "valor"
    );
  }

  get chartCenterIdx() {
    const { chartData } = this.state;
    let { chartCenterIdx } = this.state;

    if (chartData && chartCenterIdx > chartData.length)
      chartCenterIdx = parseInt(chartData.length / 2);

    return chartCenterIdx;
  }

  getChartCenterItem(chartData) {
    const chartCenterIdx = this.chartCenterIdx;

    return chartData && chartData.length && chartData[chartCenterIdx];
  }

  renderChartWithTicketData() {
    const { tickets, ticketFilters } = this.state;

    const chartData = this.getChartData();

    const monthReducedTickets = reduceMonths(tickets);

    return (
      <>
        <div className="chart">
          {chartData.length ? (
            <>
              <p className="title">
                {Object.keys(monthReducedTickets).length === 1
                  ? "Pagamento do último mês"
                  : `Pagamentos últimos ${
                      Object.keys(monthReducedTickets).length
                    } meses`}
              </p>
              <button
                className="rectangle"
                style={{ position: "absolute", right: "1rem", top: "1rem" }}
                onClick={() => {
                  this.setState({ showTicketFilterModal: true });
                }}
              >
                Alterar filtros
              </button>
              <div className="chart-container">
                <MonthlyTicketsLineChart
                  data={chartData}
                  filters={ticketFilters}
                  chartReference={this.state.chartReference}
                  valueBase="valor"
                />
              </div>
              <NavigationBar
                tabs={chartData.map((month) => ({
                  value: month,
                  label: getMonthLabel(month),
                  active: month.selected,
                  onClick: () =>
                    this.setState({
                      chartCenterIdx: month.itemIdx,
                      invoiceInFocus: month,
                    }),
                }))}
                defineActive={true}
              />
            </>
          ) : (
            <p className="text-center">
              <img
                src={EmptyDataInvoiceImage}
                alt="Empty chart placeholder"
                height={100}
              />
              <br />
              Aqui você visualizará o gráfico com os seus pagamentos
            </p>
          )}
        </div>
      </>
    );
  }

  openDocument = () => {
    const base64String = this.state.cartaBase64;

    const binaryString = atob(base64String);

    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([bytes.buffer], { type: 'application/pdf' });

    const url = URL.createObjectURL(blob);
    window.open(url);
  }

  renderAcquittanceLetter() {
    const { yearAcquittance, companyAcquittance, typeAcquittance } = this.state;
    return (
      <>
        <AcquittanceTab
          onSubmit={this.handleAcquittanceLetter}
          onOpenInvoices={() =>
            this.modalAcquittanceLetterOps &&
            this.modalAcquittanceLetterOps.open()
          }
        />
        <Modal
          shown={false}
          ref={(ref) => (this.modalAcquittanceLetterOps = ref)}
          closeButton={true}
          className="carta-quitacao-ops-modal"
          title="Ops!"
        >
          Sua carta ainda não pode ser emitida. Existem débitos em aberto no
          período selecionado. Regularize sua situação!
          <footer className="text-center">
            <button
              className="block"
              onClick={() =>
                this.setState(
                  { tab: "open-invoices" },
                  () =>
                    this.modalAcquittanceLetterOps &&
                    this.modalAcquittanceLetterOps.close()
                )
              }
            >
              Regularizar débitos
            </button>
          </footer>
        </Modal>
        <Modal
          shown={false}
          ref={(ref) => (this.modalAcquittanceLetter = ref)}
          closeButton={true}
          className="carta-quitacao-modal"
          title={
            typeAcquittance === "anual"
              ? `Carta Anual ${yearAcquittance}`
              : `Carta Total`
          }
        >
          <img
            src={InvoiceImage}
            alt="Invoice Illustration"
            height={150}
            onClick={() => this.openDocument()}
          />

        <div className="text-center">
          <button 
            className="link secondary"
            onClick={() => this.openDocument()}
          >
            Clique na imagem para visualizar
          </button>
        </div>

          <footer className="text-center">
            <button
              onClick={this.sendAcquittanceLetter}
              className="link secondary"
            >
              Compartilhar por email
            </button>
          </footer>
        </Modal>
      </>
    );
  }

  renderButton() {
    const { showSearch, tickets, selectedCondominio } = this.state;
    let ticketListFilter = () => true;

    const ticketsFiltered = (tickets || [])
      .filter(ticketListFilter)
      .filter((ticket) => {
        if (selectedCondominio !== "all")
          return (
            selectedCondominio["endereco"].indexOf(ticket["endereco"]) > -1
          );
        else return tickets;
      });

    return (
      <>
        {ticketsFiltered.length ? (
          <>
            <h1>Encontre notas fiscais</h1>
            {showSearch === false ? (
              <button
                className="rectangle"
                style={{ marginRight: "5px" }}
                onClick={() => {
                  this.setState({ showTicketFilterModal: true });
                }}
              >
                Alterar filtros
              </button>
            ) : (
              ""
            )}
            <button
              className="rectangle"
              onClick={() => {
                if (showSearch === false) this.setState({ showSearch: true });
                else this.setState({ showSearch: false });
              }}
            >
              {showSearch === true
                ? "Visualizar todas notas"
                : "Buscar NF pelo número"}
            </button>
            <br />
          </>
        ) : (
          ""
        )}
      </>
    );
  }

  render() {
    const {
      invoiceModalData,
      ticketModalData,
      tab,
      invoices,
      tickets,
      showTicketFilterModal,
      showEmissionBlockedModal,
      showSearch,
    } = this.state;
    const { segmento } = ClienteService.localEntrega || {};
    const {
      location: { state: locationState },
    } = this.props;

    if (segmento === "CONTASIM") {
      return (
        <HomeTemplate
          ref={(ref) => (this.template = ref)}
          history={this.props.history}
          activeTab="Financeiro"
          className="finances-page"
        >
          <LabelSwitch
            className="invoice-type-switch"
            options={[
              {
                value: "invoices",
                label: "Todas",
                onClick: (e, tab) => this.setState({ tab }),
              },
              {
                value: "open-invoices",
                label: "Em aberto",
                onClick: (e, tab) => this.setState({ tab }),
              },
              {
                value: "acquittance-letter",
                label: "Carta de quitação",
                onClick: (e, tab) => this.setState({ tab }),
              },
            ]}
            selected={tab}
          />
          <br />
          {(tab === "invoices" || tab === "open-invoices") && (
            <>
              {(invoices && this.renderInvoiceList()) || <LoadingLine />}
              <br />
            </>
          )}
          {tab === "acquittance-letter" && this.renderAcquittanceLetter()}
          <InvoiceModal
            ref={(ref) => (this.modalBoleto = ref)}
            template={this.template}
            invoiceData={invoiceModalData}
            onCopy={() => this.modalBoleto && this.modalBoleto.close()}
          />
          <ConsumptionModal
            ref={(ref) => (this.modalDetalhes = ref)}
            history={this.props.history}
            invoiceData={invoiceModalData}
            onShowInvoice={() => {
              this.modalDetalhes && this.modalDetalhes.close();
              this.modalBoleto && this.modalBoleto.open();
            }}
          />
        </HomeTemplate>
      );
    } else {
      return (
        <HomeTemplate
          ref={(ref) => (this.template = ref)}
          history={this.props.history}
          activeTab="Financeiro"
          className="finances-page"
        >
          <LabelSwitch
            className="invoice-type-switch"
            options={[
              {
                value: "invoices",
                label: "Notas Fiscais",
                onClick: (e, tab) => this.setState({ tab }),
              },
              {
                value: "graphics",
                label: "Grafico de Pagamentos",
                onClick: (e, tab) => this.setState({ tab, showSearch: false }),
              },
              {
                value: "acquittance-letter",
                label: "Carta de quitação",
                onClick: (e, tab) => this.setState({ tab }),
              },
            ]}
            selected={tab}
          />
          <br />
          {tab === "acquittance-letter" && this.renderAcquittanceLetter()}
          {(tab === "invoices" || tab === "open-invoices") && (
            <>
              {tickets ? (
                <>
                  <h1>Encontre notas fiscais</h1>
                  {showSearch === false ? (
                    <button
                      className="rectangle"
                      style={{ marginRight: "5px" }}
                      onClick={() => {
                        this.setState({ showTicketFilterModal: true });
                      }}
                    >
                      Alterar filtros
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    className="rectangle"
                    onClick={() => {
                      if (showSearch === false)
                        this.setState({ showSearch: true });
                      else this.setState({ showSearch: false });
                    }}
                  >
                    {showSearch === true
                      ? "Visualizar todas notas"
                      : "Buscar NF pelo número"}
                  </button>
                  <br />
                  {this.renderTicketList()}
                </>
              ) : (
                <LoadingLine />
              )}
            </>
          )}
          {tab === "graphics" && (
            <>
              {(tickets && this.renderChartWithTicketData()) || <LoadingLine />}
              <br />
            </>
          )}
          <TicketModal
            ref={(ref) => (this.modalNotaFiscal = ref)}
            template={this.template}
            ticketData={ticketModalData}
            onCopy={() => this.modalNotaFiscal && this.modalNotaFiscal.close()}
          />
          <ConsumptionModal
            ref={(ref) => (this.modalDetalhes = ref)}
            history={this.props.history}
            ticketData={ticketModalData}
            onShowInvoice={() => {
              this.modalDetalhes && this.modalDetalhes.close();
              this.modalNotaFiscal && this.modalNotaFiscal.open();
            }}
          />
           <EmissionBlockedModal
            ref={(ref) => (this.emissionBlockedModalRef = ref)}
            shown={tab !== "acquittance-letter" && showEmissionBlockedModal}
            goToHelp={this.goToHelp}
            onDismiss={() => this.setState({ showEmissionBlockedModal: false })}
          />
          <TicketFilterModal
            ref={(ref) => (this.ticketFilterModalRef = ref)}
            selectedStatus={(locationState || {}).selectedStatus || []}
            shown={tab !== "acquittance-letter" && showTicketFilterModal}
            statusData={Object.values(reduceMonths(tickets || [])).reduce(
              (reducedStatus, month) => {
                (month || []).forEach((ticket) => {
                  (ticket.Duplicatas || []).forEach((duplicata) => {
                    const key = duplicata["nr-nota-fis"];
                    reducedStatus[key] = duplicata["situacao"];
                  });
                });
                return reducedStatus;
              },
              {}
            )}
            productsData={Object.values(reduceMonths(tickets || [])).reduce(
              (reduced, month) => {
                (month || []).forEach((ticket) => {
                  (ticket.Itens || []).forEach((item) => {
                    const key = item["it-codigo"].toLowerCase();
                    reduced[key] = item;
                  });
                });
                return reduced;
              },
              {}
            )}
            addressData={Object.values(reduceMonths(tickets || [])).reduce(
              (reduced, month) => {
                (month || []).forEach((ticket) => {
                  const key = `${ticket.endereco}, ${ticket.cidade}, ${ticket.estado}`;
                  if (reduced.indexOf(key) === -1) reduced.push(key);
                });
                return reduced;
              },
              []
            )}
            onSubmit={(filters) => {
              if (
                this.chartFilterModalRef &&
                this.chartFilterModalRef.showProductsFilter &&
                filters.selectedProduct !== "granel" &&
                filters.selectedUnits.length <= 0
              ) {
                this.props.showToast(
                  "Ops",
                  "Para prosseguir, selecione o(s) tipo(s) de produto(s) desejado(s)"
                );
              } else {
                this.setState({
                  showTicketFilterModal: false,
                  ticketFilters: filters,
                });
              }
            }}
            onDismiss={() => this.setState({ showTicketFilterModal: false })}
          />
        </HomeTemplate>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Finances);
