import React, { Component } from "react";

import "./HomeDelivery.scss";

import apiContext from "../../api";
import { ServiceCards } from "../Services/Services";
import { DeliveryConfirmContent } from "../DeliveryConfirm/DeliveryConfirm";
import HomeTemplate from "../HomeTemplate/HomeTemplate";

import CondominioService from "../../services/condominio";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../state/ducks/loading";
import Card from "../../components/Card/Card";
import moment from "moment";
import Modal from "../../components/Modal/Modal";
import ServiceItem from "../../components/ServiceItem/ServiceItem";

class HomeServices extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    const { location } = this.props;

    this.state = {
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
      tab: "protocol",
      isVacation: false,
      selectedService:
        location.state && location.state.selectedService
          ? location.state.selectedService
          : null,
    };
  }

  render() {
    const {
      submitedServices,
      selectedCondominio,
      serviceProtocol,
      selectedService,
    } = this.state;

    return (
      <HomeTemplate
        history={this.props.history}
        activeTab="HomeDelivery"
        className="home-services-page"
      >
        <h1>Gerencie suas entregas</h1>
        <p>
          Tenha maior controle sobre o seu fornecimento e diminua o risco da
          falta de gás.
        </p>
        <div
          style={{
            overflowY: "hidden",
            overflowX: "auto",
            padding: "1rem 0",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {selectedCondominio !== "all" &&
              (selectedCondominio.Itens || []).map((item) => (
                <>
                  {item["dt-ini-ferias"] !== null ? (
                    <Card
                      title="Férias Coletivas"
                      styleName="primary"
                      style={{
                        minWidth: "200px",
                        margin: "0 .5rem",
                      }}
                    >
                      <p
                        className="no-margin"
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          Produto: {item["desc-comercial"]}
                        </div>
                        <div>
                          Período:{" "}
                          {`${moment(
                            item["dt-ini-ferias"],
                            "YYYY-MM-DD"
                          ).format("DD/MM/YYYY")} - ${moment(
                            item["dt-fim-ferias"],
                            "YYYY-MM-DD"
                          ).format("DD/MM/YYYY")}`}
                        </div>
                      </p>
                      <p className="no-margin text-center">
                        <button
                          className="link white"
                          style={{ marginBottom: 0, marginTop: 10 }}
                          onClick={() => {
                            this.editService && this.editService("19;;", item);
                          }}
                        >
                          Alterar
                        </button>
                      </p>
                    </Card>
                  ) : (
                    ""
                  )}
                </>
              ))}
          </div>
        </div>
        <hr />
        {submitedServices ? (
          <>
            <br />
            <DeliveryConfirmContent
              history={this.props.history}
              location={{
                state: { services: submitedServices },
              }}
              showIllustration={false}
              onFinish={(services) =>
                this.setState(
                  {
                    submitedServices: null,
                    serviceProtocol: services,
                  },
                  () => {
                    this.modalProtocol && this.modalProtocol.open();
                  }
                )
              }
            />
          </>
        ) : (
          <ServiceCards
            editService={(editService) => {
              this.editService = editService;
            }}
            history={this.props.history}
            selectedService={selectedService}
            cardClass={"delivery"}
            confirmationUrl={"/home-services-confirm"}
            onSubmit={(submitedServices) =>
              this.setState({ submitedServices, selectedService: null })
            }
          />
        )}

        <Modal
          shown={false}
          ref={(ref) => (this.modalProtocol = ref)}
          title="Solicitação feita!"
          confirmButton={true}
          confirmButtonText={"Ok"}
        >
          <p>Anote o protocolo:</p>
          {serviceProtocol &&
            serviceProtocol.map((service) => (
              <ServiceItem key={`service-${service.id}`} title={service.title}>
                <>
                  {service.dtprev &&
                    `Serviço previsto para até o dia ${service.dtprev}`}
                  <br />
                  Protocolo:
                  <span
                    style={{
                      float: "right",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {service.protocol || "Não encontrado"}
                  </span>
                </>
              </ServiceItem>
            ))}
        </Modal>
      </HomeTemplate>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(HomeServices);
