import React from "react";
import apiContext from "../../../api";
import { Component } from "react";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../../state/ducks/loading";
import "./ServiceDetail.scss";
import ClienteService from "../../../services/cliente";
import SelectAddressList from "../SelectAddressList";
import CondominioService from "../../../services/condominio";

class ChangePayment extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      complementaryData: this.initialComplementaryData,
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
      haveSubmited: false,
    };
  }

  get initialComplementaryData() {
    return {
      selectedDays: "",
    };
  }

  submit = () => {
    
  };

  generateOrder = () => {
    const {
      complementaryData,
      selectedCondominio,
      selectedAddress,
    } = this.state;
    const { onToggle } = this.props;

    if (selectedCondominio === "all") {
      if (
        CondominioService.selectMany &&
        CondominioService.selectMany.length > 0
      ) {
        CondominioService.selectMany &&
          CondominioService.selectMany.map((teste) =>
            selectedAddress.push(teste)
          );
        CondominioService.selectMany = selectedAddress;
      } else CondominioService.selectMany = selectedAddress;
    }
    this.setState({ haveSubmited: false });

    this.priorityModal && this.priorityModal.close();
    onToggle &&
      onToggle({
        informacoes: `Desejo alterar o prazo de pagamento em ${complementaryData.selectedDays}. Detalhes da solicitação: ${complementaryData.informacoes}`,
      });
  };

  render() {
    const { complementaryData, selectedCondominio, haveSubmited } = this.state;
    const { checked, rawData } = this.props;
    const { [`prazo-pagto`]: prazopgto } = ClienteService.localEntrega;

    return (
      <>
        <h2 className="service-detail-title">{rawData.title}</h2>

        {selectedCondominio === "all" && haveSubmited === true && (
          <>
            <SelectAddressList
              onSubmit={(address) =>
                this.setState(
                  {
                    selectedAddress: address,
                  },
                  () => this.generateOrder()
                )
              }
              cardClass={"service"}
              service={rawData}
            />
          </>
        )}

        {haveSubmited === false && (
          <>
            <div className="pgto-prazo">
              <p>Prazo atual:</p>
              <p> {prazopgto}</p>
            </div>

            <p style={{ marginTop: "3%" }}>Insira o prazo desejado:</p>
            <input
              type="text"
              className="changeData"
              placeholder="X dias"
              style={{ width: "40%" }}
              value={this.state.selectedDays}
              onChange={(e) =>
                this.setState({
                  complementaryData: {
                    ...complementaryData,
                    selectedDays: e.target.value,
                  },
                })
              }
            />
            <br />
            <br />
            <p>
              <b>
                Por favor, insira no campo abaixo o motivo da sua solicitação
              </b>
              <br />
              Coloque o maior número de informações possíveis. Assim,
              garantiremos maior agilidade em seu atendimento!
            </p>
            <>
              <textarea
                style={{ width: "100%" }}
                value={complementaryData.informacoes || ""}
                onChange={(e) =>
                  this.setState({
                    complementaryData: {
                      ...complementaryData,
                      informacoes: e.target.value,
                    },
                  })
                }
              />
            </>

            <footer className="service-detail-footer">
              <button
                className="block"
                onClick={() => {
                  selectedCondominio === "all" ? this.submit() : this.generateOrder();
                }}
                disabled={complementaryData["selectedDays"].length === 0}
              >
                {!checked ? "Selecionar" : "Desselecionar"}
              </button>
            </footer>
          </>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(ChangePayment);
