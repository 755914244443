import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import LineChart from "../LineChart/LineChart";
import { weightFormat, moneyFormat } from "../Masks/Masks";
import { sliceData } from "../MonthlyConsumptionLineChart/MonthlyConsumptionLineChart";
import TicketService from "../../services/ticket";

export const reduceMonths = (tickets) =>
  tickets.reduce((reduced, ticket) => {
    const key = moment(ticket["dt-emis-nota"], "YYYY-MM-DD").format("YYYYMM");

    if (Object.keys(reduced).indexOf(key) === -1) reduced[key] = [];
    reduced[key].push(ticket);
    return reduced;
  }, {});

export const getMonthTicketValue = (
  monthTicket,
  filters,
  valueBase = "unidade"
) => {
  const getDuplicataValueOnIndex = (idx) =>
    ((monthTicket.Duplicatas || [])[idx] || {}).valor || 0;

  return (
    (TicketService.getTicketStatus(monthTicket) !== "cancel" &&
      monthTicket.Itens) ||
    []
  ).reduce((total, item, idx) => {
    if (filters && filters.selectedProduct === "cilindros") {
      if (
        !filters ||
        !filters.selectedUnits ||
        filters.selectedUnits.length === 0
      )
        if (valueBase === "valor") return total + getDuplicataValueOnIndex(idx);
        else return total + item["qt-faturada"];
      else if (
        filters.selectedUnits.indexOf(item["it-codigo"].toLowerCase()) > -1
      )
        if (valueBase === "valor") return total + getDuplicataValueOnIndex(idx);
        else return total + item["qt-faturada"];
    } else if (filters && filters.selectedProduct === "granel") {
      if (item["un"].toLowerCase() === "kg")
        if (valueBase === "valor") return total + getDuplicataValueOnIndex(idx);
        else return total + item["qt-faturada"];
    } else {
      if (valueBase === "valor") return total + getDuplicataValueOnIndex(idx);
      else return total + item["qt-faturada"];
    }
    return total;
  }, 0);
};

export const getFilteredValue = (
  monthTickets,
  filters,
  valueBase = "unidade"
) =>
  (monthTickets || []).reduce((reducedMonthTickets, monthTicket) => {
    return (
      reducedMonthTickets + getMonthTicketValue(monthTicket, filters, valueBase)
    );
  }, 0);

export const getTicketsChartData = (
  tickets,
  centerIdx,
  filters,
  shouldSlice = true,
  valueBase = "unidade"
) => {
  const reducedMonths = reduceMonths(tickets || []);

  const mapedData = Object.entries(reducedMonths || [])
    .map(([monthKey, monthTickets], itemIdx) => {
      const monthNumber = monthKey.substr(4)
      if (filters) {
        if (
          filters.selectedInterval === "comparativo" &&
          filters.selectedMonths.indexOf(parseInt(monthNumber)) === -1
        )
          return null;

        if (filters.selectedAddress === "especificos")
          monthTickets = (monthTickets || []).filter((m) => {
            if ((filters.selectedDeliveryPlaces || []).length) {
              return (
                filters.selectedDeliveryPlaces.indexOf(
                  m["local-entrega"]
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toUpperCase()
                ) > -1
              );
            } else {
              return (
                filters.selectedAddresses.indexOf(
                  `${m.endereco}, ${m.cidade}, ${m.estado}`
                ) > -1
              );
            }
          });

        if (filters.selectedInitialDate) {
          const initialDate = moment(filters.selectedInitialDate, "DD/MM/YYYY");

          monthTickets = (monthTickets || []).filter((ticket) => {
            const ticketDate = moment(ticket["dt-emis-nota"], "YYYY-MM-DD");
            return ticketDate.isSameOrAfter(initialDate);
          });

          if (monthTickets.length === 0) return null;
        }

        if (filters.selectedLastDate) {
          const lastDate = moment(filters.selectedLastDate, "DD/MM/YYYY");

          monthTickets = (monthTickets || []).filter((ticket) => {
            const ticketDate = moment(ticket["dt-emis-nota"], "YYYY-MM-DD");
            return ticketDate.isSameOrBefore(lastDate);
          });

          if (monthTickets.length === 0) return null;
        }
      }

      if (monthTickets.length === 0) return null;

      const value = getFilteredValue(monthTickets, filters, valueBase);


      return {
        itemIdx,
        value,
        monthNumber: +monthNumber,
        selected: false,
      };
    })
    .filter((e) => e)
    .map((m, itemIdx) => ({
      ...m,
      itemIdx,
      selected: itemIdx === centerIdx,
    }));

  if (shouldSlice) return sliceData(mapedData, centerIdx);
  else return mapedData;
};

export default class MonthlyTicketsLineChart extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        monthNumber: PropTypes.number,
        value: PropTypes.number,
        period: PropTypes.shape({
          start: PropTypes.string,
          end: PropTypes.string,
        }),
      })
    ),
  };

  getPointLabel = (point, valueBase = "unidade") => {
    const { filters } = this.props;

    if (valueBase === "valor") return moneyFormat(point.y);
    else if (!filters || filters.selectedProduct === "granel")
      return weightFormat(point.y);
    else return `${(point.y || 0).toFixed(0)} un.`;
  };

  render() {
    const { data, valueBase } = this.props;

    const chartData = [
      {
        id: "Valor",
        data: data.map((month, idx) => ({
          x: `${idx}`,
          y: `${month.value || 0}`,
        })),
      },
    ];

    return (
      <LineChart
        data={chartData}
        pointColor="#1e61a2"
        pointBorderColor="#fff"
        enablePointLabel={true}
        pointLabel={(point) => this.getPointLabel(point, valueBase)}
        // onClick={(node) => {
        //   if (this.props.onClick) {
        //     // const monthData = this.props.monthsData[node.index]
        //     // this.props.onClick(monthData)
        //   }
        // }}
      />
    );
  }
}
