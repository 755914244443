import CondominioService from "./condominio";

const storage = localStorage;
export const setItem = (key, value) => {
  if (value === undefined) {
    storage.removeItem(key);
  } else {
    storage.setItem(key, JSON.stringify(value));
  }
};
export const getItem = (key) => {
  const value = storage.getItem(key);

  if (value && value !== "undefined") {
    try {
      return JSON.parse(value);
    } catch (error) {
      console.warn(`Storage key ${key} cannot be parsed`);
    }
  }

  return undefined;
};

export default class ClienteService {
  static get localEntrega() {
    return getItem("cliente_local_entrega");
  }

  static set localEntrega(value) {
    if (value && !CondominioService.selectAll && !CondominioService.selected) {
      CondominioService.selected = value.ClienteEntrRetorno[0];
    }

    const previousValue = ClienteService.localEntrega;
    if (previousValue && previousValue.isAdmLogin && value)
      value.isAdmLogin = previousValue.isAdmLogin;

    return setItem("cliente_local_entrega", value);
  }

  static consultaLocalEntregaApiParser = (value) => {
    if (Object.keys(value).indexOf("Cliente") === -1) return value;

    const {
      Cliente: { ClienteRetorno },
    } = value;

    ClienteService.localEntrega = ClienteRetorno[0];

    return value;
  };

  // ----------------------------------

  static haveJoinedTheApp(cpfcnpj) {
    return getItem(`cliente_${cpfcnpj}_have_joined_the_app`);
  }

  static setHaveJoinedTheApp(cpfcnpj, value = true) {
    return setItem(`cliente_${cpfcnpj}_have_joined_the_app`, value);
  }

  // ----------------------------------

  static haveClosedPersonalDataModal(cpfcnpj) {
    return getItem(`cliente_${cpfcnpj}_have_closed_personal_data_Modal`);
  }

  static setHaveClosedPersonalDataModal(cpfcnpj, value = true) {
    return setItem(`cliente_${cpfcnpj}_have_closed_personal_data_Modal`, value);
  }

  // ----------------------------------

  static get newClienteData() {
    return getItem("cliente_new_cliente_data");
  }

  static set newClienteData(value) {
    return setItem("cliente_new_cliente_data", value);
  }

  // ----------------------------------

  static get industrialContato() {
    return getItem("cliente_industrial_contato_data");
  }

  static set industrialContato(value) {
    localStorage.removeItem("cliente_industrial_contato_data");
    return setItem("cliente_industrial_contato_data", value);
  }

  // ----------------------------------

  static get isLoggedIn() {
    return ClienteService.localEntrega !== undefined;
  }

  static async logout() {
    ClienteService.localEntrega = undefined;
    ClienteService.newClienteData = undefined;
    CondominioService.selected = undefined;
  }
}
