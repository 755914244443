import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import ApiContext, { defaultContext as defaultApiContext } from "../../api";

import WellcomePage from "../../pages/Wellcome/Wellcome";
import WellcomeAgainPage from "../../pages/WellcomeAgain/WellcomeAgain";
import NewClientPage from "../../pages/NewClient/NewClient";
import NewIndustrialClientPage from "../../pages/NewIndustrialClient/NewIndustrialClient";
import ExistentClientPage from "../../pages/ExistentClient/ExistentClient";
import AddressSearchPage from "../../pages/AddressSearch/AddressSearch";
import AddressComplementPage from "../../pages/AddressComplement/AddressComplement";
import AddressActivePage from "../../pages/AddressActive/AddressActive";
import AddressDonePage from "../../pages/AddressDone/AddressDone";
import AddressListPage from "../../pages/AddressList/AddressList";
import CancellationPage from "../../pages/Cancellation/Cancellation";
import ContestationPage from "../../pages/Contestation/Contestation";
import SaveTimePage from "../../pages/SaveTime/SaveTime";
import ServicesPage from "../../pages/Services/Services";
import ServicesConfirmPage from "../../pages/ServicesConfirm/ServicesConfirm";
import HomeServicesConfirmPage from "../../pages/HomeServicesConfirm/HomeServicesConfirm";
import PrivacyPolicyPage from "../../pages/PrivacyPolicy/PrivacyPolicy";
import WarrantyTermsPage from "../../pages/WarrantyTerms/WarrantyTerms";
import HomePage from "../../pages/Home/Home";
import ConsumptionPage from "../../pages/Consumption/Consumption";
import FinancesPage from "../../pages/Finances/Finances";
import HomeServicesPage from "../../pages/HomeServices/HomeServices";
import HomeDeliveryPage from "../../pages/HomeDelivery/HomeDelivery";
import PersonalDataPage from "../../pages/PersonalData/PersonalData";
import HelpPage from "../../pages/Help/Help";
import InvoicePdfPage from "../../pages/InvoicePdf/InvoicePdf";
import AcquittanceLetterPdfPage from "../../pages/AcquittanceLetterPdf/AcquittanceLetterPdf";
import DanfePdfPage from "../../pages/DanfePdf/DanfePdf";
import AdmnistrativeLoginPage from "../../pages/AdmnistrativeLogin/AdmnistrativeLogin";
import FakeLoginPage from "../../pages/FakeLogin/FakeLogin";
import ClienteService from "../../services/cliente";
import AddressSelect from "../../pages/AddressSelect/AddressSelect";
import IndustrialContact from "../../pages/IndustrialContact/IndustrialContact";
import ClientRecommendation from "../../pages/Recommendation/Recommendation";
import ReligueProtocol from "../../pages/ReligueProtocol/ReligueProtocol";
import ReligueQuestions from "../../pages/ReligueQuestions/ReligueQuestions";

const pageRenderMiddleware = (RouteComponent) => (props) => {
  const localEntrega = ClienteService.localEntrega;

  if (localEntrega) {
    const { segmento, email } = localEntrega;

    if (segmento === "CONTASIM" && !email) {
      return <Redirect to="/personal-data" />;
    }
  }

  return <RouteComponent {...props} />;
};

export default class App extends Component {
  render() {
    return (
      <ApiContext.Provider value={defaultApiContext}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect from="/" to="/wellcome" />
            </Route>
            {[
              ["/wellcome", WellcomePage],
              ["/wellcome-again", WellcomeAgainPage],
              ["/new-client", NewClientPage],
              ["/new-industrial-client", NewIndustrialClientPage],
              ["/existent-client", ExistentClientPage],
              ["/address-search", AddressSearchPage],
              ["/address-complement", AddressComplementPage],
              ["/address-active", AddressActivePage],
              ["/address-done", AddressDonePage],
              ["/address-select", AddressSelect],
              ["/religue-questions", ReligueQuestions],
              ["/admnistrative-login", AdmnistrativeLoginPage],
              ["/fake-login", FakeLoginPage],
              ["/address-list", AddressListPage],
              ["/cancellation", CancellationPage],
              ["/contestation", ContestationPage],
              ["/save-time", SaveTimePage],
              ["/services", ServicesPage],
              ["/services-confirm", ServicesConfirmPage],
              ["/home-services-confirm", HomeServicesConfirmPage],
              ["/privacy-policy", PrivacyPolicyPage],
              ["/warranty-terms", WarrantyTermsPage],
              ["/home", pageRenderMiddleware(HomePage)],
              ["/consumption", pageRenderMiddleware(ConsumptionPage)],
              ["/finances", pageRenderMiddleware(FinancesPage)],
              ["/home-services", HomeServicesPage],
              ["/home-delivery", HomeDeliveryPage],
              ["/personal-data", PersonalDataPage],
              ["/help", HelpPage],
              ["/invoice-pdf", InvoicePdfPage],
              ["/consigaz/invoice-pdf", InvoicePdfPage],
              ["/acquittance-letter-pdf", AcquittanceLetterPdfPage],
              ["/danfe-pdf", DanfePdfPage],
              ["/consigaz/danfe-pdf", DanfePdfPage],
              ["/industrial-contact", IndustrialContact],
              ["/recommendation", ClientRecommendation],
              ["/religue-protocol", ReligueProtocol],
            ].map(([path, component]) => (
              <Route key={`route-${path}`} path={path} component={component} />
            ))}
          </Switch>
        </Router>
      </ApiContext.Provider>
    );
  }
}
