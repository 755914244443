import apiContext from '../../api'

import PdfPage from '../../components/PdfPage/PdfPage'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toastOperations } from '../../state/ducks/toast'

class AcquittanceLetterPdfPage extends PdfPage {

  static contextType = apiContext

  get fileName() {
    return 'Carta de Quitação'
  }

  componentDidMount() {
    const { type, clientTaxId, year, company } = this.state.params

    this.context.ConsigazReportApi
      .exportDischargeLetter(clientTaxId, year, type, company)
      .then(
        ({ data: file }) => this.setState({ file }),
        errorDescription => this.props.showToast('Ops!', errorDescription)
      )
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    showToast: toastOperations.show
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(AcquittanceLetterPdfPage)