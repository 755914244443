import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './TermOfUseModal.scss'

export default class TermOfUseModal extends Component {

  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    imgSrc: PropTypes.string,
    children: PropTypes.node,
    shown: PropTypes.bool,
    confirmButton: PropTypes.bool,
    confirmButtonAction: PropTypes.func,
    confirmButtonText: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.state = {
      shown: props.shown !== undefined ? props.shown : true
    }
  }

  get className() {
    return [
      `TermOfUseModal`,
      this.props.className,
      this.state.shown ? 'shown' : 'hidden'
    ].filter(c => c).join(' ')
  }

  get backdropClassName() {
    return [
      `TermOfUseModal-backdrop`,
      this.state.shown ? 'shown' : 'hidden'
    ].filter(c => c).join(' ')
  }

  close = () => this.setState({ shown: false }, () => this.props.onDismiss && this.props.onDismiss())

  open = () => this.setState({ shown: true })

  renderTitle() {
    const { title } = this.props

    if (title)
      return <h1>{title}</h1>
  }

  renderImg() {
    const { imgSrc } = this.props

    if (imgSrc)
      return (
        <>
          <br />
          <img src={imgSrc} height={140} alt="Modal img"/>
        </>
      )
  }

  renderFooterButtons() {
    const {
      confirmButton,
      confirmButtonAction,
      confirmButtonText
    } = this.props

    return (
      <>
        {confirmButton &&
          <button
            className="accept"
            onClick={() => confirmButtonAction ? confirmButtonAction(this) : this.close()}>
            {confirmButtonText || 'Confirmar'}
          </button>}
      </>
    )
  }

  render() {
    const {
      confirmButton
    } = this.props

    return <>
      <div
        className={this.backdropClassName}
        onClick={this.close}>
        <div className={this.className} onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}>
          {this.renderTitle()}
          {this.props.children}
          {this.renderImg()}
          {(confirmButton) &&
            <footer>
              {this.renderFooterButtons()}
            </footer>
          }
        </div>
      </div>
    </>
  }
}