import React, { Component } from "react";

import ClienteService from "../../services/cliente";

import apiContext from "../../api";

import Page, { redirect } from "../../components/Page/Page";
import PageCenterContent from "../../components/PageCenterContent/PageCenterContent";
import ServiceItem from "../../components/ServiceItem/ServiceItem";
import PageHeaderImage from "../../components/PageHeaderImage/PageHeaderImage";

import TechnicianImage from "../../assets/illustrations/technician.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { loadingOperations } from "../../state/ducks/loading";
import { moneyFormat } from "../../components/Masks/Masks";
import CondominioService from "../../services/condominio";

class ServicesConfirmContentComponent extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    let services = [];

    if (props.location && props.location.state && props.location.state.services)
      services = props.location.state.services;

    this.state = {
      serviceRemoveId: null,
      submited: false,
      services,
    };
  }

  get priceComplement() {
    if ((CondominioService.selectMany || []).length > 1)
      return 'por endereço'
    return ''
  }

  handleSubmit = async () => {
    const {
      CPFCNPJ,
      email,
      [`nome-emit`]: nome,
      telefone,
    } = ClienteService.localEntrega;
    const selectMany = CondominioService.selectMany;
    const { [`local-entrega`]: localEntrega } =
      CondominioService.selected || {};
    const splitedName = nome.split(" ");
    const firstName = splitedName.shift();
    const lastName = splitedName.join(" ");

    this.props.showLoading();

    const updatedServices = [];

    for (const service of this.state.services) {
      let compDataList = service.complementaryData;
      if (!compDataList.length) compDataList = [compDataList];

      for (const compData of compDataList) {
        if (selectMany && selectMany.length > 0) {
          await selectMany
            .filter((address) => address.idservice === service.service)
            .map((addresses) =>
              this.context.ConsigazApi.geraOcorrencia(
                "",
                addresses.idservice,
                addresses.price,
                addresses.codMotivo,
                addresses.nrAcordoComercial,
                CPFCNPJ,
                addresses["local-entrega"],
                {
                  ...compData,
                  contact: firstName,
                  lastNameDept: lastName,
                  phone: telefone,
                  email: email,
                }
              )
                .then(({ Ocorrencia }) => {
                  service.protocol = Ocorrencia[0]["v-nr-ocorrencia"] || "";
                  service.dtprev = Ocorrencia[0]["v-dt-prev-atendimento"] || "";

                  updatedServices.push(service);
                  this.setState({ services: updatedServices, submited: true });
                })
                .catch((errorDescription) =>
                  this.props.showToast("Ops!", errorDescription)
                )
            );
        } else {
          this.context.ConsigazApi.geraOcorrencia(
            "",
            service.service,
            service.price,
            service.codMotivo,
            service.nrAcordoComercial,
            CPFCNPJ,
            localEntrega,
            {
              ...compData,
              contact: firstName,
              lastNameDept: lastName,
              phone: telefone,
              email: email,
            }
          )
            .then(({ Ocorrencia }) => {
              if (Ocorrencia) {
                service.protocol = Ocorrencia[0]["v-nr-ocorrencia"] || "";
                service.dtprev = Ocorrencia[0]["v-dt-prev-atendimento"] || "";
                service.deadline = Ocorrencia[0]["v-dt-prev-atendimento"] || "";
                if (service.deadline.length === 10) {
                  const [year, month, day] = service.deadline.split("-");
                  service.deadline = new Date(
                    parseInt(year),
                    parseInt(month) - 1,
                    parseInt(day)
                  ).toLocaleDateString();
                }
                this.setState({ submited: true });
              }
            })
            .catch((errorDescription) =>
              this.props.showToast("Ops!", errorDescription)
            );
        }
      }
    }

    this.props.dismissLoading();
  };

  getServiceDeliveryDay(days) {
    return `${days} dias úteis`;
  }

  getServicePrice(service) {
    if (!service.complementaryData.length)
      if (service.complementaryData && service.complementaryData.value) {
        return moneyFormat(service.complementaryData.value);
      }
    if (
      service.service === "2;;" &&
      service.complementaryData.tipoVazamento === "Interno"
    )
      return moneyFormat(service.priceConditional);
    if (service.service === "15;;") 
      return moneyFormat(service.complementaryData.value);
    return moneyFormat(service.price);
  }

  render() {
    return (
      <>
        {this.props.showIllustration !== false && (
          <PageHeaderImage src={TechnicianImage} />
        )}
        <h1>
          {this.state.submited
            ? this.state.services.filter(
                (service) => service.service === "20;;"
              ).length > 0
              ? "Solicitação(ões) Contratada(s)!"
              : "Serviço(s) Contratado(s)!"
            : "Confirme a contratação"}
        </h1>
        <p>
          {this.state.submited
            ? "Anote o(s) protocolo(s):"
            : this.state.services.filter(
                (service) => service.service === "20;;"
              ).length > 0
            ? "Você está prestes a solicitar os serviços abaixo. Confirmar solicitação?"
            : "Você está prestes a contratar o(s) serviço(s) abaixo. Confirma a contratação?"}
        </p>
        {this.state.services.map((service) => (
          <ServiceItem key={`service-${service.id}`} title={service.title}>
            {this.state.submited ? (
              <>
                {service.deadline &&
                  `Serviço previsto para até o dia ${service.deadline}`}
                <br />
                Protocolo:
                <span
                  style={{
                    float: "right",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {service.protocol || "Não encontrado"}
                </span>
                {((service.price ||
                  (service.complementaryData &&
                    !service.complementaryData.length &&
                    service.complementaryData.value)) && (
                  <p style={{ margin: 0 }}>
                    O boleto será enviado para o email cadastrado após a
                    execução do serviço.
                  </p>
                )) ||
                  ""}
              </>
            ) : (
              <>
                {" "}
                {`Valor total: ${this.getServicePrice(service)} ${this.priceComplement}`}
                <br />
                {`Previsão de atendimento: ${service.deadline}`}
              </>
            )}
          </ServiceItem>
        ))}

        <br />

        {!(ClienteService.localEntrega || {}).isAdmLogin && (
          <button
            className="block"
            style={{ marginTop: "1rem" }}
            onClick={
              this.state.submited
                ? () => this.props.onFinish && this.props.onFinish()
                : this.handleSubmit
            }
          >
            {this.state.submited ? "OK" : "Confirmar contratação"}
          </button>
        )}
      </>
    );
  }
}

function mapDispatchToServicesConfirmContentProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export const ServicesConfirmContent = connect(
  null,
  mapDispatchToServicesConfirmContentProps
)(ServicesConfirmContentComponent);

const ServicesConfirmPage = (props) => (
  <Page history={props.history}>
    <PageCenterContent style={{ width: "900px" }}>
      <ServicesConfirmContent
        history={props.history}
        location={props.location}
        onFinish={() => redirect(props, "/home")}
      />
    </PageCenterContent>
  </Page>
);

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(ServicesConfirmPage);
