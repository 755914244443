import React, { Component } from "react";
import { Link } from "react-router-dom";

import apiContext from "../../api";

import Page from "../../components/Page/Page";
import { toastOperations } from "../../state/ducks/toast";
import { loadingOperations } from "../../state/ducks/loading";
import PageCenterContent from "../../components/PageCenterContent/PageCenterContent";
import ClienteService from "../../services/cliente";
import { redirect } from "../HomeTemplate/HomeTemplate";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

 class ReligueQuestions extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      electronicConcierge: null,
      howWillAccess: null,
    };
  }

  encontrarUltimoLocalDeEntregaCriado(locais) {
    let maiorEntrega = '';
    let maiorNumero = -1;

    locais.forEach(local => {
        // Extrai o número do local
        const numero = parseInt(local.replace(/\D/g, ''), 10);
        
        // Verifica se o número extraído é maior que o atual maior número
        if (numero > maiorNumero) {
            maiorNumero = numero;
            maiorEntrega = local;
        }
    });

    return maiorEntrega;
}

  generateOrder = () => {
    const { CPFCNPJ, email, ClienteEntrRetorno } = ClienteService.localEntrega;
    const { howWillAccess, electronicConcierge } = this.state;
    
    let localEntrega;

    if(ClienteEntrRetorno?.length === 1) {
      localEntrega = ClienteEntrRetorno[0]['local-entrega'] 
    } else {
      const locais = ClienteEntrRetorno.map((item) => item['local-entrega'])
      localEntrega = this.encontrarUltimoLocalDeEntregaCriado(locais)
    }

    this.props.showLoading();

    this.context.ConsigazApi.geraOcorrencia(
      "",
      "Religue",
      0,
      0,
      0,
      CPFCNPJ,
      localEntrega,
      { 
        email,
        howWillAccess,
        portariaEletronica: electronicConcierge ? 'YES' : 'NO',
      }
    ).then(
      ({ Ocorrencia }) => {
        this.props.dismissLoading();
        if (Ocorrencia) {
          redirect(this.props, "/religue-protocol", { protocolNumber: Ocorrencia[0]['v-nr-ocorrencia'] })
        }
      },
      (errorDescription) => {
        this.props.dismissLoading();
        this.props.showToast("Ops!", String(errorDescription));
      }
    );
  };

  render() {
    return (
      <Page history={this.props.history} className="existent-client-page">
        <PageCenterContent>
          <h1>Religue</h1>

          <br />

          <p className="">
            Precisamos entender o tipo de portaria do seu <br /> condomínio para
            agendar o religue do gás.
          </p>
          <div className="wrap-itens">
            <div className="item">
              <p
                style={{ padding: "5px 25px", margin: 0 }}
                className="text-blue border-bottom"
              >
                O condomínio tem portaria eletrônica?
              </p>
              <div style={{ display: "flex", minWidth: "200px" }}>
                <p style={{ textAlign: "left", margin: "0 auto" }}>
                  <label>
                    <input
                      type="radio"
                      name="eletronic-concierge"
                      checked={this.state.electronicConcierge}
                      onChange={(e) =>
                        this.setState({
                          ...this.state,
                          electronicConcierge: true,
                        })
                      }
                    />
                    Sim
                  </label>
                </p>

                <p style={{ textAlign: "left", margin: "0 auto" }}>
                  <label>
                    <input
                      type="radio"
                      name="eletronic-concierge"
                      checked={this.state.electronicConcierge === false}
                      onChange={(e) =>
                        this.setState({
                          ...this.state,
                          electronicConcierge: false,
                        })
                      }
                    />
                    Não
                  </label>
                </p>
              </div>
            </div>

            {this.state.electronicConcierge && (
              <div className="item">
                <p
                  style={{ padding: "5px 25px", margin: 0 }}
                  className="text-blue border-bottom"
                >
                  Informe como será o acesso
                </p>

                <p
                  style={{
                    lineHeight: "23px",
                    marginBottom: 0,
                    color: "##A4A0A0",
                    marginTop: "18px",
                    marginBottom: "18px",
                  }}
                >
                  Como o técnico poderá acessar o condomínio? Inclua o código do portão, instruções para usar o interfone, se há algum código QR code e outras orientações necessárias.
                </p>

                <textarea
                  style={{
                    width: "90%",
                    padding: "16px",
                    border: 0,
                    backgroundColor: "#F8F9FD",
                    borderRadius: '8px'
                  }}
                  value={this.state.howWillAccess || ""}
                  rows={5}
                  maxLength={200}
                  placeholder="Digite ou cole aqui"
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      howWillAccess: e.target.value,
                    })
                  }
                />
              </div>
            )}
          </div>

          <br />
          <br />

          <Link
            to="/save-time"
            className="block"
            onClick={() => this.generateOrder()}
          >
            Confirmar
          </Link>
          <br />
        </PageCenterContent>
      </Page>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(ReligueQuestions);