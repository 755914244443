import React, { Component } from 'react'

import ClienteService from '../../services/cliente'

import Page, { redirect } from '../../components/Page/Page'
import PageCenterContent from '../../components/PageCenterContent/PageCenterContent'
import PageHeaderImage from '../../components/PageHeaderImage/PageHeaderImage'
import { maskPhoneNumber, validatePhoneNumber, validateEmailAddress } from '../../components/Masks/Masks'

import ProfileImage from '../../assets/illustrations/profile.png'

export default class NewClient extends Component {

  constructor(props) {
    super(props)

    let name = ''
    if (props.location && props.location.state)
      name = props.location.state.name || name

    this.state = {
      phoneNumber: '',
      isPhoneNumberValid: false,
      emailAddress: '',
      isEmailAddressValid: false,
      name,
      isNameValid: name !== '',
    }
  }

  get shouldLockName() {
    const { location } = this.props
    if (location && location.state)
      return !!location.state.name
    return false
  }

  handleNameChange = e => {
    const name = e.target.value

    this.setState({
      name,
      isNameValid: name !== ''
    })
  }

  handlePhoneNumberChange = e => {
    const phoneNumberMasked = maskPhoneNumber(e.target.value)

    this.setState({
      phoneNumber: phoneNumberMasked,
      isPhoneNumberValid: validatePhoneNumber(phoneNumberMasked)
    })
  }

  handleEmailAddressChange = e => {
    const emailAddress = e.target.value

    this.setState({
      emailAddress: emailAddress,
      isEmailAddressValid: validateEmailAddress(emailAddress)
    })
  }

  handleSubmit = () => {
    const { isNameValid, isPhoneNumberValid, isEmailAddressValid } = this.state

    if (isNameValid && isPhoneNumberValid && isEmailAddressValid) {
      ClienteService.newClienteData = {
        ...ClienteService.newClienteData,
        name: this.state.name,
        phoneNumber: this.state.phoneNumber,
        emailAddress: this.state.emailAddress ,
      }
      redirect(this.props, '/address-search')
    }

    this.setState({
      haveSubmited: true
    })
  }

  render() {
    return (
      <Page
        history={this.props.history}>
        <PageCenterContent>
          <PageHeaderImage src={ProfileImage} />

          <h1>Ainda não é cliente? <br /> Vamos começar!</h1>
          <p>Insira nome, celular e email para começar:</p>

          <input
            type="text"
            className={`block ${!this.state.isNameValid && this.state.haveSubmited ? 'error' : ''}`}
            placeholder="Digite seu nome completo"
            value={this.state.name}
            onChange={this.handleNameChange}
            readOnly={this.shouldLockName} />

          <br />

          <input
            type="text"
            className={`block ${!this.state.isPhoneNumberValid && this.state.haveSubmited ? 'error' : ''}`}
            placeholder="Digite seu telefone"
            value={this.state.phoneNumber}
            onChange={this.handlePhoneNumberChange} />

          <br />

          <input
            type="text"
            className={`block ${!this.state.isEmailAddressValid && this.state.haveSubmited ? 'error' : ''}`}
            placeholder="Digite seu e-mail"
            value={this.state.emailAddress}
            onChange={this.handleEmailAddressChange} />

          <br />

          <button
            to="/address-search"
            type="submit"
            className="block"
            onClick={this.handleSubmit}>
            Avançar
          </button>
        </PageCenterContent>
      </Page>
    )
  }
}