import React, { Component } from "react";
import apiContext from "../../api";
import ClienteService from "../../services/cliente";
import Select from "../../components/Select/Select";
class AcquittanceTabComponent extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.yearOptions = [
      {
        value: new Date().getFullYear(),
        title: `Ano de ${new Date().getFullYear()}`,
      },
      {
        value: new Date().getFullYear() - 1,
        title: `Ano de ${new Date().getFullYear() - 1}`,
      },
      {
        value: new Date().getFullYear() - 2,
        title: `Ano de ${new Date().getFullYear() - 2}`,
      },
      {
        value: new Date().getFullYear() - 3,
        title: `Ano de ${new Date().getFullYear() - 3}`,
      },
    ];

    this.companyOptions = [
      {
        value: "CONSIGAZ",
        label: "CONSIGAZ",
      },
      {
        value: "GASBALL",
        label: "GASBALL",
      },
      {
        value: "PROPANGAS",
        label: "PROPANGAS",
      },
    ];

    this.state = {
      year: this.yearOptions[0].value,
      company: this.companyOptions[0].value,
    };
  }

  handleSubmit = () => {
    const { CPFCNPJ } = ClienteService.localEntrega;
    const { onSubmit, onOpenInvoices } = this.props;
    const { year, company } = this.state;

    if (!onSubmit) return;

    this.props.showLoading();
    this.context.ConsigazApi.consultaCartaQuitacao(CPFCNPJ, company, year).then(
      ({ ClienteRetorno }) => {
        this.props.dismissLoading();
        try {
          if (ClienteRetorno[0].SITUACAO === "não possui")
            onSubmit({ type: "anual", year, company, cartaBase64: ClienteRetorno[0]?.CARTAPDF });
          else onOpenInvoices();
        } catch (error) {
          onOpenInvoices();
        }
      },
      () => {
        this.props.dismissLoading();
        onOpenInvoices();
      }
    );
  };

  handleTotalLetter = () => {
    const { CPFCNPJ } = ClienteService.localEntrega;
    const { onSubmit, onOpenInvoices } = this.props;
    const { year, company } = this.state;

    if (!onSubmit) return;

    this.props.showLoading();
    this.context.ConsigazApi.consultaCartaQuitacao(CPFCNPJ, company).then(
      ({ ClienteRetorno }) => {
        this.props.dismissLoading();
        try {
          if (ClienteRetorno[0].SITUACAO === "não possui")
            onSubmit({ type: "total", year, company, cartaBase64: ClienteRetorno[0]?.CARTAPDF });
          else onOpenInvoices();
        } catch (error) {
          onOpenInvoices();
        }
      },
      () => {
        this.props.dismissLoading();
        onOpenInvoices();
      }
    );
  };

  render() {
    const {
      yearOptions,
      companyOptions,
      state: { year, company },
    } = this;

    return (
      <>
        <h2>Selecione o ano e a empresa para emitir a carta de quitação</h2>
        <Select
          className="block no-margin"
          options={yearOptions || []}
          value={year}
          onChange={(e) => this.setState({ year: e.target.value })}
        />

        {(companyOptions || []).map((comp, idx) => (
          <label className="company" key={`acquittance-company-item-${idx}`}>
            <input
              type="radio"
              name="company"
              value={comp.value}
              checked={comp.value === company}
              onChange={() => this.setState({ company: comp.value })}
            />
            {comp.label}
          </label>
        ))}

        <br />

        <button
          type="submit"
          onClick={this.handleSubmit}
          className="block no-margin"
        >
          Emitir carta de quitação anual
        </button>

        <br />
        <br />

        <button
          type="submit"
          onClick={this.handleTotalLetter}
          className="block no-margin"
        >
          Emitir carta de quitação total
        </button>
      </>
    );
  }
}

export default AcquittanceTabComponent;
