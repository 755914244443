import React from "react";

import "./ServiceItem.scss";

import { IconClose } from "../Icons/Icons";

export default (props) => (
  <div
    className={`service-item ${props.className || ""}`}
    style={{
      [`--width-compensation`]: "calc(calc(var(--padding-x) * 2) + calc(var(--margin) * 2))",
    }}
  >
    {props.endereco && (
      <p
        className="title"
        style={props.titleMultiline ? { whiteSpace: "normal" } : {}}
      >
        {props.endereco}
      </p>
    )}
    {props.title && (
      <p
        className="title"
        style={props.titleMultiline ? { whiteSpace: "normal" } : {}}
      >
        {props.title}
      </p>
    )}
    {props.children}
    {props.iconClose && (
      <IconClose
        data-testid="service-item-icon-close"
        size={32}
        color="#fff"
        className="close-button"
        onClick={props.iconCloseAction}
      />
    )}
  </div>
);
