import React, { Component } from "react";

import Page, { redirect } from "../../components/Page/Page";
import PageCenterContent from "../../components/PageCenterContent/PageCenterContent";
import ServiceItem from "../../components/ServiceItem/ServiceItem";
import PageHeaderImage from "../../components/PageHeaderImage/PageHeaderImage";
import Switch from "../../components/Switch/Switch";

import BuildingImage from "../../assets/illustrations/building.png";
import apiContext from "../../api";

import CondominioService from "../../services/condominio";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { loadingOperations } from "../../state/ducks/loading";
import Modal from "../../components/Modal/Modal";
import Select2 from "../../components/Select2/Select";

const getTypeValidator = (type) =>
  ({
    INTEGER: /\d+/g,
    CHARACTER: null,
  }[type]);

class AddressComplement extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      condominioData: {},
      fields: [],
      termsAcknowledged: false,
      haveSubmited: false,
      addressValues: {}
    };

    Object.assign(this.state, this.mapLocationToState(props.location));

    if (
      this.state.condominioData &&
      Object.keys(this.state.condominioData).length === 0 &&
      this.state.addressOnReactivation &&
      Object.keys(this.state.addressOnReactivation).length === 0
    )
      redirect(props, this.redirectPath);
  }

  get redirectPath() {
    const { condominioData, addressOnReactivation } = this.state;
    const { location } = this.props;

    if (Object.keys(condominioData || {}).length > 0) return "/address-search";
    else if (Object.keys(addressOnReactivation || {}).length > 0)
      return "/address-list";
    else return (location.state && location.state.from) || "/home";
  }

  mapLocationToState(location) {
    if (location && location.state)
      if (location.state.condominioData)
        return {
          condominioData: location.state.condominioData,
          ...this.getDataFields(location.state.condominioData),
        };
      else if (location.state.addressOnReactivation) {
        const addressOnReactivation = location.state.addressOnReactivation;
        return {
          addressOnReactivation: addressOnReactivation,
          [`local-entrega`]: addressOnReactivation["cod-entrega-cond"],
          ...this.getDataFromReactivation(addressOnReactivation),
        };
      }
  }

  getDataFields(condominio) {
    let fields = [];

    if (condominio["tt-tipo-inst"]) {
      fields = condominio["tt-tipo-inst"][0]["tt-item-tipo-inst"].map(
        (item) => ({
          maxLength: item.formato.length,
          description: item.descricao,
          codTipo: item["cod-tipo-complemento"],
          validator: getTypeValidator(item["tipo-dado"]),
          tipoDado: item["tipo-dado"],
        })
      );
    }
    return {
      addressTitle: condominio.nome,
      addressDescription: `${condominio.endereco}, ${condominio.cidade}`,
      fields,
    };
  }

  getDataFromReactivation(addressOnReactivation) {
    if (addressOnReactivation)
      return {
        addressTitle: addressOnReactivation["nome-condominio"],
        addressDescription: `${addressOnReactivation.endereco}, ${addressOnReactivation.cidade}`,
      };

    return {};
  }

  handleConsultaOcupacaoIsEmpty = (data) =>
    !(
      data.Erros &&
      data.Erros.length &&
      data.Erros[0].Descricao === "Localizacao ocupada"
    );

  getApiLocationInfo = (join = true, pad = false) => {
    const { fields, addressOnReactivation } = this.state;
    if (join) {
      if (addressOnReactivation) return addressOnReactivation.localizacao;
      else
        return (fields || [])
          .map(
            (field) => `${field.codTipo}${this.getFieldValue(field, "", pad)}`
          )
          .join(" ");
    } else {
      if (addressOnReactivation) return addressOnReactivation.conteudo;
      else
        return (fields || []).map((field) =>
          this.getFieldValue(field, "", pad)
        );
    }
  };

  getLocationLabel = () => {
    const { fields, addressOnReactivation } = this.state;

    if (addressOnReactivation) return addressOnReactivation.localizacao;
    else
      return (fields || [])
        .map(
          (field) =>
            `${field.description} ${this.getFieldValue(field, "", "pad")}`
        )
        .join(" - ");
  };

  handleSubmit = () => {
    if (this.state.termsAcknowledged && this.validateFields()) {
      const { condominioData, addressOnReactivation } = this.state;
      const localizacao = this.getApiLocationInfo(true, true);

      CondominioService.inRegistration = {
        ...(addressOnReactivation || {}),
        // 'cod-entrega-cond': addressOnReactivation && addressOnReactivation['cod-entrega-cond'],
        codigo:
          addressOnReactivation && addressOnReactivation["cod-condominio"],
        ...(condominioData || {}),
        localizacao,
        conteudo: this.getApiLocationInfo(false, true),
        localizacaoLabel: this.getLocationLabel(),
        isReactivation: Object.keys(addressOnReactivation || {}).length > 0,
      };

      this.props.showLoading();
      this.context.ConsigazApi.consultaOcupacao(
        addressOnReactivation
          ? addressOnReactivation["cod-condominio"]
          : condominioData.codigo,
        addressOnReactivation
          ? addressOnReactivation["cod-entrega-cond"]
          : condominioData["cod-entrega-cond"],
        localizacao
      )
        .then(this.handleConsultaOcupacaoIsEmpty)
        .then((isEmpty) => {
          this.props.dismissLoading();
          if (isEmpty)
            redirect(this.props, "/address-done", { isAddressEmpty: true });
          else redirect(this.props, "/address-active");
        })
        .catch((err) => {
          this.props.dismissLoading();
          redirect(this.props, "/address-active");
        });
    } else {
      this.setState({ haveSubmited: true }, () => {
        if (!this.state.termsAcknowledged)
          this.props.showToast(
            "Ops!",
            "Para continuar você precisa aceitar os termos"
          );
      });
    }
  };

  handleInputChange = (field, value) => {    
    if (field.validator) {
      value = (value.match(field.validator) || []).join("");
    } else if(value?.length > 0 && value?.toLowerCase()?.includes("selecione")) {
      value = null;
    }
    
    this.setState({
      [field.description.toLowerCase()]: value,
    });
  };

  getFieldValue = (field, defaultValue, pad = false) => {
    let value = this.state[field.description.toLowerCase()];

    if (pad && field.tipoDado === "INTEGER") {
      value = value ? `${value}`.padStart(field.maxLength, "0") : value;
    }

    return value || defaultValue;
  };

  validateFields = () =>
    this.state.fields.reduce((reduced, field) => {
      return reduced && this.getFieldValue(field, "").length > 0;
    }, true);

  componentDidMount() {
    const options = this.state.condominioData;

    let locations = {};

    const arr1 = options.Unidades?.forEach((unidade) => {
      const arr2 = unidade.descricao?.map((item) => {
        if(item?.length > 0) {
          const index = unidade.descricao.indexOf(item)
          const obj = { title: unidade.conteudo[index], value: unidade.conteudo[index] }

          if(locations[item]) {
            locations[item].push(obj)
          } else {
            locations[item] = [obj]
          }
        }
      });
    });

    const noRepeatValues = {}

    Object.keys(locations).forEach(key => {
      noRepeatValues[key] = Array.from(new Set(locations[key].map(obj => JSON.stringify(obj))))
      .map(str => JSON.parse(str));
    });

    this.setState({
      ...this.state,
      fields: this.state.fields.map((field) => {
        return {
          ...field,
          values: noRepeatValues[field.description]
        }
      })
    });
  }

  open = () => this.modalRef && this.modalRef.open();
  close = () => this.modalRef && this.modalRef.close();

  isToggle = () => {
    this.open();
  };

  render() {
    const { addressOnReactivation } = this.state;

    return (
      <Page history={this.props.history}>
        <PageCenterContent>
          <PageHeaderImage src={BuildingImage} />

          <h1>Este é o seu imóvel:</h1>

          <ServiceItem
            title={this.state.addressTitle}
            titleMultiline={true}
            iconClose={true}
            iconCloseAction={() => redirect(this.props, this.redirectPath)}
          >
            {this.state.addressDescription}
          </ServiceItem>

          {this.state.fields.length > 0 && this.state?.fields[0]?.values && (
            <>
              <h2>Informe o complemento</h2>
              <div className="wrap-material-ui-select">
                {this.state.fields.map((field) => (
                  <>
                    <Select2
                      key={`address_field_${field.description.toLowerCase()}`}
                      options={field?.values}
                      label={field.description}
                      onSelect={(value) => this.handleInputChange(field, value)}
                    />
                  </>
                ))}
              </div>
            </>
          )}

          <br />

          <div style={{ marginBottom: "2rem", marginTop: "1rem" }}>
            <Switch
              checked={this.state.termsAcknowledged}
              onChange={() =>
                this.setState({
                  termsAcknowledged: !this.state.termsAcknowledged,
                })
              }
            />
            <span style={{ marginLeft: "1rem" }}>
              Li e concordo com os{" "}
              <a href="/termo.html" target="blank" className="inline-link">
                Termos de uso
              </a>
              .
            </span>
          </div>
          <Modal
            shown={false}
            ref={(ref) => (this.modalRef = ref)}
            className="consumption-modal"
            title="Confirmação de dados"
            closeButton={true}
          >
            <br />
            <p>Os dados cadastrados estão certos?</p>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <button
                style={{ width: "30%" }}
                className="block"
                onClick={this.close}
              >
                Não
              </button>
              <button
                style={{ width: "30%" }}
                className="block"
                onClick={this.handleSubmit}
              >
                Sim
              </button>
            </div>
          </Modal>

          <button className="block" onClick={this.isToggle}>
            {Object.keys(addressOnReactivation || {}).length > 0
              ? "Reativar imóvel"
              : "Cadastrar imóvel na minha conta"}
          </button>
        </PageCenterContent>
      </Page>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(AddressComplement);
