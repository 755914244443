import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import React, { Component } from "react";
import CondominioService from "../../services/condominio";
import Modal from "../../components/Modal/Modal";
import moment from "moment";

class TicketFilterModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStatus: props.selectedStatus || [],
      selectedProduct: "todos",
      selectedUnits: [],
      selectedAddress: "todos",
      selectedAddresses: [],
      selectedInitialDate: "",
      selectedLastDate: "",
      tab: "",
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
    };
  }

  get availableProducts() {
    const { productsData } = this.props;
    return Object.entries(productsData || {}).reduce(
      (reduced, [key, value]) => {
        if (reduced.indexOf(key) === -1) reduced.push(key);
        return reduced;
      },
      []
    );
  }

  get showProductsFilter() {
    return this.availableProducts.length > 1;
  }

  get onlyProduct() {
    const { productsData } = this.props;
    return (Object.values(
      Object.entries(productsData || {}).reduce((reduced, [key, value]) => {
        if (!reduced[key]) reduced[key] = value;
        return reduced;
      }, {})
    ) || [])[0];
  }

  onSubmit = () => {
    const { onSubmit } = this.props;

    const {
      selectedStatus,
      selectedProduct,
      selectedUnits,
      selectedInitialDate,
      selectedLastDate,
      selectedAddress,
      selectedAddresses,
    } = this.state;


    onSubmit &&
      onSubmit({
        selectedStatus,
        selectedProduct: this.showProductsFilter
          ? selectedProduct
          : ((this.onlyProduct || {}).un || "").toLowerCase() === "kg"
          ? "granel"
          : "cilindros",
        selectedUnits: this.showProductsFilter
          ? selectedUnits
          : this.availableProducts,
        selectedInitialDate,
        selectedLastDate,
        selectedAddress,
        selectedAddresses,
      });
  };

  filterByUnits(selectedUnits) {
    this.setState(
      { selectedProduct: "cilindros", selectedUnits },
      this.onSubmit
    );
  }

  toggleUnit(unit) {
    const { selectedUnits } = this.state;

    if (selectedUnits.indexOf(unit) === -1)
      this.setState({ selectedUnits: [...selectedUnits, unit] });
    else
      this.setState({
        selectedUnits: selectedUnits.filter((un) => un !== unit),
      });
  }

  toggleStatus(stat) {
    const { selectedStatus } = this.state;

    if (selectedStatus.indexOf(stat) === -1)
      this.setState({ selectedStatus: [...selectedStatus, stat] });
    else
      this.setState({
        selectedStatus: selectedStatus.filter((un) => un !== stat),
      });
  }

  toggleAddress(addr) {
    const { selectedAddresses } = this.state;

    if (selectedAddresses.indexOf(addr) === -1)
      this.setState({ selectedAddresses: [...selectedAddresses, addr] });
    else
      this.setState({
        selectedAddresses: selectedAddresses.filter((un) => un !== addr),
      });
  }

  filterByAddress(address) {
    const { addressData } = this.props;

    if (addressData.indexOf(address) > -1)
      this.setState(
        {
          selectedAddress: "especificos",
          selectedAddresses: [address],
        },
        this.onSubmit
      );
  }

  render() {
    const { shown, productsData, addressData } = this.props;
    const {
      selectedStatus,
      selectedProduct,
      selectedUnits,
      selectedInitialDate,
      selectedLastDate,
      selectedAddress,
      selectedAddresses,
      selectedCondominio,
    } = this.state;

    if (shown === false) return null;

    let filteredProps = {
      ...this.props,
      onSubmit: null,
    };

    return (
      <Modal className="filter-modal" {...filteredProps}>
        <h3>Selecione os parâmetros abaixo para filtrar suas notas fiscais:</h3>

        <div className="filter-options">
          <p className="title">Status (opcional)</p>
          <div>
            <label>
              <input
                type="checkbox"
                className={
                  selectedStatus.indexOf("avencer") > -1 ? "checked" : ""
                }
                onClick={() => this.toggleStatus("avencer")}
              />
              Nota fiscal a vencer
            </label>

            <label>
              <input
                type="checkbox"
                className={
                  selectedStatus.indexOf("outdated") > -1 ? "checked" : ""
                }
                onClick={() => this.toggleStatus("outdated")}
              />
              Nota fiscal vencida
            </label>

            <label>
              <input
                type="checkbox"
                name="paid"
                className={selectedStatus.indexOf("paid") > -1 ? "checked" : ""}
                onClick={() => this.toggleStatus("paid")}
              />
              Nota fiscal paga
            </label>

            <label>
              <input
                type="checkbox"
                className={
                  selectedStatus.indexOf("cancel") > -1 ? "checked" : ""
                }
                onClick={() => this.toggleStatus("cancel")}
              />
              Nota fiscal cancelada/devolvida
            </label>
          </div>

          {this.showProductsFilter && (
            <>
              <p className="title">Produtos</p>

              <label>
                <input
                  type="radio"
                  name="is-ready"
                  value="todos"
                  className={selectedProduct === "todos" ? "checked" : ""}
                  onChange={() => this.setState({ selectedProduct: "todos" })}
                />
                Todos os produtos
              </label>

              <label>
                <input
                  type="radio"
                  name="is-ready"
                  value="granel"
                  className={selectedProduct === "granel" ? "checked" : ""}
                  onChange={() => this.setState({ selectedProduct: "granel" })}
                />
                Fornecimento à granel
              </label>

              <label>
                <input
                  type="radio"
                  name="is-ready"
                  value="null"
                  className={selectedProduct === "cilindros" ? "checked" : ""}
                  onChange={() =>
                    this.setState({ selectedProduct: "cilindros" })
                  }
                />
                Cilindros (unidades)
              </label>

              <div style={{ paddingLeft: "2rem" }}>
                {Object.entries(productsData || {}).map(([key, value]) => {
                  if (value.un.toLowerCase() === "un" && value.tipo.toLowerCase() === "glp envasado") {
                    return (
                      <label>
                        <input
                          type="checkbox"
                          className={
                            selectedUnits.indexOf(key) > -1 ? "checked" : ""
                          }
                          onClick={() => this.toggleUnit(key)}
                          disabled={selectedProduct !== "cilindros"}
                        />
                        {value["desc-item"].toUpperCase()}
                      </label>
                    );
                  }

                  return null;
                })}
              </div>
            </>
          )}

          {selectedCondominio === "all" ? (
            <>
              <p className="title">Endereços</p>

              <label>
                <input
                  type="radio"
                  name="is-ready"
                  value={"todos"}
                  className={selectedAddress === "todos" ? "checked" : ""}
                  onChange={() => this.setState({ selectedAddress: "todos" })}
                />
                Todos os endereços
              </label>

              <label>
                <input
                  type="radio"
                  name="is-ready"
                  value={"especificos"}
                  className={selectedAddress === "especificos" ? "checked" : ""}
                  onChange={() =>
                    this.setState({ selectedAddress: "especificos" })
                  }
                />
                Selecione os endereços
              </label>

              <div style={{ paddingLeft: "2rem" }}>
                {(addressData || []).map((addr) => (
                  <label key={`addr-checkbox-${addr}`}>
                    <input
                      type="checkbox"
                      className={
                        selectedAddresses.indexOf(addr) > -1 ? "checked" : ""
                      }
                      onClick={() => this.toggleAddress(addr)}
                      disabled={selectedAddress !== "especificos"}
                    />
                    {addr}
                  </label>
                ))}
              </div>
            </>
          ) : (
            ""
          )}

          <p className="title">Período (opcional)</p>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale={moment.locale("pt-br")}
            >
              <DatePicker
                locale="pt_BR"
                style={{ margin: "1rem auto" }}
                cancelLabel="Cancelar"
                onChange={(date) => {
                  this.setState({
                    selectedInitialDate: date.format("DD/MM/YYYY"),
                  });
                }}
                labelFunc={() => selectedInitialDate || "Data inicial"}
              />
            </MuiPickersUtilsProvider>
            <br></br>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale={moment.locale("pt-br")}
            >
              <DatePicker
                locale="pt_BR"
                style={{ margin: "1rem auto" }}
                cancelLabel="Cancelar"
                onChange={(date) => {
                  this.setState({
                    selectedLastDate: date.format("DD/MM/YYYY"),
                  });
                }}
                labelFunc={() => selectedLastDate || "Data final"}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <button className="block link" onClick={this.clearDates}>
              Limpar datas
            </button>
          </div>
        </div>

        <button className="block" onClick={this.onSubmit}>
          Filtrar
        </button>
      </Modal>
    );
  }
}
export default TicketFilterModal;
