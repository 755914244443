import React, { Component } from "react";

import ClienteService from "../../services/cliente";
import CondominioService from "../../services/condominio";

import Page, { redirect } from "../../components/Page/Page";
import PageCenterContent from "../../components/PageCenterContent/PageCenterContent";
import ServiceItem from "../../components/ServiceItem/ServiceItem";
import PageHeaderImage from "../../components/PageHeaderImage/PageHeaderImage";

import BuildingImage from "../../assets/illustrations/building.png";
import apiContext from "../../api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { loadingOperations } from "../../state/ducks/loading";

class AddressDone extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      addressComponents: {},
      clientCode: null,
      requestSuccess: null,
      isExistentClient: (ClienteService.localEntrega || null) !== null,
    };

    if (props.location && props.location.state) {
      if (
        !(
          props.location.state.shouldOverwrite ||
          props.location.state.isAddressEmpty
        )
      )
        // prevent direct access to the page
        redirect(props, "/");
    }
  }

  componentDidMount() {
    if (
      !(ClienteService.newClienteData || ClienteService.localEntrega) ||
      !CondominioService.inRegistration
    ) {
      this.props.showToast(
        "Ops!",
        "Dados insuficientes para continuar o cadastro"
      );
      return;
    }

    let cpfcnpj, nome, telefone, email;

    const newClienteData = ClienteService.newClienteData;
    const localEntregaCliente = ClienteService.localEntrega;
    if (localEntregaCliente) {
      cpfcnpj = localEntregaCliente.CPFCNPJ;
      nome = localEntregaCliente["nome-emit"];
      telefone = localEntregaCliente.telefone;
      email = localEntregaCliente.email;
    } else if (newClienteData) {
      cpfcnpj = newClienteData.cpfcnpj;
      nome = newClienteData.name;
      telefone = newClienteData.phoneNumber;
      email = newClienteData.emailAddress;
    }

    const {
      [`cod-entrega`]: codEntrega,
      [`cod-entrega-cond`]: codEntregaCond,
      [`local-entrega`]: localEntrega,
      codigo,
      localizacao,
      conteudo,
      endereco,
      [`tt-tipo-inst`]: ttTipoInst,
    } = CondominioService.inRegistration;

    this.props.showLoading();
    this.context.ConsigazApi.cadastraCondominio(
      cpfcnpj,
      nome,
      telefone,
      email,
      localizacao,
      codigo,
      codEntrega || localEntrega,
      codEntregaCond,
      (ttTipoInst && ttTipoInst.length && ttTipoInst[0]["nr-sequencia"]) || 0,
      [
        conteudo[0] || "",
        conteudo[1] || "",
        conteudo[2] || "",
        conteudo[3] || "",
        conteudo[4] || "",
        conteudo[5] || "",
        conteudo[6] || "",
        conteudo[7] || "",
        conteudo[8] || "",
        conteudo[9] || "",
      ]
    )
      .then(({ Sucesso, Retorno }) => {
        if (Sucesso) {
          this.context.ConsigazApi.consultaLocalEntrega(cpfcnpj).then(
            data => {
              ClienteService.consultaLocalEntregaApiParser(data)

              try {
                // eslint-disable-next-line
                const selected = data.Cliente.ClienteRetorno[0].ClienteEntrRetorno.filter(cond => cond.endereco == endereco)[0]
                CondominioService.selected = selected
              } catch (error) {
                console.warn(error)
              }
            }
          );

          this.props.dismissLoading();
          this.setState({
            requestSuccess: Sucesso !== undefined,
            clientCode: Sucesso && Sucesso[0].codigo,
          });
        } else if (Retorno) {
          this.props.dismissLoading();
          this.setState(
            {
              requestSuccess: false,
            },
            () => {
              if (Retorno[0].Descricao === "Condominio nao esta ativo em nosso sistema")
                this.props.showToast(
                  "Ops!",
                  "Este condomínio não é mais cliente da Consigaz. Entre em contato com o síndico do condomínio para verificar o atual fornecedor."
                );
              else this.props.showToast("Ops!", JSON.stringify(Retorno[0].Descricao));
            }
          );
        }
      })
      .catch((errorDescription) => {
        this.props.dismissLoading();
        this.setState(
          {
            requestSuccess: false,
          },
          () => this.props.showToast("Ops!", JSON.stringify(errorDescription))
        );
      });
  }

  render() {
    const { isExistentClient } = this.state;

    let addressTitle, addressDescription, addressComplement;

    const condominio = CondominioService.inRegistration;

    if (condominio) {
      addressTitle = condominio.nome;
      addressDescription = `${condominio.endereco}, ${condominio.cidade}`;
      addressComplement = condominio.localizacaoLabel || condominio.localizacao;
    }

    return (
      <Page history={this.props.history}>
        <PageCenterContent>
          <PageHeaderImage src={BuildingImage} />

          <br />
          <br />

          {this.state.requestSuccess === false && (
            <>
              <p>Erro ao realizar o cadastro</p>
              <button
                className="block"
                onClick={() => redirect(this.props, "/home")}
              >
                Voltar
              </button>
            </>
          )}

          {this.state.requestSuccess === true && (
            <>
              <h1>Parabéns, cadastro feito!</h1>

              <p>O imóvel abaixo foi vinculado ao seu CPF/CNPJ.</p>

              <ServiceItem title={addressTitle} titleMultiline={true}>
                {addressDescription && (
                  <p style={{ margin: 0 }}>{addressDescription || ""}</p>
                )}
                {addressComplement && (
                  <p style={{ margin: 0 }}>{addressComplement || ""}</p>
                )}
              </ServiceItem>

              <br />
              <br />

              {isExistentClient ? (
                <p>Seu código de cliente permanece:</p>
              ) : (
                <p>
                  Anote o código de cliente, você irá utiliza-lo na próxima vez
                  que acessar a plataforma.
                </p>
              )}

              <p
                style={{
                  color: "var(--light-blue)",
                  fontSize: "26px",
                  fontWeight: 600,
                  fontStyle: "oblique",
                }}
              >
                {this.state.clientCode}
              </p>


              <div>
                <h2>Deseja solicitar o religue do gás?</h2>
                <div>
                  <button
                    className="block"
                    style={{ maxWidth: 200, margin: 4 }}
                    onClick={() => redirect(this.props, "religue-questions")}
                  >
                    Solicitar
                  </button>
                  <button
                    className="block"
                    style={{ maxWidth: 200, margin: 4 }}
                    onClick={() => redirect(this.props, '/save-time')}
                  >
                    Não
                  </button>
                </div>
              </div>
            </>
          )}
        </PageCenterContent>
      </Page>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(AddressDone);
