import React from "react";
import apiContext from "../../../api";
import { Component } from "react";
import Modal from "../../../components/Modal/Modal";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../../state/ducks/loading";
import "./ServiceDetail.scss";
import { maskCpfCnpj, validateCpfCnpj } from "../../../components/Masks/Masks";
import CondominioService from "../../../services/condominio";
import SelectAddressList from "../SelectAddressList";

class ChangeDataDetail extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      complementaryData: this.initialComplementaryData,
      selectedData: [],
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
      isCpfCnpjValid: false,
      haveSubmited: false,
    };
  }

  get initialComplementaryData() {
    return {
      cpfcnp: null,
      name: null,
      inscricao: null,
      endereco: null,
    };
  }

  submit = () => {
    const {
      complementaryData,
      isCpfCnpjValid,
      selectedData,
    } = this.state;
    if (
      complementaryData &&
      complementaryData.informacoes &&
      complementaryData.informacoes.length
    ) {
      if (
        selectedData.map((data) => data.indexOf("cnpj")) > -1 &&
        isCpfCnpjValid === false
      )
        this.props.showToast("Ops!", "Insira um CPF/CNPJ válido!");
      else this.setState({ haveSubmited: true });
    } else {
      this.assistanceModal && this.assistanceModal.open();
    }
  };

  generateOrder = () => {
    const {
      complementaryData: { cpfcnp, endereco, name, inscricao, informacoes },
      complementaryData,
      selectedCondominio,
      selectedAddress,
    } = this.state;
    const { onToggle } = this.props;
    if (selectedCondominio === "all") {
      if (
        CondominioService.selectMany &&
        CondominioService.selectMany.length > 0
      ) {
        CondominioService.selectMany &&
          CondominioService.selectMany.map((teste) =>
            selectedAddress.push(teste)
          );
        CondominioService.selectMany = selectedAddress;
      } else CondominioService.selectMany = selectedAddress;
    }
    this.setState({ haveSubmited: false });

    var newInfos = ""
    if (cpfcnp) newInfos += `Novo CNPJ: ${cpfcnp}\n `
    if (endereco) newInfos += `Novo Endereço: ${endereco}\n `
    if (name) newInfos += `Nova Razão social / Nome: ${name}\n `
    if (inscricao) newInfos += `Nova Inscrição Estadual: ${inscricao}\n `

    newInfos += `Detalhes: ${informacoes || ""}`

    onToggle &&
      onToggle({
        complementaryData,
        informacoes: newInfos,
      });
  };

  toggleData(data) {
    const { selectedData } = this.state;

    if (selectedData.indexOf(data) === -1)
      this.setState({ selectedData: [...selectedData, data] });
    else
      this.setState({
        selectedData: selectedData.filter((un) => un !== data),
      });
  }

  handleCpfcnpjChange = (e) => {
    const { complementaryData } = this.state;
    const maskedCpfcnpj = maskCpfCnpj(e.target.value);

    this.setState({
      complementaryData: {
        ...complementaryData,
        cpfcnp: maskedCpfcnpj,
      },
      isCpfCnpjValid: validateCpfCnpj(maskedCpfcnpj),
    });
  };

  render() {
    const {
      complementaryData,
      selectedData,
      selectedCondominio,
      haveSubmited,
    } = this.state;
    const { checked, rawData } = this.props;

    return (
      <>
        {selectedCondominio === "all" && haveSubmited === true && (
          <>
            <SelectAddressList
              onSubmit={(address) =>
                this.setState(
                  {
                    selectedAddress: address,
                  },
                  () => this.generateOrder()
                )
              }
              cardClass={"service"}
              service={rawData}
            />
          </>
        )}
        {haveSubmited === false && (
          <>
            <h2 className="service-detail-title">{rawData.title}</h2>

            <p style={{ textAlign: "justify" }}>{rawData.description}</p>
            <p style={{ textAlign: "justify" }}>
              <b>qual tipo de alteração deseja fazer?</b>
            </p>

            <div className="center">
              <div className="center-options">
                <label>
                  <input
                    type="checkbox"
                    className={
                      selectedData.indexOf("cnpj") > -1 ? "checked" : ""
                    }
                    onClick={() => this.toggleData("cnpj")}
                    disabled={checked}
                  />
                  CNPJ/CPF
                </label>

                <label>
                  {selectedData.indexOf("cnpj") > -1 ? (
                    <>
                      <input
                        type="text"
                        className="changeData"
                        maxLength="18"
                        value={complementaryData.cpfcnp || ""}
                        onChange={this.handleCpfcnpjChange}
                        disabled={checked}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </label>

                <label>
                  <input
                    type="checkbox"
                    className={
                      selectedData.indexOf("name") > -1 ? "checked" : ""
                    }
                    onClick={() => this.toggleData("name")}
                    disabled={checked}
                  />
                  Razão social/Nome
                </label>

                <label>
                  {selectedData.indexOf("name") > -1 ? (
                    <>
                      <input
                        type="text"
                        className="changeData"
                        disabled={checked}
                        onChange={(e) =>
                          this.setState({
                            complementaryData: {
                              ...complementaryData,
                              name: e.target.value,
                            },
                          })
                        }
                      />
                    </>
                  ) : (
                    ""
                  )}
                </label>

                <label>
                  <input
                    type="checkbox"
                    className={
                      selectedData.indexOf("inscricao") > -1 ? "checked" : ""
                    }
                    onClick={() => this.toggleData("inscricao")}
                    disabled={checked}
                  />
                  Inscrição Estadual
                </label>

                <label>
                  {selectedData.indexOf("inscricao") > -1 ? (
                    <>
                      <input
                        type="text"
                        className="changeData"
                        disabled={checked}
                        onChange={(e) =>
                          this.setState({
                            complementaryData: {
                              ...complementaryData,
                              inscricao: e.target.value,
                            },
                          })
                        }
                      />
                    </>
                  ) : (
                    ""
                  )}
                </label>

                <label>
                  <input
                    type="checkbox"
                    className={
                      selectedData.indexOf("endereco") > -1 ? "checked" : ""
                    }
                    onClick={() => this.toggleData("endereco")}
                    disabled={checked}
                  />
                  Endereço
                </label>

                <label>
                  {selectedData.indexOf("endereco") > -1 ? (
                    <>
                      <input
                        type="text"
                        className="changeData"
                        disabled={checked}
                        onChange={(e) =>
                          this.setState({
                            complementaryData: {
                              ...complementaryData,
                              endereco: e.target.value,
                            },
                          })
                        }
                      />
                    </>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>

            <>
              <br></br>
              <p>
                <b>Use o campo para detalhar</b>
              </p>

              <textarea
                style={{ width: "100%" }}
                value={complementaryData.informacoes || ""}
                maxLength={200}
                onChange={(e) =>
                  this.setState({
                    complementaryData: {
                      ...complementaryData,
                      informacoes: e.target.value,
                    },
                  })
                }
                disabled={checked}
              />
            </>
            <footer className="service-detail-footer">
              <button
                className="block"
                onClick={() => {
                  !checked ? this.submit() : this.generateOrder();
                }}
                disabled={selectedData.length === 0}
              >
                {checked ? "Desselecionar" : "Selecionar"}
              </button>
            </footer>
          </>
        )}
        <Modal
          shown={false}
          ref={(ref) => (this.assistanceModal = ref)}
          title="OPS!"
          closeButton={true}
          confirmButton={true}
          confirmButtonText={"ok"}
        >
          <p style={{ textAlign: "justify" }}>
            É necessário que descreva o motivo das alterações solicitadas.
          </p>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(ChangeDataDetail);
