import React from "react";
import apiContext from "../../../api";
import { Component } from "react";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../../state/ducks/loading";
import "./ServiceDetail.scss";
import CondominioService from "../../../services/condominio";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import ClienteService from "../../../services/cliente";
import Modal from "../../../components/Modal/Modal";
import SelectAddressList from "../SelectAddressList";

class CollectiveVacation extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
      complementaryData: this.initialComplementaryData,
    };
  }

  get initialComplementaryData() {
    const { data = {} } = this.props;

    return {
      initialDate: data["dt-ini-ferias"]
        ? moment(data["dt-ini-ferias"], "YYYY-MM-DD")
        : null,
      lastDate: data["dt-fim-ferias"]
        ? moment(data["dt-fim-ferias"], "YYYY-MM-DD")
        : null,
      selectedModel: data["it-codigo"] || null,
      informacoes: "",
    };
  }

  componentDidUpdate() {
    const { complementaryData } = this.state;
    const { data } = this.props;

    if (data && !complementaryData.selectedModel) {
      this.setState({
        complementaryData: this.initialComplementaryData,
      });
    }
  }

  get formattedInitialDate() {
    const { complementaryData } = this.state;

    if (complementaryData && complementaryData["initialDate"])
      return complementaryData["initialDate"].format("DD/MM/YYYY");
  }

  get formattedLastDate() {
    const { complementaryData } = this.state;

    if (complementaryData && complementaryData["lastDate"])
      return complementaryData["lastDate"].format("DD/MM/YYYY");
  }

  componentDidMount() {
    const { CPFCNPJ } = ClienteService.localEntrega || {};

    this.props.showLoading();
    this.context.ConsigazApi.consultaLocalEntrega(CPFCNPJ)
      .then(ClienteService.consultaLocalEntregaApiParser)
      .then(({ Cliente }) => {
        if (Cliente) {
          this.props.dismissLoading();
          this.setState({
            addressList: Cliente.ClienteRetorno[0].ClienteEntrRetorno,
          });
        }
      })
      .catch((errorDescription) => {
        this.props.dismissLoading();
        this.setState({}, () => this.props.showToast("Ops!", errorDescription));
      });
  }

  toggleModel(model) {
    const { complementaryData } = this.state;

    if (complementaryData["selectedModel"] === model)
      this.setState({
        complementaryData: {
          ...complementaryData,
          selectedModel: null,
        },
      });
    else
      this.setState({
        complementaryData: {
          ...complementaryData,
          selectedModel: model,
        },
      });
  }

  submit = () => {
    const { complementaryData } = this.state;
    const { checked } = this.props;
    if (!complementaryData["initialDate"] || !complementaryData["lastDate"]) {
      this.props.showToast("Ops!", "Você precisa escolher uma data!");
    } else if (complementaryData["selectedModel"] === null) {
      this.props.showToast(
        "Ops!",
        "Você precisa selecionar no mínimo um modelo de cilindro!"
      );
    } else if (complementaryData.informacoes.length < 1) {
      this.props.showToast(
        "Ops!",
        "Você precisa descrever o motivo da solicitação!"
      );
    } else if (!checked) this.modalConfirm && this.modalConfirm.open();
    else this.generateOrder();
  };

  generateOrder = () => {
    const {
      complementaryData: { initialDate, lastDate, selectedModel, informacoes },
    } = this.state;
    const { onToggle } = this.props;
    onToggle &&
      onToggle({
        itCodigo: selectedModel,
        dtInicio: initialDate.format("YYYY/MM/DD"),
        dtFim: lastDate.format("YYYY/MM/DD"),
        informacoes: informacoes,
      });
  };

  render() {
    const { complementaryData, selectedCondominio } = this.state;
    const { checked, rawData } = this.props;
    const { credito } = ClienteService.localEntrega || {};
    const { situacao } = CondominioService.selected || {};
    return (
      <>
        <h2 className="service-detail-title">{rawData.title}</h2>
        <p style={{ textAlign: "justify" }}>{rawData.description}</p>

        {selectedCondominio === "all" && (
          <>
            <SelectAddressList
              onSubmit={(address) =>
                this.setState({
                  selectedCondominio: address,
                })
              }
            />
          </>
        )}
        {selectedCondominio !== "all" &&
          situacao !== 2 &&
          credito !== "Suspenso" && (
            <>
              <h3>Período (obrigatório)</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  locale={moment.locale("pt-br")}
                >
                  <DatePicker
                    locale="pt_BR"
                    style={{ margin: "1rem auto" }}
                    cancelLabel="Cancelar"
                    onChange={(date) => {
                      this.setState({
                        complementaryData: {
                          ...complementaryData,
                          initialDate: date,
                        },
                      });
                    }}
                    shouldDisableDate={(momentDate) =>
                      momentDate.day() === 0 || momentDate.day() === 6
                    }
                    labelFunc={() =>
                      this.formattedInitialDate || "Data inicial"
                    }
                  />
                  <DatePicker
                    locale="pt_BR"
                    style={{ margin: "1rem auto" }}
                    cancelLabel="Cancelar"
                    onChange={(date) => {
                      this.setState({
                        complementaryData: {
                          ...complementaryData,
                          lastDate: date,
                        },
                      });
                    }}
                    shouldDisableDate={(momentDate) =>
                      momentDate < complementaryData["initialDate"] ||
                      momentDate.day() === 0 ||
                      momentDate.day() === 6
                    }
                    labelFunc={() => this.formattedLastDate || "Data final"}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <h3>
                Produtos que terão o fornecimento interrompido (obrigatório)
              </h3>
              {(selectedCondominio.Itens || []).map((value) => {
                return (
                  <label>
                    <input
                      type="checkbox"
                      className={
                        complementaryData["selectedModel"] ===
                        value["it-codigo"]
                          ? "checked"
                          : ""
                      }
                      onClick={() => this.toggleModel(value["it-codigo"])}
                    />
                    {value["desc-comercial"]}
                  </label>
                );
              })}
              <br></br>
              <h3>Detalhamento da solicitação (obrigatório)</h3>
              <textarea
                style={{ width: "100%" }}
                value={complementaryData.informacoes || ""}
                placeholder="Detalhe sua solicitação aqui"
                maxLength={200}
                onChange={(e) =>
                  this.setState({
                    complementaryData: {
                      ...complementaryData,
                      informacoes: e.target.value,
                    },
                  })
                }
                disabled={checked}
              />
              <footer className="service-detail-footer">
                <button className="block" onClick={() => this.submit()}>
                  {checked ? "Cancelar contratação" : "Contratar"}
                </button>
              </footer>
            </>
          )}

        <Modal
          shown={false}
          ref={(ref) => (this.modalConfirm = ref)}
          title="Confirmar solicitação"
          declineButton={true}
          declineButtonText="Cancelar"
          confirmButton={true}
          confirmButtonText={"Ok"}
          confirmButtonAction={() => this.generateOrder()}
        >
          <p style={{ textAlign: "justify" }}>
            O abastecimento dos produtos abaixo será interrompido dentro do
            período especificado. Deseja confirmar o envio da solicitação?
          </p>
          <div
            className="border-info"
            style={{
              borderRadius: 0,
              padding: ".5rem",
            }}
          >
            <p style={{ margin: 0 }}>
              <b>Período</b>
            </p>
            {this.formattedInitialDate} - {this.formattedLastDate}
          </div>
          <br />
          <div
            className="border-info"
            style={{
              borderRadius: 0,
              padding: ".5rem",
            }}
          >
            <p style={{ margin: 0 }}>
              <b>Produtos</b>
            </p>
            {((selectedCondominio || {}).Itens || [])
              .filter(
                (item) =>
                  item["it-codigo"] === complementaryData["selectedModel"]
              )
              .map((item) => (
                <p style={{ margin: 0 }}>{item["desc-comercial"]}</p>
              ))}
          </div>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(CollectiveVacation);
