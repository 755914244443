import React, { Component } from "react";

import apiContext from "../../api";

import Page, { redirect } from "../../components/Page/Page";
import PageCenterContent from "../../components/PageCenterContent/PageCenterContent";
import PageHeaderImage from "../../components/PageHeaderImage/PageHeaderImage";
import Select from "../../components/Select/Select";
import {
  maskPhoneNumber,
  validatePhoneNumber,
  maskCpfCnpj,
  validateCpf,
  toTitleCase,
  validateEmailAddress,
} from "../../components/Masks/Masks";

import ProfileImage from "../../assets/user.svg";
import TermOfUseModal from "../../components/TermsOfUseModal/TermOfUseModal";
import ClienteService from "../../services/cliente";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";

class NewIndustrialClient extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    let name = "";
    let department = "";
    let phoneNumber = "";
    let emailAddress = "";
    if (props.location && props.location.state)
      name = props.location.state.name || name;

    const lastAccess = ClienteService.industrialContato;
    if (lastAccess) {
      name = lastAccess.name;
      department = lastAccess.department;
      phoneNumber = lastAccess.phoneNumber;
      emailAddress = lastAccess.emailAddress;
    }

    this.state = {
      phoneNumber,
      emailAddress,
      isEmailAddressValid: validateEmailAddress(emailAddress),
      isPhoneNumberValid: validatePhoneNumber(phoneNumber),
      name,
      isNameValid: name ? true : false,
      department,
      contactTypes: null,
      contactTypesLoaded: false,
    };
  }

  componentDidMount() {
    this.context.ConsigazApi.tiposContato().then(
      ({ Retorno }) => {
        if (Retorno && Retorno.length) {
          const contactTypes = Retorno[0].descricao.split(",");
          this.setState({
            contactTypesLoaded: true,
            contactTypes,
            department:
              contactTypes && contactTypes.length > 0 && contactTypes[0],
            isDepartmentValid: contactTypes && contactTypes.length > 0,
          });
        } else
          this.props.showToast("Ops", "Não foram encontrados tipos de contato");
      },
      (error) => {
        this.setState({
          ...this.state,
          contactTypesLoaded: false,
        })
        this.props.showToast("Ops", "Erro ao buscar os tipos de contato");
      }
    );
  }

  handleNameChange = (e) => {
    const name = e.target.value;

    this.setState({
      name,
      isNameValid: name !== "",
    });
  };

  handleCpfChange = (e) => {
    const cpf = e.target.value.replace(/[^\d]+/g, "");

    this.setState({
      cpf: cpf,
      isCpfValid: validateCpf(cpf),
    });
  };

  handleDepartmentChange = (e) => {
    const department = e.target.value;

    this.setState({
      department: department,
      isDepartmentValid: department !== "",
    });
  };

  handlePhoneNumberChange = (e) => {
    const phoneNumberMasked = maskPhoneNumber(e.target.value);

    this.setState({
      phoneNumber: phoneNumberMasked,
      isPhoneNumberValid: validatePhoneNumber(phoneNumberMasked),
    });
  };

  handleEmailAddressChange = (e) => {
    const emailAddress = e.target.value;

    this.setState({
      emailAddress,
      isEmailAddressValid: validateEmailAddress(emailAddress),
    });
  };

  handleSubmit = () => {
    const { name, cpf, department, phoneNumber, emailAddress } = this.state;

    const isNameValid = name ? true : false;
    const isCpfValid = validateCpf(cpf);
    const isDepartmentValid = department ? true : false;
    const isPhoneNumberValid = validatePhoneNumber(phoneNumber);

    if (isNameValid && isCpfValid && isDepartmentValid && isPhoneNumberValid && emailAddress) {
        const { industrialClientInfos } = this.props.location.state;
        ClienteService.localEntrega = industrialClientInfos.cliente;
      const { [`cod-emitente`]: codEmitente } =
        ClienteService.localEntrega || {};

      this.context.ConsigazApi.verificaAceiteTermo(codEmitente, cpf).then(
        ({ Retorno }) => {
          if (Retorno && Retorno.length) {
            if (Retorno[0].situacao === "OK") {
              this.handleManutencaoContato();
            } else {
              this.termsModal && this.termsModal.open();
            }
          }
        }
      );
    }

    this.setState({
      haveSubmited: true,
      isNameValid,
      isCpfValid,
      isDepartmentValid,
      isPhoneNumberValid,
    });
  };

  handleAcceptedTerms = () => {
    const { cpf } = this.state;

    const { [`cod-emitente`]: codEmitente } = ClienteService.localEntrega || {};

    this.context.ConsigazApi.aceiteTermo(codEmitente, cpf).then(
      () => {
        this.handleManutencaoContato();
      },
      (error) => this.props.showToast("Ops", error)
    );
  };

  handleManutencaoContato() {
    const { cpf, name, phoneNumber, department, emailAddress } = this.state;
    const { CPFCNPJ } = ClienteService.localEntrega || {};

    const saveData = (sequencia = undefined) => {
      this.context.ConsigazApi.manutencaoContatos(
        CPFCNPJ,
        cpf,
        name,
        phoneNumber,
        emailAddress,
        sequencia,
        department
      ).then(
        ({ ClienteRetorno }) => {
          if (ClienteRetorno && ClienteRetorno.length) {
            this.saveDataToStorage(ClienteRetorno[0].sequencia || sequencia);
            redirect(this.props, "/address-select");
          } else {
            this.props.showToast(
              "Ops",
              "Não foi possível atualizar os dados de contato."
            );
          }
        },
        (error) => this.props.showToast("Ops", error)
      );
    };

    this.context.ConsigazApi.consultaContatos(CPFCNPJ).then(
      ({ Cliente }) => {
        if (Cliente && Cliente.ClienteRetorno && Cliente.ClienteRetorno.length)
          if (Cliente.ClienteRetorno[0].ClienteContatoRetorno) {
            const contatos = Cliente.ClienteRetorno[0].ClienteContatoRetorno;

            const filtered = contatos.filter((c) => c["cpf-contato"] === cpf);

            if (filtered.length) {
              saveData(filtered[0].sequencia);
              return;
            }
          }
        saveData();
      },
      (error) => this.props.showToast("Ops", error)
    );
  }

  saveDataToStorage = (sequencia) => {
    const { name, cpf, phoneNumber, department, emailAddress } = this.state;

    ClienteService.industrialContato = {
      sequencia,
      name,
      cpf,
      phoneNumber,
      department,
      email: emailAddress,
    };
  };

  render() {
    const {
      contactTypes,
      haveSubmited,
      name,
      isNameValid,
      cpf,
      isCpfValid,
      department,
      phoneNumber,
      isPhoneNumberValid,
      emailAddress,
      isEmailAddressValid,
    } = this.state;
    return (
      <Page history={this.props.history}>
        <PageCenterContent>
          <PageHeaderImage src={ProfileImage} />

          <h1>Identifique-se!</h1>
          <p>
            Identifique-se abaixo.
            <br /> Tenha em mente que esses serão os dados utilizados na
            contratação
            <br /> de novos serviços e solicitações de abastecimento para esse
            endereço.
            <br /> O preenchimento de todos os campos é obrigatório.
          </p>

          {!this.state.contactTypesLoaded && <h2>Aguarde, carregando...</h2>}
          
          <input
            type="text"
            className={`block ${!isNameValid && haveSubmited ? "error" : ""}`}
            placeholder="Nome completo"
            value={name}
            onChange={this.handleNameChange}
            disabled={!this.state.contactTypesLoaded}
          />

          <br />

          <input
            type="text"
            className={`block ${!isCpfValid && haveSubmited ? "error" : ""}`}
            placeholder="CPF"
            value={maskCpfCnpj(cpf || "")}
            onChange={this.handleCpfChange}
            disabled={!this.state.contactTypesLoaded}
          />

          <br />

          <Select
            className="block"
            titleSelect="Selecione o departamento"
            options={(contactTypes || []).map((contactType) => ({
              title: toTitleCase(contactType),
              value: contactType,
            }))}
            placeholder="Departamento"
            value={department}
            onChange={this.handleDepartmentChange}
            style={{ 'text-align-last': 'center' }}
            disabled={!this.state.contactTypesLoaded}
          />

          <br />

          <input
            type="text"
            className={`block ${
              !isPhoneNumberValid && haveSubmited ? "error" : ""
            }`}
            placeholder="Telefone"
            value={phoneNumber}
            onChange={this.handlePhoneNumberChange}
            disabled={!this.state.contactTypesLoaded}
          />

          <br />

          <input
            type="text"
            className={`block ${
              !isEmailAddressValid && haveSubmited ? "error" : ""
            }`}
            placeholder="Email"
            value={emailAddress}
            onChange={this.handleEmailAddressChange}
            disabled={!this.state.contactTypesLoaded}
          />

          <br />

          <button
            to="/address-search"
            type="submit"
            className="block"
            onClick={this.handleSubmit}
            disabled={!this.state.contactTypesLoaded}
          >
            Salvar
          </button>
        </PageCenterContent>
        <TermOfUseModal
          ref={(ref) => (this.termsModal = ref)}
          title="Termos de Uso"
          shown={false}
          confirmButton={true}
          confirmButtonText="Aceitar"
          confirmButtonAction={this.handleAcceptedTerms}
        >
          <iframe
            title="Terms"
            src={`/termo.html`}
            style={{
              border: "0",
              width: "100%",
              maxHeight: "500px",
              height: "50vh",
            }}
          ></iframe>
        </TermOfUseModal>
      </Page>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(NewIndustrialClient);
