import React from 'react'

import './Page.scss'

import FooterImage from '../../assets/footer.png'
import LogoImage from '../../assets/logo.png'
import { Redirect } from 'react-router-dom'
import { Component } from 'react'
import Toast, { LoadingToast } from '../Toast/Toast'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toastOperations } from '../../state/ducks/toast'

export const redirect = (props, redirectTo, redirectState = {}) => {
  const { history } = props

  if (history && redirectTo) {
    Object.assign(redirectState, {
      from: history.location && history.location.pathname
    })

    history.push(redirectTo, redirectState)
  }
}

class Page extends Component {

  constructor(props) {
    super(props)

    this.state = {
      pathname: null,
      toastTitle: props.toast && props.toast.title,
      toastDescription: props.toast && props.toast.description,
      toastTimeout: props.toast && props.toast.timeout,
      isLoading: props.loading
    }
  }

  componentDidUpdate() {
    const { toast, loading, history } = this.props

    const newState = {}

    if (toast && this.state.toastDescription !== toast.description)
      Object.assign(newState, {
        toastTitle: toast && toast.title,
        toastDescription: toast && toast.description,
        toastTimeout: toast && toast.timeout,
      })

    if (loading !== undefined && loading !== this.state.isLoading)
      Object.assign(newState, { isLoading: loading })

    if (history && history.location.pathname !== this.state.pathname)
      Object.assign(newState, { pathname: history.location.pathname, isLoading: false })

    if (Object.keys(newState).length > 0)
      this.setState(newState)
  }

  render() {
    const { toastTitle, toastDescription, toastTimeout } = this.state
    const {
      whiteBackground,
      className,
      style,
      children,
      redirectTo,
      redirectFrom,
      redirectState,
      loading
    } = this.props

    return (
      <div
        className={[
          'page',
          whiteBackground && 'white-background',
          className
        ].filter(c => c).join(' ')}
        style={style}>
        {children}
        {!whiteBackground && <>
          <img
            src={FooterImage}
            alt="footer"
            className="footer-image" />
          <img
            src={LogoImage}
            alt="Logo"
            className="footer-logo-image" />
        </>}
        {redirectTo &&
          <Redirect
            from={redirectFrom}
            to={redirectTo}
            state={redirectState}/>}
        {toastDescription &&
          <Toast
            title={toastTitle}
            description={toastDescription}
            dismissTimeout={toastTimeout}
            autodismiss={true}
            onUnmount={() => this.props.dismissToast()} />}
        {loading && <LoadingToast />}
      </div>
    )
  }
}

function mapStateToProps({ toast, loading }) {
  return {
    toast,
    loading
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    dismissToast: toastOperations.dismiss
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Page)