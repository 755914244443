import React, { Component } from "react";
import PropTypes from "prop-types";

import "./Card.scss";

const getSolidStyle = (baseColor, textColor, gradient) => ({
  [`--color`]: textColor,
  [`--bg-color`]: baseColor,
  [`--bg-gradient`]: gradient,
});
const getOutlineStyle = (baseColor, textColor, customStyle = {}) => ({
  [`--color`]: textColor,
  [`--bg-color`]: "#fff",
  [`--bg-gradient`]: "#fff",
  [`--border-color`]: baseColor,
  borderWidth: "1px",
  borderColor: "var(--border-color)",
  borderStyle: "solid",
  ...customStyle,
});
const getToplineStyle = (baseColor, textColor) => ({
  [`--color`]: textColor,
  [`--bg-color`]: "#fff",
  [`--bg-gradient`]: "#fff",
  [`--border-color`]: baseColor,
  borderTopWidth: "3px",
});

const cardStyles = {
  primary: getSolidStyle(
    "#2065a8",
    "#fff",
    "linear-gradient(33deg, rgba(11,50,100,1) 0%, rgba(32,101,168,1) 100%)"
  ),
  outlineWhite: getOutlineStyle("#FFF", "#FFF", {
    backgroundColor: "transparent",
  }),
  outlinePrimary: getOutlineStyle("var(--light-blue)", "#000"),
  outlinePrimaryClear: getOutlineStyle("var(--light-blue)", "#FFF", {
    backgroundColor: "transparent",
  }),
  toplinePrimary: getToplineStyle("var(--light-blue)", "#000"),
  danger: getSolidStyle("#dc3e31", "#fff", "#dc3e31"),
  ontlineDanger: getOutlineStyle("#dc3e31", "#000"),
  toplineDanger: getToplineStyle("#dc3e31", "#000"),
  warning: getSolidStyle("#ff8f3b", "#fff", "#ff8f3b"),
  ontlineWarning: getOutlineStyle("#ff8f3b", "#000"),
  toplineWarning: getToplineStyle("#ff8f3b", "#000"),
  toplineCancel: getToplineStyle("#808080", "#000"),
  success: getSolidStyle("#00a23b", "#fff", "#00a23b"),
  ontlineSuccess: getOutlineStyle("#00a23b", "#000"),
  toplineSuccess: getToplineStyle("#00a23b", "#000"),
  ontlineSecondary: getOutlineStyle("#999", "#333"),
  outlineSecondaryClear: getOutlineStyle("#999", "#666", {
    backgroundColor: "transparent",
  }),
  outlineSecondaryDashed: {
    [`--color`]: "#000",
    [`--bg-color`]: "#fff",
    [`--bg-gradient`]: "#fff",
    [`--border-color`]: "#999",
    borderStyle: "dashed",
    borderWidth: "1px",
  },
  clear: {
    [`--color`]: "#000",
    [`--bg-color`]: "#fff",
    [`--bg-gradient`]: "#fff",
  },
};

export const getStyle = (key) => cardStyles[key || "primary"];

export default class Card extends Component {
  static propTypes = {
    styleName: PropTypes.oneOf(Object.keys(cardStyles)),
    children: PropTypes.node,
    footerButtons: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      })
    ),
    className: PropTypes.string,
  };

  get className() {
    return `card ${this.props.className}`;
  }

  renderTitle() {
    const { title } = this.props;

    if (title) return <p className="title">{title}</p>;
  }

  renderFooterButtons() {
    const { footerButtons } = this.props;

    if (footerButtons)
      return (
        <div className="footer-buttons">
          {footerButtons.map((btn, idx) => (
            <button
              key={`card-footer-button-${idx}`}
              onClick={btn.onClick}
              disabled={btn.disabled}
            >
              {btn.label}
            </button>
          ))}
        </div>
      );
  }

  render() {
    const { children, style, onClick } = this.props;
    const cardStyle = cardStyles[this.props.styleName || "primary"];

    return (
      <div
        className={this.className}
        style={{
          ...cardStyle,
          ...style,
        }}
        onClick={onClick}
      >
        {this.renderTitle()}
        {children}
        {this.renderFooterButtons()}
      </div>
    );
  }
}
