import React from "react";
import apiContext from "../../../api";
import { Component } from "react";
import Modal from "../../../components/Modal/Modal";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../../state/ducks/loading";
import "./ServiceDetail.scss";
import CondominioService from "../../../services/condominio";
import SelectAddressList from "../SelectAddressList";

class DuplicateContract extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      complementaryData: [],
      haveSubmited: false,
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
    };
  }

  submit = () => {
    const {
      complementaryData: { informacoes },
    } = this.state;
    if (!informacoes)
      this.props.showToast(
        "Ops!",
        "Você deve inserir o motivo da sua solicitação."
      );
    else this.confirmationModal && this.confirmationModal.open();
  };

  generateOrder = () => {
    const {
      complementaryData: { informacoes },
      selectedCondominio,
      selectedAddress,
    } = this.state;

    this.priorityModal && this.priorityModal.close();

    const { onToggle } = this.props;

    if (selectedCondominio === "all") {
      if (
        CondominioService.selectMany &&
        CondominioService.selectMany.length > 0
      ) {
        CondominioService.selectMany &&
          CondominioService.selectMany.map((teste) =>
            selectedAddress.push(teste)
          );
        CondominioService.selectMany = selectedAddress;
      } else CondominioService.selectMany = selectedAddress;
    }
    this.setState({ haveSubmited: false });

    onToggle &&
      onToggle({
        informacoes: informacoes,
      });
  };

  render() {
    const { complementaryData, selectedCondominio, haveSubmited } = this.state;
    const { checked, rawData } = this.props;

    return (
      <>
        {selectedCondominio === "all" && haveSubmited === true && (
          <>
            <SelectAddressList
              onSubmit={(address) =>
                this.setState(
                  {
                    selectedAddress: address,
                  },
                  () => this.generateOrder()
                )
              }
              cardClass={"service"}
              service={rawData}
            />
          </>
        )}

        {haveSubmited === false && (
          <>
            <h2 className="service-detail-title">{rawData.title}</h2>
            <p>
              <b>
                Por favor, insira no campo abaixo o motivo da sua solicitação
              </b>
              <br />
              Coloque o maior número de informações possíveis. Assim,
              garantiremos maior agilidade em seu atendimento!
            </p>
            <>
              <textarea
                style={{ width: "100%" }}
                maxLength={200}
                value={complementaryData.informacoes || ""}
                onChange={(e) =>
                  this.setState({
                    complementaryData: {
                      ...complementaryData,
                      informacoes: e.target.value,
                    },
                  })
                }
              />
            </>

            <footer className="service-detail-footer">
              <button
                className="block"
                onClick={() => {
                  !checked ? this.submit() : this.generateOrder();
                }}
              >
                {!checked ? "Selecionar" : "Desselecionar"}
              </button>
            </footer>
          </>
        )}

        <Modal
          shown={false}
          ref={(ref) => (this.confirmationModal = ref)}
          title="Fique atento!"
          confirmButton={true}
          confirmButtonText={"Ok"}
          confirmButtonAction={() => {
            selectedCondominio === "all"
              ? this.setState(
                  { haveSubmited: true },
                  this.confirmationModal && this.confirmationModal.close()
                )
              : this.generateOrder();
          }}
        >
          <p style={{ textAlign: "justify" }}>
            Sua solicitação será encaminhada para o departamento responsável
            avaliar. Daremos retorno em até: {rawData.deadline}.
          </p>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(DuplicateContract);
