import React from "react";
import apiContext from "../../../api";
import { Component } from "react";
import Modal from "../../../components/Modal/Modal";
import WarningImage from "../../../assets/illustrations/warning.png";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../../state/ducks/loading";

import "./ServiceDetail.scss";

class RegulationDetail extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      complementaryData: this.initialComplementaryData,
    };
  }

  get initialComplementaryData() {
    return {
      isReady: null,
    };
  }

  submit = () => {
    const { complementaryData } = this.state;
    const { checked } = this.props;

    if (!checked) {
      // if (complementaryData["tipo-vazamento"] === "Desconhecido") {
      //   if (!haveSubmited) {
      //     this.billingIfEnterWarningModal &&
      //       this.billingIfEnterWarningModal.open();
      //     this.setState({ haveSubmited: true });
      //     return;
      //   } else {
      //     this.priorityModal && this.priorityModal.open();
      //     return;
      //   }
      // }
    }

    if (complementaryData.isReady === false) {
      this.assistanceModal && this.assistanceModal.open();
    } else {
      this.confirmationModal && this.confirmationModal.open();
    }
  };

  generateOrder = () => {
    const {
      complementaryData: { isReady, informacoes },
    } = this.state;
    const { onToggle } = this.props;

    this.priorityModal && this.priorityModal.close();
    onToggle &&
      onToggle({
        informacoes: `O fogão ${
          isReady ? "está" : "não está"
        } preparado para GLP. ${informacoes}`,
      });
  };

  render() {
    const { complementaryData } = this.state;
    const { checked, onWarranty, rawData } = this.props;

    return (
      <>
        <h2 className="service-detail-title">{rawData.title}</h2>
        <p>
          <b>
            Os registros interno e externo (próximo ao medidor) estão abertos?
          </b>
        </p>
        <p>
          Observe a imagem abaixo e verifique se os seus registros estão abertos
          . Caso não estejam, você poderá abri-los.
        </p>

        {rawData.image && (
          <img
            alt="service"
            src={rawData.image}
            style={{
              maxHeight: "300px",
              maxWidth: "100%",
            }}
          />
        )}

        <p>
          <b>O seu fogão está preparado para a utilização do GLP?</b>
        </p>

        <label>
          <input
            type="radio"
            name="is-ready"
            checked={complementaryData.isReady === false}
            className={complementaryData.isReady === false && "checked"}
            disabled={checked}
            onChange={(e) =>
              this.setState({
                complementaryData: {
                  ...complementaryData,
                  isReady: false,
                },
              })
            }
          />
          Não
        </label>
        <label>
          <input
            type="radio"
            name="is-ready"
            checked={complementaryData.isReady === true}
            className={complementaryData.isReady === true && "checked"}
            disabled={checked}
            onChange={(e) =>
              this.setState({
                complementaryData: {
                  ...complementaryData,
                  isReady: true,
                },
              })
            }
          />
          Sim
        </label>

        <p>
          Caso o seu fogão tenha sido convertido para a utilização de gás
          natural, será necessário contatar uma assistência técnica
          especializada para desfazê-la.
        </p>

        {complementaryData.isReady && (
          <>
            <p>
              <b>Use o campo para detalhar</b>
            </p>
            <textarea
              style={{ width: "100%" }}
              value={complementaryData.informacoes || ""}
              onChange={(e) =>
                this.setState({
                  complementaryData: {
                    ...complementaryData,
                    informacoes: e.target.value,
                  },
                })
              }
            />
          </>
        )}

        <footer className="service-detail-footer">
          <button onClick={onWarranty} className="inline-link">
            Termos de garantia
          </button>
          <button
            className="block"
            onClick={this.submit}
            disabled={complementaryData.isReady === null}
          >
            {checked ? "Desselecionar" : "Selecionar"}
          </button>
        </footer>
        <Modal
          shown={false}
          ref={(ref) => (this.assistanceModal = ref)}
          title="Fique atento!"
          imgSrc={WarningImage}
          closeButton={true}
          confirmButton={true}
          confirmButtonText={"ok"}
        >
          Neste caso será necessário que você contrate uma assistência técnica
          especializada.
        </Modal>
        <Modal
          shown={false}
          ref={(ref) => (this.confirmationModal = ref)}
          title="Ordem de Serviço"
          imgSrc={WarningImage}
          closeButton={true}
          confirmButton={true}
          confirmButtonText="Confirmar"
          confirmButtonAction={(modal) => {
            modal.close();
            this.generateOrder();
          }}
          declineButton={true}
          declineButtonText="Desistir da contratação"
        >
          Será aberta uma ordem de serviço.
          <ul style={{ textAlign: "left" }}>
            <li>O serviço na parte externa é gratuito.</li>
            <li>
              O morador deve estar no apartamento para atestar se o serviço foi
              executado corretamente.
            </li>
            <li>
              Caso haja necessidade de uma vistoria técnica dentro do
              apartamento, será cobrada uma taxa de <b>R$ 150,00</b>
            </li>
          </ul>
          Ao confirmar você está autorizando a cobrança do serviço!
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(RegulationDetail);
