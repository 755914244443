import React from "react";
import { IconLocation, IconForwardArrow } from "../Icons/Icons";

export const SearchOption = (props) => (
  <li
    onClick={() => props.onClick(props.value)}
    style={{
      display: "grid",
      gridTemplateRows: "50px",
      gridTemplateColumns: "50px 250px 50px",
      padding: ".3rem 0",
      borderTop: "1px solid lightgrey",
      cursor: "pointer"
    }}
  >
    <div>
      {props.leftIcon ? (
        <props.leftIcon
          color="#444"
          size={18}
          style={{ display: "block", margin: "13px auto" }}
        />
      ) : (
        <IconLocation
          color="#444"
          size={18}
          style={{ display: "block", margin: "13px auto" }}
        />
      )}
    </div>
    <div style={{ padding: "5px" }}>
      <p
        style={{
          fontSize: "14px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          marginBottom: 0,
          lineHeight: 1.4,
        }}
      >
        {props.title}
      </p>
      <p
        style={{
          fontSize: "12px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          marginBottom: 0,
          lineHeight: 1.4,
          opacity: ".7",
        }}
      >
        {props.description}
      </p>
    </div>
    <div>
      {props.showRightIcon && (
        <IconForwardArrow
          color="#444"
          size={22}
          style={{ display: "block", margin: "10px auto" }}
        />
      )}
    </div>
  </li>
);

export const SearchOptions = (props) => (
  <ul
    style={{
      width: "350px",
      background: "#fff",
      display: "inline-block",
      margin: 0,
      marginTop: "1.5rem",
      padding: 0,
      color: "#000",
      textAlign: "left",
      maxHeight: "200px",
      overflowY: "auto",
      overflowX: "hidden",
      ...(props.style || {}),
    }}
  >
    {(props.options || []).map((opt, idx) => (
      <SearchOption
        leftIcon={props.itemLeftIcon}
        showRightIcon={props.showItemRightIcon}
        key={`search-opt-${idx}`}
        {...opt}
      />
    ))}
    {props.showEmptyMessage &&
      <p style={{ padding: '.3rem', margin: 0, textAlign: 'center', opacity: .5 }}>Não foram encontrados resultados</p>}
  </ul>
);
