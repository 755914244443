import React from "react";
import apiContext from "../../../api";
import Select from "../../../components/Select/Select";
import { IconCheck } from "../../../components/Icons/Icons";
import { Component } from "react";
import Modal from "../../../components/Modal/Modal";
import WarningImage from "../../../assets/illustrations/warning.png";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../../state/ducks/loading";

import "./ServiceDetail.scss";

class LeakageDetail extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      complementaryData: this.initialComplementaryData,
      haveSubmited: false,
    };
  }

  get isValid() {
    const { complementaryData } = this.state;
    return (
      (complementaryData["tipo-vazamento"] &&
        complementaryData["local-vazamento"] === null) ||
      (complementaryData["tipo-vazamento"] === "Desconhecido" &&
        complementaryData["local-identificado"] === false)
    );
  }

  get initialComplementaryData() {
    return {
      "tipo-vazamento": null,
      "local-vazamento": false,
      "local-identificado": null,
    };
  }

  submit = () => {
    const { complementaryData, haveSubmited } = this.state;
    const { checked } = this.props;

    if (!checked) {
      if (complementaryData["tipo-vazamento"] === "Desconhecido") {
        if (!haveSubmited) {
          this.billingIfEnterWarningModal &&
            this.billingIfEnterWarningModal.open();
          this.setState({ haveSubmited: true });
          return;
        } else {
          this.priorityModal && this.priorityModal.open();
          return;
        }
      }
    }

    this.setState({ haveSubmited: true }, this.generateOrder);
  };

  get value() {
    const {
      complementaryData: {
        "tipo-vazamento": tipoVazamento,
        "local-vazamento": localVazamento,
      },
    } = this.state;
    const { rawData } = this.props;

    if (tipoVazamento === "Interno" && localVazamento !== "prumada")
      return rawData.priceConditional;

    return null;
  }

  generateOrder = () => {
    const {
      complementaryData: {
        "tipo-vazamento": tipoVazamento,
        "local-vazamento": localVazamento,
        informacoes,
      },
    } = this.state;
    const { onToggle } = this.props;

    this.priorityModal && this.priorityModal.close();
    onToggle &&
      onToggle({
        tipoVazamento,
        localVazamento,
        informacoes,
        value: this.value,
      });
  };

  render() {
    const { complementaryData, haveSubmited } = this.state;
    const { checked, onWarranty } = this.props;

    return (
      <>
        <h2 className="service-detail-title">Vazamento</h2>
        {[
          "Feche o registro de gás.",
          "Afaste as pessoas do local.",
          "Não acione interruptores de eletricidade.",
          "Não fume nem acenda fósforos ou isqueiros.",
          "Abra portas e janelas.",
          "Em casos mais graves também entre em contato com o Corpo de Bombeiros (193)",
        ].map((item, idx) => (
          <p key={`leakage-service-item-${idx}`} style={{ textAlign: "left" }}>
            <IconCheck color="green" /> {item}
          </p>
        ))}
        <Select
          className="block"
          options={[
            {
              value: "Não selecionado",
              title: "Tipos de Vazamento",
            },
            {
              value: "Interno",
              title: "Dentro do imóvel",
            },
            {
              value: "Externo",
              title: "Fora do imóvel",
            },
            {
              value: "Desconhecido",
              title: "Não sei onde é",
            },
          ]}
          value={complementaryData["tipo-vazamento"]}
          onChange={(e) =>
            this.setState({
              complementaryData: {
                ...this.initialComplementaryData,
                "tipo-vazamento": e.target.value,
              },
              haveSubmited: false,
            })
          }
          disabled={checked}
        />
        <br />

        {complementaryData["tipo-vazamento"] === "Interno" && (
          <p style={{ textAlign: "justify" }}>
            A Consigaz não realiza manutenção em equipamentos domésticos como
            por exemplo fogões, chuveiros e aquecedores. Caso o problema seja em
            algum desses equipamentos, procure a assistência técnica dos mesmos.
            Os reparos na prumada (tubulação interna) do condomínio e das
            unidades cabem à construtora para condomínios com até 5 anos, e ao
            condomínio após este período.
          </p>
        )}

        {complementaryData["tipo-vazamento"] === "Externo" && (
          <p style={{ textAlign: "justify" }}>
            Os reparos na prumada (tubulação interna) do condomínio e das
            unidades cabem à construtora para condomínios com até 5 anos, e ao
            condomínio após este período.
          </p>
        )}

        {(complementaryData["tipo-vazamento"] === "Interno" ||
          complementaryData["tipo-vazamento"] === "Externo") && (
          <>
            <p>
              <b>O vazamento é na prumada (tubulação interna)?</b>
            </p>
            <label>
              <input
                type="radio"
                name="local-vazamento"
                // checked={complementaryData["local-vazamento"] === null}
                className={
                  (complementaryData["local-vazamento"] === null) && "checked"
                }
                onChange={(e) =>
                  this.setState(
                    {
                      complementaryData: {
                        ...complementaryData,
                        "local-vazamento": null,
                      },
                    },
                    () => {
                      if (complementaryData["tipo-vazamento"] === "Interno")
                        this.billingWarningModal &&
                          this.billingWarningModal.open();
                      else
                        this.billingIfEnterWarningModal &&
                          this.billingIfEnterWarningModal.open();
                    }
                  )
                }
              />
              Não
            </label>
            <label>
              <input
                type="radio"
                name="local-vazamento"
                // checked={complementaryData["local-vazamento"] === "prumada"}
                className={
                  (complementaryData["local-vazamento"] === "prumada") &&
                  "checked"
                }
                onChange={(e) =>
                  this.setState(
                    {
                      complementaryData: {
                        ...complementaryData,
                        "local-vazamento": "prumada",
                      },
                    },
                    () =>
                      this.legalRepresentantModal &&
                      this.legalRepresentantModal.open()
                  )
                }
              />
              Sim
            </label>
          </>
        )}

        {complementaryData["tipo-vazamento"] === "Desconhecido" && (
          <p style={{ textAlign: "justify" }}>
            Para confirmar se realmente há vazamento ou o local do problema,
            passe uma esponja com água e sabão na superfície suspeita. O
            aparecimento de bolhas indicará a existência de vazamento.
          </p>
        )}

        {complementaryData["tipo-vazamento"] === "Desconhecido" &&
          complementaryData["local-identificado"] !== true && (
            <>
              <p>
                <b>Conseguiu identificar onde é o vazamento?</b>
              </p>
              <label>
                <input
                  type="radio"
                  name="local-identificado"
                  checked={complementaryData["local-identificado"] === false}
                  className={
                    complementaryData["local-identificado"] === false &&
                    "checked"
                  }
                  onChange={(e) =>
                    this.setState({
                      complementaryData: {
                        ...complementaryData,
                        "local-identificado": false,
                      },
                    })
                  }
                />
                Não
              </label>
              <label>
                <input
                  type="radio"
                  name="local-identificado"
                  checked={complementaryData["local-identificado"] === true}
                  className={
                    complementaryData["local-identificado"] === true &&
                    "checked"
                  }
                  onChange={(e) =>
                    this.setState({
                      complementaryData: {
                        ...complementaryData,
                        "tipo-vazamento": "Não selecionado",
                        "local-identificado": true,
                      },
                    })
                  }
                />
                Sim
              </label>
            </>
          )}

        {complementaryData["tipo-vazamento"] === "Desconhecido" &&
          complementaryData["local-identificado"] === false && (
            <p style={{ textAlign: "justify" }}>
              A Consigaz não realiza manutenção em equipamentos domésticos como
              por exemplo fogões, chuveiros e aquecedores. Caso o problema seja
              em algum desses equipamentos, procure a assistência técnica dos
              mesmos. Os reparos na prumada (tubulação interna) do condomínio e
              das unidades cabem à construtora para condomínios com até 5 anos,
              e ao condomínio após este período.
            </p>
          )}

        {complementaryData["tipo-vazamento"] !== null && (
          <>
            <p>
              <b>Use o campo para detalhar</b>
            </p>
            <textarea
              style={{ width: "100%" }}
              value={complementaryData.informacoes || ""}
              onChange={(e) =>
                this.setState({
                  complementaryData: {
                    ...complementaryData,
                    informacoes: e.target.value,
                  },
                })
              }
            />
          </>
        )}

        <footer className="service-detail-footer">
          <button onClick={onWarranty} className="inline-link">
            Termos de garantia
          </button>
          <button
            className="block"
            onClick={this.submit}
            disabled={!this.isValid}
          >
            {checked
              ? "Desselecionar"
              : haveSubmited
              ? "Confirmar contratação"
              : "Selecionar"}
          </button>
        </footer>
        <Modal
          shown={false}
          ref={(ref) => (this.billingWarningModal = ref)}
          title="Fique atento!"
          imgSrc={WarningImage}
          confirmButton={true}
          confirmButtonText={"ok"}
        >
          Será cobrada a visita no valor de R$ 150,00 e caso seja necessário
          trocar alguma peça fora da garantia, esta também será cobrada.
        </Modal>
        <Modal
          shown={false}
          ref={(ref) => (this.billingIfEnterWarningModal = ref)}
          title="Fique atento!"
          imgSrc={WarningImage}
          confirmButton={true}
          confirmButtonText={"ok"}
        >
          Caso o técnico entre no imóvel, será cobrada a visita no valor de R$
          150,00 e caso seja necessário trocar alguma peça fora da garantia,
          esta também será cobrada.
        </Modal>
        <Modal
          shown={false}
          ref={(ref) => (this.legalRepresentantModal = ref)}
          title="Fique atento!"
          imgSrc={WarningImage}
          confirmButton={true}
          confirmButtonText={"ok"}
        >
          Você deve procurar o representante legal do condomínio.
        </Modal>
        <Modal
          shown={false}
          ref={(ref) => (this.priorityModal = ref)}
          title="Fique atento!"
          imgSrc={WarningImage}
          confirmButton={true}
          confirmButtonText={"ok"}
          confirmButtonAction={this.generateOrder}
        >
          Sua solicitação será atendida com prioridade. O técnico mais próximo
          será direcionado ao local e prestará o atendimento. A fatura será
          emitida após a visita do técnico.
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(LeakageDetail);
