import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './Modal.scss'

import { IconClose } from '../Icons/Icons'

export default class Modal extends Component {

  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    imgSrc: PropTypes.string,
    children: PropTypes.node,
    shown: PropTypes.bool,
    closeButton: PropTypes.bool,
    declineButton: PropTypes.bool,
    declineButtonAction: PropTypes.func,
    declineButtonText: PropTypes.string,
    confirmButton: PropTypes.bool,
    confirmButtonAction: PropTypes.func,
    onClose: PropTypes.func,
    confirmButtonText: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.state = {
      shown: props.shown !== undefined ? props.shown : true
    }
  }

  get className() {
    return [
      `modal`,
      this.props.className,
      this.state.shown ? 'shown' : 'hidden'
    ].filter(c => c).join(' ')
  }

  get backdropClassName() {
    return [
      `modal-backdrop`,
      this.state.shown ? 'shown' : 'hidden'
    ].filter(c => c).join(' ')
  }

  close = () => this.setState({ shown: false }, () => this.props.onDismiss && this.props.onDismiss())

  handleClose = () => {
    if(this.props.onClose) this.props.onClose();
    this.close();
  }
  
  open = () => this.setState({ shown: true })

  renderTitle() {
    const { title } = this.props

    if (title)
      return <h1>{title}</h1>
  }

  renderImg() {
    const { imgSrc } = this.props

    if (imgSrc)
      return (
        <>
          <br />
          <img src={imgSrc} height={140} alt="Modal img"/>
        </>
      )
  }

  renderCloseButton() {
    const { closeButton } = this.props

    if (closeButton)
      return (
        <IconClose
          data-testid="modal-close-button"
          className="close-button"
          size={32}
          color={'#444'}
          onClick={this.close}>
        </IconClose>
      )
  }

  renderFooterButtons() {
    const {
      declineButton,
      declineButtonAction,
      declineButtonText,
      confirmButton,
      confirmButtonAction,
      confirmButtonText
    } = this.props

    return (
      <>
        {declineButton &&
          <button
            className="link danger"
            onClick={() => declineButtonAction ? declineButtonAction(this) : this.close()}>
            {declineButtonText || 'Desistir'}
          </button>}
        {confirmButton &&
          <button
            className="link"
            onClick={() => confirmButtonAction ? confirmButtonAction(this) : this.close()}>
            {confirmButtonText || 'Confirmar'}
          </button>}
      </>
    )
  }

  render() {
    const {
      declineButton,
      confirmButton,
    } = this.props

    return <>
      <div
        className={this.backdropClassName}
        onClick={this.handleClose}>
        <div className={this.className} onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}>
          {this.renderCloseButton()}
          {this.renderTitle()}
          {this.props.children}
          {this.renderImg()}
          {(declineButton || confirmButton) &&
            <footer>
              {this.renderFooterButtons()}
            </footer>
          }
        </div>
      </div>
    </>
  }
}