import React, { Component } from "react";

import ClienteService from "../../services/cliente";

import Page, { redirect } from "../../components/Page/Page";
import PageCenterContent from "../../components/PageCenterContent/PageCenterContent";

import apiContext from "../../api";

import Modal from "../../components/Modal/Modal";

import BackgroundImage from "../../assets/background.jpg";
import { validateCpfCnpj, maskCpfCnpj } from "../../components/Masks/Masks";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { loadingOperations } from "../../state/ducks/loading";

class Wellcome extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      haveSubmited: false,
      cpfcnpj: "",
      isCpfcnpjValid: false,
      haveOpenedTheApp: null,
    };

    if (ClienteService.localEntrega !== undefined) redirect(props, "/home");
  }

  get existentClientRedirectPath() {
    const { haveOpenedTheApp, cpfcnpj } = this.state;

    if (haveOpenedTheApp === null)
      this.setState({
        haveOpenedTheApp: ClienteService.haveJoinedTheApp(cpfcnpj),
      });

    ClienteService.setHaveJoinedTheApp(cpfcnpj);

    return haveOpenedTheApp ? "/wellcome-again" : "/existent-client";
  }

  handleCpfcnpjChange = (e) => {
    const maskedCpfcnpj = maskCpfCnpj(e.target.value);

    this.setState({
      cpfcnpj: maskedCpfcnpj,
      isCpfcnpjValid: validateCpfCnpj(maskedCpfcnpj),
    });
  };

  onSubmit = () => {
    const { haveOpenedTheApp } = this.state;
    if (this.state.isCpfcnpjValid) {
      const cleanCpfcnpj = this.state.cpfcnpj.replace(/[^\d]+/g, "");

      //   if (cleanCpfcnpj == '01597589000209') // CNPJ da consigaz
      //     return redirect(this.props, '/admnistrative-login')

      this.props.showLoading();
      this.context.ConsigazApi.consultaLocalEntrega(cleanCpfcnpj).then(
        ({ Cliente, Erros }) => {
          this.props.dismissLoading();
          if (
            Cliente &&
            Cliente.ClienteRetorno &&
            Cliente.ClienteRetorno.length
          )
            if (Cliente.ClienteRetorno[0].segmento === "ADM")
              redirect(this.props, "/admnistrative-login");
            else if (
              ["CONTASIM", "INDUSTRIAL"].indexOf(
                Cliente.ClienteRetorno[0].segmento
              ) > -1
            ) {
              const { segmento } = Cliente.ClienteRetorno[0];
              redirect(
                this.props,
                segmento === "INDUSTRIAL"
                  ? "/wellcome-again"
                  : this.existentClientRedirectPath,
                {
                  cliente: Cliente.ClienteRetorno[0],
                  haveOpenedTheApp,
                }
              );
            } else {
              //   this.props.showToast(
              //     "Em breve!",
              //     "Estamos preparando uma novidade pra você e em breve ela estará disponível aqui! Você poderá consultar suas faturas e entregas, contratar serviços e muito mais!",
              //     10000
              //   );
              this.openModal();
            }
        },
        () => {
          this.context.CpfApi[
            cleanCpfcnpj.length <= 11 ? "validateCpf" : "validateCnpj"
          ](cleanCpfcnpj).then(
            ({ nome, situacao, situacaoCadastral, nomeFantasia, nomeEmpresarial }) => {
              this.props.dismissLoading();
              if (situacao || situacaoCadastral) {
                if (
                  (situacao?.codigo === "0") ||
                  (situacaoCadastral?.codigo === "2")
                ) {
                  ClienteService.newClienteData = {
                    cpfcnpj: cleanCpfcnpj,
                  };
                  redirect(this.props, "/new-client", {
                    name: nomeFantasia || nomeEmpresarial || nome,
                  });
                } else {
                  this.props.showToast(
                    "Ops",
                    `Não foi possível cadastrar o cliente, situação do CPF/CNPJ: ${
                      (situacao?.descricao) ||
                      (situacaoCadastral?.motivo)
                    }`
                  );
                }
              } else {
                this.props.showToast(
                  "Ops",
                  `Não foi possível identificar a situação do CPF/CNPJ informado.`
                );
              }
            },
            (error) => {
              this.props.dismissLoading();
              this.props.showToast("Ops", `CPF/CNPJ não encontrado.`);
            }
          );
        }
      );
    }
    this.setState({
      haveSubmited: true,
    });
  };

  openModal = () => this.modalRef && this.modalRef.open();

  render() {
    return (
      <>
        <Page
          history={this.props.history}
          style={{
            background: `var(--dark-blue) url(${BackgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundBlendMode: "multiply",
          }}
        >
          <PageCenterContent>
            <h1>Olá!</h1>
            <p>
              Que bom ter você aqui! Vamos começar! <br /> Insira abaixo seu CPF
              ou CNPJ para verificarmos se já possui cadastro em nosso sistema.
            </p>

            <br />
            <br />

            <input
              type="text"
              maxLength="18"
              className={`block ${
                !this.state.isCpfcnpjValid && this.state.haveSubmited
                  ? "error"
                  : ""
              }`}
              placeholder="000.000.000-00"
              value={this.state.cpfcnpj}
              onChange={this.handleCpfcnpjChange}
              onKeyPress={(e) => e.charCode === 13 && this.onSubmit()}
            />

            <br />

            <button type="submit" className="block" onClick={this.onSubmit}>
              Avançar
            </button>
          </PageCenterContent>
        </Page>
        <Modal
          shown={false}
          ref={(ref) => (this.modalRef = ref)}
          className="consumption-modal"
          title="Atenção!"
          closeButton={true}
        >
          <br />
          <p>
            Para acessar a plataforma, entre em contato com a central de
            atendimento.
          </p>
          <br />
          <p>
            Entre em contato conosco pelo Whatsapp. Caso não tenha instalado em
            seu smartphone, envie um e-mail que em breve entraremos em contato
            com você!
          </p>
          <div style={{ textAlign: "center" }}>
            <a
              href={`https://api.whatsapp.com/send?phone=5511997770000`}
              target="blank"
              className="block"
              style={{ background: "#00bd4c", borderColor: "#00bd4c" }}
            >
              Whatsapp
            </a>
            <br />
            <a
              href="mailto:cac@consigaz.com.br"
              target="blank"
              className="block outline"
              style={{ textTransform: "none", color: "#000" }}
            >
              cac@consigaz.com.br
            </a>
            <br />
            <h1>Ou pode nos ligar também</h1>

            <p>Telefones da CAC (Central de atendimento ao cliente)</p>

            <button className="block outline" style={{ color: "#000" }}>
              0800 770 4922
            </button>
            <br />
            <button className="block outline" style={{ color: "#000" }}>
              (11) 4197 9300
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Wellcome);
