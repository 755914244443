import React, { useState } from 'react';

export const CustomRadio = ({ options, onChange }) => {
  const [selectedOption, setSelectedOption] = useState({});

  const handleSelect = (option) => {
    setSelectedOption(option);
    onChange(option)
  };

  return (
    <div style={styles.container}>
      {options.map((option) => (
        <>
          <div
            key={option.id}
            onClick={() => handleSelect(option)}
            style={{
              ...styles.circle,
              border: selectedOption.id === option.id ? '6px solid #00c4ff' : '1px solid #929292',
            }}
          />
          <span 
            onClick={() => handleSelect(option)} style={{ cursor: "pointer" }}
          >
            {option.label}
          </span>
        </>
      ))}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  circle: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    border: '1px solid #929292',
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
};