const storage = localStorage;
export const setItem = (key, value) =>
  storage.setItem(key, JSON.stringify(value));

export const getItem = (key) => {
  const value = storage.getItem(key);
  if (value) {
    try {
      return JSON.parse(value);
    } catch (error) {
      // console.warn(`Storage key ${key} cannot be parsed`);
    }
  }

  return undefined;
};

export default class CondominioService {
  static get inRegistration() {
    return getItem("condominio_em_cadastro");
  }

  static set inRegistration(value) {
    return setItem("condominio_em_cadastro", value);
  }

  static consultaConcominioApiParser = (value) => {
    if (value.Retorno && value.Retorno.Condominio)
      CondominioService.inRegistration = value.Retorno.Condominio[0];

    return value;
  };

  // ----------------------------------

  static get selected() {
    return getItem("condominio_selected");
  }

  static set selected(value) {
    if (value) CondominioService.selectAll = undefined;
    return setItem("condominio_selected", value);
  }

  // ----------------------------------

  static get selectAll() {
    return getItem("condominio_select_all");
  }

  static set selectAll(value) {
    if (value) CondominioService.selected = undefined;
    return setItem("condominio_select_all", value);
  }

  static get selectMany() {
    return getItem("condominio_select_many");
  }

  static set selectMany(value) {
    if (value) CondominioService.selected = undefined;
    return setItem("condominio_select_many", value);
  }
}
