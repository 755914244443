import React, { Component } from 'react'

import ClienteService from '../../services/cliente'

import Page, { redirect } from '../../components/Page/Page'
import PageCenterContent from '../../components/PageCenterContent/PageCenterContent'

import apiContext from '../../api'

import BackgroundImage from '../../assets/background.jpg'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toastOperations } from '../../state/ducks/toast'
import { loadingOperations } from '../../state/ducks/loading'

class AdmnistrativeLogin extends Component {

  static contextType = apiContext

  constructor(props) {
    super(props)

    this.state = {
      haveSubmited: false,
      cpfcnpj: '',
      isCpfcnpjValid: false,
    }

    if (ClienteService.localEntrega !== undefined)
      redirect(props, '/home')
  }

  onSubmit = () => {
    const { login, pass } = this.state;

    if (login && pass) {
      // TODO: Remove for proper use
    //   if (login === "consigaz" && pass === '123')
    //     return redirect(this.props, '/fake-login')

      this.props.showLoading()
      this.context.ConsigazApi
        .admnistrativeLogin(login, pass)
        .then(
          ({ Retorno }) => {
            this.props.dismissLoading()

            if (Retorno && Retorno.length && Retorno[0].situacao === "NOK")
                this.props.showToast('Ops', Retorno[0].descricao)

            if (Retorno[0].situacao === "OK")
                redirect(this.props, '/fake-login')

          },
          () => {
            this.props.dismissLoading()
            this.props.showToast("Ops", "Usuário ou senha informados não estão corretos.")
          }
        )
    }

    this.setState({
      haveSubmited: true
    })
  }

  render() {
    const { login, pass } = this.state;

    return (
      <Page
        history={this.props.history}
        style={{
          background: `var(--dark-blue) url(${BackgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundBlendMode: 'multiply'
        }}>
        <PageCenterContent>
          <h1>Boas Vindas!</h1>
          <p>Você acessou o perfil de administrador, digite seu código ou login de acesso.</p>

          <br />
          <br />

          <input
            type="text"
            maxLength='18'
            className={`block ${!login && this.state.haveSubmited ? 'error' : ''}`}
            placeholder="Login"
            value={login || ''}
            onChange={e => this.setState({ login: e.target.value })}
            onKeyPress={e => e.charCode === 13 && this.onSubmit()}/>

          <br />

          <input
            type="password"
            maxLength='18'
            className={`block ${!pass && this.state.haveSubmited ? 'error' : ''}`}
            placeholder="Senha"
            value={pass || ''}
            onChange={e => this.setState({ pass: e.target.value })}
            onKeyPress={e => e.charCode === 13 && this.onSubmit()}/>

          <br />

          <button
            type="submit"
            className="block"
            onClick={this.onSubmit}>
            Entrar
          </button>
        </PageCenterContent>
      </Page>
    )
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    showToast: toastOperations.show,
    showLoading: loadingOperations.show,
    dismissLoading: loadingOperations.dismiss
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(AdmnistrativeLogin)