/* eslint-disable no-useless-escape */

export const maskPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";

  phoneNumber = `${+phoneNumber.replace(/[^\d]/g, "")}`;
  if (phoneNumber.length > 10) {
    return phoneNumber
      .replace(/[^\d]+/g, "")
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  } else {
    return phoneNumber
      .replace(/[^\d]+/g, "")
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  }
};

export const percentualFormat = (value) => {
  if (!value) return "";

  return (value = `${value
    .replace(/[^\d]+/g, "")
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1,$2")}%`);
};

export const timeFormat = (value) => {
  if (!value) return "";

  return (value = `${value.replace(/(\d{2})(\d)/, "$1:$2")}`);
};

export const validatePhoneNumber = (phoneNumber) => {
  const haveMoreThanOneNumber = !phoneNumber
    .replace(/[^\d]/g, "")
    .split("")
    .reduce(
      (reduced, value) => {
        if (reduced[0] === null) reduced[0] = value;
        else return [reduced[0], reduced[1] && reduced[0] === value];
        return reduced;
      },
      [null, true]
    )[1];

  return (
    (haveMoreThanOneNumber && (
      /^\((\d{2})\) (\d{4})-(\d{4})$/g.test(phoneNumber) ||
    /^\((\d{2})\) (\d{5})-(\d{4})$/g.test(phoneNumber)))
  );
};

export const maskCep = (cep) => {
  return cep
    .replace(/[^\d]+/g, "")
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+?$/, "$1");
};

export const validateCep = (cep) =>
  /^([\d]{2}).([\d]{3})-([\d]{3})$/.test(cep) && cep.length === 10;

export const validateEmailAddress = (emailAddress) =>
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
    emailAddress
  );

export const maskCpfCnpj = (value = "") => {
  if (value.length <= 14) {
    // Mask for cpf
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  } else {
    // Mask for cnpj
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  }
};
/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
export const validateCpf = function (cpf) {
  if (
    !cpf ||
    cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999"
  )
    return false;
  var soma = 0;
  var resto;
  for (var i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (var i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(10, 11))) return false;
  return true;
};
export const validateCnpj = function (cnpj) {
  if (
    !cnpj ||
    cnpj.length != 14 ||
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false;
  var tamanho = cnpj.length - 2;
  var numeros = cnpj.substring(0, tamanho);
  var digitos = cnpj.substring(tamanho);
  var soma = 0;
  var pos = tamanho - 7;
  for (var i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;
  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (var i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;
  return true;
};
/* eslint-enable no-redeclare */
/* eslint-enable eqeqeq */

export const validateCpfCnpj = (cpfcnpj) => {
  const cleanCpfcnpj = cpfcnpj.replace(/[^\d]+/g, "");
  if (cleanCpfcnpj.length <= 11) {
    return validateCpf(cleanCpfcnpj);
  } else {
    return validateCnpj(cleanCpfcnpj);
  }
};

/* eslint-enable no-useless-escape */

export const moneyFormat = (value) =>
  value
    ? `R$ ${parseFloat(value)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, "{thousand}")
        .replace(".", ",")
        .replace(/{thousand}/g, ".")}`
    : "--";

// export const weightFormat = (value, sufix = null) => `${parseFloat(value || 0).toFixed(3).replace('.', ',')} ${sufix !== null ? sufix : 'kg'}`
export const weightFormat = (value, sufix = null) =>
  `${parseFloat(value || 0)
    .toFixed(3)
    .replace(/\B(?=(\d{3})+(?!\d))/g, "{thousand}")
    .replace(".", ",")
    .replace(/{thousand}/g, ".")} ${sufix !== null ? sufix : "kg"}`;

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
