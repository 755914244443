import React, { Component } from "react";

import "./Contestation.scss";

import apiContext from "../../api";

import HomeTemplate, { redirect } from "../HomeTemplate/HomeTemplate";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { loadingOperations } from "../../state/ducks/loading";
import { weightFormat } from "../../components/Masks/Masks";
import Modal from "../../components/Modal/Modal";

import RelogioImage from "../../assets/services/relogio.png";
import { IconCheck } from "../../components/Icons/Icons";
import CondominioService from "../../services/condominio";
import ClienteService from "../../services/cliente";
import ServiceItem from "../../components/ServiceItem/ServiceItem";

class ContestationPage extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      content: "landing",
      currentStep: 0,
    };
  }

  get invoiceData() {
    const { location } = this.props;
    if (location && location.state) return location.state.invoiceData;
    return null;
  }

  get dadosGerais() {
    const invoiceData = this.invoiceData;
    if (
      invoiceData &&
      invoiceData["tt-dados-gerais"] &&
      invoiceData["tt-dados-gerais"].length
    )
      return invoiceData["tt-dados-gerais"][0];
    return null;
  }

  checkOpenOrder = (isLeakage) => {
    return new Promise((resolve, reject) => {
      const { [`local-entrega`]: localEntrega } = CondominioService.selected;
      const { [`cod-emitente`]: codEmitente } = ClienteService.localEntrega;

      if (isLeakage)
        resolve()

      this.props.showLoading();
      this.context.ConsigazApi.verificaProtocolos(
        codEmitente,
        localEntrega,
        isLeakage ? 4 : null,
        isLeakage ? "2;;" : "RevisaoLeitura",
      )
      .then(
        ({ Retorno }) => {
          this.props.dismissLoading();

          if (Retorno && Retorno.length > 0) {
            reject()
            return;
          }
          resolve()
        },
        () => {
          this.props.dismissLoading();
          reject()
        }
      )
    })
  }

  handleOrder = (isLeakage) => {
    const dadosTotais = this.invoiceData;
    const { meterRead } = this.state;
    const {
      CPFCNPJ,
      email,
      [`nome-emit`]: nome,
      telefone,
    } = ClienteService.localEntrega;
    const { [`local-entrega`]: localEntrega } = CondominioService.selected;

    const splitedName = nome.split(" ");
    const firstName = splitedName.shift();
    const lastName = splitedName.join(" ");

    this.checkOpenOrder(isLeakage)
      .then(
        () => {
          this.props.showLoading();
          this.context.ConsigazApi.geraOcorrencia(
            null,
            isLeakage ? "2;;" : "RevisaoLeitura",
            isLeakage ? 0 : null,
            isLeakage ? 4 : null,
            null,
            CPFCNPJ,
            localEntrega,
            {
              informacoes: `${dadosTotais.cod_tit_acr} / ${dadosTotais.cod_ser_docto}`,
              contact: firstName,
              lastNameDept: lastName,
              phone: telefone,
              email: email,
              reading: meterRead,
              ...(isLeakage
                ? {
                    tipoVazamento: "Desconhecido",
                    localVazamento: null,
                  }
                : {}),
            }
          ).then(
            ({ Ocorrencia }) => {
              this.props.dismissLoading();
              // redirect(this.props, '/home-services', { tab: "protocol" })
              if (Ocorrencia)
                this.setState({
                  protocol: {
                    ...Ocorrencia[0],
                    title: isLeakage ? "Vazamento" : "Revisao de Leitura",
                  },
                  content: "protocol",
                });
              else
                this.props.showToast("Ops!", "Não foi possível gerar a ocorrencia.");
            },
            (errorDescription) => {
              this.props.dismissLoading();
              this.props.showToast("Ops!", String(errorDescription));
            }
          );
        },
        () => this.props.showToast("Ops", "Você já possui um pedido de revisão aberto.")
      )

  };

  getInterval = () => {
    const dadosGerias = this.dadosGerais;

    const startDate = new Date(
      `${dadosGerias["dt-leitura-ant"]} 00:00:00`
    ).toLocaleDateString("pt-br");
    const endDate = new Date(
      `${dadosGerias["dt-leitura"]} 00:00:00`
    ).toLocaleDateString("pt-br");

    return `${startDate} - ${endDate}`;
  };

  openServiceOrder = () => {
    this.serviceOrderModal && this.serviceOrderModal.open();
  };

  analyseRead = () => {
    /* eslint-disable eqeqeq */
    const { meterRead } = this.state;
    const dadosGerais = this.dadosGerais;

    if (meterRead > dadosGerais["leitura-atual"])
      this.looksAlrightModal && this.looksAlrightModal.open();
    else if (meterRead == dadosGerais["leitura-atual"])
      this.looksEqualModal && this.looksEqualModal.open();
    else this.notOkModal && this.notOkModal.open();
    /* eslint-enable eqeqeq */
  };

  renderLandingContent() {
    const { meterRead } = this.state;
    const dadosGerais = this.dadosGerais;
    const { isAdmLogin } = ClienteService.localEntrega || {};

    return (
      <>
        <small>Período do Consumo</small>
        <p className="value">{this.getInterval()}</p>

        <small>Leitura inicial-final</small>
        <p className="value">
          {weightFormat(dadosGerais["leitura-ant"], "")} -{" "}
          {weightFormat(dadosGerais["leitura-atual"], "")}
        </p>

        <small>Consumo em Kg</small>
        <p className="value blue">{weightFormat(dadosGerais["kg"])}</p>

        <img
          alt="Equipamentos"
          src={RelogioImage}
          style={{ maxWidth: "80%", height: "300px" }}
        />
        {!isAdmLogin && (
            <>
                <h2>Veja seu medidor</h2>
                <p>
                Informe a leitura/numeração que marca no seu medidor como na imagem
                acima.
                </p>

                <input
                placeholder="Leitura/Numeração"
                type="text"
                maxLength="14"
                value={meterRead ? meterRead.toFixed(3).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ''}
                onChange={(e) =>
                    this.setState({
                    meterRead: e.target.value.replace(/[^\d]/g, '') / 1000
                    })
                }
                />
                <br />

                <button
                className="block"
                onClick={this.analyseRead}
                disabled={!meterRead}
                >
                Analisar
                </button>
            </>
        )}
      </>
    );
  }

  renderHelpContent() {
    return (
      <>
        <h2>Vamos te ajudar!</h2>
        <p>
          O aumento na quantidade de kg apresentada pode ser em decorrência de
          um possível vazamento. Para identificar se este é o seu caso, faça o
          seguinte teste
        </p>
        <p style={{ fontWeight: 600, marginBottom: "1rem" }}>
          <IconCheck color="green" style={{ marginRight: ".5rem" }} />
          Desligue todos os equipamentos que consomem gás no imóvel e anote a
          leitura que está marcando no medidor agora. Aguarde cerca de 30
          minutos sem utilizar gás e verifique novamente a leitura
        </p>

        <button
          className="block"
          onClick={() =>
            this.testConfirmationModal && this.testConfirmationModal.open()
          }
        >
          Já fiz o teste
        </button>
      </>
    );
  }

  renderProtocolContent() {
    const { protocol } = this.state;

    if (!protocol) return;

    return (
      <>
        <h1>Ocorrência(s) gerada(s)!</h1>
        <p>Anote o(s) protocolo(s):</p>

        <ServiceItem title={protocol.title}>
          <>
            {protocol["v-dt-prev-atendimento"] && (
              <>
                Serviço previsto para até o dia{" "}
                {protocol["v-dt-prev-atendimento"]
                  .split("-")
                  .reverse()
                  .join("/")}
                <br />
              </>
            )}
            Protocolo:
            <span
              style={{ float: "right", fontWeight: "bold", fontSize: "16px" }}
            >
              {protocol["v-nr-ocorrencia"] || "Não encontrado"}
            </span>
          </>
        </ServiceItem>

        <button className="block" onClick={() => redirect(this.props, "/home")}>
          Ok
        </button>
      </>
    );
  }

  render() {
    const { redirectState, content } = this.state;

    return (
      <HomeTemplate
        ref={(ref) => (this.template = ref)}
        history={this.props.history}
        redirectFrom="Contestation"
        redirectState={redirectState}
        className="contestation-page"
      >
        {content === "landing" && this.renderLandingContent()}
        {content === "help" && this.renderHelpContent()}
        {content === "protocol" && this.renderProtocolContent()}
        <Modal
          ref={(ref) => (this.looksAlrightModal = ref)}
          title="Parece normal"
          shown={false}
          confirmButton={true}
          confirmButtonText="Ok, entendi"
          confirmButtonAction={() => redirect(this.props, "/home")}
          declineButton={true}
          declineButtonText="Não concordo"
          declineButtonAction={(modal) =>
            this.setState({ content: "help" }, () => modal.close())
          }
        >
          <p>
            Identificamos que seu consumo aparentemente está normal, pois a
            leitura atual é maior que a da fatura recebida
          </p>
        </Modal>
        <Modal
          ref={(ref) => (this.looksEqualModal = ref)}
          title="Parece normal"
          shown={false}
          confirmButton={true}
          confirmButtonText="Ok, entendi"
          confirmButtonAction={() => redirect(this.props, "/home")}
          declineButton={true}
          declineButtonText="Não concordo"
          declineButtonAction={(modal) => {
            modal.close();
            this.handleOrder(false);
          }}
        >
          <p>
            Aparentemente não houve consumo após a emissão da fatura, pois a
            leitura permanece a mesma
          </p>
        </Modal>
        <Modal
          shown={false}
          ref={(ref) => (this.testConfirmationModal = ref)}
          title="Tudo certo?"
          confirmButton={true}
          confirmButtonText="Houve alteração"
          confirmButtonAction={(modal) => {
            modal.close();
            this.openServiceOrder();
          }}
          declineButton={true}
          declineButtonText="Não teve alteração, tudo certo"
          declineButtonAction={(modal) => {
            modal.close();
            this.checkAlwrightModal && this.checkAlwrightModal.open();
          }}
        >
          <p>Você conseguiu identificar se houve alterações na leitura?</p>
        </Modal>
        <Modal
          shown={false}
          ref={(ref) => (this.serviceOrderModal = ref)}
          title="Ordem de serviço"
          confirmButton={true}
          confirmButtonText="Ok"
          confirmButtonAction={(modal) => {
            modal.close();
            this.handleOrder(true);
          }}
        >
          <p>
            Abriremos uma ordem de serviço de vazamento e será feita uma
            verificação no equipamento do medidor na parte externa do imóvel.
          </p>
        </Modal>
        <Modal
          shown={false}
          ref={(ref) => (this.checkServiceOrderModal = ref)}
          title="Ordem de serviço"
          confirmButton={true}
          confirmButtonText="Ok"
          confirmButtonAction={(modal) => {
            modal.close();
            this.handleOrder(false);
          }}
        >
          <p>
            Sua fatura será encaminhada ao departamento responsável para
            revisão.
          </p>
        </Modal>
        <Modal
          shown={false}
          ref={(ref) => (this.notOkModal = ref)}
          title="Ops!"
          confirmButton={true}
          confirmButtonText="Ok"
          confirmButtonAction={(modal) => {
            modal.close();

            this.handleOrder(false);
          }}
        >
          <p>
            Há indícios de erro na aferição da sua última leitura, pois a
            leitura informada está menor que a da fatura. Sendo assim, estamos
            registrando o pedido de revisão
          </p>
        </Modal>
        <Modal
          shown={false}
          ref={(ref) => (this.checkAlwrightModal = ref)}
          title="Parece normal"
          confirmButton={true}
          confirmButtonText="Ok, entendi"
          confirmButtonAction={() => redirect(this.props, "/home")}
          declineButton={true}
          declineButtonText="Não concordo"
          declineButtonAction={(modal) => {
            modal.close();
            this.checkServiceOrderModal && this.checkServiceOrderModal.open()
            // this.handleOrder(false);
          }}
        >
          <p>
            Não há indícios de vazamento, portanto o aumento na quantidade de kg
            refere-se ao consumo.
          </p>
        </Modal>
      </HomeTemplate>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(ContestationPage);
