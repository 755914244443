import React from "react";

import { IconChevonDown } from "../Icons/Icons";

export default ({
  className,
  onChange,
  options,
  value,
  disabled,
  background,
  style,
  titleSelect,
}) => (
  <>
    <select
      className={className}
      onChange={onChange}
      value={value || titleSelect || (options.length && options[0].value)}
      disabled={disabled}
      style={style}
    >
      <option key={titleSelect} value={titleSelect} disabled>{titleSelect}</option>
      {options.map((option, idx) => (
        <option key={idx} value={option.value || option.title}>
          {option.title}
        </option>
      ))}
    </select>
    {options && options.length > 1 && (
      <IconChevonDown
        color={background === "white" ? "#444" : "currentColor"}
        size={16}
        style={{
          marginLeft: "-20px",
          transform: "translateY(4px)",
          position: "relative",
          zIndex: "-1",
        }}
      ></IconChevonDown>
    )}
  </>
);
