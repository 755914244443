import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './Notifications.scss'

import { CSSTransition } from 'react-transition-group'
import Card, { getStyle } from '../Card/Card'
import { IconClose } from '../Icons/Icons'

export default class Notifications extends Component {

  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      styleName: PropTypes.string,
      closeButton: PropTypes.bool,
      children: PropTypes.node
    }))
  }

  constructor(props){
    super(props)

    this.state = {
      items: props.items || [],
    }
  }

  static getDerivedStateFromProps(props, state) {
    return { items: props.items }
  }

  closeItem(idx) {
    const { items } = this.state

    items[idx].isIn = false

    this.setState({ items })
  }

  render() {
    let { items } = this.state

    items = items.map(item => {
      if (item.isIn === undefined)
        item.isIn = true

      return item
    })

    return (
      <div className="notification-container">
        {(items || []).map((item, idx) =>
          <CSSTransition
            in={item.isIn || undefined}
            key={`notification-${idx}`}
            classNames="notification"
            timeout={300}
            unmountOnExit>
            <Card
              title={item.title}
              className={`notification ${(item.onClick && 'clickable') || ''}`}
              styleName={item.styleName}
              style={{
                paddingLeft: item.closeButton ? '2.5rem' : 0,
              }}
              onClick={item.onClick}>
              {item.closeButton &&
                <IconClose
                  data-testid={`notification-${item.title}-close-button`}
                  size={16}
                  color={getStyle(item.styleName).color}
                  className="icon-close"
                  onClick={e => {
                    e.stopPropagation()
                    this.closeItem(idx)
                  }} />}
              {item.children}
            </Card>
          </CSSTransition>)
        }
      </div>
    )
  }

}