/* istanbul ignore file */

import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import App from './components/app/App';
import * as serviceWorker from './services/serviceWorker';
import configureStore from './state/store';

import './index.scss';

const store = configureStore({})

const container = document.getElementById('root');
const root = createRoot(container);
root.render( 
  <Provider store={store}>
    <App />
  </Provider>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
