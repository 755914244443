import React from "react";
import apiContext from "../../../api";
import { Component } from "react";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../../state/ducks/loading";
import "./ServiceDetail.scss";
import Select from "../../../components/Select/Select";
import ClienteService from "../../../services/cliente";
import SelectAddressList from "../SelectAddressList";
import CondominioService from "../../../services/condominio";

class RepresentativeContact extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      complementaryData: this.initialComplementaryData,
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
      haveSubmited: false,
    };
  }

  get initialComplementaryData() {
    return {
      "motivo-visita": "Não selecionado",
      informacoes: [],
    };
  }

  submit = () => {
    const { complementaryData, selectedCondominio } = this.state;

    if (complementaryData.informacoes.length < 1) {
      this.props.showToast(
        "Ops!",
        "Você precisa descrever o motivo da solicitação!"
      );
    } else if (selectedCondominio !== "all") this.generateOrder();
    this.setState({ haveSubmited: true });
  };

  generateOrder = () => {
    const {
      complementaryData: { "motivo-visita": motivoVisita, informacoes },
      selectedCondominio,
      selectedAddress,
    } = this.state;

    const { onToggle } = this.props;
    if (selectedCondominio === "all") {
      if (
        CondominioService.selectMany &&
        CondominioService.selectMany.length > 0
      ) {
        CondominioService.selectMany &&
          CondominioService.selectMany.map((teste) =>
            selectedAddress.push(teste)
          );
        CondominioService.selectMany = selectedAddress;
      } else CondominioService.selectMany = selectedAddress;
    }
    this.setState({ haveSubmited: false });
    onToggle &&
      onToggle({
        informacoes: `
        O motivo da visita: ${motivoVisita}. Detalhes: ${informacoes}`,
      });
  };

  render() {
    const { complementaryData, selectedCondominio, haveSubmited } = this.state;
    const { checked, rawData } = this.props;
    const { segmento } = ClienteService.localEntrega;

    return (
      <>
        {selectedCondominio === "all" && haveSubmited === true && (
          <>
            <SelectAddressList
              onSubmit={(address) =>
                this.setState(
                  {
                    selectedAddress: address,
                  },
                  () => this.generateOrder()
                )
              }
              cardClass={"service"}
              service={rawData}
            />
          </>
        )}

        {segmento === "revenda" && (
          <Select
            className="block"
            style={{
              textAlignLast: "center",
            }}
            options={[
              {
                value: "Não selecionado",
                title: "Motivos da visita",
              },
              {
                value: "Orientação Comercial",
                title: "Visita orientação Comercial",
              },
              {
                value: "Orientação Mercado",
                title: "Visita Orientação de mercado (Região)",
              },
            ]}
            value={complementaryData["motivo-visita"]}
            onChange={(e) =>
              this.setState({
                complementaryData: {
                  ...this.initialComplementaryData,
                  "motivo-visita": e.target.value,
                },
                haveSubmited: false,
              })
            }
            disabled={checked}
          />
        )}

        <>
          {(selectedCondominio !== "all" || haveSubmited === false) && (
            <>
              <h2 className="service-detail-title">{rawData.title}</h2>

              <p style={{ textAlign: "justify" }}>{rawData.description}</p>
              <h3>
                Por favor, insira no campo abaixo o motivo da sua solicitação.
              </h3>
              <p>
                Coloque o maior número de informações possíveis. Assim,
                garantiremos maior agilidade em seu atendimento!
              </p>
              <textarea
                style={{ width: "100%" }}
                value={complementaryData.informacoes || ""}
                maxLength={200}
                onChange={(e) =>
                  this.setState({
                    complementaryData: {
                      ...complementaryData,
                      informacoes: e.target.value,
                    },
                  })
                }
                disabled={checked}
              />

              <footer className="service-detail-footer">
                <button
                  className="block"
                  onClick={() => {
                    !checked ? this.submit() : this.generateOrder();
                  }}
                >
                  {checked ? "Desselecionar" : "Selecionar"}
                </button>
              </footer>
            </>
          )}
        </>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(RepresentativeContact);
