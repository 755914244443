import React, { Component } from "react";
import Card from "../../components/Card/Card";
import InvoiceService from "../../services/invoice";
import { weightFormat, moneyFormat } from "../../components/Masks/Masks";

const getCardStyleFromInvoice = (invoice) => {
  const invoiceStatus = InvoiceService.getInvoiceStatus(invoice);

  return {
    paid: "toplineSuccess",
    outdated: "toplineDanger",
    open: "toplineWarning",
  }[invoiceStatus];
};

class InvoiceInFocus extends Component {
  getInterval = () => {
    const { invoice } = this.props;
    if (!invoice) return null;

    const dadosGerias =
      invoice["tt-dados-gerais"] && invoice["tt-dados-gerais"][0];
    if (!dadosGerias) return null;

    const startDate = new Date(
      `${dadosGerias["dt-leitura-ant"]} 00:00:00`
    ).toLocaleDateString("pt-br");
    const endDate = new Date(
      `${dadosGerias["dt-leitura"]} 00:00:00`
    ).toLocaleDateString("pt-br");

    return `${startDate} - ${endDate}`;
  };

  render() {
    const { invoice } = this.props;

    let dadosGerais;
    if (
      invoice &&
      invoice["tt-dados-gerais"] &&
      invoice["tt-dados-gerais"].length
    )
      dadosGerais = invoice["tt-dados-gerais"][0];

    return (
      <Card
        className="invoice-in-focus"
        styleName={getCardStyleFromInvoice(invoice)}
      >
        {InvoiceService.getInvoiceBadge(invoice)}
        {dadosGerais && <span className="interval">{this.getInterval()}</span>}

        <p>
          <span className="consumption">
            Consumo de gás
            <span className="value">
              {dadosGerais && weightFormat(dadosGerais["kg"])}
            </span>
          </span>

          <span className="consumption">
            Valor da Fatura
            <span className="value">
              {dadosGerais && moneyFormat(dadosGerais["valor-total"])}
            </span>
          </span>
        </p>

        <p className="text-center no-margin">
          <button
            className="block link"
            onClick={this.props.onDetail}
            disabled={!dadosGerais}
          >
            Detalhamento do consumo
          </button>
        </p>
      </Card>
    );
  }
}

export default InvoiceInFocus