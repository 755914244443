import React, { Component } from "react";

import apiContext from "../../api";

import "./Consumption.scss";

import HomeTemplate from "../HomeTemplate/HomeTemplate";
import InvoiceModal from "../../components/InvoiceModal/InvoiceModal";
import ConsumptionModal from "../../components/ConsumptionModal/ConsumptionModal";
import MonthlyConsumptionLineChart, {
  getMonthLabel,
  getChartData,
  adjustCenterIdx,
} from "../../components/MonthlyConsumptionLineChart/MonthlyConsumptionLineChart";
import {
  getTicketsChartData,
  reduceMonths,
} from "../../components/MonthlyTicketsLineChart/MonthlyTicketsLineChart";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import { IconSpinner } from "../../components/Icons/Icons";
import EmptyDataInvoiceImage from "../../assets/illustrations/empty-data-invoice.png";
import LabelSwitch from "../../components/LabelSwitch/LabelSwitch";
import ClienteService from "../../services/cliente";
import CondominioService from "../../services/condominio";
import Card from "../../components/Card/Card";
import InvoiceService from "../../services/invoice";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { moneyFormat, weightFormat } from "../../components/Masks/Masks";
import MonthlyTicketsLineChart from "../../components/MonthlyTicketsLineChart/MonthlyTicketsLineChart";
import moment from "moment";
import ChartFilterModal from "./ChartFilterModal";
import MonthTickets, {
  addressReduceTickets,
  getTicketValue,
} from "./MonthTickets";
import InvoiceInFocus from "./InvoiceInFocus";

const LoadingLine = (props) => (
  <p className="text-center">
    <IconSpinner color="#000" style={{ animation: "1s infinite spin" }} />
  </p>
);

class Consumption extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartReference: "reais",
      showChartFilterModal: true,
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
    };
  }

  static contextType = apiContext;

  get lastInvoice() {
    const { invoices } = this.state;
    return invoices && invoices.length && invoices[invoices.length - 1];
  }

  get invoiceInFocusChart() {
    const { invoices } = this.state;

    return (
      invoices && InvoiceService.getValidInvoices(invoices)[this.chartCenterIdx]
    );
  }

  componentDidMount() {
    const { segmento } = ClienteService.localEntrega;

    if (segmento === "CONTASIM") this.getDataForContaSim();
    else if (segmento === "INDUSTRIAL") this.getDataForIndustrial();
  }

  getDataForContaSim() {
    const { CPFCNPJ } = ClienteService.localEntrega;
    const { [`local-entrega`]: localEntrega } = CondominioService.selected;

    const dataFim = new Date();
    const dataIni = new Date();
    dataIni.setFullYear(dataFim.getFullYear() - 1);

    this.context.ConsigazApi.memConsultaSegundaViaBoleto(
      CPFCNPJ,
      this.context.ConsigazApi.formatDate(dataIni),
      this.context.ConsigazApi.formatDate(dataFim),
      localEntrega
    )
      .then(({ dsRetorno }) => {
        if (dsRetorno && dsRetorno["tt-cliente-retorno"]) {
          const invoices = dsRetorno["tt-cliente-retorno"];

          const validInvoices = InvoiceService.getValidInvoices(invoices);

          this.setState({
            invoices,
            chartCenterIdx: validInvoices.length - 1,
            invoiceInFocus: validInvoices[validInvoices.length - 1],
          });
        } else {
          this.setState({ invoices: [] });
        }
      })
      .catch((errorDescription) =>
        this.props.showToast("Ops!", errorDescription)
      );
  }

  getDataForIndustrial() {
    const { CPFCNPJ } = ClienteService.localEntrega;

    const dataFim = moment().startOf("month").format("YYYY/MM/DD");
    const dataIni = moment()
      .subtract(1, "year")
      .endOf("month")
      .format("YYYY/MM/DD");

    this.context.ConsigazApi.memConsultaNotaFiscal(CPFCNPJ, dataIni, dataFim)
      .then(({ Notas }) => {
        if (Notas && Notas.NotasFiscais && Notas.NotasFiscais.length) {
          const tickets = Notas.NotasFiscais.filter((ticket) => {
            const { "local-entrega": localEntrega } =
              CondominioService.selected || {};

            if (localEntrega)
              return (
                ticket["local-entrega"]
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase() ===
                localEntrega
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase()
              );

            return true;
          });
          const chartData = this.getChartData();
          const chartCenterIdx = adjustCenterIdx(
            chartData,
            Notas.NotasFiscais.length - 1
          );

          this.setState(
            {
              tickets,
              chartCenterIdx,
              invoiceInFocus: Notas.NotasFiscais[chartCenterIdx],
            },
            () => {
              let haveWeightUnit = false;
              const possibleItems = (Notas.NotasFiscais || []).reduce(
                (reduced, ticket) => {
                  (ticket.Itens || []).forEach((item) => {
                    haveWeightUnit =
                      (item.un || "").toLowerCase() === "kg" || haveWeightUnit;
                    if (reduced.indexOf(item["it-codigo"]) === -1)
                      reduced.push(item["it-codigo"]);
                  });
                  return reduced;
                },
                []
              );

              if (!haveWeightUnit)
                this.chartFilterModalRef &&
                  this.chartFilterModalRef.filterByUnits(possibleItems);

              const {
                endereco,
                cidade,
                estado,
              } = this.state.selectedCondominio;
              this.chartFilterModalRef &&
                this.chartFilterModalRef.filterByAddress(
                  `${endereco}, ${cidade}, ${estado}`
                );
            }
          );
        } else {
          this.setState({ tickets: [] });
        }
      })
      .catch((errorDescription) =>
        this.props.showToast("Ops!", errorDescription)
      );
  }

  getChartData(shouldSlice = true) {
    const { segmento } = ClienteService.localEntrega;
    const { tickets, invoices, chartReference, chartFilters } = this.state;

    if (segmento === "CONTASIM")
      return getChartData(invoices, this.chartCenterIdx, chartReference);
    else if (segmento === "INDUSTRIAL")
      return getTicketsChartData(
        tickets,
        this.chartCenterIdx,
        chartFilters,
        shouldSlice
      );
  }

  getInvoiceAverageValue() {
    const { invoices, chartReference } = this.state;
    const validInvoices = InvoiceService.getValidInvoices(invoices);

    const value =
      validInvoices &&
      validInvoices.reduce((a, b) => {
        if (chartReference === "reais") return a + b["val_origin_tit_acr"];
        else
          try {
            return a + b["tt-dados-gerais"][0]["kg"];
          } catch (error) {
            return a;
          }
      }, 0) / validInvoices.length;

    if (chartReference === "reais")
      return `${(value && moneyFormat(value)) || "--"}`;
    else return `${(value && weightFormat(value)) || "--"}`;
  }

  renderWithInvoiceData() {
    const { invoices } = this.state;
    let invoiceInFocusChart = this.invoiceInFocusChart;

    let lastInvoice = this.lastInvoice;
    const chartData = this.getChartData();

    return (
      <>
        <div className="chart">
          <p className="title">
            {invoices.length === 1
              ? "Média do último mes"
              : `Média últimos ${
                  InvoiceService.getValidInvoices(invoices).length
                } meses`}
            <span className="value">{this.getInvoiceAverageValue()}</span>
          </p>
          <LabelSwitch
            className="invoice-type-switch"
            options={[
              {
                label: "Reais",
                onClick: () => this.setState({ chartReference: "reais" }),
              },
              {
                label: "Quilos",
                onClick: () => this.setState({ chartReference: "quilos" }),
              },
            ]}
          />
          {chartData.length ? (
            <>
              <div className="chart-container">
                <MonthlyConsumptionLineChart
                  data={chartData}
                  chartReference={this.state.chartReference}
                />
              </div>
              <NavigationBar
                tabs={(chartData || []).map((month) => ({
                  value: month,
                  label: getMonthLabel(month),
                  active: month.selected,
                  onClick: () =>
                    this.setState({
                      chartCenterIdx: month.itemIdx,
                      invoiceInFocus: month,
                    }),
                }))}
                defineActive={true}
              />
            </>
          ) : (
            <p className="text-center">
              <img
                src={EmptyDataInvoiceImage}
                alt="Empty chart placeholder"
                height={100}
              />
            </p>
          )}
          <InvoiceInFocus
            invoice={invoiceInFocusChart}
            onDetail={() => {
              this.setState(
                {
                  invoiceInFocus: invoiceInFocusChart,
                },
                () => this.consumptionModal && this.consumptionModal.open()
              );
            }}
          />
        </div>
        <div>
          <Card
            title="Última fatura"
            styleName="primary"
            className="last-invoice"
            footerButtons={[
              {
                label: "Detalhe da fatura",
                onClick: () => {
                  const validInvoices = InvoiceService.getValidInvoices(
                    invoices
                  );
                  this.setState(
                    {
                      chartCenterIdx: validInvoices.length - 1,
                      invoiceInFocus: validInvoices[validInvoices.length - 1],
                    },
                    () => this.consumptionModal && this.consumptionModal.open()
                  );
                },
                disabled: !lastInvoice,
              },
            ]}
          >
            {(lastInvoice && (
              <>
                {InvoiceService.getInvoiceBadge(lastInvoice)}
                <br />
                {(() => {
                  const date = new Date(
                    `${lastInvoice["dat_transacao"]} 00:00:00`
                  );
                  return `${String(date.getMonth() + 1).padStart(
                    2,
                    0
                  )}/${date.getFullYear()}`;
                })()}
                <span className="price">
                  {moneyFormat(lastInvoice["val_origin_tit_acr"])}
                </span>
              </>
            )) ||
              "Não existem faturas abertas"}
          </Card>
        </div>
      </>
    );
  }

  getTicketAverage(reducedTickets) {
    const { chartFilters } = this.state;
    const chartData = this.getChartData(false);

    if (chartData && chartData.length) {
      const value =
        chartData.reduce((reducedValue, item) => reducedValue + item.value, 0) /
        chartData.length;
      if (!chartFilters || chartFilters.selectedProduct === "granel")
        return weightFormat(value);
      else return `${(value || 0).toFixed(0)} un.`;
    } else {
      return 0;
    }
  }

  getTicketTotal(reducedTickets) {
    const { chartFilters } = this.state;
    const chartData = this.getChartData(false);

    if (chartData && chartData.length) {
      const value = chartData.reduce(
        (reducedValue, item) => reducedValue + item.value,
        0
      );
      if (!chartFilters || chartFilters.selectedProduct === "granel")
        return weightFormat(value);
      else return `${(value || 0).toFixed(0)} un.`;
    } else {
      return 0;
    }
  }

  get chartCenterIdx() {
    const { chartData } = this.state;
    let { chartCenterIdx } = this.state;

    if (chartData && chartCenterIdx > chartData.length)
      chartCenterIdx = parseInt(chartData.length / 2);

    return chartCenterIdx;
  }

  getChartCenterItem(chartData) {
    const chartCenterIdx = this.chartCenterIdx;

    return chartData && chartData.length && chartData[chartCenterIdx];
  }

  renderWithTicketData() {
    const { tickets, chartFilters } = this.state;

    const chartData = this.getChartData();
    const monthReducedTickets = reduceMonths(tickets);

    const currentMonth =
      (this.getChartCenterItem(this.getChartData(false)) || {}).monthNumber ||
      (chartData && chartData.length && chartData[0].monthNumber);

    const filteredTickets = tickets.filter((ticket) => {
      const ticketMonth =
        moment(ticket["dt-emis-nota"], "YYYY-MM-DD").month() + 1;
      
      return (
        ticketMonth === currentMonth &&
        (!chartFilters ||
          !chartFilters.selectedAddresses ||
          chartFilters.selectedAddress === "todos" ||
          chartFilters.selectedDeliveryPlaces.indexOf(
            (ticket["local-entrega"] || "")
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase()
          ) > -1)
      );
    });

    return (
      <>
        <div className="chart">
          <p className="title">
            {Object.keys(monthReducedTickets).length === 1
              ? "Média do último mes"
              : Object.keys(monthReducedTickets).length > 1
              ? `Média últimos ${
                  (!chartFilters && Object.keys(monthReducedTickets).length) ||
                  (chartFilters &&
                    chartFilters.selectedInterval === "ultimo-ano" &&
                    Object.keys(monthReducedTickets).length) ||
                  (chartFilters &&
                    chartFilters.selectedMonths &&
                    chartFilters.selectedMonths.length)
                } meses`
              : "Histórico de consumo"}

            <p>
              {this.state.selectedCondominio !== "all"
                ? this.state.selectedCondominio.endereco
                : "Todos os endereços"}
            </p>
          </p>
          {chartData.length ? (
            <>
              <button
                className="rectangle"
                style={{ position: "absolute", right: "1rem", top: "1rem" }}
                onClick={() => {
                  this.setState({ showChartFilterModal: true });
                }}
              >
                Filtrar parâmetros
              </button>
              <div className="chart-container">
                <MonthlyTicketsLineChart
                  data={chartData}
                  filters={chartFilters}
                  chartReference={this.state.chartReference}
                />
              </div>
              <NavigationBar
                className="months-nav"
                style={
                  (chartData || []).length === 1
                    ? { justifyContent: "space-around" }
                    : {}
                }
                tabs={chartData.map((month) => ({
                  value: month,
                  label: getMonthLabel(month),
                  active: month.selected,
                  onClick: () =>
                    this.setState({
                      chartCenterIdx: month.itemIdx,
                      invoiceInFocus: month,
                    }),
                }))}
                defineActive={true}
              />

              <div className="grid">
                <div className="row">
                  {Object.keys(monthReducedTickets).length === 1
                    ? "Média do último mes"
                    : `Média de consumo dos últimos ${
                        (!chartFilters &&
                          Object.keys(monthReducedTickets).length) ||
                        (chartFilters &&
                          chartFilters.selectedInterval === "ultimo-ano" &&
                          Object.keys(monthReducedTickets).length) ||
                        (chartFilters &&
                          chartFilters.selectedMonths &&
                          chartFilters.selectedMonths.length)
                      } meses`}
                  <span className="average">{this.getTicketAverage()}</span>
                  <br />
                </div>

                <div className="row2">
                  {Object.keys(monthReducedTickets).length === 1
                    ? "Média do último mes"
                    : `Consumo total dos últimos ${
                        (!chartFilters &&
                          Object.keys(monthReducedTickets).length) ||
                        (chartFilters &&
                          chartFilters.selectedInterval === "ultimo-ano" &&
                          Object.keys(monthReducedTickets).length) ||
                        (chartFilters &&
                          chartFilters.selectedMonths &&
                          chartFilters.selectedMonths.length)
                      } meses`}
                  <span className="average">{this.getTicketTotal()}</span>
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="text-center">
                <img
                  src={EmptyDataInvoiceImage}
                  alt="Empty chart placeholder"
                  height={100}
                />
                <br />
                Aqui você visualizará seu gráfico de consumo por mês.
              </p>
            </>
          )}
          {chartData.length ? (
            <MonthTickets
              data={filteredTickets}
              activeMonth={
                monthReducedTickets &&
                monthReducedTickets.length &&
                Object.values(monthReducedTickets)[this.chartCenterIdx][0]
              }
              filters={chartFilters}
              onSendMail={(tickets) => {
                if (
                  !monthReducedTickets ||
                  !Object.values(monthReducedTickets || {}).length
                )
                  return;

                const {
                  CPFCNPJ,
                  email,
                  "nome-emit": name,
                } = ClienteService.localEntrega;
                const {
                  email: emailContato,
                } = ClienteService.industrialContato;

                this.context.ConsigazReportApi.sendIndustrialConsumeReport(
                  CPFCNPJ,
                  emailContato || email,
                  name,
                  Object.entries(addressReduceTickets(filteredTickets)).reduce(
                    (reduced, [address, tickets]) => [
                      ...reduced,
                      ...tickets.map((ticket) => ({
                        address,
                        deliveryLocation: ticket["local-entrega"],
                        deliveryDate: moment(
                          ticket["dt-emis-nota"],
                          "YYYY-MM-DD"
                        ).format("DD/MM/YYYY"),
                        gasVolume: getTicketValue(ticket, chartFilters),
                      })),
                    ],
                    []
                  )
                ).then(
                  () =>
                    this.props.showToast(
                      "Enviado!",
                      `O relatório foi enviado para ${emailContato || email}.`
                    ),
                  () => this.props.showToast("Ops!", "Erro ao enviar o boleto.")
                );
              }}
            />
          ) : (
            <Card styleName={"toplineCancel"}>
              <p className="text-center">
                <img
                  src={EmptyDataInvoiceImage}
                  alt="Empty chart placeholder"
                  height={100}
                />
                <br />
                Aqui você acompanhará seu consumo por local de entrega.
              </p>
            </Card>
          )}
        </div>
      </>
    );
  }

  render() {
    const {
      tickets,
      invoices,
      invoiceInFocus,
      showChartFilterModal,
    } = this.state;
    const { segmento } = ClienteService.localEntrega;

    const monthReducedTickets = reduceMonths(tickets || []);

    return (
      <HomeTemplate
        className="consumption-page"
        ref={(ref) => (this.template = ref)}
        history={this.props.history}
        activeTab="Consumo"
      >
        <div
          className={`consumption-page-grid ${
            segmento === "INDUSTRIAL" ? "industrial" : ""
          }`}
        >
          {(invoices && this.renderWithInvoiceData()) ||
            (tickets && this.renderWithTicketData()) || <LoadingLine />}
        </div>
        {invoiceInFocus && (
          <>
            <InvoiceModal
              ref={(ref) => (this.invoiceModal = ref)}
              template={this.template}
              invoiceData={invoiceInFocus}
              onCopy={() => this.modalBoleto && this.modalBoleto.close()}
            />
            <ConsumptionModal
              ref={(ref) => (this.consumptionModal = ref)}
              history={this.props.history}
              invoiceData={invoiceInFocus}
              onShowInvoice={(invoiceData) => {
                this.invoiceModal && this.invoiceModal.open();
                this.consumptionModal && this.consumptionModal.close();
              }}
            />
          </>
        )}
        {segmento === "INDUSTRIAL" && 
        <ChartFilterModal
          ref={(ref) => (this.chartFilterModalRef = ref)}
          shown={showChartFilterModal}
          monthsData={Object.keys(monthReducedTickets).map((key) => ({
            number: +key.substr(4),
            label: getMonthLabel({ monthNumber: +key.substr(4) }),
          }))}
          productsData={Object.values(monthReducedTickets).reduce(
            (reduced, month) => {
              (month || []).forEach((ticket) => {
                (ticket.Itens || []).forEach((item) => {
                  const key = item["it-codigo"].toLowerCase();
                  reduced[key] = item;
                });
              });
              return reduced;
            },
            {}
          )}
          addressData={Object.values(monthReducedTickets).reduce(
            (reduced, month) => {
              (month || []).forEach((ticket) => {
                const key = `${ticket.endereco}, ${ticket.cidade}, ${ticket.estado}`;

                if (reduced.addresses.indexOf(key) === -1) {
                  reduced.addresses.push(key);
                  reduced.deliveryPlaces.push(
                    (ticket["local-entrega"] || "")
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toUpperCase()
                  );
                }
              });
              return reduced;
            },
            { addresses: [], deliveryPlaces: [] }
          )}
          onSubmit={(filters) => {
            if (
              this.chartFilterModalRef &&
              this.chartFilterModalRef.showProductsFilter &&
              filters.selectedProduct !== "granel" &&
              filters.selectedUnits.length <= 0
            ) {
              this.props.showToast(
                "Ops",
                "Para prosseguir, selecione o(s) tipo(s) de produto(s) desejado(s)"
              );
            } else if (
              filters.selectedAddress !== "todos" &&
              filters.selectedAddresses.length <= 0
            ) {
              this.props.showToast(
                "Ops",
                "Para prosseguir, selecione o(s) endereço(s) desejado(s)"
              );
            } else if (
              filters.selectedInterval !== "ultimo-ano" &&
              filters.selectedMonths.length <= 0
            ) {
              this.props.showToast(
                "Ops",
                "Para prosseguir, selecione os meses que deseja comparar"
              );
            } else {
              this.setState({
                showChartFilterModal: false,
                chartFilters: filters,
              });
            }
          }}
          onDismiss={() => this.setState({ showChartFilterModal: false })}
        />
      }
      </HomeTemplate>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Consumption);
