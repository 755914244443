import React, { Component } from "react";

import "./ServiceCard.scss";

import { IconCheck } from "../Icons/Icons";
import Switch from "../Switch/Switch";
import { moneyFormat } from "../Masks/Masks";

export default class ServiceCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked || false,
    };
  }

  render() {
    const { className } = this.props;

    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          this.props.onDetail(this.props.serviceId);
        }}
        className={`service-card ${className || ""} ${
          this.state.checked && "checked"
        }`}
      >
        {this.state.checked && (
          <IconCheck className="icon-check" color="#00a326" size={28} />
        )}
        {this.props.title && <p className="title">{this.props.title}</p>}
        <span className={`price-badge ${!this.props.price && "hidden"}`}>
          {this.props.price ? moneyFormat(this.props.price) : "--"}
        </span>
        {this.props.description && (
          <p className="description">{this.props.description}</p>
        )}
        <footer>
          <Switch
            data-testid="contract-switch"
            checked={this.state.checked}
            onChange={this.props.onContract || (() => null)}
          />

          <button className="link" onClick={this.props.onContract}>
            Selecionar
          </button>

          <button
            className="link"
            style={{ float: "right" }}
            onClick={() => this.props.onDetail(this.props.serviceId)}
          >
            Ver Mais
          </button>
        </footer>
      </div>
    );
  }
}
