import React from "react";
import apiContext from "../../../api";
import { Component } from "react";
import Modal from "../../../components/Modal/Modal";
import WarningImage from "../../../assets/illustrations/warning.png";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../../state/ducks/loading";

import "./ServiceDetail.scss";
import { redirect } from "../../../components/Page/Page";
import CondominioService from "../../../services/condominio";
import SelectAddressList from "../SelectAddressList";

class IndustrialRegulation extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      complementaryData: this.initialComplementaryData,
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
      haveSubmited: false,
    };
  }

  get initialComplementaryData() {
    return {
      isReady: null,
    };
  }

  generateOrder = () => {
    const {
      complementaryData: { isReady, informacoes },
      selectedCondominio,
      selectedAddress,
    } = this.state;
    const { onToggle } = this.props;

    if (selectedCondominio === "all") {
      if (
        CondominioService.selectMany &&
        CondominioService.selectMany.length > 0
      ) {
        CondominioService.selectMany &&
          CondominioService.selectMany.map((teste) =>
            selectedAddress.push(teste)
          );
        CondominioService.selectMany = selectedAddress;
      } else CondominioService.selectMany = selectedAddress;
    }
    this.setState({ haveSubmited: false });
    this.detailModal && this.detailModal.close();

    onToggle &&
      onToggle({
        informacoes: `O fogão ${
          isReady ? "está" : "não está"
        } preparado para GLP. ${informacoes}`,
      });
  };

  componentDidMount() {
    this.regulationModal && this.regulationModal.open();
  }

  render() {
    const { rawData } = this.props;
    const { selectedCondominio, haveSubmited } = this.state;

    return (
      <>
        <>
          {selectedCondominio === "all" &&
            haveSubmited === true &&
            this.detailModal &&
            this.detailModal.open()}

          <Modal
            ref={(ref) => (this.detailModal = ref)}
            shown={false}
            className="large"
            closeButton={true}
          >
            <SelectAddressList
              onSubmit={(address) =>
                this.setState(
                  {
                    selectedAddress: address,
                  },
                  () => this.confirmModal.open()
                )
              }
              cardClass={"service"}
              service={rawData}
            />
          </Modal>

          <Modal
            shown={false}
            ref={(ref) => (this.regulationModal = ref)}
            title="Fique atento!"
            imgSrc={WarningImage}
            closeButton={true}
            confirmButton={true}
            confirmButtonText={"Solicitar regulagem de pressão"}
            confirmButtonAction={() => {
              if (selectedCondominio === "all")
                this.setState(
                  { haveSubmited: true },
                  this.regulationModal.close()
                );
              else this.confirmModal && this.confirmModal.open();
            }}
            declineButton={true}
            declineButtonText={"Solicitar abastecimento"}
            declineButtonAction={() => {
              redirect(this.props, "/home-delivery");
            }}
          >
            <br />
            <p style={{ textAlign: "justify" }}>
              Antes de solicitar a regulagem certifique-se de que o registro do
              gás está aberto e que o seu tanque/cilindro(botijão) tem gás
              suficiente. Caso não tenha gás suficiente, solicite um novo
              abastecimento.
            </p>
          </Modal>
          <Modal
            shown={false}
            ref={(ref) => (this.confirmModal = ref)}
            title="Confirmar?"
            imgSrc={WarningImage}
            confirmButton={true}
            confirmButtonText={"Confirmar"}
            confirmButtonAction={(modal) => {
              modal.close();
              this.regulationModal && this.regulationModal.close();
              this.generateOrder();
            }}
          >
            <p style={{ textAlign: "justify" }}>
              Abriremos uma ordem de serviço para que nossa equipe técnica vá ao
              local realizar o serviço.
            </p>
          </Modal>
        </>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(IndustrialRegulation);
