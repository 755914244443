import React from "react";
import apiContext from "../../../api";
import { Component } from "react";
import Modal from "../../../components/Modal/Modal";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../../state/ducks/loading";
import "./ServiceDetail.scss";
import LabelSwitch from "../../../components/LabelSwitch/LabelSwitch";
import Card from "../../../components/Card/Card";
import moment from "moment";
import TicketService from "../../../services/ticket";
import { redirect } from "../../../components/Page/Page";
import CondominioService from "../../../services/condominio";
import ClienteService from "../../../services/cliente";
import SelectAddressList from "../SelectAddressList";
import Select from "../../../components/Select/Select";
import { percentualFormat } from "../../../components/Masks/Masks";
import ServiceItem from "../../../components/ServiceItem/ServiceItem";

class RequestSupply extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      tab: "programado",
      complementaryData: this.initialComplementaryData,
      haveSubmited: false,
      nextPage: false,
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
      prevEntrega: null,
      status: null,
      nrpedido: null,
    };
  }

  get initialComplementaryData() {
    return {
      selectedProduct: "",
      selectedModel: null,
      data: null,
      initialTime: moment().format("HH:mm"),
      lastTime: moment().format("HH:mm"),
      qtde: 0,
      gasPercent: "",
      horarioPreferencia: "",
    };
  }

  get itensCilindro() {
    const { selectedCondominio = {} } = this.state;
    return (selectedCondominio.Itens || []).filter(
      (it) => it.tipo.toLowerCase().indexOf("glp envasado") > -1
    );
  }

  get itensGranel() {
    const { selectedCondominio = {} } = this.state;
    return (selectedCondominio.Itens || []).filter(
      (it) => it.tipo.toLowerCase().indexOf("granel") > -1
    );
  }

  get itensEspecial() {
    const { selectedCondominio = {} } = this.state;
    return (selectedCondominio.Itens || []).filter(
      (it) => it.tipo.toLowerCase().indexOf("gás especial") > -1
    );
  }

  submit = () => {
    const { complementaryData, nextPage, tab } = this.state;
    const hourObj = new Date();
    const limitHour = new Date();
    limitHour.setHours(15);
    limitHour.setMinutes(0);
    limitHour.setSeconds(0);
    const limitedHour = moment(limitHour);

    if (tab === "programado" && nextPage === false) {
      if (
        complementaryData["selectedProduct"] === "cilindros" &&
        complementaryData["selectedModel"] === null
      )
        this.props.showToast(
          "Ops!",
          "Você precisa selecionar um modelo de cilindro."
        );
      else this.setState({ nextPage: true, prevEntrega: false });
    } else if (tab === "programado" && nextPage === true) {
      if (!complementaryData["data"]) {
        this.props.showToast("Ops!", "Você precisa selecionar uma data.");
        return;
      } else if (complementaryData["data"] === "outra") {
        this.modalHelp && this.modalHelp.open();
        return;
      } else if (
        complementaryData["selectedProduct"] === "cilindros" &&
        complementaryData["qtde"] === 0
      ) {
        this.props.showToast("Ops!", "Você precisa informar a quantidade.");
        return;
      } else {
        this.generateOrder();
        this.modalConfirm && this.modalConfirm.open();
      }
    } else if (tab === "emergencial")
      if (
        complementaryData["selectedProduct"] === "cilindros" &&
        complementaryData["selectedModel"] === null
      )
        this.props.showToast(
          "Ops!",
          "Você precisa selecionar um modelo de cilindro."
        );
      else if (moment(hourObj).isSameOrAfter(limitedHour))
        this.modalEmergencial && this.modalEmergencial.open();
      else this.modalConfirmEmergencial && this.modalConfirmEmergencial.open();
  };

   removeAccents = (str) => {
    return str?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "");
  }

  generateOrder = () => {
    const {
      complementaryData: {
        selectedProduct,
        selectedModel,
        gasPercent,
        qtde,
        horarioPreferencia,
      },
      complementaryData,
      tab,
    } = this.state;
    const { onToggle } = this.props;
    this.setState({ nextPage: false });
    let { [`local-entrega`]: codEntrega } = CondominioService.selected || {};
    if (!codEntrega)
      codEntrega =
        ClienteService.localEntrega &&
        ClienteService.localEntrega.ClienteEntrRetorno[0][`cod-entrega`];
    const { [`cod-emitente`]: codEmitente } = ClienteService.localEntrega;

    if (tab === "emergencial") {
      onToggle &&
        onToggle({
          itCodigo: selectedModel,
          informacoes: `${
            selectedProduct === "cilindros"
              ? `Quantidade: ${qtde} un. \n Tipo de cilindro: ${selectedModel}`
              : `Percentual: ${gasPercent}, \n Tipo de item: Granel (${selectedModel})`
          }
          Horario de preferencia: ${this.removeAccents(horarioPreferencia)}`,
        });
    } else {
      this.context.ConsigazApi.geraAbastecimento(
        codEmitente,
        codEntrega,
        complementaryData
      ).then(
        ({ Retorno }) => {
          if (!Retorno)
            this.props.showToast(
              "Ops!",
              "Não foi possível gerar o pedido de abastecimento."
            );
          else
            this.setState({
              nrpedido: Retorno[0]["nr-pedido"],
            });
          // onDismiss && onDismiss();
        },
        (errorDescription) => {
          this.props.showToast("Ops!", String(errorDescription));
        }
      );
    }
  };

  toggleModel(model) {
    const { complementaryData } = this.state;

    if (complementaryData["selectedModel"] === model)
      this.setState({
        complementaryData: {
          ...complementaryData,
          selectedModel: null,
        },
      });
    else
      this.setState({
        complementaryData: {
          ...complementaryData,
          selectedModel: model,
        },
      });
  }

  getOptionsData() {
    const {
      complementaryData: { selectedModel },
      prevEntrega,
      selectedCondominio,
    } = this.state;

    if (!prevEntrega && selectedCondominio !== "all" && selectedModel) {
      const { CPFCNPJ } = ClienteService.localEntrega || {};
      let { [`local-entrega`]: localEntrega } =
        CondominioService.selected || {};
      if (!localEntrega)
        localEntrega =
          ClienteService.localEntrega &&
          ClienteService.localEntrega.ClienteEntrRetorno[0][`cod-entrega`];

      this.context.ConsigazApi.memConsultaPrevEntrega(
        CPFCNPJ,
        localEntrega,
        selectedModel
      ).then(
        ({ Retorno }) => {
          this.setState({
            prevEntrega: (Retorno || []).map((prev) => prev["dt-prevista"]),
          });
        },
        () => {
          this.props.showToast(
            "Ops",
            "Não foram encontradas datas disponíveis."
          );
        }
      );
    }
  }

  renderOptions() {
    const { complementaryData, tab } = this.state;
    const nextDelivery = TicketService.getNextDelivery(complementaryData["selectedModel"]);

    return (
      <>
        <div
          style={{
            textAlign: "left",
            margin: "0 auto",
            width: "270px",
            paddingLeft: "20%",
          }}
        >
          <>
            <b>Produto selecionado</b>
            {(this.itensGranel || []).length > 0 ? (
              <label>
                <input
                  type="radio"
                  name="is-ready"
                  value="granel"
                  className={
                    complementaryData["selectedProduct"] === "granel"
                      ? "checked"
                      : ""
                  }
                  onChange={() =>
                    this.setState(
                      {
                        complementaryData: {
                          ...complementaryData,
                          selectedProduct: "granel",
                        },
                      },
                      () => this.toggleModel(this.itensGranel[0]["it-codigo"])
                    )
                  }
                />
                Fornecimento à granel (kg)
              </label>
            ) : null}
            {this.itensCilindro.length > 0 && (
              <label>
                <input
                  type="radio"
                  name="is-ready"
                  value="null"
                  className={
                    complementaryData["selectedProduct"] === "cilindros"
                      ? "checked"
                      : ""
                  }
                  onChange={() =>
                    this.setState({
                      complementaryData: {
                        ...complementaryData,
                        selectedProduct: "cilindros",
                        selectedModel: null,
                      },
                    })
                  }
                />
                Cilindros (unidades)
              </label>
            )}
            <div style={{ paddingLeft: "2rem" }}>
              {this.itensCilindro.map((value) => {
                return (
                  <label>
                    <input
                      type="checkbox"
                      className={
                        complementaryData["selectedModel"] ===
                        value["it-codigo"]
                          ? "checked"
                          : ""
                      }
                      onClick={() => this.toggleModel(value["it-codigo"])}
                      disabled={
                        complementaryData["selectedProduct"] !== "cilindros"
                      }
                    />
                    {value["desc-comercial"]}
                  </label>
                );
              })}
            </div>
            {(this.itensEspecial || []).length > 0 ? (
              <label>
                <input
                  type="radio"
                  name="is-ready"
                  value="Gás especial"
                  className={
                    complementaryData["selectedProduct"] === "gás especial"
                      ? "checked"
                      : ""
                  }
                  onChange={() =>
                    this.setState({
                      complementaryData: {
                        ...complementaryData,
                        selectedProduct: "gás especial",
                      },
                    })
                  }
                />
                Gás especial
              </label>
            ) : null}
          </>
        </div>
        {(tab === "emergencial" && complementaryData["selectedProduct"]) ||
        tab === "programado" ? (
          <Card
            className="text-center no-shadow"
            styleName="outlinePrimary"
            style={{ marginBottom: 0 }}
          >
            <p className="title no-margin">Data da próxima entrega:</p>
            <b
              style={{
                display: "block",
                fontSize: 20,
              }}
            >
              {nextDelivery !== null ? (
                moment(nextDelivery["dt-prox-entrega"], "YYYY-MM-DD").isSame(
                  moment().format("YYYY-MM-DD")
                ) && tab === "emergencial" ? (
                  this.sameDeliveryDateModal &&
                  this.sameDeliveryDateModal.open()
                ) : (
                  moment(nextDelivery["dt-prox-entrega"], "YYYY-MM-DD").format(
                    "DD/MM/YYYY"
                  )
                )
              ) : (
                <p style={{ margin: 0, color: "var(--light-blue)" }}>
                  Sem data prevista
                </p>
              )}{" "}
              <br />
              {this.state.status !== null && (
                <span className="badge warning">Aguardando abastecimento</span>
              )}
            </b>
          </Card>
        ) : (
          ""
        )}
      </>
    );
  }

  handlePercentChange = (e) => {
    const percentMask = percentualFormat(e.target.value);
    const { complementaryData } = this.state;

    this.setState({
      complementaryData: {
        ...complementaryData,
        gasPercent: percentMask,
      },
    });
  };

  renderEmergencialTab() {
    const { complementaryData } = this.state;

    return (
      <>
        {this.renderOptions()}
        {complementaryData["selectedProduct"] && (
          <>
            <br />
            {complementaryData["selectedProduct"] === "cilindros" ? (
              <input
                type="number"
                maxLength={3}
                placeholder={"Quantidade de cilindros"}
                value={complementaryData["qtde"] || ""}
                onChange={(e) =>
                  this.setState({
                    complementaryData: {
                      ...complementaryData,
                      qtde: Math.abs(e.target.value),
                    },
                  })
                }
              />
            ) : (
              <input
                type="text"
                placeholder={"% atual do tanque"}
                maxLength={7}
                value={complementaryData["gasPercent"]}
                onChange={this.handlePercentChange}
              />
            )}

            <br />
            <input
              placeholder="Faixa de horário preferencial:"
              value={complementaryData["horarioPreferencia"]}
              onChange={(e) =>
                this.setState({
                  complementaryData: {
                    ...complementaryData,
                    horarioPreferencia: e.target.value,
                  },
                })
              }
            />
          </>
        )}
      </>
    );
  }

  renderProgramadoTab() {
    const { complementaryData, nextPage, prevEntrega } = this.state;

    return (
      <>
        {nextPage === false ? (
          this.renderOptions()
        ) : (
          <>
            <p>Escolha uma data</p>
            <div
              className="calendar"
              style={{ justifyContent: "center", display: "flex" }}
            >
              <Select
                className="block"
                options={[
                  { value: null, title: "Selecione uma data" },
                  ...(prevEntrega || []).map((prev) => ({
                    value: prev,
                    title: moment(prev, "YYYY-MM-DD").format("DD/MM/YYYY"),
                  })),
                  { value: "outra", title: "Outra data" },
                ]}
                value={complementaryData["data"]}
                onChange={(e) =>
                  this.setState({
                    complementaryData: {
                      ...complementaryData,
                      data: e.target.value,
                    },
                    haveSubmited: false,
                  })
                }
              />
            </div>
            {complementaryData["selectedProduct"] === "cilindros" ? (
              <input
                placeholder="Quantidade:"
                type="number"
                value={complementaryData["qtde"] || ""}
                onChange={(e) =>
                  this.setState({
                    complementaryData: {
                      ...complementaryData,
                      qtde: Math.abs(e.target.value),
                    },
                  })
                }
              />
            ) : null}

            <br />

            <button onClick={() => this.setState({ nextPage: false })}>
              Voltar
            </button>
          </>
        )}
      </>
    );
  }

  render() {
    const {
      complementaryData,
      tab,
      nextPage,
      selectedCondominio,
      nrpedido,
    } = this.state;
    const { rawData } = this.props;

    this.getOptionsData();

    return (
      <>
        <h2 className="service-detail-title">{rawData.title}</h2>

        <p style={{ textAlign: "left" }}>{rawData.description}</p>
        {selectedCondominio !== "all" && (
          <LabelSwitch
            options={[
              {
                label: "Pedido Programado",
                value: "programado",
                onClick: (e, tab) => this.setState({ tab }),
              },
              {
                label: "Pedido Emergencial",
                value: "emergencial",
                onClick: (e, tab) => this.setState({ tab }),
              },
            ]}
          />
        )}

        {selectedCondominio !== "all" ? (
          <>
            {tab === "emergencial"
              ? this.renderEmergencialTab()
              : this.renderProgramadoTab()}
            <footer className="service-detail-footer">
              <button
                className="block"
                onClick={() => this.submit()}
                disabled={
                  (nextPage === true &&
                    (complementaryData["data"] === null ||
                      complementaryData["data"] === "Selecione uma data")) ||
                  (tab === "emergencial" &&
                    ((complementaryData["gasPercent"] === "" && complementaryData["qtde"] === "") ||
                      complementaryData["horarioPreferencia"] === ""))
                }
              >
                {tab === "programado" && nextPage === false
                  ? "Solicitar abastecimento"
                  : tab === "programado"
                  ? "Confirmar Pedido"
                  : "Abastecimento para outra data"}
              </button>
            </footer>
          </>
        ) : (
          <SelectAddressList
            onSubmit={(address) =>
              this.setState({
                selectedCondominio: address,
              })
            }
          />
        )}

        <Modal
          shown={false}
          ref={(ref) => (this.modalConfirm = ref)}
          title="Pedido feito!"
          confirmButton={true}
          confirmButtonText={"Ok"}
          confirmButtonAction={() =>
            this.props.onDismiss && this.props.onDismiss()
          }
        >
          <br />

          <p>O seu pedido será programado conforme data solicitada!</p>

          <p>Anote o protocolo:</p>
          <ServiceItem title="Pedido Programado">
            <>
              <br />
              Protocolo:
              <span
                style={{
                  float: "right",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {nrpedido || "Não encontrado"}
              </span>
            </>
          </ServiceItem>
        </Modal>

        <Modal
          shown={false}
          ref={(ref) => (this.modalHelp = ref)}
          title="Atendimento!"
          confirmButton={true}
          confirmButtonText={"Ok"}
        >
          <p style={{ textAlign: "justify" }}>
            Para solicitar abastecimento para outra data, entre em contato com a
            central de atendimento.
          </p>
          <p className="text-center">
            <button className="block outline" style={{ color: "#000" }}>
              0800 770 4922
            </button>
            <button className="block outline" style={{ color: "#000" }}>
              (11) 4197 9300
            </button>
          </p>
        </Modal>

        <Modal
          shown={false}
          ref={(ref) => (this.modalConfirmEmergencial = ref)}
          title="Aguarde!"
          confirmButton={true}
          confirmButtonText={"Ok"}
          confirmButtonAction={() => this.generateOrder()}
        >
          <p style={{ textAlign: "justify" }}>
            Nossas entregas são realizadas de segunda a sábado e são programadas
            com, pelo menos, 1 dia de antecedência. Mas fique tranquilo que
            vamos verificar a possibilidade de lhe atender ainda hoje!
          </p>
        </Modal>

        <Modal
          shown={false}
          ref={(ref) => (this.modalEmergencial = ref)}
          title="Aguarde!"
          confirmButton={true}
          confirmButtonText={"Central de atendimento"}
          confirmButtonAction={() => redirect(this.props, "/help")}
          declineButton={true}
          declineButtonText={"Cancelar"}
        >
          <p style={{ textAlign: "justify" }}>
            Nossas entregas são programadas com, pelo menos, 1 dia de
            antecedência. Para solicitar abastecimento emergencial, por favor,
            clique aqui que direcionaremos você para nossa central de
            atendimento!
          </p>
        </Modal>

        <Modal
          ref={(ref) => (this.sameDeliveryDateModal = ref)}
          title="Aguarde!"
          shown={false}
          declineButton={true}
          declineButtonText={"Manter data atual"}
          declineButtonAction={() => {
            this.waitModal && this.waitModal.open();
            this.sameDeliveryDateModal && this.sameDeliveryDateModal.close();
            this.setState({ status: "aguardando" });
          }}
          confirmButton={true}
          confirmButtonText="Abastecimento para outra data"
          confirmButtonAction={() => {
            this.setState({ tab: "programado" });
            this.sameDeliveryDateModal && this.sameDeliveryDateModal.close();
          }}
        >
          Já existe uma entrega prevista para hoje. Deseja solicitar
          abastecimento para outra data?
        </Modal>

        <Modal
          ref={(ref) => (this.waitModal = ref)}
          title="Aguarde"
          shown={false}
          confirmButton={true}
          confirmButtonText="Ok"
          confirmButtonAction={() => {
            this.waitModal && this.waitModal.close();
            this.sameDeliveryDateModal && this.sameDeliveryDateModal.close();
            this.setState({ tab: "programado" });
          }}
        >
          Por favor, aguarde o abastecimento que está previsto para hoje.
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(RequestSupply);
