import React, { Component } from "react";
import Modal from "../../components/Modal/Modal";
import CondominioService from "../../services/condominio";

class ChartFilterModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProduct: "granel",
      selectedUnits: [],
      selectedAddress: "todos",
      selectedAddresses: [],
      selectedDeliveryPlaces: [],
      selectedInterval: "ultimo-ano",
      selectedMonths: [],
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
    };
  }

  get availableProducts() {
    const { productsData } = this.props;
    return Object.entries(productsData || {}).reduce(
      (reduced, [key, value]) => {
        if (reduced.indexOf(key) === -1) reduced.push(key);
        return reduced;
      },
      []
    );
  }

  get showProductsFilter() {
    return this.availableProducts.length > 1;
  }

  get onlyProduct() {
    const { productsData } = this.props;
    return (Object.values(
      Object.entries(productsData || {}).reduce((reduced, [key, value]) => {
        if (!reduced[key]) reduced[key] = value;
        return reduced;
      }, {})
    ) || [])[0];
  }

  filterByUnits(selectedUnits) {
    this.setState(
      { selectedProduct: "cilindros", selectedUnits },
      this.onSubmit
    );
  }

  filterByAddress(address) {
    const { addressData } = this.props;

    const addrIdx = addressData.addresses.indexOf(address);
    if (addrIdx > -1)
      this.setState(
        {
          selectedAddress: "especificos",
          selectedAddresses: [address],
          selectedDeliveryPlaces: [addressData.deliveryPlaces[addrIdx]],
        },
        this.onSubmit
      );
  }

  onSubmit = () => {
    const { onSubmit } = this.props;
    const {
      selectedProduct,
      selectedUnits,
      selectedAddress,
      selectedAddresses,
      selectedDeliveryPlaces,
      selectedInterval,
      selectedMonths,
    } = this.state;

    onSubmit &&
      onSubmit({
        selectedProduct: this.showProductsFilter
          ? selectedProduct
          : ((this.onlyProduct || {}).un || "").toLowerCase() === "kg"
          ? "granel"
          : "cilindros",
        selectedUnits: this.showProductsFilter
          ? selectedUnits
          : this.availableProducts,
        selectedAddress,
        selectedAddresses,
        selectedDeliveryPlaces,
        selectedInterval,
        selectedMonths,
      });
  };

  toggleUnit(unit) {
    const { selectedUnits } = this.state;

    if (selectedUnits.indexOf(unit) === -1)
      this.setState({ selectedUnits: [...selectedUnits, unit] });
    else
      this.setState({
        selectedUnits: selectedUnits.filter((un) => un !== unit),
      });
  }

  toggleAddress(addr) {
    const { selectedAddresses } = this.state;

    if (selectedAddresses.indexOf(addr) === -1)
      this.setState({ selectedAddresses: [...selectedAddresses, addr] });
    else
      this.setState({
        selectedAddresses: selectedAddresses.filter((un) => un !== addr),
      });
  }

  toggleMonth(number) {
    const { selectedMonths } = this.state;

    if (selectedMonths.indexOf(number) === -1)
      if (selectedMonths.length < 3) {
        this.setState({ selectedMonths: [...selectedMonths, number] });
      } else {
        selectedMonths.shift();
        this.setState({ selectedMonths: [...selectedMonths, number] });
      }
    else
      this.setState({
        selectedMonths: selectedMonths.filter((un) => un !== number),
      });
  }

  render() {
    const { shown, monthsData, productsData, addressData } = this.props;
    const {
      selectedProduct,
      selectedUnits,
      selectedAddress,
      selectedAddresses,
      selectedInterval,
      selectedMonths,
      selectedCondominio,
    } = this.state;

    if (shown === false) return null;

    let filteredProps = {
      ...this.props,
      onSubmit: null,
    };

    return (
      <Modal className="filter-modal" {...filteredProps}>
        <h3>
          Selecione os parâmetros abaixo para gerar o seu gráfico de consumo:
        </h3>

        <div className="filter-options">
          {this.showProductsFilter && (
            <>
              <p className="title">Produtos</p>

              <label>
                <input
                  type="radio"
                  name="is-ready"
                  value="granel"
                  className={selectedProduct === "granel" ? "checked" : ""}
                  onChange={() => this.setState({ selectedProduct: "granel" })}
                />
                Fornecimento à granel
              </label>

              <label>
                <input
                  type="radio"
                  name="is-ready"
                  value="null"
                  className={selectedProduct === "cilindros" ? "checked" : ""}
                  onChange={() =>
                    this.setState({ selectedProduct: "cilindros" })
                  }
                />
                Cilindros (unidades)
              </label>

              <div style={{ paddingLeft: "2rem" }}>
                {Object.entries(productsData || {}).map(([key, value]) => {
                  if (value.un.toLowerCase() === "un" && value.tipo.toLowerCase() === "glp envasado") {
                    return (
                      <label>
                        <input
                          type="checkbox"
                          className={
                            selectedUnits.indexOf(key) > -1 ? "checked" : ""
                          }
                          onClick={() => this.toggleUnit(key)}
                          disabled={selectedProduct !== "cilindros"}
                        />
                        {value["desc-item"].toUpperCase()}
                      </label>
                    );
                  }

                  return null;
                })}
              </div>
            </>
          )}

          {selectedCondominio === "all" ? (
            <>
              <p className="title">Endereços</p>

              <label>
                <input
                  type="radio"
                  name="is-ready"
                  value={"todos"}
                  className={selectedAddress === "todos" ? "checked" : ""}
                  onChange={() => this.setState({ selectedAddress: "todos" })}
                />
                Todos os endereços
              </label>

              <label>
                <input
                  type="radio"
                  name="is-ready"
                  value={"especificos"}
                  className={selectedAddress === "especificos" ? "checked" : ""}
                  onChange={() =>
                    this.setState({ selectedAddress: "especificos" })
                  }
                />
                Selecione os endereços
              </label>

              <div style={{ paddingLeft: "2rem" }}>
                {((addressData && addressData.addresses) || []).map((addr) => (
                  <label key={`addr-checkbox-${addr}`}>
                    <input
                      type="checkbox"
                      className={
                        selectedAddresses.indexOf(addr) > -1 ? "checked" : ""
                      }
                      onClick={() => this.toggleAddress(addr)}
                      disabled={selectedAddress !== "especificos"}
                    />
                    {addr}
                  </label>
                ))}
              </div>
            </>
          ) : (
            ""
          )}

          <p className="title">Período</p>

          <label>
            <input
              type="radio"
              name="is-ready"
              value={"ultimo-ano"}
              className={selectedInterval === "ultimo-ano" ? "checked" : ""}
              onChange={() => this.setState({ selectedInterval: "ultimo-ano" })}
            />
            Últimos 12 meses
          </label>

          <label>
            <input
              type="radio"
              name="is-ready"
              value={"comparativo"}
              className={selectedInterval === "comparativo" ? "checked" : ""}
              onChange={() =>
                this.setState({ selectedInterval: "comparativo" })
              }
            />
            Fazer comparativo (você poderá selecionar até 3 meses)
          </label>

          <div style={{ paddingLeft: "2rem" }}>
            {(monthsData || []).map((month) => (
              <label key={`addr-checkbox-${month.number}`}>
                <input
                  type="checkbox"
                  className={
                    selectedMonths.indexOf(month.number) > -1 ? "checked" : ""
                  }
                  onClick={() => this.toggleMonth(month.number)}
                  disabled={selectedInterval !== "comparativo"}
                />
                {month.label}
              </label>
            ))}
          </div>
        </div>

        <button className="block link" onClick={this.onSubmit}>
          Filtrar
        </button>
      </Modal>
    );
  }
}

export default ChartFilterModal;
