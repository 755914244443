import React, { Component } from "react";

import "./HomeServices.scss";

import apiContext from "../../api";
import { ServiceCards } from "../Services/Services";

import HomeTemplate, { redirect } from "../HomeTemplate/HomeTemplate";

import LabelSwitch from "../../components/LabelSwitch/LabelSwitch";
import ServiceItem from "../../components/ServiceItem/ServiceItem";
import { IconSpinner, IconArrowLeft } from "../../components/Icons/Icons";
import ClienteService from "../../services/cliente";
import CondominioService from "../../services/condominio";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { connect } from "react-redux";
import Modal from "../../components/Modal/Modal";
import { ServicesConfirmContent } from "../ServicesConfirm/ServicesConfirm";
import { moneyFormat } from "../../components/Masks/Masks";
import { loadingOperations } from "../../state/ducks/loading";
import DocsImage from "../../assets/illustrations/google-docs.png";

const LoadingLine = (props) => (
  <p className="text-center">
    <IconSpinner color="#000" style={{ animation: "1s infinite spin" }} />
  </p>
);

const ProtocolItem = (props) => (
  <ServiceItem
    className="protocol-item"
    endereco={props.address.length > 0 ? props.address[0].endereco : ""}
    title={props["assunto"]}
  >
    Protocolo: {props["nr-ordem-servico"]}<br/>
    Valor: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props["valor"])}
    <button
      className="link block"
      onClick={() => props.onDetail && props.onDetail()}
    >
      Ver detalhes do protocolo
    </button>
  </ServiceItem>
);

class HomeServices extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    let tab = "services";
    if (props.location && props.location.state && props.location.state.tab)
      tab = props.location.state.tab;

    this.state = {
      tab,
      protocols: null,
      protocolOnDetail: null,
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
      address: {},
    };
  }

  componentDidMount() {
    const { ClienteEntrRetorno } = ClienteService.localEntrega || {};
    const { selectedCondominio } = this.state;
     
    if (ClienteEntrRetorno) {
      if (selectedCondominio === "all")
        this.setState({
          address: ClienteEntrRetorno,
        });
      else
        this.setState({
          address: ClienteEntrRetorno[0],
        });
    }

    // this.context.ConsigazApi.consultaLocalEntrega(CPFCNPJ)
    //   .then(ClienteService.consultaLocalEntregaApiParser)
    //   .then(({ Cliente }) => {
    //     if (Cliente) {
    //       this.props.dismissLoading();
    //       if (selectedCondominio === "all")
    //         this.setState({
    //           address: Cliente.ClienteRetorno[0].ClienteEntrRetorno,
    //         });
    //       else
    //         this.setState({
    //           address: Cliente.ClienteRetorno[0].ClienteEntrRetorno[0],
    //         });
    //     }
    //   })
    //   .catch((errorDescription) => {
    //     this.props.dismissLoading();
    //     this.setState({}, () => this.props.showToast("Ops!", errorDescription));
    //   });

    if (selectedCondominio === "all") CondominioService.selectMany = [];
  }

  componentDidUpdate() {
    const { location } = this.props;
    const { locationTab } = this.state;

    if (
      location &&
      location.state &&
      location.state.tab &&
      location.state.tab !== locationTab
    )
      this.setState({
        tab: location.state.tab,
        locationTab: location.state.tab,
        isRedirectTab: true,
        protocols: null,
        protocolOnDetail: null,
      });
  }

  handleCancelation(protocol, cancelationReason) {
    const { CPFCNPJ } = ClienteService.localEntrega;
    const { "local-entrega": localEntrega } = CondominioService.selected;

    this.props.showLoading();
    this.context.ConsigazApi.cancelaProtocolo(
      CPFCNPJ,
      localEntrega,
      protocol["tipo"],
      protocol["nr-ordem-servico"],
      cancelationReason
    )
      .then(({ Retorno }) => {
        this.setState({ protocols: null, protocolOnDetail: null });
        this.props.dismissLoading();
        if (Retorno) {
          this.props.showToast("Cancelado", Retorno[0].situacao);
        } else {
          this.props.showToast("Ops", "Não foi possível cancelar o protocolo");
        }
      })
      .catch((errorDescription) => {
        this.props.dismissLoading();
        this.setState(
          {
            requestSuccess: false,
          },
          () => this.props.showToast("Ops!", errorDescription)
        );
      });
  }

  renderProtocolTab() {
    const { CPFCNPJ, isAdmLogin } = ClienteService.localEntrega;
    const { [`local-entrega`]: localEntrega } =
      CondominioService.selected || "";
    const { selectedCondominio, address } = this.state;
    const dataIni = new Date();
    const dataFim = new Date();
    dataFim.setFullYear(dataFim.getFullYear() - 1);

    const {
      protocols,
      protocolOnDetail,
      showCancelationModal,
      protocolAddress,
    } = this.state;
    
    if (protocols)
      return (
        <>
          <div className="protocol-list">
            {(!protocols || protocols.length === 0) && (
              <>
                <img
                  alt="DocsImage"
                  src={DocsImage}
                  style={{ height: "100px", margin: "1rem 0" }}
                />
                <br />
                <i>
                  <b>Não há nenhum registro de protocolo!</b>
                </i>
              </>
            )}
            {(protocols || []).map((p, idx) => (
              <ProtocolItem
                key={`protocol-item-${idx}`}
                {...p}
                address={
                  selectedCondominio === "all" &&
                  address.filter(
                    (addr) => addr["local-entrega"] === p["local-entrega"]
                  )
                }
                onDetail={() =>
                  this.setState({
                    protocolOnDetail: p,
                    protocolAddress:
                      selectedCondominio === "all" &&
                      address.filter(
                        (addr) => {
                          return addr["local-entrega"].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase() === p["local-entrega"].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()
                        }
                      ),
                    showCancelationModal: false,
                    cancelationReason: "",
                  })
                }
              />
            ))}
          </div>
          {protocolOnDetail && !showCancelationModal && (
            <Modal
              ref={(ref) => (this.protocolDetailModal = ref)}
              className="protocol-detail-modal"
              closeButton={true}
              onDismiss={() => this.setState({ protocolOnDetail: null })}
            >
              {protocolOnDetail.situacao.toLowerCase() === "cancelada" && (
                <span className="badge danger badge-cancel"> Cancelada </span>
              )}
              <p className="title">
                {protocolAddress &&
                  protocolAddress.length > 0 &&
                  protocolAddress[0].endereco}
              </p>
              <p className="title">{protocolOnDetail["assunto"]}</p>
              <p>Protocolo: {protocolOnDetail["nr-ordem-servico"]}</p>

              <small>Data de contratação</small>
              <p className="value">
                {new Date(
                  `${protocolOnDetail["dt-ordem-servico"]} 00:00:00`
                ).toLocaleDateString("pt-br")}
              </p>

              <small>Valor</small>
              <p className="value">
                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(protocolOnDetail?.valor)}
              </p>

              <small>Data máxima para atendimento</small>
              <p className="value">
                {new Date(
                  `${protocolOnDetail["dt-prev-atend"]} 00:00:00`
                ).toLocaleDateString("pt-br")}
              </p>

              {(protocolOnDetail["valor"] && (
                <>
                  <small>Valor do serviço</small>
                  <p className="value blue">
                    {moneyFormat(protocolOnDetail["valor"])}
                  </p>
                </>
              )) ||
                null}

              {protocolOnDetail["motivo"] && (
                <>
                  <p>
                    <b>Detalhe Cancelamento</b>
                  </p>
                  {protocolOnDetail["motivo"]}
                </>
              )}

              <footer className="text-center">
                {protocolOnDetail["cancela"] === true && !isAdmLogin && (
                  <button
                    onClick={() =>
                      this.setState({
                        showCancelationModal: true,
                        cancelationReason: "",
                      })
                    }
                    className="link secondary"
                  >
                    Cancelar Pedido
                  </button>
                )}
                {false && (
                  <p>
                    <b>Detalhe cancelamento</b>
                    <br />
                    Lorem ipsum dolor sit amet
                  </p>
                )}
              </footer>
            </Modal>
          )}
          <Modal
            ref={(ref) => (this.protocolContestationModal = ref)}
            shown={false}
            closeButton={true}
            title="Contestação"
          >
            Para contestar a finalização do seu protocolo fale com a nossa
            central de atendimento.
            <br />
            <button
              onClick={() => redirect(this.props, "/help")}
              className="link block"
            >
              Fale conosco
            </button>
          </Modal>
        </>
      );
    else {
      this.context.ConsigazApi.memConsultaProtocolos(
        CPFCNPJ,
        this.context.ConsigazApi.formatDate(dataIni),
        this.context.ConsigazApi.formatDate(dataFim),
        1,
        selectedCondominio === "all" ? "" : localEntrega
      ).then(
        ({ Protocolos: protocols }) =>
          this.setState({ protocols: protocols.reverse() }),
        (data) => {
          this.setState({ protocols: [] }, () => {
            this.props.showToast("Ops", "Erro ao buscar os protocolos");
          });
        }
      );
      return <LoadingLine />;
    }
  }

  renderServicesTab() {
    const { submitedServices } = this.state;

    if (submitedServices)
      return (
        <>
          <br />
          <IconArrowLeft
            color="#000"
            size={24}
            style={{ float: "left", cursor: "pointer" }}
            onClick={() => this.setState({ submitedServices: null })}
          />
          <ServicesConfirmContent
            history={this.props.history}
            location={{
              state: { services: submitedServices },
            }}
            showIllustration={false}
            onFinish={(services) =>
              this.setState({
                submitedServices: null,
                protocols: null,
                // serviceProtocol: services,
                tab: "protocol",
              })
            }
          />
        </>
      );
    else
      return (
        <>
          <br />
          <ServiceCards
            history={this.props.history}
            confirmationUrl={"/home-services-confirm"}
            onSubmit={(submitedServices) => this.setState({ submitedServices })}
          />
        </>
      );
  }

  render() {
    const {
      tab,
      showCancelationModal,
      protocolOnDetail,
      cancelationReason,
    } = this.state;
    const { situacao } = CondominioService.selected || {};

    return (
      <HomeTemplate
        history={this.props.history}
        activeTab="HomeServices"
        className="home-services-page"
      >
        <LabelSwitch
          selected={tab}
          options={[
            {
              label: "Serviços",
              value: "services",
              onClick: (e, tab) => this.setState({ tab }),
            },
            {
              label: "Protocolos",
              value: "protocol",
              onClick: (e, tab) => this.setState({ tab }),
            },
          ]}
        />

        {situacao !== 2 && tab === "services"
          ? this.renderServicesTab()
          : this.renderProtocolTab()}
        {protocolOnDetail && showCancelationModal && (
          <Modal
            title="Deseja cancelar?"
            confirmButton={true}
            confirmButtonText="Sim, desejo cancelar o pedido"
            confirmButtonAction={() =>
              this.handleCancelation(protocolOnDetail, cancelationReason)
            }
            declineButton={true}
            declineButtonText="Não"
            declineButtonAction={(modal) => {
              modal.close();
              this.setState({ protocolOnDetail: null });
            }}
          >
            <p>Tem certeza que deseja fazer o cancelamento do pedido?</p>
            <p>
              <b>
                Por favor, informe no campo abaixo o motivo do cancelamento.
              </b>
            </p>
            <textarea
              style={{ width: "100%" }}
              maxLength={200}
              onChange={(e) =>
                this.setState({ cancelationReason: e.target.value })
              }
            >
              {/* {cancelationReason} */}
            </textarea>
          </Modal>
        )}
      </HomeTemplate>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(HomeServices);
