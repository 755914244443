import React from "react";
import apiContext from "../../../api";
import Select from "../../../components/Select/Select";
import { Component } from "react";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../../state/ducks/toast";
import { connect } from "react-redux";
import { loadingOperations } from "../../../state/ducks/loading";
import { moneyFormat } from "../../../components/Masks/Masks";
import Modal from "../../../components/Modal/Modal";
import WarningImage from "../../../assets/illustrations/warning.png";
import { CustomRadio } from "../../../components/CustomRadio/customRadio";

class TightnessTest extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      complementaryData: this.initialComplementaryData,
      haveSubmited: false,
    };
  }

  get isValid() {
    const { complementaryData } = this.state;
    return (
      complementaryData["tipo-prumada"] !== null &&
      complementaryData["tipo-prumada"] !== "Tipos de prumada"
    );
  }

  get initialComplementaryData() {
    return {
      "tipo-prumada": null,
      requestedByCondominium: null,
      suggestedTime: null,
      gasPlantHighOrLowPressure: null,
      internalRegulator: null,
      measuringBoard: null,
      numberOfApartments: null,
      numberOfConsumptionPoints: null,
    };
  }

  get value() {
    const { complementaryData } = this.state;

    if (complementaryData["tipo-prumada"] === "individual") {
      return 180;
    } else if (complementaryData["tipo-prumada"] === "coletiva") {
      return 712;
    } else {
      return null;
    }
  }

  submit = () => {
    const { complementaryData } = this.state;

    if (complementaryData["tipo-prumada"] === "indefinido") {
      this.undefinedModal && this.undefinedModal.open();
      return;
    }

    this.setState({ haveSubmited: true }, this.generateOrder);
  };

  generateOrder = () => {
    const { onToggle } = this.props;
    this.priorityModal && this.priorityModal.close();

   const {
      "tipo-prumada": tipoPrumada,
      informacoes,
      requestedByCondominium,
      suggestedTime,
      gasPlantHighOrLowPressure,
      internalRegulator,
      measuringBoard,
      numberOfApartments,
      numberOfConsumptionPoints } = this.state.complementaryData;

    onToggle &&
      onToggle({
        informacoes: `A prumada é ${tipoPrumada}
        ${requestedByCondominium ? `O serviço está sendo solicitado por condomínio: ${requestedByCondominium}` : ""}
        ${suggestedTime ? `Sugira um horário de atendimento: ${suggestedTime}` : ""}
        ${gasPlantHighOrLowPressure ? `A central de gás é de alta ou baixa pressão: ${gasPlantHighOrLowPressure}` : ""}
        ${internalRegulator ? `O regulador está interno na residência: ${internalRegulator}` : ""}
        ${measuringBoard ? `O quadro de medição está no hall ou área externa: ${measuringBoard}` : ""}
        ${numberOfApartments ? `Número de apartamentos: ${numberOfApartments}` : ""}
        ${numberOfConsumptionPoints ? `Número de pontos de consumo: ${numberOfConsumptionPoints}` : ""}
        ${informacoes ? `Observações: ${informacoes}` : ""}`,
        servico: tipoPrumada === "indefinido" ? "Estanqueidade" : null,
        value: this.value,
      });
  };

  handleSelectInfo(field, value) {
    this.setState({ ...this.state, complementaryData: {
      ...this.state.complementaryData,
      [field]: value,
    }})
  }

  render() {
    const { complementaryData } = this.state;
    const { checked, onWarranty, rawData, removeServiceSelection } = this.props;

    return (
      <>
        <h2
          style={{
            marginTop: 0,
            textAlign: "left",
            fontStyle: "oblique",
          }}
        >
          {rawData.title}
        </h2>
        <p style={{ textAlign: "justify" }}>{rawData.description}</p>

        <p>
          <b>Por favor, informe qual é o tipo de prumada do seu condomínio.</b>
        </p>
        <Select
          className="block"
          options={[
            {
              value: null,
              title: "Tipos de prumada",
            },
            {
              value: "individual",
              title: "Prumada individual",
            },
            {
              value: "coletiva",
              title: "Prumada coletiva",
            },
            {
              value: "indefinido",
              title: "Não sei o tipo de prumada",
            },
          ]}
          value={complementaryData["tipo-prumada"]}
          onChange={(e) =>
            this.setState({
              complementaryData: {
                ...this.initialComplementaryData,
                "tipo-prumada": e.target.value,
              },
              haveSubmited: false,
            })
          }
          disabled={checked}
        />
        <br />

        {complementaryData["tipo-prumada"] === "individual" && (
          <>
            <p style={{ textAlign: "left" }}>
              <b>O valor cobrado para este serviço é de</b>
              &nbsp;
              <span className="price-badge">{moneyFormat(this.value)}</span>
            </p>
            <hr />
            <h3 style={{ textAlign: "left" }}>Prazos</h3>
            <p style={{ textAlign: "left" }}>
              <b>
                {rawData.condPagamento && (
                  <>
                    Prazo para pagamento: {rawData.condPagamento || ""}
                    <br />
                  </>
                )}
                {rawData.deadline &&
                  `Prazo para atendimento: ${rawData.deadline || ""}`}
              </b>
            </p>
            <p style={{ textAlign: "left" }}>
              Para a sua realização será necessário fechar o registro de gás do
              seu apartamento.
            </p>
          </>
        )}

        {complementaryData["tipo-prumada"] === "coletiva" && (
          <>
            <p style={{ textAlign: "left" }}>
              <b>O valor cobrado para este serviço é de</b>
              &nbsp;
              <span className="price-badge">{moneyFormat(this.value)}</span>
            </p>
            <hr />
            <h3 style={{ textAlign: "left" }}>Prazos</h3>
            <p style={{ textAlign: "left" }}>
              <b>
                {rawData.condPagamento && (
                  <>
                    Prazo para pagamento: {rawData.condPagamento || ""}
                    <br />
                  </>
                )}
                {rawData.deadline &&
                  `Prazo para atendimento: ${rawData.deadline || ""}`}
              </b>
            </p>
            <p style={{ textAlign: "left" }}>
              Para a sua realização será necessário fechar o registro de gás do
              seu prédio, portanto sugerimos que entre em contato com o síndico
              ou zelador antes da contratação do serviço.
            </p>
          </>
        )}

        {complementaryData["tipo-prumada"] === "indefinido" && (
          <>
            <p style={{ textAlign: "justify" }}>
              Para que possamos orçar o serviço, é necessário sabermos o tipo de
              prumada. Neste caso sugerimos que entre em contato com o sindico
              ou zelador do condomínio para que possam lhe orientar
            </p>
            <hr />
            <h4>Informação Adicional</h4>
            <p>
              <b>
                O boleto para pagamento será enviado para o e-mail cadastrado,
                após a conclusão do serviço.
              </b>
            </p>
          </>
        )}

        {complementaryData["tipo-prumada"] &&
          complementaryData["tipo-prumada"] !== "indefinido" &&
          complementaryData["tipo-prumada"] !== "Tipos de prumada" && (
            <>
              <div className="wrap-itens">
                <div className="item">
                  <p
                    style={{ padding: "5px 25px", margin: 0 }}
                    className="text-blue border-bottom"
                  >
                    Sugira um horário de atendimento
                  </p>
                  <input
                    type="time"
                    id="suggested-time"
                    onChange={(e) => this.handleSelectInfo("suggestedTime", e.target.value)}
                  />
                </div>
                <div className="item">
                  <p
                    style={{ padding: "5px 25px", margin: 0 }}
                    className="text-blue border-bottom"
                  >
                    A central de gás é de alta ou baixa pressão?
                  </p>
                  <CustomRadio
                    onChange={(option) => this.handleSelectInfo("gasPlantHighOrLowPressure", option.label)}
                    options={[
                      { id: "option_1", label: "ALTA PRESSÃO" },
                      { id: "option_2", label: "BAIXA PRESSÃO" },
                    ]}
                  />
                </div>

                <div className="item">
                  <p
                    style={{ padding: "5px 25px", margin: 0 }}
                    className="text-blue border-bottom"
                  >
                    O regulador está interno na residência?
                  </p>
                  <CustomRadio
                    onChange={(option) => this.handleSelectInfo("internalRegulator", option.label)}
                    options={[
                      { id: "option_1", label: "SIM" },
                      { id: "option_2", label: "NÃO" },
                    ]}
                  />
                </div>

                <div className="item">
                  <p
                    style={{ padding: "5px 25px", margin: 0 }}
                    className="text-blue border-bottom"
                  >
                    O quadro de medição está no hall ou área externa?
                  </p>
                  <CustomRadio
                    onChange={(option) => this.handleSelectInfo("measuringBoard", option.label)}
                    options={[
                      { id: "option_1", label: "HALL" },
                      { id: "option_2", label: "ÁREA EXTERNA" },
                    ]}
                  />
                </div>

                <div className="item">
                  <p
                    style={{ padding: "5px 25px", margin: 0 }}
                    className="text-blue border-bottom"
                  >
                    O serviço está sendo solicitado por condomínio?
                  </p>
                  <CustomRadio
                    onChange={(option) => this.handleSelectInfo("requestedByCondominium", option.label)}
                    options={[
                      { id: "option_1", label: "SIM" },
                      { id: "option_2", label: "NÃO" },
                    ]}
                  />
                </div>

                {complementaryData.requestedByCondominium === "SIM" && <>
                  <div className="item">
                    <p
                      style={{ padding: "5px 25px", margin: 0 }}
                      className="text-blue border-bottom"
                    >
                      Número de apartamentos:
                    </p>
                    <input type="number" onChange={(e) => this.handleSelectInfo("numberOfApartments", e.target.value)} />
                  </div>

                  <div className="item">
                    <p
                      style={{ padding: "5px 25px", margin: 0 }}
                      className="text-blue border-bottom"
                    >
                      Número de pontos de consumo:
                    </p>
                    <input type="number" onChange={(e) => this.handleSelectInfo("numberOfConsumptionPoints", e.target.value)} />
                  </div>
                </>}
              </div>
              <p>
                <b>Use o campo para detalhar</b>
              </p>
              <textarea
                style={{ width: "100%" }}
                value={complementaryData.informacoes || ""}
                onChange={(e) => this.handleSelectInfo("informacoes", e.target.value)}
              />
            </>
          )}

        <footer
          style={{
            [`--container-width`]: "100%",
            background: "var(--dark-blue)",
            textAlign: "center",
            margin: "calc(var(--padding) * -1)",
            marginTop: "var(--padding)",
            borderBottomRightRadius: ".3rem",
            borderBottomLeftRadius: ".3rem",
            padding: "var(--padding)",
            color: "white",
          }}
        >
          <div style={{ marginBottom: "1rem" }}>
            {complementaryData["tipo-prumada"] !== "indefinido" && (
              <button
                onClick={() => onWarranty && onWarranty()}
                className="inline-link"
              >
                Termos de garantia
              </button>
            )}
          </div>
          <button
            className="block"
            style={{ margin: "0rem auto" }}
            onClick={this.submit}
            disabled={!this.isValid}
          >
            {!this.isValid
              ? "Selecione o tipo de prumada"
              : complementaryData["tipo-prumada"] === "indefinido"
              ? checked
                ? "Desselecionar"
                : "Selecionar"
              : "Confirmar a solicitação do serviço"}
          </button>
        </footer>
        <Modal
          ref={(ref) => (this.undefinedModal = ref)}
          shown={false}
          title="Ops"
          closeButton={true}
          onClose={() => removeServiceSelection(rawData)}
          confirmButton={true}
          confirmButtonText="Ok"
          confirmButtonAction={(modal) => {
            modal.close();
            removeServiceSelection(rawData);
          }}
        >
          <br />
          Para que possamos orçar o serviço, é necessário sabermos o tipo de
          prumada. Neste caso sugerimos que entre em contato com o sindico ou
          zelador do condomínio para que possam lhe orientar.
          <br />
          <br />
          <img src={WarningImage} height={140} alt="Modal img" />
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(TightnessTest);
