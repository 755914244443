import * as types from './types'
import { createReducer } from '../../utils'

/* State shape
  false
*/

const initialState = false

const reducer = createReducer(initialState)({
  [types.SHOW]: () => true,
  [types.DISMISS]: () => false,
})

export default reducer