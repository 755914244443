import * as types from "./types";

export const show = ( title, description, timeout=2000 ) => ({
    type: types.SHOW,
    payload: {
        title,
        description,
        timeout
    },
} )

export const dismiss = ( ) => ({
    type: types.DISMISS,
    payload: {},
} )