import * as types from "./types";

export const show = () => ({
    type: types.SHOW,
    payload: true,
} )

export const dismiss = () => ({
    type: types.DISMISS,
    payload: false,
} )