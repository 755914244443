import React from 'react'

export default props => (
  <>
    <h1>Termos de Garantia</h1>

    <h2>Garantia</h2>
    <p>- Nossa garantia abrange somente materiais e serviços por nós executados, com validade de 01 (Hum) ano para os Reguladores e Medidores, e 05 (Cinco) anos para os demais itens.</p>

    <h2>Extinção da Garantia</h2>
    <p>- Por decurso de prazo;</p>
    <p>- Interrupção do fornecimento de Gás GLP;</p>
    <p>- Assistência Técnica prestada por terceiros, não autorizados pela CONSIGAZ.</p>

    <h2>Parte Comercial</h2>
    <p>- As peças relacionadas na "página 1" poderão sofrer algumas alterações ao término das instalações.</p>

    <h2>Assistência Técnica e Abastecimento</h2>
    <p>- Mantemos em quadro de funcionários, equipes especializadas e treinadas dentro dos padrões técnicos exigidos pelos órgãos competentes para execução destes serviços.</p>

    <h2>Vantagens do Cliente CONSIGAZ</h2>
    <p>- Assistência Técnica Permanente;</p>
    <p>- Veículos técnicos abastecidos e equipados com rádio comunicação para Agilidade no Atendimento</p>
    <p>- Equipe técnica, treinada dentro das normas da A.B.N.T;</p>
    <p>Caso ocorram alterações nos preços do material acima descrito, o orçamento será reavaliado.</p>
    <p>- Permanecemos a inteira disposição de V.S.as. quanto ao acima exposto e quaisquer esclarecimento que se fizerem necessários.</p>
  </>
)