import React from "react";

import WarrantyTerms from "../../components/WarrantyTerms/WarrantyTerms";

export default ({ location }) => {
  let { search } = location || {};

  search = (search || "")
    .replace("?", "")
    .split("&")
    .reduce((reduced, param) => {
      const splited = param.split("=");
      if (splited.length > 1) reduced[splited[0]] = splited[1];
      return reduced;
    }, {});

  const style = {
    padding: "2rem",
  };

  if (!search["disable-scroll"])
    Object.assign(style, {
      maxHeight: "calc(100vh - 4rem)",
      overflowY: "scroll",
      overflowX: "hidden",
      WebkitOverflowScrolling: "touch",
    });

  return (
    <div style={style}>
      <WarrantyTerms />
    </div>
  );
};
