import { createContext } from "react";

import ConsigazApi from './ConsigazApi'
import ConsigazReportApi from './ConsigazReportApi'
import CpfApi from './CpfApi'

export const defaultContext = {
  ConsigazApi,
  ConsigazReportApi,
  CpfApi
}
export default createContext(defaultContext)