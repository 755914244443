import React from "react";
import apiContext from '../../api'

import "./CardCarrossel.scss";

export default class CardCarrossel extends React.Component {
  static contextType = apiContext

  constructor(props) {
    super(props);

    this.state = {
      banners: [],
    };
  }

  componentDidMount() {
    this.context.ConsigazApi.dinamicBanners().then((response) => {
      this.setState({ banners: response.Saida.Banners })
    });
  }

  get className() {
    return `card ${this.props.className}`;
  }

  renderTitle() {
    const { title } = this.props;

    if (title) return <p className="title">{title}</p>;
  }

  handleClick2() {
    window.open('https://conteudo.consigaz.com.br/energia', '_blank');
    return null;
  }

  render() {
    const { children, style } = this.props;
    return (
      <>
        {this.state.banners.map((banner) => (
          <img
            src={banner.CaminhoWeb}
            className={"imgDinamicBanner"}
            style={{
              ...style,
              marginBottom: 20,
            }}
            onClick={() => window.open(banner.ApontarPara, "_blank")}
          />
        ))}
      </>
    );
  }
}
