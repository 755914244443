import React, { Component } from 'react'

import Modal from '../Modal/Modal'

import './ConsumptionModal.scss'
import { redirect } from '../Page/Page'
import InvoiceService from '../../services/invoice'
import { moneyFormat, weightFormat } from '../Masks/Masks'
import ClienteService from '../../services/cliente'
import TicketService from '../../services/ticket'
import moment from 'moment'
export default class ConsumptionModal extends Component {

  constructor(props) {
    super(props)

    this.state = {
      invoiceData: props.invoiceData,
      ticketData: props.ticketData
    }
  }

  static getDerivedStateFromProps(props, state) {
    return { invoiceData: props.invoiceData, ticketData: props.ticketData }

  }

  getInterval = () => {
    const { invoiceData } = this.state
    if (!invoiceData) return null

    const dadosGerias = invoiceData['tt-dados-gerais'] && invoiceData['tt-dados-gerais'][0]
    if (!dadosGerias) return null

    const startDate = new Date(`${dadosGerias['dt-leitura-ant']} 00:00:00`).toLocaleDateString('pt-br')
    const endDate = new Date(`${dadosGerias['dt-leitura']} 00:00:00`).toLocaleDateString('pt-br')

    return `${startDate} - ${endDate}`
  }

  getDadosGerias = () => {
    const { invoiceData } = this.state

    if (!invoiceData) return null

    try {
      return invoiceData['tt-dados-gerais'][0]
    } catch (error) {}

    return null
  }

  getItens = () => {
    const { invoiceData } = this.state

    if (!invoiceData) return null

    try {
      return invoiceData['tt-itens']
    } catch (error) {}

    return null
  }

  open = () => this.modalRef && this.modalRef.open()

  close = () => this.modalRef && this.modalRef.close()

  render() {
    const { invoiceData, ticketData } = this.state
    const dadosGerais = this.getDadosGerias()
    const itens = this.getItens()
    const { isAdmLogin } = ClienteService.localEntrega;
    const { segmento } = ClienteService.localEntrega;

    if (segmento === "CONTASIM") {
    if (!dadosGerais)
      return (
        <Modal
          shown={false}
          ref={ref => this.modalRef = ref}
          className="consumption-modal"
          title="Opa!"
          closeButton={true}>
          <br />
          <p>Para visualizar mais detalhes dessa fatura, entre em contato com a nossa central de atendimento.</p>

          <button
            className="link block"
            onClick={() => redirect(this.props, '/help')}>
            Fale conosco
          </button>
        </Modal>
      )

    return (
      <Modal
        shown={false}
        ref={ref => this.modalRef = ref}
        className="consumption-modal"
        closeButton={true}>
        <div className="content">
          {InvoiceService.getInvoiceBadge(invoiceData)}
          <br />

          <small>Período do Consumo</small>
          <p className="value">{this.getInterval()}</p>

          <small>Leitura inicial-final</small>
          <p className="value">{weightFormat(dadosGerais['leitura-ant'], '')} - {weightFormat(dadosGerais['leitura-atual'], '')}</p>

          <small>Consumo em m³</small>
          <p className="value">{weightFormat(dadosGerais['metros3'], 'm³')}</p>

          <small>Fator de conversão</small>
          <p className="value">{weightFormat(dadosGerais['fator-conv'], '')}</p>

          <small>Consumo em Kg</small>
          <p className="value">{weightFormat(dadosGerais['kg'])}</p>

          <small>Valor do kg do gás</small>
          <p className="value blue">{moneyFormat(dadosGerais['vl-unit'])}</p>

          {(itens || []).map((item, idx) => (
            <span key={`consumption-modal-tt-item-${idx}`}>
              <small>{item.descricao}</small>
              <p className="value blue">{moneyFormat(item.valor)}</p>
            </span>))}

          <small>Valor da fatura</small>
          <p className="value blue">{moneyFormat(dadosGerais['valor-total'])}</p>
        </div>

        <footer className="text-center">
          <button
            onClick={() => this.props.onShowInvoice && this.props.onShowInvoice(invoiceData)}
            className="link">
            {InvoiceService.getInvoiceStatus(invoiceData) !== 'paid'
              ? 'Ver boleto'
              : 'Ver nota fiscal'}
          </button><br/>
          {!isAdmLogin && <button
            className="link secondary"
            onClick={() => redirect(this.props, '/contestation', { invoiceData })}>
            Dúvidas com o seu consumo?
          </button>}
        </footer>
      </Modal>
    )
  }

    if (segmento === "INDUSTRIAL") {
      if (!ticketData)
        return (
          <Modal
            shown={false}
            ref={ref => this.modalRef = ref}
            className="consumption-modal"
            title="Opa!"
            closeButton={true}>
            <br />
            <p>Para visualizar mais detalhes dessa fatura, entre em contato com a nossa central de atendimento.</p>

            <button
              className="link block"
              onClick={() => redirect(this.props, '/help')}>
              Fale conosco
            </button>
          </Modal>
        )

      return (
        <Modal
          shown={false}
          ref={ref => this.modalRef = ref}
          className="consumption-modal"
          closeButton={true}>
          <div className="content">
            {TicketService.getTicketBadge(ticketData)}
            <br />

            <small>Número da nota fiscal</small>
            <p className="value">{(ticketData['nr-nota-fis'])}</p>

            <small>Série da nota fiscal</small>
            <p className="value">{(ticketData['serie'])}</p>

            <small>Data de emissão</small>
            <p className="value">{moment(ticketData['dt-emis-nota']).format("DD/MM/YYYY")}</p>

            <small>Data de vencimento</small>
            <p className="value">{TicketService.getConsumptionValues(ticketData, "vencimento")}</p>

            <small>Nome do(s) item/itens</small>
            <p className="value blue">{TicketService.getConsumptionValues(ticketData, "itens")}</p>

            <small>Quantidade</small>
            <p className="value blue">{TicketService.getConsumptionValues(ticketData, "quantidade")}
            {TicketService.getConsumptionValues(ticketData, "un") === "Un" ?
            " unidades"
            : ""}</p>

            <small>Valor unitário</small>
            <p className="value">{moneyFormat(TicketService.getConsumptionValues(ticketData, "unitario"))}</p>

            <small>Valor total</small>
            <p className="value blue">{moneyFormat(ticketData['vl-tot-nota'])}</p>

            <small>Local de entrega</small>
            <p className="value">{ticketData['endereco']}</p>

            <small>Número de romaneio</small>
            <p className="value">{ticketData['nr-romaneio']}</p>
          </div>

          <footer className="text-center">
            <button
              onClick={() => this.props.onShowInvoice && this.props.onShowInvoice(invoiceData)}
              className="link">
              {TicketService.getTicketStatus(ticketData) !== 'paid'
                ? 'Ver boleto'
                : 'Ver nota fiscal'}
            </button><br/>
          </footer>
        </Modal>
      )
  }
}
}