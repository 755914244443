import React from 'react'
import Switch from 'react-switch'

import './Switch.scss'

export default props => (
  <Switch
    checkedIcon={false}
    uncheckedIcon={false}
    height={10}
    width={20}
    handleDiameter={14}
    onHandleColor="#00c4ff"
    offHandleColor="#999"
    onColor="#036994"
    offColor="#BBB"
    className="toggle-switch"
    data-testid={props['data-testid']}
    {...props} />
)