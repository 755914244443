import React from 'react'
import { Link } from 'react-router-dom'

import Page from '../../components/Page/Page'
import PageCenterContent from '../../components/PageCenterContent/PageCenterContent'
import PageHeaderImage from '../../components/PageHeaderImage/PageHeaderImage'

import TechnicianImage from '../../assets/illustrations/technician.png'

import './SaveTime.scss'

export default props => (
  <Page
    history={props.history}
    className="save-time-page">
    <PageCenterContent>
      <PageHeaderImage src={TechnicianImage} />

      <h1>Precisa de alguma instalação em seu imóvel?</h1>
      <p>Não deixe para depois. Peça já!</p>

      <Link className="block" to="/services">Ver serviços</Link><br />

      <p>Ou, se preferir, você pode contrata-los mais tarde.</p>

      <Link className="block" to="/home">Me leve pra home</Link><br />
    </PageCenterContent>
  </Page>
)