import React from 'react';

export default class InvoiceService {

  static getInvoiceStatus(invoiceData) {

    if (!invoiceData) return null

    if (invoiceData['val_sdo_tit_acr'] === 0) {
      return 'paid'
    } else {
      const dateVencimento = new Date(`${invoiceData['dat_vencto_tit_acr']} 00:00:00`)
      const dateNow = new Date()
      dateNow.setHours(0)
      dateNow.setMinutes(0)
      dateNow.setSeconds(0)
      dateNow.setMilliseconds(0)

      if (dateVencimento < dateNow)
        return 'outdated'
      else
        return 'open'
    }
  }

  static getInvoiceBadge(invoiceData) {
    return {
      'paid': <span className="badge success">Pago</span>,
      'outdated': <span className="badge danger">Vencido</span>,
      'open': <span className="badge warning">Aguardando Pagamento</span>
    }[InvoiceService.getInvoiceStatus(invoiceData)]
  }

  static getValidInvoices(invoices) {
    if (!invoices) return null

    return invoices.filter(i => i['tt-dados-gerais'] !== undefined)
  }

  static getTicketExpiration(invoiceData) {
    if (!invoiceData) return null

    return !!invoiceData['dt-cancela']
  }
}