import HttpHandler from './HttpHandler';

const CONSUMER_KEY = 'Kr2AD_enFrDYwTpCv6OWFv9fvbEa'
const CONSUMER_SECRET = 'KUeYHYxXYuwNv2MGBmu4bylrgswa'
export const API_URL = 'https://gateway.apiserpro.serpro.gov.br';

class CpfApi {
	constructor(dependencies = { HttpHandler }) {
		const baseUrl = API_URL;
		this.api = new dependencies.HttpHandler({ baseUrl });
  }

  get token() {
    return this.api.post({
      url: `/token`,
      headers: this.getHeaders({
        'Authorization': `Basic ${btoa(`${CONSUMER_KEY}:${CONSUMER_SECRET}`)}`
      }),
      body: 'grant_type=client_credentials'
    }).then(({ data: { access_token } }) => access_token)

  }

  getHeaders = headers => ({
    ...headers,
    "Content-type": "application/x-www-form-urlencoded"
  })

  formatDate = date => `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`

  async validateCpf(cpf) {
    const token = await this.token

    if (token)
      return this.api.get({
        url: `/consulta-cpf-df/v1/cpf/${cpf}`,
        headers: {
          'Accept': "application/json",
          'Authorization': `Bearer ${token}`
        },
      }).then(({ data }) => data)
  }

  async validateCnpj(cnpj) {
    const token = await this.token

    if (token)
      return this.api.get({
        url: `/consulta-cnpj-df/v2/basica/${cnpj}`,
        headers: {
          'Accept': "application/json",
          'Authorization': `Bearer ${token}`
        },
      }).then(({ data }) => data)
  }

}

const consigazReportApi = new CpfApi();
export default Object.freeze(consigazReportApi);
