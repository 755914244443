import React from 'react'
import { CSSTransition } from 'react-transition-group'

import './Sidebar.scss'

export default props => (
  <CSSTransition
    in={props.in || undefined}
    classNames="sidebar"
    timeout={300}
    unmountOnExit>
    <div className="sidebar">
      <ul>
        {props.options.map((opt, idx) =>
          <li key={`sidebar-option-${idx}`}>
            <a
              href={opt.href || '#'}
              target={opt.target || ''}
              onClick={opt.onClick}
              className="clear">
              {opt.label}
            </a>
          </li>
        )}
      </ul>
    </div>
  </CSSTransition>
)