import React, { Component } from "react";

import "./HomeTemplate.scss";

import apiContext from "../../api";

import ClienteService from "../../services/cliente";
import CondominioService from "../../services/condominio";

import Page, { redirect } from "../../components/Page/Page";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import Select from "../../components/Select/Select";
import Sidebar from "../../components/Sidebar/Sidebar";
import {
  IconClose,
  IconMenu,
  IconAdmin,
  IconHomeSign,
  IconDollar,
  IconHomeOutline,
  IconDelivery,
} from "../../components/Icons/Icons";

import HomeHeaderImage from "../../assets/home-header.svg";
import UserImage from "../../assets/user.svg";

export { redirect } from "../../components/Page/Page";

class HomeTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarIn: false,
      redirectState: null,
      selectedCondominio: CondominioService.selectAll
        ? "all"
        : CondominioService.selected || {},
      activeTab: props.activeTab,
    };
    if (!ClienteService.isLoggedIn) redirect(this.props, "/");
  }

  static contextType = apiContext;

  getAddressKey = (addr) =>
    `${addr["cod-condominio"]}-${addr["local-entrega"]}`;

  componentDidMount() {
    if (!ClienteService.isLoggedIn) redirect(this.props, "/");
  }

  renderAdmLogin() {
    const { isAdmLogin } = ClienteService.localEntrega || {};
    if (isAdmLogin) return <small>⬤ Login de suporte</small>;
  }

  handleAddressChange = (e) => {
    const { ClienteEntrRetorno: addressList } =
      ClienteService.localEntrega || {};
    const cod = e.target.value;

    if (cod === "all") {
      CondominioService.selectAll = true;
      this.context.ConsigazApi.clearMemoize();
      window.location.reload();
    } else {
      const filter = addressList.filter(
        (addr) => this.getAddressKey(addr) === cod
      );

      if (filter.length) {
        CondominioService.selected = filter[0];
        this.context.ConsigazApi.clearMemoize();
        window.location.reload();
      }
    }
  };

  render() {
    const { sidebarIn, activeTab, selectedCondominio } = this.state;
    const {
      segmento,
      [`nome-emit`]: name,
      ClienteEntrRetorno: addressList,
      isAdmLogin,
    } = ClienteService.localEntrega || {};

    let nomeContato;
    if (segmento === "INDUSTRIAL")
      nomeContato = (ClienteService.industrialContato || {}).name;

    const addressOptions = [
      ...(segmento === "INDUSTRIAL"
        ? [{ title: "Todos os endereços", value: "all" }]
        : []),
      ...(addressList || []).map((address) => ({
        title: address["endereco"],
        value: this.getAddressKey(address),
      })),
    ];

    const sidebarOpts = [
      {
        label: "Home",
        onClick: () => redirect(this.props, "/home"),
      },
      {
        label: "Dados Pessoais",
        onClick: () => redirect(this.props, "/personal-data"),
      },
      (segmento === "INDUSTRIAL" && {
        label: "Contatos da empresa",
        onClick: () => redirect(this.props, "/industrial-contact"),
      }) ||
        null,
      (segmento === "INDUSTRIAL" && {
        label: "Indicação de Clientes",
        onClick: () => redirect(this.props, "/recommendation"),
      }) ||
        null,
      {
        label: "Meus Endereços",
        onClick: () => redirect(this.props, "/address-list"),
      },
      (segmento !== "INDUSTRIAL" && {
        label: "Lista de Protocolos",
        onClick: () =>
          redirect(this.props, "/home-services", { tab: "protocol" }),
      }) ||
        null,
      {
        label: "Ajuda",
        onClick: () => redirect(this.props, "/help"),
      },
      {
        label: "Termos de Uso",
        href: "/termo.html",
        target: "blank",
      },
      {
        label: "Sair",
        onClick: () =>
          ClienteService.logout().then(() => redirect(this.props, "/")),
      },
    ].filter((e) => e);

    const sidebarOptsAdm = [
      {
        label: "Home",
        onClick: () => redirect(this.props, "/home"),
      },
      {
        label: "Dados Pessoais",
        onClick: () => redirect(this.props, "/personal-data"),
      },
      {
        label: "Meus Endereços",
        onClick: () => redirect(this.props, "/address-list"),
      },
      (segmento !== "INDUSTRIAL" && {
        label: "Lista de Protocolos",
        onClick: () =>
          redirect(this.props, "/home-services", { tab: "protocol" }),
      }) ||
        null,
      {
        label: "Sair",
        onClick: () =>
          ClienteService.logout().then(() => redirect(this.props, "/")),
      },
    ].filter((e) => e);

    const navbarTabs = [
      {
        label: "Home",
        icon: IconHomeOutline,
        onClick: () => redirect(this.props, "/home"),
      },
      {
        label: "Consumo",
        icon: IconHomeSign,
        onClick: () => redirect(this.props, "/consumption"),
      },
      {
        label: "Financeiro",
        icon: IconDollar,
        onClick: () => redirect(this.props, "/finances"),
      },
      {
        label: "Serviços",
        icon: IconAdmin,
        onClick: () => redirect(this.props, "/home-services"),
      },
      segmento === "INDUSTRIAL" && {
        label: "Entregas",
        icon: IconDelivery,
        onClick: () => redirect(this.props, "/home-delivery"),
      },
    ];

    return (
      <Page history={this.props.history} whiteBackground={true}>
        <div className={`home-template ${this.props.className || ""}`}>
          <Sidebar
            in={sidebarIn}
            options={isAdmLogin ? sidebarOptsAdm : sidebarOpts}
          />
          <span
            className="header-bg"
            style={{ backgroundImage: `url(${HomeHeaderImage})` }}
          ></span>
          <div className="header">
            <div className="wellcome">
              {sidebarIn ? (
                <IconClose
                  color="#fff"
                  data-testid="sidebar-toggle-button"
                  className="menu-icon"
                  size={30}
                  onClick={() => this.setState({ sidebarIn: !sidebarIn })}
                />
              ) : (
                <IconMenu
                  color="#fff"
                  data-testid="sidebar-toggle-button"
                  className="menu-icon"
                  size={30}
                  onClick={() => this.setState({ sidebarIn: !sidebarIn })}
                />
              )}

              <p className="title">
                {this.renderAdmLogin()}
                Olá, {(nomeContato || name || "").split(" ")[0]}
              </p>
              <Select
                options={addressOptions}
                value={
                  selectedCondominio === "all"
                    ? selectedCondominio
                    : this.getAddressKey(selectedCondominio)
                }
                onChange={this.handleAddressChange}
                style={{
                  position: "relative",
                  zIndex: "100",
                  height: "14px",
                  paddingRight: "24px",
                }}
              />
            </div>
            <div className="navbar">
              <NavigationBar
                tabs={navbarTabs}
                className="top-nav"
                activeTab={navbarTabs.reduce((reduced, tab, idx) => {
                  if (tab.label === activeTab) return idx;
                  else return reduced !== undefined ? reduced : undefined;
                }, undefined)}
                activeStyle="fill"
              />
              <img src={UserImage} alt="user" />
            </div>
          </div>
          <div className="content">{this.props.children}</div>
        </div>
      </Page>
    );
  }
}

export default HomeTemplate;
