import React from "react";

// import PrivacyPolicyComponent from "../../components/PrivacyPolicy/PrivacyPolicy";

export default ({ location }) => {
  window.location.href = "/termo.html"

  return (
    <div />
  );
};
