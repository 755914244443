import React, { Component } from 'react'

import ClienteService from '../../services/cliente'

import Page, { redirect } from '../../components/Page/Page'
import PageCenterContent from '../../components/PageCenterContent/PageCenterContent'
import { IconInfo } from '../../components/Icons/Icons'
import Modal from '../../components/Modal/Modal'

import BackgroundImage from '../../assets/background.jpg'
import ProfileImage from '../../assets/illustrations/client-code.png'
import { bindActionCreators } from 'redux'
import { toastOperations } from '../../state/ducks/toast'
import { connect } from 'react-redux'


class WellcomeAgain extends Component {

  constructor(props){
    super(props)

    this.state = {
      title: 'Bem vindo novamente!',
      description: 'Felizes em tê-lo como cliente. Digite seu código de cliente para entrar no seu perfil.',
      codCliente: '',
      haveSubmited: false,
      isCodClienteValid: false,
      cliente: undefined
    }

    if (props.location && props.location.state) {
      const locationState = props.location.state

      if (locationState.from === '/existent-client' || locationState.haveOpenedTheApp === false || locationState.haveOpenedTheApp === null)
        Object.assign(this.state, {
          title: "Só mais um passo!",
          description: 'Insira o Código do Cliente de um dos seus imóveis e carregaremos todos os seus registros no aplicativo.',
          cliente: locationState.cliente
        })
      else if (locationState.from === '/wellcome')
        Object.assign(this.state, {
          cliente: locationState.cliente
        })
      else
        redirect(props, '/')
    }
  }

  handleAccept = () => {
    redirect(this.props, '/home')
  }

  handleSubmit = () => {
    if (this.state.codCliente) {
      if (parseInt(this.state.codCliente) === this.state.cliente['cod-emitente']) {
        const { segmento } = this.state.cliente;
        if (segmento === "INDUSTRIAL") {
            redirect(this.props, "/new-industrial-client", {industrialClientInfos: this.state});
        } else {
            ClienteService.localEntrega = this.state.cliente
            redirect(this.props, '/address-select')
        }
      } else {
        // The code provided is incorrect
        this.setState({
          haveSubmited: true,
          isCodClienteValid: false
        }, () => this.errorModal && this.errorModal.setState({ shown: true }))
      }
    } else {
      // No code priveded
      this.setState({
        haveSubmited: true,
        isCodClienteValid: false
      }, () => this.props.showToast('Ops', 'Você precisa informar um código de cliente'))
    }



    // if (this.state.codCliente) {
    //     const { segmento } = this.state.cliente;
    //     if (segmento === "INDUSTRIAL"){
    //         if (parseInt(this.state.codCliente) === this.state.cliente['cod-emitente']) {
    //             redirect(this.props, "/new-industrial-client")
    //         } else {
    //             // The code provided is incorrect
    //             this.setState({
    //             haveSubmited: true,
    //             isCodClienteValid: false
    //             }, () => this.errorModal && this.errorModal.setState({ shown: true }))
    //         }
    //     } else {
    //         if (parseInt(this.state.codCliente) === this.state.cliente['cod-emitente']) {
    //             ClienteService.localEntrega = this.state.cliente
    //             const { segmento } = ClienteService.localEntrega
    //             redirect(this.props, '/address-select')
    //         } else {
    //             // The code provided is incorrect
    //             this.setState({
    //             haveSubmited: true,
    //             isCodClienteValid: false
    //             }, () => this.errorModal && this.errorModal.setState({ shown: true }))
    //         }
    //     }
    // } else {
    //     // No code priveded
    //     this.setState({
    //     haveSubmited: true,
    //     isCodClienteValid: false
    //     }, () => this.props.showToast('Ops', 'Você precisa informar um código de cliente'))
    // }



    // if (this.state.codCliente) {
    //   if (parseInt(this.state.codCliente) === this.state.cliente['cod-emitente']) {
    //     ClienteService.localEntrega = this.state.cliente
    //     const { segmento } = ClienteService.localEntrega;
    //     if (segmento === "INDUSTRIAL")
    //       redirect(this.props, "/new-industrial-client");
    //     else
    //       redirect(this.props, '/address-select')
    //   } else {
    //     // The code provided is incorrect
    //     this.setState({
    //       haveSubmited: true,
    //       isCodClienteValid: false
    //     }, () => this.errorModal && this.errorModal.setState({ shown: true }))
    //   }
    // } else {
    //   // No code priveded
    //   this.setState({
    //     haveSubmited: true,
    //     isCodClienteValid: false
    //   }, () => this.props.showToast('Ops', 'Você precisa informar um código de cliente'))
    // }
  }

  render () {
    return (
      <Page
        history={this.props.history}
        style={{
          background: `var(--dark-blue) url(${BackgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundBlendMode: 'multiply'
        }}>
        <PageCenterContent>
          <h1>{this.state.title}</h1>
          <p>{this.state.description}</p>

          <input
            type="text"
            className={`block ${!this.state.isCodClienteValid && this.state.haveSubmited ? 'error' : ''}`}
            placeholder="Código de cliente"
            value={this.state.codCliente}
            onChange={e => this.setState({ codCliente: (e.target.value.match(/\d+/g) || []).join('') })}
            onKeyPress={e => e.charCode === 13 && this.handleSubmit()} /><br />

          <button
            className="block"
            // onClick={() => this.termsModal && this.termsModal.setState({ shown: true })}>
            onClick={this.handleSubmit}>
            Entrar
          </button><br />

          <button
            className="link white"
            style={{ color: '#2894f7' }}
            onClick={() => this.helpModal && this.helpModal.setState({ shown: true })}>
            <IconInfo color="#2894f7" size={'1.5em'} style={{
              marginRight: '.5rem',
              marginBottom: '-.4em'
            }}/>
            Encontrar meu código de cliente
          </button>
        </PageCenterContent>



        <Modal
          ref={ref => this.helpModal = ref}
          title="Localize seu código!"
          shown={false}
          confirmButton={true}
          confirmButtonText="OK"
          imgSrc={ProfileImage}>
          <p>Seu código de cliente está localizado no canto superior esquerdo do boleto.</p>
        </Modal>

        <Modal
          ref={ref => this.errorModal = ref}
          title="Ops!"
          shown={false}
          confirmButton={true}
          confirmButtonText="OK"
          imgSrc={ProfileImage}>
          <p>Você inseriu o código do cliente errado, veja abaixo onde você pode achá-lo.</p>
        </Modal>

      </Page>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    showToast: toastOperations.show
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(WellcomeAgain)